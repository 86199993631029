//////////////////////////////
// 技能向上セミナー /seminar/
//////////////////////////////
.seminar {
    .lowerMiddleTtl {
        margin-bottom: 35px;
        @include media() {
            font-size: 2rem;
            margin-bottom: 31px;
        }
    }
    .lowerSmallTtl {
        margin-bottom: 22px;
        @include media() {
            font-size: 1.8rem;
        }
    }
    &__inner {
        padding-bottom: 204px;
        padding-top: 68px;
        @include media() {
            padding-top: 31px;
            padding-bottom: 80px;
        }
    }
    &__lead {
        font-size: 1.8rem;
        line-height: 2;
        margin-bottom: 50px;
        @include media() {
            font-size: 1.6rem;
            line-height: 2;
            margin-bottom: 35px;
        }
    }
    &__unit {
        &:not(:last-of-type) {
            margin-bottom: 53px;
            @include media() {
                margin-bottom: 48px;
            }
        }
    }
    &__fig {
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 80px;
        @include media() {
            border-radius: 6px;
            height: 300px;
            margin-bottom: 51px;
        }
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    &__group {
        border: 4px solid #f8f8f8;
        border-radius: 6px;
        padding: 35px 46px 40px;
        @include media() {
            padding: 31px 20px 40px 17px;
        }
        &:not(:last-of-type) {
            margin-bottom: 60px;
            @include media() {
                margin-bottom: 30px;
            }
        }
        &:last-of-type {
            margin-bottom: 74px;
            @include media() {
                margin-bottom: 55px;
            }
        }
    }
    &__detail {
        // border: 4px solid #f8f8f8;
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(#000, 0.08);
        padding: 32px 75px 37px 56px;
        @include media() {
            padding: 20px 10px 37px 16px;
        }
        p{
            font-size: 1.6rem;
            line-height: 2;
            margin-bottom: 6px;
            padding-left: 1.2em;
            position: relative;
            span{
                font-size: 1.6rem !important;
                @include media() {
                    font-size: 1.5rem !important;
                    line-height: 2.1333333333 !important;
                }
            }
            &:before {
                background-color: $blue;
                border-radius: 9999px;
                content: "";
                display: block;
                height: 8px;
                left: 0;
                position: absolute;
                top: 13px;
                width: 8px;
            }
            @include media() {
                font-size: 1.5rem;
                line-height: 2.1333333333;
            }
        }
    }
    &__detailHead{
        font-size: 1.3em;
        font-weight: bold;
        color: #ff0000;
        margin-bottom: .5em;
    }
    &__addTxt {
        font-size: 1.8rem;
        line-height: 1.7777777778;
        @include media() {
            font-size: 1.6rem;
            line-height: 2;
        }
    }
    &__tel {
        transition: all 0.2s;
        &:hover {
            opacity: 0.5;
        }
    }
    &__mail {
        color: $blue;
        text-decoration: underline;
        transition: all 0.2s;
        &:hover {
            opacity: 0.5;
            text-decoration: none;
        }
    }
    &__att {
        color: $red;
        font-size: 1.6rem;
        line-height: 2;
        margin-top: 20px;
    }
    &__txt {
        font-size: 1.6rem;
        line-height: 2;
        margin-bottom: 6px;
        padding-left: 1.2em;
        position: relative;
        &:before {
            background-color: $blue;
            border-radius: 9999px;
            content: "";
            display: block;
            height: 8px;
            left: 0;
            position: absolute;
            top: 13px;
            width: 8px;
        }
        @include media() {
            font-size: 1.5rem;
            line-height: 2.1333333333;
        }
    }
    &__txtRed {
        color: $red;
    }
    &__item {
        &:not(:last-of-type) {
            margin-bottom: 7px;
            @include media() {
                margin-bottom: 6px;
            }
        }
    }
    &__detailLink {
        color: $blue;
        text-decoration: underline;
        transition: all 0.2s;
        &:hover {
            opacity: 0.5;
            text-decoration: none;
        }
    }
    &__link {
        display: inline-block;
        font-size: 1.6rem;
        letter-spacing: 0.05em;
        line-height: 2;
        padding-left: 1em;
        position: relative;
        text-decoration: underline;
        transition: all 0.2s;
        &:before {
            background-color: $blue;
            border-radius: 9999px;
            content: "";
            display: block;
            height: 5px;
            left: 0;
            position: absolute;
            top: 13px;
            width: 5px;
        }
        &:hover {
            opacity: 0.5;
            text-decoration: none;
        }
    }
    @include media() {
        font-size: 1.5rem;
        line-height: 2.1333333333;
    }
}

//詳細ページ
.seminarDet {
    .lowerMiddleTtl {
        margin-bottom: 46px;
        @include media() {
            font-size: 2rem;
            margin-bottom: 14px;
        }
    }
    .moreBtn {
        @include media() {
            display: flex;
            justify-content: center;
            padding: 19px 70px 19px 70px;
            width: 100%;
            &:before {
                left: 28px;
            }
            &:after {
                left: 36px;
            }
        }
    }
    &__inner {
        padding-bottom: 208px;
        padding-top: 68px;
        @include media() {
            padding-top: 40px;
            padding-bottom: 80px;
        }
    }
    &__seminarDetTtl {
        font-size: 2.8rem;
        font-weight: bold;
        margin-bottom: 34px;
        @include media() {
            font-size: 1.8rem;
            line-height: 2;
            margin-bottom: 23px;
        }
    }
    &__list {
        margin-bottom: 60px;
        @include media() {
            margin-bottom: 50px;
        }
    }
    &__item {
        align-items: flex-start;
        display: flex;
        &:not(:last-of-type) {
            margin-bottom: 25px;
            @include media() {
                margin-bottom: 12px;
            }
        }
        &_type_target,
        &_type_text,
        &_type_contents,
        &_type_detail {
            @include media () {
                flex-direction: column;
                .seminarDet__cat {
                    margin-bottom: 15px;
                }
                .seminarDet__contents {
                    padding: 0;
                }
            }
        }
    }
    &__cat {
        align-items: center;
        background-color: $blue;
        border-radius: 9999px;
        color: #fff;
        display: flex;
        font-size: 1.8rem;
        height: 36px;
        justify-content: center;
        margin-top: 2px;
        min-width: 134px;
        width: 134px;
        @include media() {
            font-size: 1.4rem;
            min-width: 90px;
            width: 90px;
        }
    }
    &__contents {
        font-size: 1.8rem;
        line-height: 1.7777777778;
        padding-left: 23px;
        @include media() {
            font-size: 1.6rem;
            line-height: 2;
            padding-left: 13px;
        }
        &_type_bold {
            font-size: 2.4rem;
            font-weight: bold;
            @include media() {
                font-size: 2.2rem;
                line-height: 1.6363636364;
            }
        }
    }
    &__link {
        transition: all 0.2s;
        text-decoration: underline;
        &:hover {
            opacity: 0.5;
            text-decoration: none;
        }
    }
}
