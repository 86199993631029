//////////////////////////////
// 後援会・同窓会 /association/
//////////////////////////////
.association {
    .lowerMiddleTtl {
        margin-bottom: 20px;
        @include media() {
            font-size: 2rem;
            margin-bottom: 30px;
        }
    }
    .moreBtn {
        padding: 23px 78px 23px 88px;
        @include media() {
            padding: 19px 22px 19px 68px;
            width: 100%;
        }
        &::before {
            left: 27px;
            top: 50%;
            @include media() {
                left: 20px;
            }
        }
        &::after {
            left: 35px;
            @include media() {
                left: 28px;
            }
        }
    }
    &__inner {
        padding-bottom: 252px;
        padding-top: 100px;
        @include media() {
            padding-bottom: 80px;
            padding-top: 40px;
        }
    }
    &__unit {
        & + & {
            margin-top: 70px;
            @include media() {
                margin-top: 60px;
            }
            &_has_hr {
                border-top: 1px solid #e6e6e6;
                margin-top: 110px;
                padding-top: 80px;
                @include media() {
                    margin-top: 60px;
                    padding-top: 60px;
                }
            }
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        margin: 10px 0 0;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__list {
        margin: 10px 0 0;
    }
    &__item {
        font-size: 1.8rem;
        line-height: 2;
        padding-left: 20px;
        position: relative;
        @include media() {
            font-size: 1.6rem;
        }
        &::before {
            background: $blue;
            border-radius: 50%;
            content: "";
            height: 5px;
            left: 0;
            position: absolute;
            top: 1em;
            transform: translateY(-50%);
            width: 5px;
        }
    }
    &__btn {
        margin: 25px 0 0;
        @include media() {
            margin: 35px 0 0;
        }
    }

    &__group {
        border: 5px solid #f2f2f2;
        border-radius: 6px;
        margin: 80px 0 0;
        padding: 38px 65px 48px;
        @include media() {
            border-width: 3px;
            margin: 40px 0 0;
            padding: 30px 17px 40px;
        }
    }
    &__grTtl {
        margin-bottom: 10px;
        margin-left: -25px;
        @include media() {
            margin-bottom: 20px;
            margin-left: 0;
        }
    }
    &__grTxt {
        font-size: 1.8rem;
        line-height: 2;
        a {
            color: $blue;
            text-decoration: underline;
            transition: .2s;
            &:hover {
                opacity: 0.5;
                text-decoration: underline transparent;
            }
        }
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__grAnnotation {
        font-size: 1.4rem;
        line-height: 2;
        margin: 10px 0 0;
    }
}
