//////////////////////////////
// 20周年記念事業 /anniversary/
//////////////////////////////
.anv {
    &__inner {
        padding-bottom: 214px;
        padding-top: 94px;
        @include media() {
            padding-bottom: 184px;
            padding-top: 40px;
        }
    }
    &__unit {
        &:not(:last-of-type) {
            border-bottom: 1px solid #e6e6e6;
        }
        &:nth-of-type(1) {
            padding-bottom: 107px;
            @include media() {
                padding-bottom: 44px;
            }
        }
        &:nth-of-type(2) {
            padding-bottom: 58px;
            padding-top: 78px;
            @include media() {
                padding-bottom: 25px;
                padding-top: 54px;
            }
        }
        &:nth-of-type(3) {
            padding-bottom: 139px;
            padding-top: 126px;
            @include media() {
                padding-bottom: 51px;
                padding-top: 62px;
            }
            .anv__ttl {
                margin-bottom: 29px;
                @include media() {
                    margin-bottom: 27px;
                }
            }
            .anv__txtWrap {
                margin-bottom: 47px;
                @include media() {
                    margin-bottom: 14px;
                }
            }
        }
        &:nth-of-type(4) {
            padding-bottom: 139px;
            padding-top: 126px;
            @include media() {
                padding-bottom: 170px;
                padding-top: 54px;
            }
            .anv__ttl {
                margin-bottom: 38px;
                @include media() {
                    margin-bottom: 28px;
                }
            }
        }
        &:nth-of-type(5) {
            padding-top: 126px;
            @include media() {
                padding-top: 54px;
            }
        }
    }
    &__ttl {
        margin-bottom: 52px;
        @include media() {
            margin-bottom: 25px;
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
            line-height: 2.25;
        }
    }
    &__imgWrap {
        max-width: 70%;
        margin: 60px auto;
        @include media("tab") {
            max-width: 90%;
        }
        @include media("sp") {
            margin: 28px auto;
            max-width: 100%;
        }
    }
    &__supWrap {
        display: flex;
        @include media() {
            flex-direction: column;
        }
    }
    &__supportList {
        &:first-of-type {
            width: 52.4%;
            @include media() {
                width: 100%;
            }
        }
        &:last-of-type {
            width: 47.6%;
            @include media() {
                width: 100%;
            }
        }
    }
    &__supportItem {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.4rem;
            line-height: 2.2857142857;
        }
    }
    &__flex {
        display: flex;
        justify-content: space-between;
        @include media() {
            flex-direction: column;
            position: relative;
        }
    }
    &__maTxt {
        font-size: 1.8rem;
        line-height: 2;
        margin-bottom: 45px;
        @include media() {
            font-size: 1.6rem;
            margin-bottom: 26px;
        }
    }
    &__maLink {
        @include media("sp") {
            &.moreBtn {
                bottom: -100px;
                left: 50%;
                position: absolute;
                padding: 19px clamp(30px, 0.143rem + 8.93vw, 70px) 19px clamp(90px, 7.571rem + 4.46vw, 110px);
                transform: translateX(-50%);
                white-space: nowrap;
                width: 340px;
            }
        }
        @include media("spm") {
            &.moreBtn {
                width: 100%;
            }
        }
    }
    &__pdfWrap {
        max-width: 351px;
        margin: 0 164px 0 40px;
        @include media("lt") {
            margin: 0 40px;
        }
        @include media("sp") {
            margin: 0px auto;
            max-width: 480px;
        }
    }
    &__pdfLink {
        transition: all 0.2s;
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
    &__contents {
        width: 48%;
        @include media() {
            width: 100%;
        }
    }
    &__iframeWrap {
        margin-bottom: 28px;
        padding-top: 56.2711%;
        position: relative;
        width: 100%;
        iframe {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
    &__time {
        color: $blue;
        display: block;
        font-family: $mont;
        font-size: 1.4rem;
        font-weight: $semiBold;
        letter-spacing: 0.1em;
        margin-bottom: 10px;
    }
    &__videoTtl {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 12px;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__videoTxt {
        font-size: 1.6rem;
        line-height: 2.25;
        @include media() {
            font-size: 1.4rem;
        }
        
    }
}
