//////////////////////////////
// サイトマップ /sitemap/
//////////////////////////////
.sitemap {
    .lowerMiddleTtl {
        margin-bottom: 20px;
        @include media() {
            font-size: 2rem;
            margin-bottom: 30px;
        }
    }
    &__inner {
        padding-bottom: 200px;
        padding-top: 70px;
        @include media() {
            padding-bottom: 100px;
            padding-top: 50px;
        }
    }
    &__unit {
        & + & {
            margin-top: 60px;
            @include media() {
                margin-top: 40px;
            }
        }
    }
    &__list {
        column-gap: 60px;
        display: flex;
        flex-wrap: wrap;
        row-gap: 15px;
        @include media() {
            column-gap: 30px;
            row-gap: 5px;
        }
        &_has_children {
            justify-content: space-between;
            @include media() {
                justify-content: flex-start;
            }
        }
        &_has_children & {
            display: block;
        }
    }
    &__item {
        font-size: 1.6rem;
        line-height: 2;
        padding-left: 20px;
        position: relative;
        @include media() {
            font-size: 1.5rem;
        }
        &::before {
            background: $blue;
            border-radius: 50%;
            content: "";
            height: 5px;
            left: 0;
            position: absolute;
            top: 1em;
            transform: translateY(-50%);
            width: 5px;
        }
    }
    &__link {
        color: $blue;
        text-decoration: underline;
        transition: .2s;
        &:hover {
            opacity: 0.5;
            text-decoration: underline transparent;
        }
    }
    &__btn {
        box-shadow: 0 2px 4px rgba(#000, 0.08);
        margin: 80px 0 0;
        @include media() {
            margin: 60px 0 0;
        }
    }


    &__group {
        border: 5px solid #f8f8f8;
        border-radius: 6px;
        margin: 40px 0 0;
        padding: 30px 45px;
        @include media() {
            border-width: 3px;
            margin: 30px 0 0;
            padding: 20px 17px;
        }
    }
}
