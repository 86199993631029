@charset "UTF-8";
/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: http://meyerweb.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {
  display: block;
}

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects */
/* if you don't have full control over `img` tags (if you have to overcome attributes), consider adding height: auto */
img,
object,
embed {
  max-width: 100%;
}

/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll;
}

/* we use a lot of ULs that aren't bulleted.
	you'll have to restore the bullets within content,
	which is fine because they're probably customized anyway */
ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */
}

input[type=radio] {
  vertical-align: text-bottom;
}

input[type=checkbox] {
  vertical-align: bottom;
}

.ie7 input[type=checkbox] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select, input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td, td img {
  vertical-align: top;
}

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0;
}

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

@media screen and (max-width: 768px) {
  .pagination {
    margin-bottom: 36px;
  }
}
.pagination__list {
  align-items: center;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .pagination__list {
    flex-wrap: wrap;
    position: relative;
    padding: 0 20px;
  }
}
.pagination__item {
  flex: none;
  font-size: 1.5rem;
  line-height: 65px;
  margin: 0 20px;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .pagination__item {
    line-height: 50px;
    margin: 0 10px;
  }
}
@media screen and (max-width: 768px) {
  .pagination__item {
    font-size: 1.3rem;
    line-height: 38px;
    margin: 0 4px 10px;
    padding: 0px;
  }
}
.pagination__item_type_prev:before, .pagination__item_type_next:before, .pagination__item_type_back:before {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  height: 30px;
  position: absolute;
  transition: all 0.2s;
  width: 30px;
}
@media screen and (max-width: 768px) {
  .pagination__item_type_prev:before, .pagination__item_type_next:before, .pagination__item_type_back:before {
    height: 20px;
    width: 20px;
  }
}
.pagination__item_type_prev:after, .pagination__item_type_next:after, .pagination__item_type_back:after {
  content: "";
  height: 7px;
  position: absolute;
  transition: all 0.2s;
  width: 7px;
}
@media screen and (max-width: 768px) {
  .pagination__item_type_prev:after, .pagination__item_type_next:after, .pagination__item_type_back:after {
    height: 5px;
    width: 5px;
  }
}
.pagination__item_type_prev .pagination__link, .pagination__item_type_next .pagination__link, .pagination__item_type_back .pagination__link {
  font-size: 1.8rem;
  letter-spacing: 0.15em;
}
@media screen and (max-width: 1024px) {
  .pagination__item_type_prev .pagination__link, .pagination__item_type_next .pagination__link, .pagination__item_type_back .pagination__link {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 768px) {
  .pagination__item_type_prev .pagination__link, .pagination__item_type_next .pagination__link, .pagination__item_type_back .pagination__link {
    font-size: 1.2rem;
  }
}
.pagination__item_type_prev {
  margin: 0 auto 0 0;
  width: 210px;
}
@media screen and (max-width: 1024px) {
  .pagination__item_type_prev {
    width: 150px;
  }
}
@media screen and (max-width: 768px) {
  .pagination__item_type_prev {
    margin: 0 20px 10px 0;
    padding: 0;
    width: 94px;
  }
}
@media screen and (max-width: 480px) {
  .pagination__item_type_prev {
    margin: 0 10px 10px 0;
  }
}
.pagination__item_type_prev:before {
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 1024px) {
  .pagination__item_type_prev:before {
    left: 10px;
  }
}
.pagination__item_type_prev:after {
  border-bottom: solid 2px #fff;
  border-left: solid 2px #fff;
  left: 40px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
}
@media screen and (max-width: 1024px) {
  .pagination__item_type_prev:after {
    left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .pagination__item_type_prev:after {
    left: 17px;
    border-bottom: solid 1px #fff;
    border-left: solid 1px #fff;
  }
}
.pagination__item_type_prev .pagination__link {
  justify-content: center;
  padding: 0 70px;
  min-width: 110px;
}
@media screen and (max-width: 768px) {
  .pagination__item_type_prev .pagination__link {
    padding: 0 25px;
    padding-right: 0px;
    min-width: 80px;
  }
}
@media screen and (max-width: 480px) {
  .pagination__item_type_prev .pagination__link {
    min-width: 40px;
  }
}
.pagination__item_type_back:before {
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 1024px) {
  .pagination__item_type_back:before {
    left: 10px;
  }
}
@media screen and (max-width: 768px) {
  .pagination__item_type_back:before {
    content: none;
  }
}
.pagination__item_type_back:after {
  border-bottom: solid 2px #fff;
  border-left: solid 2px #fff;
  left: 40px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
}
@media screen and (max-width: 1024px) {
  .pagination__item_type_back:after {
    left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .pagination__item_type_back:after {
    content: none;
  }
}
.pagination__item_type_back .pagination__link {
  padding: 0px 60px 0 87px;
  min-width: 110px;
}
@media screen and (max-width: 768px) {
  .pagination__item_type_back .pagination__link {
    min-width: 80px;
    padding: 0px 11px;
  }
}
@media screen and (max-width: 480px) {
  .pagination__item_type_back .pagination__link {
    min-width: 40px;
  }
}
.pagination__item_type_next {
  margin: 0 0 0 auto;
  width: 210px;
}
@media screen and (max-width: 1024px) {
  .pagination__item_type_next {
    width: 150px;
  }
}
@media screen and (max-width: 768px) {
  .pagination__item_type_next {
    margin: 0 0px 10px 20px;
    width: 94px;
  }
}
@media screen and (max-width: 480px) {
  .pagination__item_type_next {
    bottom: -49px;
    left: 50%;
    margin: 0;
    transform: translateX(-50%);
    position: absolute;
  }
}
.pagination__item_type_next:before {
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 1024px) {
  .pagination__item_type_next:before {
    right: 10px;
  }
}
.pagination__item_type_next:after {
  border-right: solid 2px #fff;
  border-top: solid 2px #fff;
  right: 36px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
}
@media screen and (max-width: 1024px) {
  .pagination__item_type_next:after {
    right: 25px;
  }
}
@media screen and (max-width: 768px) {
  .pagination__item_type_next:after {
    border-right: solid 1px #fff;
    border-top: solid 1px #fff;
    right: 20px;
  }
}
.pagination__item_type_next .pagination__link {
  justify-content: center;
  padding-right: 24px;
  min-width: 110px;
}
@media screen and (max-width: 768px) {
  .pagination__item_type_next .pagination__link {
    min-width: 80px;
    padding-left: 0px;
  }
}
@media screen and (max-width: 480px) {
  .pagination__item_type_next .pagination__link {
    min-width: 40px;
  }
}
.pagination__item:hover .pagination__link {
  background-color: #438cc8;
  color: #fff;
  transition: 0.2s;
}
.pagination__item:hover:before {
  background-color: #fff;
}
.pagination__item:hover:after {
  border-color: #438cc8;
}
.pagination__link {
  align-items: center;
  border-radius: 9999px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  color: #438cc8;
  font-family: "Montserrat", sans-serif;
  justify-content: center;
  min-height: 65px;
  min-width: 65px;
  padding: 0px 5px;
  transition: 0.2s;
}
@media screen and (max-width: 1024px) {
  .pagination__link {
    min-height: 50px;
    min-width: 50px;
  }
}
@media screen and (max-width: 768px) {
  .pagination__link {
    min-height: 38px;
    min-width: 38px;
  }
}
.pagination__item_type_current .pagination__link {
  background-color: #438cc8;
  color: #fff;
  pointer-events: none;
}

html {
  font-size: 62.5%;
}

body {
  color: #1a1a1a;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  -webkit-text-size-adjust: 100%;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  body {
    min-width: 320px;
  }
}
body.body_overflow_hidden {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.largeContainer {
  margin: 0 auto;
  max-width: 1720px;
  padding: 0 60px;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .largeContainer {
    padding: 0 40px;
  }
}
@media screen and (max-width: 768px) {
  .largeContainer {
    padding: 0 20px;
  }
}

.container {
  margin: 0 auto;
  max-width: 1360px;
  padding: 0 60px;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .container {
    padding: 0 40px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
}

.spOnly {
  display: none;
}
@media screen and (max-width: 768px) {
  .spOnly {
    display: block;
  }
}

.pcOnly {
  display: block;
}
@media screen and (max-width: 768px) {
  .pcOnly {
    display: none;
  }
}

img {
  vertical-align: bottom;
}

.main {
  display: block;
}

.lower {
  padding-top: 180px;
}
@media screen and (max-width: 768px) {
  .lower {
    padding-top: 60px;
  }
}
.lower_type_noImg {
  padding-top: 275px;
}
@media screen and (max-width: 768px) {
  .lower_type_noImg {
    padding-top: 107px;
  }
}

.topSmallTtl {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
.topSmallTtl__ja {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 5px;
}
@media screen and (max-width: 768px) {
  .topSmallTtl__ja {
    font-size: 2rem;
  }
}
.topSmallTtl__en {
  color: #438cc8;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.topTtl {
  align-items: center;
  display: flex;
}
.topTtl__en {
  color: #438cc8;
  font-size: 6.8rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0.1em;
  padding-right: 18px;
}
@media screen and (max-width: 768px) {
  .topTtl__en {
    font-size: 3.4rem;
    padding-right: 10px;
  }
}
.topTtl__ja {
  font-size: 3.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}
@media screen and (max-width: 768px) {
  .topTtl__ja {
    font-size: 1.6rem;
  }
}

.moreBtn {
  background-color: #fff;
  border-radius: 9999px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.08);
  display: inline-block;
  font-size: 1.6rem;
  padding: 21px 73px 21px 90px;
  position: relative;
  text-align: center;
  transition: all 0.2s;
}
@media screen and (max-width: 768px) {
  .moreBtn {
    padding: 19px 70px 19px 110px;
  }
}
.moreBtn:before {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  height: 30px;
  position: absolute;
  left: 22px;
  top: 52%;
  transform: translateY(-50%);
  width: 30px;
}
@media screen and (max-width: 768px) {
  .moreBtn:before {
    left: 42px;
  }
}
.moreBtn:after {
  content: "";
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  height: 7px;
  position: absolute;
  left: 30px;
  top: 49%;
  transform: rotate(45deg) translateY(-50%);
  width: 7px;
}
@media screen and (max-width: 768px) {
  .moreBtn:after {
    left: 50px;
  }
}
.moreBtn:hover {
  opacity: 0.4;
}

.lowerTtlSec {
  margin: 0 auto;
  position: relative;
}
.lowerTtlSec__figure {
  overflow: hidden;
  padding: 0 60px;
}
@media screen and (max-width: 1200px) {
  .lowerTtlSec__figure {
    padding: 0 40px;
  }
}
@media screen and (max-width: 768px) {
  .lowerTtlSec__figure {
    height: 300px;
    padding: 0px;
  }
}
.lowerTtlSec__figure img {
  border-radius: 0px 30px 0px 30px;
  object-fit: cover;
  max-height: 680px;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .lowerTtlSec__figure img {
    border-radius: 0px;
  }
}
.lowerTtlSec__sideTxt {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  position: absolute;
  letter-spacing: 0.15em;
  left: 25px;
  top: 40%;
  transform: translateY(-50%) rotate(180deg);
  writing-mode: vertical-rl;
  white-space: nowrap;
  z-index: 2;
}
@media screen and (max-width: 1200px) {
  .lowerTtlSec__sideTxt {
    left: 18px;
  }
}
@media screen and (max-width: 768px) {
  .lowerTtlSec__sideTxt {
    display: none;
  }
}
.lowerTtlSec__spTxt {
  display: none;
}
@media screen and (max-width: 768px) {
  .lowerTtlSec__spTxt {
    display: block;
    height: 259px;
    position: absolute;
    right: 18px;
    top: 19px;
    width: 10px;
  }
}
.lowerTtlSec__ttl {
  display: flex;
  flex-direction: column;
  padding-top: 73px;
  position: relative;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .lowerTtlSec__ttl {
    padding-top: 64px;
  }
}
.lowerTtlSec__ttl:before {
  background-color: #1a1a1a;
  border-radius: 9999px;
  content: "";
  height: 70px;
  left: 50%;
  position: absolute;
  top: -37px;
  transform: translateX(-50%);
  width: 6px;
}
@media screen and (max-width: 768px) {
  .lowerTtlSec__ttl:before {
    height: 35px;
    left: 49.8%;
    top: -17px;
    width: 3px;
  }
}
.lowerTtlSec__ttl:after {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  height: 20px;
  left: 50%;
  position: absolute;
  top: 40px;
  transform: translateX(-50%);
  width: 6px;
}
@media screen and (max-width: 768px) {
  .lowerTtlSec__ttl:after {
    height: 10px;
    left: 49.8%;
    top: 21px;
    width: 3px;
  }
}
.lowerTtlSec__ja {
  font-size: 4rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .lowerTtlSec__ja {
    font-size: 2.2rem;
    margin-bottom: 8px;
  }
}
.lowerTtlSec__en {
  color: #438cc8;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.15em;
}
@media screen and (max-width: 768px) {
  .lowerTtlSec__en {
    font-size: 1.2rem;
  }
}

.lowerTtlOnly__ttl {
  display: flex;
  flex-direction: column;
  padding-top: 90px;
  position: relative;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .lowerTtlOnly__ttl {
    padding-top: 64px;
  }
}
.lowerTtlOnly__ttl:before {
  background-color: #1a1a1a;
  border-radius: 9999px;
  content: "";
  height: 70px;
  left: 50%;
  position: absolute;
  top: -37px;
  transform: translateX(-50%);
  width: 6px;
}
@media screen and (max-width: 768px) {
  .lowerTtlOnly__ttl:before {
    height: 35px;
    top: -17px;
    width: 3px;
  }
}
.lowerTtlOnly__ttl:after {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  height: 20px;
  left: 50%;
  position: absolute;
  top: 40px;
  transform: translateX(-50%);
  width: 6px;
}
@media screen and (max-width: 768px) {
  .lowerTtlOnly__ttl:after {
    height: 10px;
    top: 21px;
    width: 3px;
  }
}
.lowerTtlOnly__ja {
  font-size: 4rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .lowerTtlOnly__ja {
    font-size: 2.2rem;
  }
}
.lowerTtlOnly__en {
  color: #438cc8;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.15em;
}
@media screen and (max-width: 768px) {
  .lowerTtlOnly__en {
    font-size: 1.2rem;
  }
}
.lowerTtlOnly__time {
  color: #438cc8;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.15em;
}
@media screen and (max-width: 768px) {
  .lowerTtlOnly__time {
    font-size: 1.2rem;
    margin-top: 15px;
  }
}

.lowerTtl {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.lowerTtl__ja {
  font-size: 3.2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-right: 26px;
}
@media screen and (max-width: 768px) {
  .lowerTtl__ja {
    font-size: 2.2rem;
    letter-spacing: 0em;
    margin-right: 17px;
  }
}
.lowerTtl__en {
  color: #438cc8;
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 768px) {
  .lowerTtl__en {
    font-size: 1.4rem;
  }
}

.lowerMiddleTtl {
  font-size: 2.8rem;
  font-weight: bold;
  padding-left: 40px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .lowerMiddleTtl {
    font-size: 1.8rem;
    padding-left: 37px;
  }
}
.lowerMiddleTtl:before {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  height: 6px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
}
@media screen and (max-width: 768px) {
  .lowerMiddleTtl:before {
    height: 4px;
    width: 17px;
  }
}

.lowerSmallTtl {
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 2.6rem;
  padding-bottom: 3px;
  padding-left: 22px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .lowerSmallTtl {
    font-size: 2rem;
    line-height: 2.2rem;
    padding-bottom: 0;
  }
}
.lowerSmallTtl::before {
  background-color: #438cc8;
  border-radius: 3px;
  bottom: 0;
  content: "";
  display: block;
  height: 26px;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  width: 12px;
}
@media screen and (max-width: 768px) {
  .lowerSmallTtl::before {
    height: 22px;
    width: 10px;
  }
}

.dotTtl {
  align-items: center;
  display: flex;
  font-size: 2.2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  position: relative;
}
.dotTtl:before {
  color: #438cc8;
  content: "・";
  margin-right: 4px;
}
@media screen and (max-width: 768px) {
  .dotTtl:before {
    font-size: 2.6rem;
    margin-right: 0px;
    line-height: 1;
  }
}
@media screen and (max-width: 768px) {
  .dotTtl {
    font-size: 1.8rem;
  }
}

.courseList {
  display: flex;
  flex-wrap: wrap;
  gap: 74px 56px;
}
@media screen and (max-width: 768px) {
  .courseList {
    gap: 40px;
  }
}
.courseList__item {
  width: calc(33.3333333333% - 37.3333333333px);
}
@media screen and (max-width: 1024px) {
  .courseList__item {
    width: calc(50% - 28px);
  }
}
@media screen and (max-width: 768px) {
  .courseList__item {
    width: 100%;
  }
}
.courseList__ttl {
  padding-left: 28px;
  position: relative;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 2.6rem;
  padding-bottom: 3px;
  margin-bottom: 30px;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .courseList__ttl {
    font-size: 2rem;
    line-height: 2.2rem;
    padding-bottom: 0;
    margin-bottom: 20px;
  }
}
.courseList__ttl::before {
  content: "";
  width: 12px;
  height: 26px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
@media screen and (max-width: 768px) {
  .courseList__ttl::before {
    width: 10px;
    height: 22px;
  }
}
.courseList__fig {
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 94/65;
  overflow: hidden;
}
.courseList__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.courseList__txt {
  line-height: 2;
  padding-top: 1em;
}
@media screen and (max-width: 768px) {
  .courseList__txt {
    font-size: 1.4rem;
  }
}

.footer__top {
  background-color: #333333;
  color: #fff;
  overflow: hidden;
  padding-bottom: 213px;
  padding-top: 130px;
  position: relative;
}
@media screen and (max-width: 1300px) {
  .footer__top {
    padding-bottom: 140px;
  }
}
@media screen and (max-width: 768px) {
  .footer__top {
    padding-bottom: 60px;
    padding-top: 80px;
  }
}
.footer__top .footer__container {
  position: relative;
  z-index: 1;
}
.footer__linkList {
  display: flex;
  gap: 30px;
  justify-content: flex-start;
  margin-bottom: 95px;
}
@media screen and (max-width: 1200px) {
  .footer__linkList {
    flex-direction: column;
    gap: 0px;
  }
}
@media screen and (max-width: 768px) {
  .footer__linkList {
    margin-bottom: 60px;
  }
}
.footer__topLink {
  background-color: #438cc8;
  border-radius: 3px;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 160px;
  padding: 54px clamp(25px, -2.057rem + 4.14vw, 54px);
  position: relative;
  transition: all 0.2s;
  width: calc(33.33333% - clamp(10px, -1.357rem + 2.14vw, 25px));
}
@media screen and (max-width: 1200px) {
  .footer__topLink {
    height: auto;
    padding: 25px;
    width: 100%;
  }
  .footer__topLink:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .footer__topLink {
    padding: 28px 35px;
  }
  .footer__topLink:not(:last-of-type) {
    margin-bottom: 41px;
  }
}
@media screen and (max-width: 321px) {
  .footer__topLink {
    padding: 28px 15px;
  }
}
.footer__topLink:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .footer__topLink:hover {
    opacity: 1;
  }
}
.footer__topLink:before {
  background-color: #fff;
  border-radius: 9999px;
  content: "";
  height: 36px;
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
}
@media screen and (max-width: 1300px) {
  .footer__topLink:before {
    height: 24px;
    right: 23px;
    top: 52.5%;
    width: 24px;
  }
}
.footer__topLink:after {
  border-right: solid 2px #438cc8;
  border-top: solid 2px #438cc8;
  content: "";
  height: 10px;
  position: absolute;
  right: 53px;
  top: 49%;
  transform: rotate(45deg) translateY(-50%);
  width: 10px;
}
@media screen and (max-width: 1300px) {
  .footer__topLink:after {
    height: 8px;
    right: 35px;
    top: 51%;
    width: 8px;
  }
}
@media screen and (max-width: 768px) {
  .footer__topLink:after {
    height: 7px;
    width: 7px;
  }
}
.footer__topLink_type_tel {
  background-color: #1a1a1a;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .footer__topLink_type_tel {
    padding: 23px 25px 32px;
  }
}
.footer__topLink_type_tel:before, .footer__topLink_type_tel:after {
  content: none;
}
.footer__topLinkSpan_lg_ja {
  font-size: clamp(2rem, 1.686rem + 0.29vw, 2.2rem);
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .footer__topLinkSpan_lg_ja {
    font-size: 1.6rem;
    margin-bottom: 5px;
  }
}
.footer__topLinkSpan_lg_en {
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 768px) {
  .footer__topLinkSpan_lg_en {
    font-size: 1.2rem;
  }
}
.footer__telLinkSpan_type_num {
  font-family: "Montserrat", sans-serif;
  font-size: clamp(2rem, 0.114rem + 1.71vw, 3.2rem);
  font-weight: 600;
  letter-spacing: 0.1em;
  margin-bottom: 5px;
}
@media screen and (max-width: 768px) {
  .footer__telLinkSpan_type_num {
    font-size: 2.2rem;
    margin-bottom: 0px;
  }
}
.footer__telLinkSpan_type_time {
  font-size: 1.4rem;
}
@media screen and (max-width: 768px) {
  .footer__telLinkSpan_type_time {
    font-size: 1.2rem;
  }
}
.footer__info {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1300px) {
  .footer__info {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1300px) {
  .footer__infoUnit {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .footer__infoUnit:first-of-type {
    padding: 0 5px;
  }
}
.footer__infoUnit:last-of-type {
  width: 48.1%;
}
@media screen and (max-width: 1300px) {
  .footer__infoUnit:last-of-type {
    margin: 40px auto;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .footer__infoUnit:last-of-type {
    margin: 39px auto 0 auto;
  }
}
.footer__logoWrap {
  margin-bottom: 28px;
}
@media screen and (max-width: 768px) {
  .footer__logoWrap {
    margin-bottom: 33px;
  }
}
.footer__logoImg {
  max-width: 503px;
}
.footer__addUnit {
  color: #fff;
  font-size: 1.8rem;
  font-style: normal;
  margin-bottom: 34px;
}
@media screen and (max-width: 768px) {
  .footer__addUnit {
    font-size: 1.4rem;
    margin-bottom: 16px;
  }
}
.footer__add {
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .footer__add {
    margin-bottom: 17px;
  }
}
.footer__telWrap {
  display: flex;
}
@media screen and (max-width: 768px) {
  .footer__telWrap {
    flex-direction: column;
  }
}
.footer__tel {
  margin-right: 37px;
}
@media screen and (max-width: 768px) {
  .footer__tel {
    margin-bottom: 16px;
    margin-right: 0px;
  }
}
.footer__telLink {
  transition: all 0.2s;
}
.footer__telLink:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .footer__telLink:hover {
    opacity: 1;
  }
}
.footer__telTime {
  font-size: 1.6rem;
}
@media screen and (max-width: 768px) {
  .footer__telTime {
    font-size: 1.4rem;
  }
}
.footer__mapLink {
  background-color: #438cc8;
  border-radius: 9999px;
  color: #fff;
  display: inline-block;
  padding: 8px 24px 8px 34px;
  position: relative;
  transition: all 0.2s;
}
.footer__mapLink:before {
  border-right: solid 2px #fff;
  border-top: solid 2px #fff;
  content: "";
  height: 7px;
  left: 18px;
  position: absolute;
  top: 44%;
  transform: translateY(-50%);
  transform: rotate(45deg);
  width: 7px;
}
.footer__mapLink:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .footer__mapLink:hover {
    opacity: 1;
  }
}
.footer__key {
  margin-bottom: 36px;
  padding-top: 13px;
}
@media screen and (max-width: 768px) {
  .footer__key {
    padding-top: 0px;
  }
}
.footer__keywordWrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.footer__keywordWrap:before {
  background: url(../images/icon_search_gray.svg) no-repeat center/cover;
  content: "";
  display: block;
  height: 22px;
  left: 25px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
}
@media screen and (max-width: 768px) {
  .footer__keywordWrap:before {
    height: 17px;
    left: 12px;
    top: 13px;
    transform: none;
    width: 17px;
  }
}
.footer__keyword {
  background: #fff;
  border: none;
  border-radius: 3px;
  font-size: 1.8rem;
  line-height: 70px;
  margin-right: 2px;
  padding: 0 0 0 64px;
  width: calc(100% - 180px);
}
@media screen and (max-width: 1024px) {
  .footer__keyword {
    margin-right: 20px;
  }
}
@media screen and (max-width: 768px) {
  .footer__keyword {
    border-radius: 1.5px;
    font-size: 1.4rem;
    line-height: 40px;
    margin-right: 0;
    padding: 0 0 0 35px;
    width: calc(100% - 78px);
  }
  .footer__keyword::placeholder {
    font-size: 1.4rem;
  }
}
.footer__keyword::placeholder {
  color: #9fa0a0;
}
.footer__keyword:focus {
  outline: none;
}
.footer__searchBtn {
  background: #438cc8;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-size: 1.6rem;
  line-height: 70px;
  transition: all 0.3s;
  width: 160px;
}
.footer__searchBtn:hover {
  background-color: #fff;
  color: #438cc8;
}
@media screen and (max-width: 768px) {
  .footer__searchBtn:hover {
    background: #438cc8;
    color: #fff;
  }
}
@media screen and (max-width: 768px) {
  .footer__searchBtn {
    border-radius: 1.5px;
    font-size: 1.4rem;
    line-height: 40px;
    width: 72px;
  }
}
.footer__linkUnit {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .footer__linkUnit {
    flex-direction: column;
  }
}
.footer__linkUnit:not(:last-child) {
  margin-bottom: 36px;
}
.footer__prefList {
  display: flex;
}
@media screen and (max-width: 768px) {
  .footer__prefList {
    display: none;
    flex-direction: column;
  }
}
.footer__prefItem:not(:last-of-type) {
  margin-right: 30px;
}
.footer__prefLink {
  color: #fff;
  font-size: 1.6rem;
  padding-left: 28px;
  position: relative;
  transition: all 0.2s;
}
.footer__prefLink:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .footer__prefLink:hover {
    opacity: 1;
  }
}
.footer__prefLink:before {
  background-color: #fff;
  border-radius: 9999px;
  content: "";
  height: 18px;
  left: 0px;
  position: absolute;
  top: 54%;
  transform: translateY(-50%);
  width: 18px;
}
.footer__prefLink:after {
  border-right: solid 1px #438cc8;
  border-top: solid 1px #438cc8;
  content: "";
  height: 5px;
  left: 4px;
  position: absolute;
  top: 49%;
  transform: rotate(45deg) translateY(-50%);
  width: 5px;
}
.footer__snsList {
  display: flex;
  margin-right: 5px;
}
@media screen and (max-width: 768px) {
  .footer__snsList {
    margin: 0;
    padding-top: 11px;
  }
}
.footer__snsItem:not(:last-of-type) {
  margin-right: 25px;
}
.footer__snsLink {
  transition: all 0.2s;
}
.footer__snsLink:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .footer__snsLink:hover {
    opacity: 1;
  }
}
.footer__bigTxt {
  bottom: 0px;
  color: #3a3a3a;
  font-family: "Montserrat", sans-serif;
  font-size: 12.1vw;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 0.7;
  position: absolute;
  z-index: 0;
}
@media screen and (max-width: 768px) {
  .footer__bigTxt {
    display: none;
  }
}
.footer__bottom {
  background-color: #1a1a1a;
  padding-bottom: 120px;
  padding-top: 116px;
}
@media screen and (max-width: 768px) {
  .footer__bottom {
    padding-bottom: 35px;
    padding-top: 65px;
  }
}
.footer__siteList {
  display: flex;
  justify-content: space-between;
  margin-bottom: 82px;
}
@media screen and (max-width: 1300px) {
  .footer__siteList {
    flex-wrap: wrap;
    gap: 50px 0;
  }
}
@media screen and (max-width: 768px) {
  .footer__siteList {
    gap: 0;
    margin-bottom: 110px;
  }
}
@media screen and (max-width: 1300px) {
  .footer__siteItem {
    width: 33.3333333%;
  }
}
@media screen and (max-width: 768px) {
  .footer__siteItem {
    text-align: center;
    width: 50%;
  }
}
.footer__siteLink {
  color: #438cc8;
  display: inline-block;
  font-size: 1.8rem;
  margin-bottom: 17px;
  transition: all 0.2s;
}
@media screen and (max-width: 768px) {
  .footer__siteLink {
    font-size: 1.6rem;
    margin-bottom: 26px;
  }
}
.footer__siteLink:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .footer__siteLink:hover {
    opacity: 1;
  }
}
.footer__siteLinkTxt {
  color: #438cc8;
  font-size: 1.8rem;
  margin-bottom: 17px;
}
@media screen and (max-width: 768px) {
  .footer__siteLinkTxt {
    font-size: 1.6rem;
    margin-bottom: 26px;
  }
}
@media screen and (max-width: 768px) {
  .footer__subSiteList {
    display: none;
  }
}
.footer__subSiteItem:not(:last-of-type) {
  margin-bottom: 12px;
}
.footer__subSiteItem:last-of-type .footer__smallSiteList {
  margin-bottom: 0;
}
.footer__subSiteLink {
  color: #fff;
  font-size: 1.4rem;
  transition: all 0.2s;
}
@media screen and (max-width: 1300px) {
  .footer__subSiteLink {
    font-size: 1.4rem;
  }
}
.footer__subSiteLink:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .footer__subSiteLink:hover {
    opacity: 1;
  }
}
.footer__smallSiteList {
  margin-bottom: 27px;
  margin-left: 20px;
  margin-top: 8px;
}
.footer__smallSiteItem {
  margin-bottom: 6px;
}
.footer__smallSiteLink {
  color: #fff;
  font-size: 1.4rem;
  margin-bottom: 32px;
  transition: all 0.2s;
}
.footer__smallSiteLink:before {
  content: "-";
}
.footer__smallSiteLink:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .footer__smallSiteLink:hover {
    opacity: 1;
  }
}
.footer__smallUnit {
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .footer__smallUnit {
    justify-content: flex-start;
  }
}
.footer__privacyWrap {
  order: 2;
}
@media screen and (max-width: 768px) {
  .footer__privacyWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 0;
    margin-top: -110px;
    order: 1;
    position: absolute;
    width: calc(100% - 40px);
  }
}
.footer__smallItem {
  font-size: 1.4rem;
  text-decoration: underline;
  transition: all 0.2s;
}
.footer__smallItem:not(:last-of-type) {
  margin-right: 10px;
}
@media screen and (max-width: 768px) {
  .footer__smallItem:not(:last-of-type) {
    margin-right: 0px;
  }
}
@media screen and (max-width: 768px) {
  .footer__smallItem {
    font-size: 1.2rem;
    text-align: center;
    text-decoration: underline;
    width: 50%;
  }
}
.footer__smallItem:hover {
  opacity: 0.4;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .footer__smallItem:hover {
    opacity: 1;
  }
}
.footer__smallWrap {
  display: flex;
  flex-direction: column;
  order: 1;
}
@media screen and (max-width: 768px) {
  .footer__smallWrap {
    order: 2;
  }
}
.footer__copy {
  margin-bottom: 16px;
}
@media screen and (max-width: 768px) {
  .footer__copy {
    font-size: 1.2rem;
    margin-bottom: 9px;
  }
}
@media screen and (max-width: 768px) {
  .footer__caution {
    font-size: 1.2rem;
    line-height: 2.5;
    word-break: break-all;
  }
}
.footer__bnrList {
  display: grid;
  gap: 40px 40px;
  grid-template-columns: repeat(2, minmax(100px, 300px));
}
@media screen and (max-width: 1300px) {
  .footer__bnrList {
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .footer__bnrList {
    column-gap: 20px;
    row-gap: 20px;
  }
}
.footer__bnrItem {
  border-radius: 3px;
  overflow: hidden;
}
.footer__bnrLink {
  display: block;
  transition: 0.2s;
}
.footer__bnrLink:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .footer__bnrLink:hover {
    opacity: 1;
  }
}
.footer__bnrImg {
  display: block;
  width: 100%;
}
.footer__bnrItem_type_brochure {
  align-items: center;
  display: flex;
  grid-column: span 2;
  position: relative;
  transition: all 0.2s;
  width: 70%;
}
@media screen and (max-width: 1300px) {
  .footer__bnrItem_type_brochure {
    width: 100%;
  }
}
.footer__bnrItem_type_brochure .footer__bnrLink {
  background: #438cc8;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 90px;
  justify-content: center;
  padding: 20px clamp(25px, -2.057rem + 4.14vw, 54px);
  width: 100%;
}
@media screen and (max-width: 768px) {
  .footer__bnrItem_type_brochure .footer__bnrLink {
    padding: 20px 35px;
  }
}
@media screen and (max-width: 321px) {
  .footer__bnrItem_type_brochure .footer__bnrLink {
    padding: 20px 15px;
  }
}
.footer__bnrItem_type_brochure .footer__bnrLink:before {
  background-color: #fff;
  border-radius: 9999px;
  content: "";
  height: 36px;
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
}
@media screen and (max-width: 1300px) {
  .footer__bnrItem_type_brochure .footer__bnrLink:before {
    height: 24px;
    right: 23px;
    top: 52.5%;
    width: 24px;
  }
}
.footer__bnrItem_type_brochure .footer__bnrLink:after {
  border-right: solid 2px #438cc8;
  border-top: solid 2px #438cc8;
  content: "";
  height: 10px;
  position: absolute;
  right: 53px;
  top: 49%;
  transform: rotate(45deg) translateY(-50%);
  width: 10px;
}
@media screen and (max-width: 1300px) {
  .footer__bnrItem_type_brochure .footer__bnrLink:after {
    height: 8px;
    right: 35px;
    top: 51%;
    width: 8px;
  }
}
@media screen and (max-width: 768px) {
  .footer__bnrItem_type_brochure .footer__bnrLink:after {
    height: 7px;
    width: 7px;
  }
}
.footer__bnrItem_type_brochure .footer__bnrLink .footer__bnrLink:hover {
  opacity: 1;
}
.footer__bnrLinkSpan_lg_ja {
  font-size: clamp(2rem, 1.686rem + 0.29vw, 2.2rem);
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .footer__bnrLinkSpan_lg_ja {
    font-size: 1.6rem;
    margin-bottom: 5px;
  }
}
.footer__bnrLinkSpan_lg_en {
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 768px) {
  .footer__bnrLinkSpan_lg_en {
    font-size: 1.2rem;
  }
}

.header {
  padding: 56px clamp(30px, -20.333rem + 16.67vw, 50px) 40px clamp(30px, -20.333rem + 16.67vw, 50px);
  position: fixed;
  transition: all 0.2s;
  width: 100%;
  z-index: 10;
}
.header_page_lower {
  background-color: #fff;
}
@media screen and (max-width: 1200px) {
  .header {
    display: flex;
    padding: 14px 30px;
    height: 107px;
  }
}
@media screen and (max-width: 768px) {
  .header {
    background-color: #fff;
    height: 60px;
    padding: 16px 20px 13px;
  }
}
@media screen and (max-width: 330px) {
  .header {
    padding: 16px 10px 13px;
  }
}
.header.js_scroll {
  background-color: #fff;
  padding: 20px clamp(30px, -20.333rem + 16.67vw, 50px) 20px clamp(30px, -20.333rem + 16.67vw, 50px);
}
.header.js_scroll .header__logoImg {
  height: 67px;
  width: 400px;
}
@media screen and (max-width: 768px) {
  .header.js_scroll .header__logoImg {
    width: auto;
    height: 65px;
  }
}
.header__inner {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.header__logo {
  margin-right: 30px;
  transition: all 0.2s;
}
.header__logo:hover {
  opacity: 0.5;
}
@media screen and (max-width: 768px) {
  .header__logo:hover {
    opacity: 1;
  }
}
.header__logoImg {
  transition: all 0.2s;
  width: clamp(400px, 25.6rem + 12vw, 472px);
}
@media screen and (max-width: 768px) {
  .header__logoImg {
    width: 239px;
  }
}

@media screen and (max-width: 1200px) {
  .gnav {
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    right: -380px;
    padding: 130px 40px 100px;
    position: fixed;
    top: 0px;
    transition: all 0.3s;
    width: 380px;
  }
  .gnav.is_open {
    right: 0px;
  }
}
@media screen and (max-width: 768px) {
  .gnav {
    height: calc(100vh - 60px);
    overflow: scroll;
    padding: 22px 40px;
    right: -100vw;
    top: 60px;
    width: 100%;
  }
  .gnav.is_open {
    right: 0px;
  }
}
.gnav__top {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 17px;
}
@media screen and (max-width: 1200px) {
  .gnav__top {
    flex-direction: column;
    order: 2;
  }
}
@media screen and (max-width: 1200px) {
  .gnav__bottom {
    order: 1;
  }
}
.gnav__index {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.5);
  margin-right: 18px;
  max-width: 218px;
  position: relative;
  transition: all 0.2s;
}
@media screen and (max-width: 1200px) {
  .gnav__index {
    margin-right: 0px;
    margin-bottom: 20px;
    max-width: none;
    width: 100%;
  }
}
.gnav__index:before {
  content: "|";
  color: #ccc;
  position: absolute;
  right: 31px;
  top: 6px;
}
@media screen and (max-width: 1200px) {
  .gnav__index:before {
    top: 14px;
  }
}
.gnav__index:after {
  content: "";
  border-bottom: solid 2px #ccc;
  border-right: solid 2px #ccc;
  height: 8px;
  position: absolute;
  right: 16px;
  transform: rotate(45deg);
  top: 15px;
  width: 8px;
}
@media screen and (max-width: 1200px) {
  .gnav__index:after {
    top: 22px;
  }
}
.gnav__index.js_open {
  border-radius: 6px 6px 0px 0px;
}
@media screen and (max-width: 768px) {
  .gnav__index.js_open {
    border-radius: 6px;
  }
}
.gnav__indexTxt {
  cursor: default;
  font-size: 1.5rem;
  letter-spacing: -0.01em;
  padding: 10px 49px 10px 20px;
}
@media screen and (max-width: 1200px) {
  .gnav__indexTxt {
    padding: 18px 49px 18px 16px;
  }
}
.gnav__indexList {
  display: none;
  background: #fff;
  border-radius: 0px 0px 6px 6px;
  box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.5);
  margin-top: 1px;
  position: absolute;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .gnav__indexList {
    position: relative;
    box-shadow: 0 0px 1px 0px rgba(0, 0, 0, 0.5);
  }
}
.gnav__indexItem {
  font-size: 1.5rem;
  letter-spacing: -0.01em;
}
.gnav__indexItem:not(:first-of-type) {
  border-top: 1px solid #ccc;
}
.gnav__indexItem .gnav__indexLink {
  padding: 10px 20px 10px 20px;
}
.gnav__indexLink {
  width: 100%;
  display: block;
  transition: all 0.2s;
}
.gnav__indexLink:hover {
  background-color: #438cc8;
  color: #fff;
}
.gnav__topLink {
  background-color: #438cc8;
  border-radius: 9999px;
  color: #fff;
  font-size: 1.5rem;
  padding: 9px 30px 9px 30px;
  position: relative;
  transition: all 0.2s;
}
@media screen and (max-width: 1200px) {
  .gnav__topLink {
    font-size: 1.7rem;
    padding: 16px 16px 16px 37px;
    width: 100%;
  }
}
.gnav__topLink:not(:last-of-type) {
  margin-right: 10px;
}
@media screen and (max-width: 1200px) {
  .gnav__topLink:not(:last-of-type) {
    margin: 0 auto 10px;
  }
}
.gnav__topLink:before {
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  content: "";
  height: 8px;
  position: absolute;
  left: 13px;
  transform: rotate(45deg) translateY(-50%);
  top: 48%;
  width: 8px;
}
.gnav__topLink:hover {
  opacity: 0.5;
}
@media screen and (max-width: 768px) {
  .gnav__topLink:hover {
    opacity: 1;
  }
}
.gnav__list {
  display: flex;
}
@media screen and (max-width: 1200px) {
  .gnav__list {
    flex-direction: column;
  }
}
.gnav__item:not(:last-of-type) {
  margin-right: clamp(30px, -14.5rem + 12.5vw, 45px);
}
@media screen and (max-width: 1200px) {
  .gnav__item {
    margin-right: 0;
    margin-bottom: 27px;
  }
}
.gnav__link {
  font-size: clamp(1.3rem, 0.55rem + 0.63vw, 1.5rem);
  transition: all 0.2s;
  white-space: nowrap;
}
@media screen and (max-width: 1200px) {
  .gnav__link {
    font-size: 1.7rem;
  }
}
.gnav__link:hover {
  opacity: 0.5;
}
@media screen and (max-width: 768px) {
  .gnav__link:hover {
    opacity: 1;
  }
}

.hamburger {
  display: none;
}
@media screen and (max-width: 1200px) {
  .hamburger {
    background-color: #438cc8;
    bottom: 0;
    cursor: pointer;
    display: block;
    height: 107px;
    position: absolute;
    right: 0px;
    top: 0px;
    transition: all 0.3s;
    width: 107px;
    z-index: 3;
  }
  .hamburger.is_open {
    margin: 0;
    position: fixed;
  }
}
@media screen and (max-width: 1200px) and (max-width: 768px) {
  .hamburger.is_open {
    top: 0;
  }
}
@media screen and (max-width: 1200px) {
  .hamburger:focus {
    outline: none;
  }
}
@media screen and (max-width: 768px) {
  .hamburger {
    height: 60px;
    width: 60px;
  }
}
.hamburger__bar {
  background-color: #fff;
  display: block;
  height: 2px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: 50px;
}
@media screen and (max-width: 768px) {
  .hamburger__bar {
    height: 1px;
    left: 50%;
    width: 32px;
  }
}
@media screen and (max-width: 1200px) {
  .hamburger__bar:nth-child(1) {
    top: 48px;
  }
}
@media screen and (max-width: 768px) {
  .hamburger__bar:nth-child(1) {
    top: 27px;
  }
}
@media screen and (max-width: 1200px) {
  .hamburger__bar:nth-child(2) {
    top: 57px;
  }
}
@media screen and (max-width: 768px) {
  .hamburger__bar:nth-child(2) {
    top: 32px;
  }
}
@media screen and (max-width: 1200px) {
  .hamburger.is_open .hamburger__bar {
    left: 45%;
  }
}
@media screen and (max-width: 768px) {
  .hamburger.is_open .hamburger__bar {
    left: 45%;
    top: 21px;
    transform: rotate(-42deg) translateX(-50%);
  }
}
@media screen and (max-width: 1200px) {
  .hamburger.is_open .hamburger__bar:nth-child(1) {
    top: 35px;
    transform: rotate(-44deg) translateX(-50%);
  }
}
@media screen and (max-width: 768px) {
  .hamburger.is_open .hamburger__bar:nth-child(1) {
    left: 45%;
    top: 21px;
    transform: rotate(-42deg) translateX(-50%);
  }
}
@media screen and (max-width: 1200px) {
  .hamburger.is_open .hamburger__bar:nth-child(2) {
    top: 68px;
    transform: rotate(44deg) translateX(-50%);
  }
}
@media screen and (max-width: 768px) {
  .hamburger.is_open .hamburger__bar:nth-child(2) {
    left: 45%;
    top: 41px;
    transform: rotate(42deg) translateX(-50%);
  }
}

@media screen and (max-width: 768px) {
  .topMv .swiper {
    height: 100vh;
  }
}

.swiper-slide img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.swiper-pagination-current {
  align-items: center;
  background: #438cc8;
  border-radius: 50%;
  box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.5);
  color: #1a1a1a;
  display: flex;
  height: 60px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 60px;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .swiper-pagination-current {
    font-size: 1rem;
    height: 37px;
    width: 37px;
  }
}

.swiper-pagination-total {
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.5);
  color: #1a1a1a;
  display: flex;
  height: 60px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 60px;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .swiper-pagination-total {
    background-color: #fff;
    font-size: 1rem;
    height: 35px;
    width: 35px;
  }
}

.swiper-pagination-current::before {
  background: #fff;
  content: "";
  display: block;
  height: 60px;
  left: -30px;
  position: absolute;
  top: 0;
  transform-origin: right 30px;
  width: 60px;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .swiper-pagination-current::before {
    height: 43px;
    left: -24px;
    transform-origin: right 21px;
    width: 43px;
  }
}

.swiper-pagination-current::after {
  background: #fff;
  content: "";
  display: block;
  height: 60px;
  left: 30px;
  position: absolute;
  top: 0px;
  transform-origin: left 30px;
  width: 60px;
  z-index: 3;
}
@media screen and (max-width: 768px) {
  .swiper-pagination-current::after {
    height: 43px;
    left: 18px;
    transform-origin: left 21px;
    width: 43px;
  }
}

.swiper-pagination-current.js_start::before {
  animation: circleBefore 5s linear infinite;
}

.swiper-pagination-current.js_start::after {
  animation: circleAfter 5s linear infinite;
}

.swiper-pagination-current .swiper-pagination-inner {
  align-items: center;
  background: #fff;
  border-radius: 50%;
  color: #1a1a1a;
  display: flex;
  height: 55px;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 55px;
  z-index: 4;
}
@media screen and (max-width: 768px) {
  .swiper-pagination-current .swiper-pagination-inner {
    height: 35px;
    transform: translateY(-51%);
    width: 35px;
  }
}

@keyframes circleAfter {
  0% {
    background: #fff;
    transform: rotate(0deg);
  }
  50% {
    background: #fff;
    transform: rotate(180deg);
  }
  50.01% {
    background: #438cc8;
    transform: rotate(360deg);
  }
  100% {
    background: #438cc8;
    transform: rotate(360deg);
  }
}
@keyframes circleBefore {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.swiper-pagination {
  align-items: center;
  color: transparent;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  justify-content: space-between;
  left: 84%;
  position: absolute;
  top: 52%;
  width: 160px;
  z-index: 1;
}
.swiper-pagination:before {
  background-color: #e6e6e6;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
}
@media screen and (max-width: 768px) {
  .swiper-pagination:before {
    width: 9px;
  }
}
@media screen and (max-width: 1300px) {
  .swiper-pagination {
    left: 80%;
  }
}
@media screen and (max-width: 768px) {
  .swiper-pagination {
    bottom: 13.9473684211vw;
    left: auto;
    right: 19px;
    top: auto;
    width: 98px;
  }
}

.swiper02 {
  position: relative;
}
.swiper02 .swiper-button-prev,
.swiper02 .swiper-button-next {
  position: relative;
  top: auto;
  bottom: auto;
  height: 60px;
  transition: all 0.2s;
  width: 60px;
  z-index: 0 !important;
}
.swiper02 .swiper-button-prev:hover,
.swiper02 .swiper-button-next:hover {
  opacity: 0.5;
}
@media screen and (max-width: 768px) {
  .swiper02 .swiper-button-prev:hover,
.swiper02 .swiper-button-next:hover {
    opacity: 1;
  }
}
.swiper02 .swiper-button-prev {
  left: -30px;
  margin-right: 18px;
}
@media screen and (max-width: 768px) {
  .swiper02 .swiper-button-prev {
    margin-right: 5px;
  }
}
.swiper02 .swiper-button-next {
  right: -30px;
}
.swiper02 .swiper-button-prev:after,
.swiper02 .swiper-button-next:after {
  content: "";
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  height: 60px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 60px;
}
@media screen and (max-width: 768px) {
  .swiper02 .swiper-button-prev:after,
.swiper02 .swiper-button-next:after {
    height: 50px;
    width: 50px;
  }
}
.swiper02 .swiper-button-prev:before,
.swiper02 .swiper-button-next:before {
  content: "";
  height: 10px;
  position: absolute;
  transition: all 0.3s;
  width: 10px;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .swiper02 .swiper-button-prev:before,
.swiper02 .swiper-button-next:before {
    height: 9px;
    width: 9px;
  }
}
.swiper02 .swiper-button-prev:before {
  border-bottom: solid 2px #438cc8;
  border-left: solid 2px #438cc8;
  right: 24px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.swiper02 .swiper-button-next:before {
  border-top: solid 2px #438cc8;
  border-right: solid 2px #438cc8;
  right: 27px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.swiper02 .swiper-button-wrap {
  display: flex;
  position: absolute;
  max-width: 1720px;
  justify-content: space-between;
  top: 38%;
  width: 100%;
  z-index: 1;
}
@media screen and (max-width: 1200px) {
  .swiper02 .swiper-button-wrap {
    top: 33%;
  }
}
@media screen and (max-width: 768px) {
  .swiper02 .swiper-button-wrap {
    left: 50%;
    top: 173px;
    transform: translateX(-50%);
    width: 260px;
  }
}
.swiper02 .swiper-pagination {
  align-items: center;
  color: #1a1a1a;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  justify-content: space-between;
  left: 84%;
  position: absolute;
  top: 52%;
  width: 150px;
  z-index: 1;
}
@media screen and (max-width: 1300px) {
  .swiper02 .swiper-pagination {
    left: 80%;
  }
}
@media screen and (max-width: 768px) {
  .swiper02 .swiper-pagination {
    bottom: 30px;
    left: auto;
    right: 30px;
    top: auto;
  }
}

.notFound {
  padding-top: 285px;
  padding-bottom: 200px;
}
@media screen and (max-width: 1200px) {
  .notFound {
    padding-top: 215px;
  }
}
@media screen and (max-width: 768px) {
  .notFound {
    padding-top: 107px;
    padding-bottom: 80px;
  }
}
.notFound__section {
  padding-top: 70px;
}
@media screen and (max-width: 768px) {
  .notFound__section {
    padding-top: 50px;
  }
}
.notFound__txt {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .notFound__txt {
    font-size: 1.6rem;
  }
}

.access__inner {
  padding-bottom: 230px;
  padding-top: 67px;
}
@media screen and (max-width: 768px) {
  .access__inner {
    padding-bottom: 82px;
    padding-top: 52px;
  }
}
.access__unit:not(:last-of-type) {
  border-bottom: 1px solid #e6e6e6;
}
.access__unit:nth-of-type(1) {
  padding-bottom: 145px;
}
@media screen and (max-width: 768px) {
  .access__unit:nth-of-type(1) {
    padding-bottom: 60px;
  }
}
.access__unit:nth-of-type(2) {
  padding-bottom: 161px;
  padding-top: 127px;
}
@media screen and (max-width: 768px) {
  .access__unit:nth-of-type(2) {
    padding-bottom: 0px;
    padding-top: 54px;
    border-bottom: none;
  }
}
.access__unit:nth-of-type(2) .lowerTtl {
  margin-bottom: 65px;
}
@media screen and (max-width: 768px) {
  .access__unit:nth-of-type(2) .lowerTtl {
    margin-bottom: 28px;
  }
}
.access__unit:nth-of-type(3) {
  padding-top: 127px;
}
@media screen and (max-width: 768px) {
  .access__unit:nth-of-type(3) {
    padding-top: 57px;
  }
}
.access__unit:nth-of-type(3) .lowerTtl {
  margin-bottom: 65px;
}
@media screen and (max-width: 768px) {
  .access__unit:nth-of-type(3) .lowerTtl {
    margin-bottom: 32px;
  }
}
.access__read {
  font-size: 1.8rem;
  margin-bottom: 78px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .access__read {
    font-size: 1.6rem;
    margin-bottom: 42px;
  }
}
.access__tel {
  transition: all 0.2s;
}
.access__tel:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .access__tel:hover {
    opacity: 1;
  }
}
.access__mapWrap {
  margin-bottom: 60px;
  padding-top: 54.8387%;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .access__mapWrap {
    height: 580px;
    padding-top: 0%;
  }
}
.access__mapWrap iframe {
  border-radius: 10px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .access__mapWrap iframe {
    border-radius: 6px;
  }
}
.access__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 62px 10px;
}
@media screen and (max-width: 1024px) {
  .access__list {
    gap: 20px 0px;
  }
}
.access__trans {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 30px 50px 50px;
  width: calc(50% - 31px);
}
@media screen and (max-width: 1024px) {
  .access__trans {
    padding: 30px 40px 50px;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .access__trans {
    padding: 26px 30px 40px;
  }
}
.access__trans .lowerTtl {
  margin-bottom: 28px;
}
@media screen and (max-width: 768px) {
  .access__trans .lowerTtl {
    align-items: flex-start;
    flex-direction: column;
  }
}
.access__trans .lowerTtl__ja {
  font-size: 2.2rem;
  margin-right: 12px;
}
@media screen and (max-width: 768px) {
  .access__trans .lowerTtl__ja {
    font-size: 2rem;
    margin: 0px 0px 5px 0px;
  }
}
.access__trans .lowerTtl__en {
  font-size: 1.2rem;
}
@media screen and (max-width: 768px) {
  .access__trans .lowerTtl__en {
    letter-spacing: 0.15em;
  }
}
.access__wayWrap {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .access__wayWrap {
    flex-direction: column;
  }
}
.access__way:nth-of-type(odd) {
  width: 50%;
}
@media screen and (max-width: 768px) {
  .access__way:nth-of-type(odd) {
    width: 100%;
  }
}
.access__way:nth-of-type(even) {
  width: 47%;
}
@media screen and (max-width: 768px) {
  .access__way:nth-of-type(even) {
    width: 100%;
  }
}
.access__way:not(:last-of-type) {
  margin-bottom: 22px;
}
.access__wayTtlWrap {
  align-items: center;
  display: flex;
}
.access__wayTtlWrap_type_car {
  margin-bottom: 17px;
}
.access__wayTtlWrap_type_jr {
  margin-bottom: 7px;
}
@media screen and (max-width: 768px) {
  .access__wayTtlWrap_type_jr {
    margin-bottom: 12px;
  }
}
.access__wayTtlWrap_type_bus {
  margin-bottom: 10px;
}
.access__iconWrap {
  margin-right: 17px;
}
@media screen and (max-width: 768px) {
  .access__iconWrap {
    margin-right: 21px;
  }
}
.access__wayTtl {
  font-size: 1.8rem;
  font-weight: bold;
}
.access__wayTxt {
  font-size: 1.6rem;
  line-height: 2;
}
.access__flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1024px) {
  .access__flex {
    align-items: unset;
    flex-direction: column;
  }
}
.access__txt {
  font-size: 1.8rem;
  line-height: 2;
  margin-right: 20px;
  margin-top: -50px;
}
@media screen and (max-width: 1024px) {
  .access__txt {
    margin: 0 0 30px 0;
  }
}
@media screen and (max-width: 768px) {
  .access__txt {
    font-size: 1.6rem;
    margin: 0 0 22px 0;
  }
}
@media screen and (max-width: 768px) {
  .access__ttl .lowerTtl__ja {
    font-size: 2.2rem;
    margin-right: 10px;
  }
  .access__ttl .lowerTtl__en {
    font-size: 1.4rem;
  }
}
.access__fig {
  border-radius: 10px;
  overflow: hidden;
  width: 64.5%;
  min-width: 64.5%;
}
@media screen and (max-width: 1024px) {
  .access__fig {
    margin: 0 auto;
    width: 85%;
    min-width: 85%;
  }
}
@media screen and (max-width: 768px) {
  .access__fig {
    width: 100%;
    min-width: 100%;
  }
}
.access__fig_width_full {
  width: 100%;
  min-width: 100%;
}

.anv__inner {
  padding-bottom: 214px;
  padding-top: 94px;
}
@media screen and (max-width: 768px) {
  .anv__inner {
    padding-bottom: 184px;
    padding-top: 40px;
  }
}
.anv__unit:not(:last-of-type) {
  border-bottom: 1px solid #e6e6e6;
}
.anv__unit:nth-of-type(1) {
  padding-bottom: 107px;
}
@media screen and (max-width: 768px) {
  .anv__unit:nth-of-type(1) {
    padding-bottom: 44px;
  }
}
.anv__unit:nth-of-type(2) {
  padding-bottom: 58px;
  padding-top: 78px;
}
@media screen and (max-width: 768px) {
  .anv__unit:nth-of-type(2) {
    padding-bottom: 25px;
    padding-top: 54px;
  }
}
.anv__unit:nth-of-type(3) {
  padding-bottom: 139px;
  padding-top: 126px;
}
@media screen and (max-width: 768px) {
  .anv__unit:nth-of-type(3) {
    padding-bottom: 51px;
    padding-top: 62px;
  }
}
.anv__unit:nth-of-type(3) .anv__ttl {
  margin-bottom: 29px;
}
@media screen and (max-width: 768px) {
  .anv__unit:nth-of-type(3) .anv__ttl {
    margin-bottom: 27px;
  }
}
.anv__unit:nth-of-type(3) .anv__txtWrap {
  margin-bottom: 47px;
}
@media screen and (max-width: 768px) {
  .anv__unit:nth-of-type(3) .anv__txtWrap {
    margin-bottom: 14px;
  }
}
.anv__unit:nth-of-type(4) {
  padding-bottom: 139px;
  padding-top: 126px;
}
@media screen and (max-width: 768px) {
  .anv__unit:nth-of-type(4) {
    padding-bottom: 170px;
    padding-top: 54px;
  }
}
.anv__unit:nth-of-type(4) .anv__ttl {
  margin-bottom: 38px;
}
@media screen and (max-width: 768px) {
  .anv__unit:nth-of-type(4) .anv__ttl {
    margin-bottom: 28px;
  }
}
.anv__unit:nth-of-type(5) {
  padding-top: 126px;
}
@media screen and (max-width: 768px) {
  .anv__unit:nth-of-type(5) {
    padding-top: 54px;
  }
}
.anv__ttl {
  margin-bottom: 52px;
}
@media screen and (max-width: 768px) {
  .anv__ttl {
    margin-bottom: 25px;
  }
}
.anv__txt {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .anv__txt {
    font-size: 1.6rem;
    line-height: 2.25;
  }
}
.anv__imgWrap {
  max-width: 70%;
  margin: 60px auto;
}
@media screen and (max-width: 1024px) {
  .anv__imgWrap {
    max-width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .anv__imgWrap {
    margin: 28px auto;
    max-width: 100%;
  }
}
.anv__supWrap {
  display: flex;
}
@media screen and (max-width: 768px) {
  .anv__supWrap {
    flex-direction: column;
  }
}
.anv__supportList:first-of-type {
  width: 52.4%;
}
@media screen and (max-width: 768px) {
  .anv__supportList:first-of-type {
    width: 100%;
  }
}
.anv__supportList:last-of-type {
  width: 47.6%;
}
@media screen and (max-width: 768px) {
  .anv__supportList:last-of-type {
    width: 100%;
  }
}
.anv__supportItem {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .anv__supportItem {
    font-size: 1.4rem;
    line-height: 2.2857142857;
  }
}
.anv__flex {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .anv__flex {
    flex-direction: column;
    position: relative;
  }
}
.anv__maTxt {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 45px;
}
@media screen and (max-width: 768px) {
  .anv__maTxt {
    font-size: 1.6rem;
    margin-bottom: 26px;
  }
}
@media screen and (max-width: 768px) {
  .anv__maLink.moreBtn {
    bottom: -100px;
    left: 50%;
    position: absolute;
    padding: 19px clamp(30px, 0.143rem + 8.93vw, 70px) 19px clamp(90px, 7.571rem + 4.46vw, 110px);
    transform: translateX(-50%);
    white-space: nowrap;
    width: 340px;
  }
}
@media screen and (max-width: 480px) {
  .anv__maLink.moreBtn {
    width: 100%;
  }
}
.anv__pdfWrap {
  max-width: 351px;
  margin: 0 164px 0 40px;
}
@media screen and (max-width: 1200px) {
  .anv__pdfWrap {
    margin: 0 40px;
  }
}
@media screen and (max-width: 768px) {
  .anv__pdfWrap {
    margin: 0px auto;
    max-width: 480px;
  }
}
.anv__pdfLink {
  transition: all 0.2s;
}
.anv__pdfLink:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .anv__pdfLink:hover {
    opacity: 1;
  }
}
.anv__contents {
  width: 48%;
}
@media screen and (max-width: 768px) {
  .anv__contents {
    width: 100%;
  }
}
.anv__iframeWrap {
  margin-bottom: 28px;
  padding-top: 56.2711%;
  position: relative;
  width: 100%;
}
.anv__iframeWrap iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.anv__time {
  color: #438cc8;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
}
.anv__videoTtl {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 12px;
}
@media screen and (max-width: 768px) {
  .anv__videoTtl {
    font-size: 1.6rem;
  }
}
.anv__videoTxt {
  font-size: 1.6rem;
  line-height: 2.25;
}
@media screen and (max-width: 768px) {
  .anv__videoTxt {
    font-size: 1.4rem;
  }
}

.appGuidelines .lowerMiddleTtl {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .appGuidelines .lowerMiddleTtl {
    font-size: 2rem;
    margin-bottom: 30px;
  }
}
.appGuidelines__inner {
  padding-bottom: 130px;
  padding-top: 70px;
}
@media screen and (max-width: 768px) {
  .appGuidelines__inner {
    padding-bottom: 100px;
    padding-top: 50px;
  }
}
.appGuidelines__unit + .appGuidelines__unit {
  margin-top: 100px;
}
@media screen and (max-width: 768px) {
  .appGuidelines__unit + .appGuidelines__unit {
    margin-top: 60px;
  }
}
.appGuidelines__unit + .appGuidelines__unit_has_hr {
  border-top: 1px solid #e6e6e6;
  margin-top: 80px;
  padding-top: 100px;
}
@media screen and (max-width: 768px) {
  .appGuidelines__unit + .appGuidelines__unit_has_hr {
    margin-top: 60px;
    padding-top: 60px;
  }
}
.appGuidelines__txt {
  font-size: 1.6rem;
  line-height: 2;
  margin: 30px 0 0;
}
@media screen and (max-width: 768px) {
  .appGuidelines__txt {
    margin: 20px 0 0;
  }
}
.appGuidelines__txt_size_18 {
  font-size: 1.8rem;
}
.appGuidelines__txt a {
  color: #438cc8;
  text-decoration: underline;
}
.appGuidelines__annotation {
  font-size: 1.6rem;
  line-height: 2;
  margin: 30px 0 0;
}
@media screen and (max-width: 768px) {
  .appGuidelines__annotation {
    font-size: 1.4rem;
    margin: 20px 0 0;
  }
}
.appGuidelines__markCircle {
  border: 1px solid;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  width: 18px;
}
.appGuidelines__markCircle::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.appGuidelines__list {
  margin: 30px 0 0;
}
@media screen and (max-width: 768px) {
  .appGuidelines__list {
    margin: 20px 0 0;
  }
}
.appGuidelines__item {
  font-size: 1.6rem;
  line-height: 2;
  padding-left: 20px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .appGuidelines__item {
    font-size: 1.5rem;
  }
}
.appGuidelines__item::before {
  background: #438cc8;
  border-radius: 50%;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  top: 1em;
  transform: translateY(-50%);
  width: 5px;
}
.appGuidelines__item_type_btn {
  padding-left: 0px;
}
.appGuidelines__item_type_btn::before {
  content: none;
}
.appGuidelines__link {
  text-decoration: underline;
  transition: 0.2s;
}
.appGuidelines__link:hover {
  opacity: 0.5;
  text-decoration: underline transparent;
}
@media screen and (max-width: 768px) {
  .appGuidelines__link.moreBtn {
    display: block;
    padding: 21px 73px 21px 90px;
  }
}
.appGuidelines__rows {
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
}
.appGuidelines__row {
  display: flex;
}
@media screen and (max-width: 768px) {
  .appGuidelines__row {
    display: block;
  }
}
.appGuidelines__row + .appGuidelines__row {
  border-top: 1px solid #eaeaea;
}
.appGuidelines__rowTtl {
  background: #f9f9f9;
  flex: 0 0 auto;
  font-size: 1.6rem;
  line-height: 2;
  padding: 32px 40px;
  position: relative;
  width: 260px;
}
@media screen and (max-width: 768px) {
  .appGuidelines__rowTtl {
    padding: 24px 48px;
    width: 100%;
  }
}
.appGuidelines__rowTtl::before {
  background: #438cc8;
  border-radius: 50%;
  content: "";
  display: block;
  height: 6px;
  margin: 1em 0 0 -17px;
  position: absolute;
  transform: translateY(-50%);
  width: 6px;
}
.appGuidelines__rowData {
  flex: 1 1 auto;
  padding: 32px 40px;
}
@media screen and (max-width: 768px) {
  .appGuidelines__rowData {
    padding: 24px 20px;
  }
}
.appGuidelines__table table {
  border: 1px solid #f9f9f9;
  border-collapse: collapse;
  width: 100%;
}
.appGuidelines__table table thead th {
  background: #f9f9f9;
  border: 1px solid #eaeaea;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 2;
  padding: 12px 28px;
  text-align: center;
  vertical-align: middle;
}
.appGuidelines__table table tbody th {
  background: #f9f9f9;
  border: 1px solid #eaeaea;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 2;
  padding: 12px 40px;
  position: relative;
  text-align: left;
  vertical-align: top;
  width: 260px;
}
.appGuidelines__table table tbody th::before {
  background: #438cc8;
  border-radius: 50%;
  content: "";
  display: block;
  height: 6px;
  margin: 1em 0 0 -17px;
  position: absolute;
  transform: translateY(-50%);
  width: 6px;
}
.appGuidelines__table table tbody td {
  border: 1px solid #eaeaea;
  padding: 12px 20px;
  vertical-align: top;
}
@media screen and (max-width: 768px) {
  .appGuidelines__table_for_guideline {
    display: grid;
    margin: 0 -20px;
    overflow-x: auto;
    padding: 0 20px;
  }
}
@media screen and (max-width: 768px) {
  .appGuidelines__table_for_guideline table {
    width: 1240px;
  }
}
.appGuidelines__table_for_guideline table tbody td:nth-of-type(1), .appGuidelines__table_for_guideline table tbody td:nth-of-type(2), .appGuidelines__table_for_guideline table tbody td:nth-of-type(3), .appGuidelines__table_for_guideline table tbody td:nth-of-type(4) {
  vertical-align: middle;
  width: 94px;
}
.appGuidelines__table_for_guideline table tbody td.appGuidelines__td_width_134 {
  vertical-align: middle;
  width: 134px;
}
.appGuidelines__table_for_guideline table tbody td.appGuidelines__td_width_200 {
  min-width: 200px;
}
.appGuidelines__infos_for_limit .appGuidelines__info {
  column-gap: 20px;
  display: flex;
}
.appGuidelines__infos_for_limit .appGuidelines__infoTtl {
  flex: 0 0 auto;
  width: 240px;
}
@media screen and (max-width: 768px) {
  .appGuidelines__infos_for_limit .appGuidelines__infoTtl {
    width: min(200px, 80%);
  }
}
.appGuidelines__infos_for_limit .appGuidelines__infoData {
  flex: 1 1 auto;
}
.appGuidelines__infos_for_entitle .appGuidelines__info {
  column-gap: 20px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .appGuidelines__infos_for_entitle .appGuidelines__info {
    display: block;
  }
}
.appGuidelines__infos_for_entitle .appGuidelines__infoTtl {
  flex: 0 0 auto;
  width: 240px;
}
@media screen and (max-width: 768px) {
  .appGuidelines__infos_for_entitle .appGuidelines__infoTtl {
    width: 100%;
  }
}
.appGuidelines__infos_for_entitle .appGuidelines__infoData {
  flex: 1 1 auto;
}
.appGuidelines__infos_for_schedule {
  column-gap: 20px;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .appGuidelines__infos_for_schedule {
    display: block;
  }
}
.appGuidelines__infos_for_schedule .appGuidelines__info {
  column-gap: 40px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .appGuidelines__infos_for_schedule .appGuidelines__info {
    column-gap: 20px;
  }
}
.appGuidelines__infos_for_schedule .appGuidelines__infoTtl {
  flex: 0 0 auto;
}
@media screen and (max-width: 768px) {
  .appGuidelines__infos_for_schedule .appGuidelines__infoTtl {
    width: 90px;
  }
}
.appGuidelines__infos_for_schedule .appGuidelines__infoData {
  min-width: 220px;
}
@media screen and (max-width: 768px) {
  .appGuidelines__infos_for_schedule .appGuidelines__infoData {
    flex: 1 1 auto;
    min-width: 0;
  }
}
.appGuidelines__infos_for_schedule .appGuidelines__infoData:only-child {
  margin-left: calc(2em + 40px);
}
@media screen and (max-width: 768px) {
  .appGuidelines__infos_for_schedule .appGuidelines__infoData:only-child {
    margin-left: 0;
  }
}
.appGuidelines__infoTtl {
  font-size: 1.6rem;
  line-height: 2;
}
.appGuidelines__infoData {
  font-size: 1.6rem;
  line-height: 2;
}
.appGuidelines__group {
  border: 5px solid #f2f2f2;
  border-radius: 6px;
  margin: 40px 0 0;
  padding: 38px 65px 48px;
}
@media screen and (max-width: 768px) {
  .appGuidelines__group {
    border-width: 3px;
    margin: 30px 0 0;
    padding: 30px 17px 40px;
  }
}
.appGuidelines__group_type_narrow {
  padding: 38px 45px;
}
@media screen and (max-width: 768px) {
  .appGuidelines__group_type_narrow {
    padding: 30px 17px;
  }
}
.appGuidelines__group_type_boxShadow {
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}
.appGuidelines__grTtl {
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .appGuidelines__grTtl {
    margin-bottom: 20px;
  }
}
.appGuidelines__grTtl.dotTtl {
  margin-left: -25px;
}
@media screen and (max-width: 768px) {
  .appGuidelines__grTtl.dotTtl {
    margin-left: 0;
  }
}
.appGuidelines__grTtl[data-num] {
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 2.6rem;
  padding: 0 0 0 50px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .appGuidelines__grTtl[data-num] {
    font-size: 2rem;
    line-height: 2.2rem;
  }
}
.appGuidelines__grTtl[data-num]::before {
  color: #438cc8;
  content: attr(data-num);
  font-family: "Montserrat", sans-serif;
  font-size: 4rem;
  font-weight: 600;
  left: 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(9deg);
}
@media screen and (max-width: 768px) {
  .appGuidelines__grTtl[data-num]::before {
    font-size: 3.5rem;
  }
}
.appGuidelines__grTxt {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .appGuidelines__grTxt {
    font-size: 1.6rem;
  }
}
.appGuidelines__grLink {
  color: #438cc8;
  text-decoration: underline;
  transition: 0.2s;
}
.appGuidelines__grLink:hover {
  opacity: 0.5;
  text-decoration: underline transparent;
}
.appGuidelines__grLink_color_inherit {
  color: inherit;
  text-decoration: underline transparent;
}
.appGuidelines__grAnnotation {
  color: #ed1c24;
  font-size: 1.4rem;
  line-height: 2;
  margin: 10px 0 0;
}
.appGuidelines__examLink {
  margin: 40px auto 100px;
}
@media screen and (max-width: 768px) {
  .appGuidelines__examLink {
    display: block;
    margin: 20px auto 70px;
  }
}

.association .lowerMiddleTtl {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .association .lowerMiddleTtl {
    font-size: 2rem;
    margin-bottom: 30px;
  }
}
.association .moreBtn {
  padding: 23px 78px 23px 88px;
}
@media screen and (max-width: 768px) {
  .association .moreBtn {
    padding: 19px 22px 19px 68px;
    width: 100%;
  }
}
.association .moreBtn::before {
  left: 27px;
  top: 50%;
}
@media screen and (max-width: 768px) {
  .association .moreBtn::before {
    left: 20px;
  }
}
.association .moreBtn::after {
  left: 35px;
}
@media screen and (max-width: 768px) {
  .association .moreBtn::after {
    left: 28px;
  }
}
.association__inner {
  padding-bottom: 252px;
  padding-top: 100px;
}
@media screen and (max-width: 768px) {
  .association__inner {
    padding-bottom: 80px;
    padding-top: 40px;
  }
}
.association__unit + .association__unit {
  margin-top: 70px;
}
@media screen and (max-width: 768px) {
  .association__unit + .association__unit {
    margin-top: 60px;
  }
}
.association__unit + .association__unit_has_hr {
  border-top: 1px solid #e6e6e6;
  margin-top: 110px;
  padding-top: 80px;
}
@media screen and (max-width: 768px) {
  .association__unit + .association__unit_has_hr {
    margin-top: 60px;
    padding-top: 60px;
  }
}
.association__txt {
  font-size: 1.8rem;
  line-height: 2;
  margin: 10px 0 0;
}
@media screen and (max-width: 768px) {
  .association__txt {
    font-size: 1.6rem;
  }
}
.association__list {
  margin: 10px 0 0;
}
.association__item {
  font-size: 1.8rem;
  line-height: 2;
  padding-left: 20px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .association__item {
    font-size: 1.6rem;
  }
}
.association__item::before {
  background: #438cc8;
  border-radius: 50%;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  top: 1em;
  transform: translateY(-50%);
  width: 5px;
}
.association__btn {
  margin: 25px 0 0;
}
@media screen and (max-width: 768px) {
  .association__btn {
    margin: 35px 0 0;
  }
}
.association__group {
  border: 5px solid #f2f2f2;
  border-radius: 6px;
  margin: 80px 0 0;
  padding: 38px 65px 48px;
}
@media screen and (max-width: 768px) {
  .association__group {
    border-width: 3px;
    margin: 40px 0 0;
    padding: 30px 17px 40px;
  }
}
.association__grTtl {
  margin-bottom: 10px;
  margin-left: -25px;
}
@media screen and (max-width: 768px) {
  .association__grTtl {
    margin-bottom: 20px;
    margin-left: 0;
  }
}
.association__grTxt {
  font-size: 1.8rem;
  line-height: 2;
}
.association__grTxt a {
  color: #438cc8;
  text-decoration: underline;
  transition: 0.2s;
}
.association__grTxt a:hover {
  opacity: 0.5;
  text-decoration: underline transparent;
}
@media screen and (max-width: 768px) {
  .association__grTxt {
    font-size: 1.6rem;
  }
}
.association__grAnnotation {
  font-size: 1.4rem;
  line-height: 2;
  margin: 10px 0 0;
}

.campus__inner {
  padding-bottom: 70px;
  padding-top: 105px;
}
@media screen and (max-width: 768px) {
  .campus__inner {
    padding-top: 66px;
    padding-bottom: 4px;
  }
}
.campus__unit:not(:last-of-type) {
  border-bottom: 1px solid #e6e6e6;
}
.campus__unit:nth-of-type(1) {
  padding-bottom: 125px;
}
@media screen and (max-width: 768px) {
  .campus__unit:nth-of-type(1) {
    padding-bottom: 60px;
  }
}
.campus__unit:nth-of-type(2) {
  padding-top: 89px;
}
@media screen and (max-width: 768px) {
  .campus__unit:nth-of-type(2) {
    padding-top: 55px;
  }
}
.campus__unit:nth-of-type(2) .lowerMiddleTtl {
  margin-bottom: 62px;
}
@media screen and (max-width: 768px) {
  .campus__unit:nth-of-type(2) .lowerMiddleTtl {
    margin-bottom: 36px;
  }
}
.campus__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 65px 55px;
}
@media screen and (max-width: 1024px) {
  .campus__wrap {
    gap: 50px 40px;
  }
}
@media screen and (max-width: 768px) {
  .campus__wrap {
    gap: 45px 0px;
  }
}
.campus__linkUnit {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  width: calc(33.333333% - 37px);
}
@media screen and (max-width: 1024px) {
  .campus__linkUnit {
    width: calc(33.333333% - 27px);
  }
}
@media screen and (max-width: 768px) {
  .campus__linkUnit {
    width: 100%;
  }
  .campus__linkUnit:nth-of-type(3) .campus__fig img {
    object-position: top;
  }
}
.campus__link {
  transition: all 0.2s;
}
.campus__link:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .campus__link:hover {
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
  .campus__fig {
    height: 155px;
    overflow: hidden;
  }
}
.campus__fig img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.campus__detail {
  height: 100%;
  padding: 30px 35px 30px 40px;
}
@media screen and (max-width: 1024px) {
  .campus__detail {
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .campus__detail {
    height: auto;
    padding: 25px 30px;
  }
}
.campus__ttl {
  display: flex;
  flex-direction: column;
}
.campus__ttlJa {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}
@media screen and (max-width: 1024px) {
  .campus__ttlJa {
    font-size: 1.8rem;
  }
}
.campus__ttlJa_ls_0 {
  letter-spacing: 0;
}
.campus__ttlEn {
  color: #438cc8;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.15em;
}
.campus__virtual div {
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .campus__virtual div {
    min-height: 170px;
    height: 44.7368421053vw !important;
  }
}
@media screen and (max-width: 768px) {
  .campus__virtual iframe {
    min-height: 170px;
    height: 44.7368421053vw !important;
  }
}

.career {
  padding-bottom: 190px;
}
@media screen and (max-width: 768px) {
  .career {
    padding-bottom: 84px;
  }
}
.career__section {
  padding-top: 70px;
}
@media screen and (max-width: 768px) {
  .career__section {
    padding-top: 48px;
  }
}
.career__txt {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .career__txt {
    font-size: 1.6rem;
  }
}
.career__education {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px auto 150px;
}
@media screen and (max-width: 1024px) {
  .career__education {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 768px) {
  .career__education {
    margin: 50px auto 100px;
  }
}
.career__circle {
  display: flex;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 2.4rem;
  letter-spacing: 0.05em;
  text-align: center;
  position: relative;
}
@media screen and (max-width: 768px) {
  .career__circle {
    width: 120px;
    height: 120px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 480px) {
  .career__circle {
    width: 110px;
    height: 110px;
  }
}
.career__circle_color_orange {
  background: #ffa53e;
  margin-right: 100px;
}
@media screen and (max-width: 768px) {
  .career__circle_color_orange {
    margin-right: 80px;
  }
}
@media screen and (max-width: 480px) {
  .career__circle_color_orange {
    margin-right: 60px;
  }
}
.career__circle_color_orange::after {
  content: "";
  display: block;
  width: 42px;
  height: 42px;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2243.414%22%20height%3D%2243.414%22%20viewBox%3D%220%200%2043.414%2043.414%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_272%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20272%22%20transform%3D%22translate(-642.293%20-1315.293)%22%3E%20%3Cline%20id%3D%22%E7%B7%9A_7%22%20data-name%3D%22%E7%B7%9A%207%22%20x2%3D%2242%22%20y2%3D%2242%22%20transform%3D%22translate(643%201316)%22%20fill%3D%22none%22%20stroke%3D%22%231a1a1a%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%222%22%2F%3E%20%3Cline%20id%3D%22%E7%B7%9A_8%22%20data-name%3D%22%E7%B7%9A%208%22%20x1%3D%2242%22%20y2%3D%2242%22%20transform%3D%22translate(643%201316)%22%20fill%3D%22none%22%20stroke%3D%22%231a1a1a%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%222%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat;
  position: absolute;
  right: -72px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  .career__circle_color_orange::after {
    width: 35px;
    height: 35px;
    right: -58px;
  }
}
@media screen and (max-width: 480px) {
  .career__circle_color_orange::after {
    right: -48px;
  }
}
.career__circle_color_green {
  background: #3ac98a;
}
.career__skill {
  text-align: center;
  position: relative;
  display: block;
  margin-left: 125px;
}
@media screen and (max-width: 1024px) {
  .career__skill {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .career__skill {
    margin-top: 20px;
    margin-left: 76px;
  }
}
@media screen and (max-width: 480px) {
  .career__skill {
    margin-left: 56px;
  }
}
.career__skill::before {
  content: "";
  display: block;
  width: 46px;
  height: 18px;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2246%22%20height%3D%2218%22%20viewBox%3D%220%200%2046%2018%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_273%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20273%22%20transform%3D%22translate(-953%20-1322)%22%3E%20%3Cline%20id%3D%22%E7%B7%9A_9%22%20data-name%3D%22%E7%B7%9A%209%22%20x2%3D%2246%22%20transform%3D%22translate(953%201323)%22%20fill%3D%22none%22%20stroke%3D%22%231a1a1a%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%222%22%2F%3E%20%3Cline%20id%3D%22%E7%B7%9A_10%22%20data-name%3D%22%E7%B7%9A%2010%22%20x2%3D%2246%22%20transform%3D%22translate(953%201339)%22%20fill%3D%22none%22%20stroke%3D%22%231a1a1a%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%222%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat;
  position: absolute;
  left: -85px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  .career__skill::before {
    left: -56px;
    width: 35px;
    height: 14px;
  }
}
.career__marker {
  font-weight: bold;
  font-size: 2.4rem;
  letter-spacing: 3.6rem;
  letter-spacing: 0.05em;
  position: relative;
}
@media screen and (max-width: 768px) {
  .career__marker {
    font-size: 2rem;
  }
}
@media screen and (max-width: 480px) {
  .career__marker {
    font-size: 1.8rem;
  }
}
.career__marker::after {
  content: "";
  width: 103%;
  height: 14px;
  position: absolute;
  bottom: 0;
  left: -3px;
  right: 0;
  margin: auto;
  background: #FCEE21;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .career__marker::after {
    height: 12px;
  }
}
.career__unit {
  padding-bottom: 130px;
}
@media screen and (max-width: 768px) {
  .career__unit {
    padding-bottom: 55px;
  }
}
.career__fig {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 480px;
  border-radius: 10px;
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .career__fig {
    height: 350px;
    margin-bottom: 40px;
  }
}
.career__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center/center;
}
.career__ttl {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 300px;
  border-radius: 150px;
  background: #fff;
  text-align: center;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .career__ttl {
    height: 180px;
    width: 180px;
  }
}
.career__span {
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  padding-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .career__span {
    font-size: 1.4rem;
    padding-bottom: 7px;
  }
}
.career__span:last-of-type {
  padding-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .career__span:last-of-type {
    padding-bottom: 14px;
  }
}
.career__num {
  font-family: "Montserrat", sans-serif;
  font-size: 6.8rem;
  color: #438cc8;
  font-weight: 600;
  margin-right: 13px;
}
@media screen and (max-width: 768px) {
  .career__num {
    font-size: 4rem;
    margin-right: 10px;
  }
}
.career__other {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 60px 60px 64px;
}
@media screen and (max-width: 768px) {
  .career__other {
    padding: 40px 20px;
  }
}
.career__subTtl {
  font-size: 2.2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .career__subTtl {
    font-size: 2rem;
  }
}
.career__svg {
  margin-right: 15px;
}
@media screen and (max-width: 768px) {
  .career__svg {
    width: 28px;
  }
}

.careerUl {
  display: flex;
  flex-wrap: wrap;
  gap: 74px 56px;
}
@media screen and (max-width: 768px) {
  .careerUl {
    gap: 40px;
  }
}
.careerUl__item {
  width: calc(33.3333333333% - 37.3333333333px);
}
@media screen and (max-width: 1024px) {
  .careerUl__item {
    width: calc(50% - 28px);
  }
}
@media screen and (max-width: 768px) {
  .careerUl__item {
    width: 100%;
  }
}
.careerUl__ttl {
  padding-left: 28px;
  position: relative;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 2.6rem;
  padding-bottom: 3px;
  margin-bottom: 30px;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .careerUl__ttl {
    font-size: 2rem;
    line-height: 2.2rem;
    padding-bottom: 0;
    margin-bottom: 20px;
  }
}
.careerUl__ttl::before {
  content: "";
  width: 12px;
  height: 26px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background: #438cc8;
}
@media screen and (max-width: 768px) {
  .careerUl__ttl::before {
    width: 10px;
    height: 22px;
  }
}
.careerUl__txt {
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .careerUl__txt {
    font-size: 1.4rem;
  }
}

.careerDl {
  display: flex;
  padding: 33px 0;
  border-top: 1px solid #f2f2f2;
}
@media screen and (max-width: 768px) {
  .careerDl {
    flex-direction: column;
    padding: 25px 0;
  }
}
.careerDl:last-of-type {
  border-bottom: 1px solid #f2f2f2;
}
.careerDl__term {
  width: 13em;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  padding-left: 20px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .careerDl__term {
    width: 100%;
    font-size: 1.8rem;
    padding-bottom: 10px;
    padding-left: 18px;
  }
}
.careerDl__term::before {
  content: "";
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #438cc8;
  top: 11px;
  left: 0;
}
@media screen and (max-width: 768px) {
  .careerDl__term::before {
    top: 10px;
  }
}
.careerDl__detail {
  width: calc(100% - 26rem);
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .careerDl__detail {
    width: 100%;
    font-size: 1.5rem;
  }
}

.circle .lowerMiddleTtl {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .circle .lowerMiddleTtl {
    font-size: 2rem;
    margin-bottom: 30px;
  }
}
.circle__inner {
  padding-bottom: 180px;
  padding-top: 70px;
}
@media screen and (max-width: 768px) {
  .circle__inner {
    padding-bottom: 80px;
    padding-top: 40px;
  }
}
.circle__lead {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 100px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .circle__lead {
    font-size: 1.6rem;
    margin-bottom: 50px;
    text-align: unset;
  }
}
.circle__unit + .circle__unit {
  margin-top: 110px;
}
@media screen and (max-width: 768px) {
  .circle__unit + .circle__unit {
    margin-top: 60px;
  }
}
.circle__unit + .circle__unit_has_hr {
  border-top: 1px solid #e6e6e6;
  margin-top: 160px;
  padding-top: 110px;
}
@media screen and (max-width: 768px) {
  .circle__unit + .circle__unit_has_hr {
    margin-top: 60px;
    padding-top: 60px;
  }
}
.circle__txt {
  font-size: 1.8rem;
  line-height: 2;
  margin: 0 0 50px;
}
@media screen and (max-width: 768px) {
  .circle__txt {
    font-size: 1.4rem;
    margin: 0 0 20px;
  }
}
.circle__pickup {
  column-gap: 60px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr auto auto 1fr;
}
@media screen and (max-width: 768px) {
  .circle__pickup {
    display: block;
  }
}
.circle__pickup:nth-of-type(n+2) {
  margin-top: 100px;
}
@media screen and (max-width: 768px) {
  .circle__pickup:nth-of-type(n+2) {
    margin-top: 60px;
  }
}
.circle__pickup:nth-of-type(2n) {
  direction: rtl;
}
@media screen and (max-width: 768px) {
  .circle__pickup:nth-of-type(2n) {
    direction: unset;
  }
}
.circle__pickupDetail {
  direction: ltr;
  grid-column: 1/2;
  grid-row: 2/3;
}
@media screen and (max-width: 768px) {
  .circle__pickupDetail {
    direction: unset;
  }
}
.circle__pickupTxt {
  font-size: 1.8rem;
  line-height: 2;
  margin: 30px 0 0;
}
@media screen and (max-width: 768px) {
  .circle__pickupTxt {
    font-size: 1.6rem;
  }
}
.circle__pickupFig {
  align-self: start;
  border-radius: 10px;
  direction: ltr;
  grid-column: 2/3;
  grid-row: 1/-1;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .circle__pickupFig {
    border-radius: 6px;
    direction: unset;
    margin: 20px 0 0;
  }
}
.circle__pickupFig img {
  width: 100%;
}
.circle__pickupSubFigs {
  align-self: start;
  column-gap: 40px;
  direction: ltr;
  display: flex;
  grid-column: 1/2;
  grid-row: 3/4;
  margin: 40px 0 0;
}
@media screen and (max-width: 768px) {
  .circle__pickupSubFigs {
    display: block;
    margin: 30px 0 0;
  }
}
.circle__pickupSubFig {
  border-radius: 6px;
  overflow: hidden;
  width: calc((100% - 40px) * 0.5);
}
@media screen and (max-width: 768px) {
  .circle__pickupSubFig {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .circle__pickupSubFig:nth-of-type(n+2) {
    margin-top: 30px;
  }
}
.circle__pickupSubFig img {
  width: 100%;
}
.circle__others {
  column-gap: 56px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 60px;
}
@media screen and (max-width: 768px) {
  .circle__others {
    display: block;
  }
}
.circle__other {
  width: calc((100% - 112px) * 0.3333);
}
@media screen and (max-width: 768px) {
  .circle__other {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .circle__other:nth-of-type(n+2) {
    margin-top: 30px;
  }
}
.circle__otherFig {
  border-radius: 10px;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .circle__otherFig {
    border-radius: 6px;
  }
}
.circle__otherFig img {
  width: 100%;
}
.circle__otherTtl {
  font-size: 1.8rem;
  line-height: 2;
  margin: 20px 0 0;
}
@media screen and (max-width: 768px) {
  .circle__otherTtl {
    font-size: 1.6rem;
    margin: 10px 0 0;
  }
}

.collaboration__inner {
  padding-bottom: 70px;
  padding-top: 105px;
}
@media screen and (max-width: 768px) {
  .collaboration__inner {
    padding-top: 66px;
    padding-bottom: 4px;
  }
}
.collaboration__unit:not(:last-of-type) {
  border-bottom: 1px solid #e6e6e6;
}
.collaboration__unit:nth-of-type(1) {
  padding-bottom: 125px;
}
@media screen and (max-width: 768px) {
  .collaboration__unit:nth-of-type(1) {
    padding-bottom: 60px;
  }
}
.collaboration__unit:nth-of-type(2) {
  padding-top: 89px;
}
@media screen and (max-width: 768px) {
  .collaboration__unit:nth-of-type(2) {
    padding-top: 55px;
  }
}
.collaboration__unit:nth-of-type(2) .lowerMiddleTtl {
  margin-bottom: 62px;
}
@media screen and (max-width: 768px) {
  .collaboration__unit:nth-of-type(2) .lowerMiddleTtl {
    margin-bottom: 36px;
  }
}
.collaboration__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 65px 55px;
}
@media screen and (max-width: 1024px) {
  .collaboration__wrap {
    gap: 50px 40px;
  }
}
@media screen and (max-width: 768px) {
  .collaboration__wrap {
    gap: 45px 0px;
  }
}
.collaboration__linkUnit {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  width: calc(33.333333% - 37px);
}
@media screen and (max-width: 1024px) {
  .collaboration__linkUnit {
    width: calc(33.333333% - 27px);
  }
}
@media screen and (max-width: 768px) {
  .collaboration__linkUnit {
    width: 100%;
  }
}
.collaboration__link {
  transition: all 0.2s;
}
.collaboration__link:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .collaboration__link:hover {
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
  .collaboration__fig {
    height: 155px;
    overflow: hidden;
  }
}
.collaboration__fig img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.collaboration__detail {
  height: 100%;
  padding: 30px 40px;
}
@media screen and (max-width: 1024px) {
  .collaboration__detail {
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .collaboration__detail {
    height: auto;
    padding: 25px 30px;
  }
}
.collaboration__ttl {
  display: flex;
  flex-direction: column;
}
.collaboration__ttlJa {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}
@media screen and (max-width: 1024px) {
  .collaboration__ttlJa {
    font-size: 1.8rem;
  }
}
.collaboration__ttlEn {
  color: #438cc8;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.15em;
}

.consultation {
  padding-top: 285px;
  padding-bottom: 200px;
}
@media screen and (max-width: 1200px) {
  .consultation {
    padding-top: 215px;
  }
}
@media screen and (max-width: 768px) {
  .consultation {
    padding-top: 107px;
    padding-bottom: 75px;
  }
}
.consultation__container {
  padding-top: 70px;
}
@media screen and (max-width: 768px) {
  .consultation__container {
    padding-top: 50px;
  }
}
.consultation__ttl {
  margin-bottom: 25px;
}
@media screen and (max-width: 768px) {
  .consultation__ttl {
    margin-bottom: 40px;
  }
}
.consultation__txt {
  font-size: 1.8rem;
  line-height: 2;
  padding-bottom: 1em;
}
@media screen and (max-width: 768px) {
  .consultation__txt {
    font-size: 1.6rem;
  }
}
.consultation__dotTxt {
  font-size: 1.8rem;
  line-height: 2;
  font-weight: normal;
  align-items: flex-start;
}
@media screen and (max-width: 768px) {
  .consultation__dotTxt {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 768px) {
  .consultation__dotTxt:before {
    padding-top: 3px;
  }
}
.consultation__group {
  border: 5px solid #f2f2f2;
  border-radius: 6px;
  margin: 80px 0 0;
  padding: 38px 65px 48px;
}
@media screen and (max-width: 768px) {
  .consultation__group {
    border-width: 3px;
    margin: 40px 0 0;
    padding: 30px 17px 40px;
  }
}
.consultation__grTtl {
  margin-bottom: 10px;
  margin-left: -25px;
}
@media screen and (max-width: 768px) {
  .consultation__grTtl {
    margin-bottom: 20px;
    margin-left: 0;
  }
}
.consultation__grTxt {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .consultation__grTxt {
    font-size: 1.6rem;
  }
}
.consultation__grLink {
  transition: 0.2s;
}
.consultation__grLink:hover {
  opacity: 0.5;
}
.consultation__grAnnotation {
  color: #ed1c24;
  font-size: 1.4rem;
  line-height: 2;
  margin: 10px 0 0;
}

.contact {
  margin-bottom: 150px;
}
@media screen and (max-width: 768px) {
  .contact {
    margin-bottom: 82px;
  }
}
.contact__lowerTtlOnly {
  margin-bottom: 70px;
}
@media screen and (max-width: 768px) {
  .contact__lowerTtlOnly {
    margin-bottom: 30px;
  }
}
.contact__lead {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 56px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .contact__lead {
    font-size: 1.6rem;
    line-height: 2.25;
    margin-bottom: 29px;
  }
}
.contact__section {
  border-top: 1px solid #e6e6e6;
  padding-top: 124px;
}
@media screen and (max-width: 768px) {
  .contact__section {
    padding-top: 55px;
  }
}
.contact__ttl {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .contact__ttl {
    margin-bottom: 26px;
  }
}
.contact__txt {
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 2;
  margin-bottom: 57px;
}
@media screen and (max-width: 768px) {
  .contact__txt {
    font-size: 1.6rem;
    line-height: 2.25;
    margin-bottom: 23px;
  }
}
.contact__pamphletList {
  display: grid;
  gap: 100px 80px;
  grid-template-columns: 62% 31%;
  justify-content: center;
  justify-items: center;
  padding: 0 51px;
}
@media screen and (max-width: 1200px) {
  .contact__pamphletList {
    column-gap: 40px;
    padding: 0 20px;
  }
}
@media screen and (max-width: 1024px) {
  .contact__pamphletList {
    grid-template-columns: minmax(0, auto);
  }
}
@media screen and (max-width: 768px) {
  .contact__pamphletList {
    padding: 0;
    row-gap: 63px;
  }
}
.contact__pamphlet {
  text-align: center;
}
.contact__pamphletImgLink {
  display: block;
  margin-bottom: 48px;
  transition: 0.2s;
}
@media screen and (max-width: 768px) {
  .contact__pamphletImgLink {
    margin-bottom: 40px;
  }
}
.contact__pamphletImgLink:hover {
  opacity: 0.5;
}
.contact__pamphletFig {
  overflow: hidden;
}
.contact__pamphletImg {
  display: block;
  max-height: 498px;
  object-fit: contain;
  width: 100%;
}
.contact__pamphletMoreBtn {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  .contact__pamphletMoreBtn {
    padding: 17px 30px 19px 64px;
    width: 100%;
  }
  .contact__pamphletMoreBtn::before {
    height: 28px;
    left: 28px;
    width: 28px;
  }
  .contact__pamphletMoreBtn::after {
    left: 50px;
    left: 35px;
  }
}
.contact__moreBtn {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  display: block;
  margin: 0 auto 138px;
  max-width: 100%;
  width: fit-content;
}
@media screen and (max-width: 768px) {
  .contact__moreBtn {
    margin-bottom: 62px;
  }
  .contact__moreBtn::before {
    height: 28px;
    left: 28px;
    width: 28px;
  }
  .contact__moreBtn::after {
    left: 50px;
    left: 35px;
  }
}

.form .contact__lead_type_confirm,
.mw_wp_form .contact__lead_type_confirm {
  display: none;
}
.form__outer,
.mw_wp_form__outer {
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 138px;
  padding: 96px 82px 93px;
}
@media screen and (max-width: 1200px) {
  .form__outer,
.mw_wp_form__outer {
    padding: 96px 60px 93px;
  }
}
@media screen and (max-width: 1024px) {
  .form__outer,
.mw_wp_form__outer {
    padding: 96px 40px 93px;
  }
}
@media screen and (max-width: 768px) {
  .form__outer,
.mw_wp_form__outer {
    font-size: 1.6rem;
    margin-bottom: 62px;
    padding: 42px 20px 61px;
  }
}
.form__inner,
.mw_wp_form__inner {
  margin-bottom: 46px;
  padding-right: 43px;
}
@media screen and (max-width: 1200px) {
  .form__inner,
.mw_wp_form__inner {
    padding-right: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .form__inner,
.mw_wp_form__inner {
    padding-right: 0;
  }
}
.form__dl,
.mw_wp_form__dl {
  align-items: flex-start;
  display: grid;
  gap: 20px 33px;
  grid-template-columns: 30% 1fr;
}
@media screen and (max-width: 1024px) {
  .form__dl,
.mw_wp_form__dl {
    grid-template-columns: minmax(0, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .form__dl,
.mw_wp_form__dl {
    row-gap: 14px;
  }
}
.form__dl:not(:where(:last-child)),
.mw_wp_form__dl:not(:where(:last-child)) {
  margin-bottom: 56px;
}
@media screen and (max-width: 768px) {
  .form__dl:not(:where(:last-child)),
.mw_wp_form__dl:not(:where(:last-child)) {
    margin-bottom: 23px;
  }
}
.form__dl_type_radioWrap .form__dt,
.mw_wp_form__dl_type_radioWrap .form__dt {
  padding-top: 3px;
}
.form__dt,
.mw_wp_form__dt {
  align-items: center;
  display: flex;
  gap: 12px;
  padding-top: 12px;
}
.form__itemRequired,
.mw_wp_form__itemRequired {
  background: #ff4841;
  border-radius: 3.43px;
  color: #fff;
  flex: 0 0 auto;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.785;
  padding: 0px 12px;
}
@media screen and (max-width: 768px) {
  .form__itemRequired,
.mw_wp_form__itemRequired {
    font-size: 1.3rem;
    line-height: 1.7;
    padding: 0 10px;
  }
}
.form__itemRequired_type_false,
.mw_wp_form__itemRequired_type_false {
  background: #aaa;
}
.form__itemName,
.mw_wp_form__itemName {
  letter-spacing: 0.05em;
}
.form__radioWrap,
.mw_wp_form__radioWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 31px;
}
@media screen and (max-width: 768px) {
  .form__radioWrap,
.mw_wp_form__radioWrap {
    display: grid;
    row-gap: 18px;
  }
}
.form .horizontal-item + .horizontal-item,
.mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0 !important;
}
.form__radioLabel,
.form .mwform-radio-field-text,
.mw_wp_form__radioLabel,
.mw_wp_form .mwform-radio-field-text {
  display: grid;
  gap: 8px;
  grid-template-areas: "radio .";
  grid-template-columns: 2.3rem auto;
  letter-spacing: 0.01em;
}
@media screen and (max-width: 768px) {
  .form__radioLabel,
.form .mwform-radio-field-text,
.mw_wp_form__radioLabel,
.mw_wp_form .mwform-radio-field-text {
    font-size: 1.5rem;
    grid-template-columns: 1.8rem auto;
  }
}
.form__radioLabel::before,
.form .mwform-radio-field-text::before,
.mw_wp_form__radioLabel::before,
.mw_wp_form .mwform-radio-field-text::before {
  aspect-ratio: 1;
  border: 2.3px solid #ebebeb;
  border-radius: 10rem;
  content: "";
  flex: 0 0 auto;
  grid-area: radio;
  height: auto;
  place-self: center;
  width: 100%;
}
.form__radioLabel::after,
.form .mwform-radio-field-text::after,
.mw_wp_form__radioLabel::after,
.mw_wp_form .mwform-radio-field-text::after {
  aspect-ratio: 1;
  background: #438cc8;
  border-radius: 10rem;
  content: "";
  grid-area: radio;
  height: auto;
  opacity: 0;
  place-self: center;
  transition: 0.2s;
  visibility: hidden;
  width: 60%;
}
.form__radio,
.mw_wp_form__radio {
  display: none;
}
.form__radio:checked + .form__radioLabel::after, .form__radio:checked + .mwform-radio-field-text::after,
.mw_wp_form__radio:checked + .form__radioLabel::after,
.mw_wp_form__radio:checked + .mwform-radio-field-text::after {
  opacity: 1;
  visibility: visible;
}
.form__input,
.mw_wp_form__input {
  border: 2.3px solid #ebebeb;
  border-radius: 3px;
  height: 6rem;
  padding: 10px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .form__input,
.mw_wp_form__input {
    height: 4.8rem;
  }
}
.form__input_type_textarea,
.mw_wp_form__input_type_textarea {
  height: 23rem;
  resize: vertical;
}
@media screen and (max-width: 768px) {
  .form__input_type_textarea,
.mw_wp_form__input_type_textarea {
    height: 15rem;
  }
}
.form__privacy,
.mw_wp_form__privacy {
  border-bottom: 1px solid #ededed;
  border-top: 1px solid #ededed;
  margin-bottom: 28px;
  padding: 35px 18px 47px;
}
@media screen and (max-width: 768px) {
  .form__privacy,
.mw_wp_form__privacy {
    margin-bottom: 33px;
    padding: 32px 0 24px;
  }
}
.form__privacyHead,
.mw_wp_form__privacyHead {
  font-size: 1.7rem;
  letter-spacing: 0.08em;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .form__privacyHead,
.mw_wp_form__privacyHead {
    font-size: 1.6rem;
  }
}
.form__privacyBody,
.mw_wp_form__privacyBody {
  font-size: 1.5rem;
  height: 18.2rem;
  letter-spacing: 0;
  line-height: 2.133;
  overflow: auto scroll;
  overscroll-behavior: contain;
  padding-right: 20px;
}
.form__privacyTxt:not(:where(:last-child)),
.mw_wp_form__privacyTxt:not(:where(:last-child)) {
  margin-bottom: 2em;
}
.form__privacyTxt_mb_none,
.mw_wp_form__privacyTxt_mb_none {
  margin-bottom: 0;
}
.form__privacyLink,
.mw_wp_form__privacyLink {
  color: #438cc8;
  transition: 0.2s;
}
.form__privacyLink:hover,
.mw_wp_form__privacyLink:hover {
  opacity: 0.5;
}
.form__privacyLink_type_tel,
.mw_wp_form__privacyLink_type_tel {
  color: #1a1a1a;
}
.form__privacyList:not(:where(:last-child)),
.mw_wp_form__privacyList:not(:where(:last-child)) {
  margin-bottom: 2em;
}
.form__checkWrap,
.mw_wp_form__checkWrap {
  display: block;
  margin: 0 auto 54px;
  width: fit-content;
}
.form__checkLabel,
.form .mwform-checkbox-field-text,
.mw_wp_form__checkLabel,
.mw_wp_form .mwform-checkbox-field-text {
  display: grid;
  font-size: 1.7rem;
  gap: 10px;
  grid-template-areas: "check .";
  grid-template-columns: 1.8rem auto;
  justify-content: center;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 768px) {
  .form__checkLabel,
.form .mwform-checkbox-field-text,
.mw_wp_form__checkLabel,
.mw_wp_form .mwform-checkbox-field-text {
    font-size: 1.5rem;
    grid-template-columns: 1.5rem auto;
  }
}
.form__checkLabel::before,
.form .mwform-checkbox-field-text::before,
.mw_wp_form__checkLabel::before,
.mw_wp_form .mwform-checkbox-field-text::before {
  aspect-ratio: 1;
  background: #ddd;
  content: "";
  grid-area: check;
  height: auto;
  margin-top: 0.2rem;
  place-self: center;
  transition: 0.2s;
  width: 100%;
}
.form__checkLabel::after,
.form .mwform-checkbox-field-text::after,
.mw_wp_form__checkLabel::after,
.mw_wp_form .mwform-checkbox-field-text::after {
  aspect-ratio: 5/3;
  border-bottom: 2px solid #438cc8;
  border-left: 2px solid #438cc8;
  content: "";
  grid-area: check;
  height: auto;
  opacity: 0;
  place-self: center;
  transform: translate(4%, -20%) rotate(-45deg);
  transition: 0.2s;
  visibility: hidden;
  width: 80%;
}
.form__check,
.mw_wp_form__check {
  display: none;
}
.form__check:checked + .form__checkLabel::before, .form__check:checked + .mwform-checkbox-field-text::before,
.mw_wp_form__check:checked + .form__checkLabel::before,
.mw_wp_form__check:checked + .mwform-checkbox-field-text::before {
  background: #fff;
  transition: 0.2s;
}
.form__check:checked + .form__checkLabel::after, .form__check:checked + .mwform-checkbox-field-text::after,
.mw_wp_form__check:checked + .form__checkLabel::after,
.mw_wp_form__check:checked + .mwform-checkbox-field-text::after {
  opacity: 1;
  transition: 0.2s;
  visibility: visible;
}
.form__btn,
.mw_wp_form__btn {
  appearance: none;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  color: inherit;
  display: block;
  font-family: inherit;
  font-weight: inherit;
  margin: 0 auto;
  max-width: 100%;
  width: 29.5rem;
}
@media screen and (max-width: 768px) {
  .form__btn,
.mw_wp_form__btn {
    padding: 18px 70px 20px 71px;
    width: 30rem;
  }
}
.form__btn::before,
.mw_wp_form__btn::before {
  left: 30px;
  top: 50%;
}
@media screen and (max-width: 768px) {
  .form__btn::before,
.mw_wp_form__btn::before {
    height: 28px;
    left: 29px;
    width: 28px;
  }
}
.form__btn::after,
.mw_wp_form__btn::after {
  left: 38px;
  top: 50%;
}
@media screen and (max-width: 768px) {
  .form__btn::after,
.mw_wp_form__btn::after {
    left: 36px;
    top: 48%;
  }
}
.form_input .contact__lead_type_confirm,
.mw_wp_form_input .contact__lead_type_confirm {
  display: none;
}
.form_type_confirm .contact__lead_type_input, .form_confirm .contact__lead_type_input,
.mw_wp_form_type_confirm .contact__lead_type_input,
.mw_wp_form_confirm .contact__lead_type_input {
  display: none;
}
.form_type_confirm .contact__lead_type_confirm, .form_confirm .contact__lead_type_confirm,
.mw_wp_form_type_confirm .contact__lead_type_confirm,
.mw_wp_form_confirm .contact__lead_type_confirm {
  display: block;
}
.form_type_confirm .form__dt, .form_confirm .form__dt,
.mw_wp_form_type_confirm .form__dt,
.mw_wp_form_confirm .form__dt {
  padding-top: 0;
}
.form_type_confirm .form__checkWrap, .form_confirm .form__checkWrap,
.mw_wp_form_type_confirm .form__checkWrap,
.mw_wp_form_confirm .form__checkWrap {
  display: grid;
  font-size: 1.7rem;
  gap: 10px;
  grid-template-areas: "check .";
  grid-template-columns: 1.8rem auto;
  justify-content: center;
  letter-spacing: 0.08em;
}
.form_type_confirm .form__checkWrap::after, .form_confirm .form__checkWrap::after,
.mw_wp_form_type_confirm .form__checkWrap::after,
.mw_wp_form_confirm .form__checkWrap::after {
  aspect-ratio: 5/3;
  border-bottom: 2px solid #438cc8;
  border-left: 2px solid #438cc8;
  content: "";
  grid-area: check;
  height: auto;
  place-self: center;
  transform: translate(4%, -20%) rotate(-45deg);
  transition: 0.2s;
  width: 80%;
}
.form_type_confirm .form__privacy, .form_confirm .form__privacy,
.mw_wp_form_type_confirm .form__privacy,
.mw_wp_form_confirm .form__privacy {
  display: none;
}

.form__btn + .form__btn {
  margin-top: 10px;
}

.error {
  display: block;
  width: 100%;
}

.cost {
  padding-top: 285px;
  padding-bottom: 210px;
}
@media screen and (max-width: 1200px) {
  .cost {
    padding-top: 215px;
  }
}
@media screen and (max-width: 768px) {
  .cost {
    padding-top: 107px;
    padding-bottom: 80px;
  }
}
.cost__section {
  padding-top: 75px;
}
@media screen and (max-width: 768px) {
  .cost__section {
    padding-top: 45px;
  }
}
.cost__ttl {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .cost__ttl {
    font-size: 2rem;
  }
}
.cost__smallTtl {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .cost__smallTtl {
    font-size: 1.8rem;
  }
}
.cost__dotTxt {
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .cost__dotTxt {
    font-size: 1.6rem;
  }
}
.cost__dotTxt:not(:first-of-type) {
  margin-bottom: 20px;
}
.cost__dotTxt_mb_small:not(:first-of-type) {
  margin-bottom: 6px;
}
.cost__txt {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .cost__txt {
    font-size: 1.6rem;
  }
}
.cost__txt_paddingBtm_1em {
  padding-bottom: 1em;
}
.cost__txt_paddingBtm_2em {
  padding-bottom: 2em;
}
.cost__txt_marginBtm_10px {
  margin-bottom: 10px;
}
.cost__txt_size_small {
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  line-height: 2.57;
}
.cost__txt + .cost__smallTtl {
  margin-top: 2em;
}
.cost__attention {
  font-size: 1.4rem;
  line-height: 2;
  color: #ed1c24;
}
.cost__link {
  color: #438cc8;
  text-decoration: underline;
  transition: 0.2s;
}
.cost__link:hover {
  opacity: 0.5;
  text-decoration: underline transparent;
}
.cost__link_color_inherit {
  color: inherit;
  text-decoration: underline transparent;
}
.cost__inner {
  border: 5px solid #f2f2f2;
  padding: 40px 70px 60px;
  border-radius: 6px;
  margin: 50px auto 70px;
}
@media screen and (max-width: 768px) {
  .cost__inner {
    padding: 30px 28px 30px 37px;
  }
}
.cost__dotTtl {
  margin-left: -27px;
}
.cost__btn {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  margin-top: 55px;
  min-width: 29.7rem;
}
@media screen and (max-width: 768px) {
  .cost__btn {
    margin: 55px auto 0;
    display: block;
    width: fit-content;
  }
}
@media screen and (max-width: 480px) {
  .cost__btn {
    min-width: unset;
    width: 100%;
  }
}
.cost__list {
  font-size: 1.8rem;
  line-height: 2;
}
.cost__listItem {
  display: flex;
}
.cost__listItem::before {
  content: "・";
  flex: 0 0 auto;
}

.courseMechanical .lowerTtlSec__ttl::after, .courseMechanical .courseIntro__workItem::before, .courseMechanical .courseList__ttl::before, .courseMechanical .courseSecond__balloonTtl, .courseMechanical .courseCompany__subTtl::before, .courseMechanical .courseCompany__detail::before {
  background: #ff4841;
}
.courseMechanical .lowerTtlSec__en, .courseMechanical .courseIntro__ttlAccent, .courseMechanical .courseIntro__workTxt, .courseMechanical .courseLicense__year, .courseMechanical .courseLicense__txt_color_red, .courseMechanical .courseFirst__ttl_color_red, .courseMechanical .courseSecond__num, .courseMechanical .courseSecond__whatsTxt_color_red, .courseMechanical .courseSecond__subEn {
  color: #ff4841;
}
.courseMechanical .courseIntro__work, .courseMechanical .courseLicense__year {
  background: #fff0f0;
}
.courseMechanical .courseSecond__ttl::before {
  border-color: #fff0f0 transparent transparent transparent;
}
.courseMechanical .courseSecond__balloonTtl::before {
  border-color: #ff4841 transparent transparent transparent;
}

.courseElectrical .lowerTtlSec__ttl::after, .courseElectrical .courseIntro__workItem::before, .courseElectrical .courseList__ttl::before, .courseElectrical .courseSecond__balloonTtl, .courseElectrical .courseCompany__subTtl::before, .courseElectrical .courseCompany__detail::before {
  background: #ffa53e;
}
.courseElectrical .lowerTtlSec__en, .courseElectrical .courseIntro__ttlAccent, .courseElectrical .courseIntro__workTxt, .courseElectrical .courseLicense__year, .courseElectrical .courseLicense__txt_color_orange, .courseElectrical .courseFirst__ttl_color_orange, .courseElectrical .courseSecond__num, .courseElectrical .courseSecond__whatsTxt_color_orange, .courseElectrical .courseSecond__subEn {
  color: #ffa53e;
}
.courseElectrical .courseIntro__work, .courseElectrical .courseLicense__year {
  background: #fff8f0;
}
.courseElectrical .courseSecond__ttl::before {
  border-color: #fff8f0 transparent transparent transparent;
}
.courseElectrical .courseSecond__balloonTtl::before {
  border-color: #ffa53e transparent transparent transparent;
}

.courseBuilding .lowerTtlSec__ttl::after, .courseBuilding .courseIntro__workItem::before, .courseBuilding .courseList__ttl::before, .courseBuilding .courseSecond__balloonTtl, .courseBuilding .courseCompany__subTtl::before, .courseBuilding .courseCompany__detail::before {
  background: #3ac98a;
}
.courseBuilding .lowerTtlSec__en, .courseBuilding .courseIntro__ttlAccent, .courseBuilding .courseIntro__workTxt, .courseBuilding .courseLicense__year, .courseBuilding .courseLicense__txt_color_green, .courseBuilding .courseFirst__ttl_color_green, .courseBuilding .courseSecond__num, .courseBuilding .courseSecond__whatsTxt_color_green, .courseBuilding .courseSecond__subEn {
  color: #3ac98a;
}
.courseBuilding .courseIntro__work, .courseBuilding .courseLicense__year {
  background: #effbf6;
}
.courseBuilding .courseSecond__ttl::before {
  border-color: #effbf6 transparent transparent transparent;
}
.courseBuilding .courseSecond__balloonTtl::before {
  border-color: #3ac98a transparent transparent transparent;
}

.courseLower .lowerTtlSec__small {
  margin-bottom: 12px;
}

.courseIntro {
  padding-top: 73px;
  margin-bottom: 73px;
}
@media screen and (max-width: 768px) {
  .courseIntro {
    padding-top: 50px;
    margin-bottom: 34px;
  }
}
.courseIntro__inner {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
}
@media screen and (max-width: 768px) {
  .courseIntro__inner {
    gap: 40px;
  }
}
.courseIntro__wrap {
  width: calc(50% - 30px);
}
@media screen and (max-width: 1024px) {
  .courseIntro__wrap {
    width: 100%;
  }
}
.courseIntro__ttl {
  font-size: 3.2rem;
  line-height: 1.75;
  padding-bottom: 26px;
}
@media screen and (max-width: 768px) {
  .courseIntro__ttl {
    font-size: 2.2rem;
    padding-bottom: 40px;
  }
}
.courseIntro__txt {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .courseIntro__txt {
    font-size: 1.6rem;
  }
}
.courseIntro__work {
  padding: 38px 40px 46px;
  margin-top: 42px;
  border-radius: 6px;
}
@media screen and (max-width: 768px) {
  .courseIntro__work {
    padding: 30px 20px 40px;
  }
}
.courseIntro__workTxt {
  font-size: 2.2rem;
  font-weight: 600;
  padding-bottom: 20px;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .courseIntro__workTxt {
    font-size: 1.8rem;
  }
}
.courseIntro__workList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 14px 28px;
  margin: auto;
}
.courseIntro__workItem {
  padding-left: 15px;
  position: relative;
}
.courseIntro__workItem::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.courseIntro__fig {
  width: calc(50% - 30px);
  aspect-ratio: 59/62;
  border-radius: 10px;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .courseIntro__fig {
    width: 100%;
  }
}
.courseIntro__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center/center;
}

.courseElectrical .courseIntro__workItem {
  width: 143px;
}
@media screen and (max-width: 1024px) {
  .courseElectrical .courseIntro__workItem {
    width: auto;
  }
}

.courseLicense {
  padding-bottom: 133px;
}
@media screen and (max-width: 768px) {
  .courseLicense {
    padding-bottom: 60px;
  }
}
.courseLicense__inner {
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 60px 60px 57px;
}
@media screen and (max-width: 768px) {
  .courseLicense__inner {
    padding: 40px 20px 50px;
  }
}
.courseLicense__ttl {
  display: flex;
  align-items: center;
  font-size: 2.2rem;
  font-weight: 600;
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .courseLicense__ttl {
    font-size: 2rem;
  }
}
.courseLicense__svg {
  margin-right: 17px;
}
@media screen and (max-width: 768px) {
  .courseLicense__svg {
    width: 28px;
    height: auto;
  }
}
.courseLicense__list {
  list-style: none;
}
.courseLicense__item {
  display: flex;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .courseLicense__item {
    flex-direction: column;
  }
}
.courseLicense__item + .courseLicense__item {
  padding-top: 20px;
}
@media screen and (max-width: 768px) {
  .courseLicense__item + .courseLicense__item {
    padding-top: 40px;
  }
}
.courseLicense__year {
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 38px;
  line-height: 1;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
@media screen and (max-width: 768px) {
  .courseLicense__year {
    width: 100%;
    height: 80px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    padding-top: 0;
    justify-content: center;
  }
}
.courseLicense__num {
  font-size: 3.6rem;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 768px) {
  .courseLicense__num {
    font-size: 2.8rem;
  }
}
.courseLicense__small {
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  padding-top: 8px;
}
@media screen and (max-width: 768px) {
  .courseLicense__small {
    padding-top: 2px;
  }
}
.courseLicense__wrap {
  width: calc(100% - 120px);
  padding: 36px 40px 40px;
  background: #f8f8f8;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
@media screen and (max-width: 768px) {
  .courseLicense__wrap {
    padding: 30px 18px 40px;
    width: 100%;
  }
}
.courseLicense__txt {
  line-height: 2;
  word-wrap: break-word;
}
.courseLicense__txt_color_red, .courseLicense__txt_color_orange, .courseLicense__txt_color_green {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  padding-top: 6px;
  padding-bottom: 8px;
}
.courseLicense__txt + .courseLicense__txt_color_red, .courseLicense__txt + .courseLicense__txt_color_orange, .courseLicense__txt + .courseLicense__txt_color_green {
  padding-top: 23px;
}
.courseLicense__txt .courseLicense__span {
  margin-left: 5px;
}
.courseLicense__span {
  color: #c93a3a;
  font-size: 1.2rem;
}
.courseLicense__smallTxt {
  font-size: 1.2rem;
  line-height: 2.6666666667;
}
.courseLicense__smallTxt + .courseLicense__txt_color_red, .courseLicense__smallTxt + .courseLicense__txt_color_orange, .courseLicense__smallTxt + .courseLicense__txt_color_green {
  padding-top: 23px;
}

.courseFirst {
  padding-bottom: 138px;
}
@media screen and (max-width: 768px) {
  .courseFirst {
    padding-bottom: 60px;
  }
}
.courseFirst__inner {
  border-top: 1px solid #e6e6e6;
  padding-top: 105px;
}
@media screen and (max-width: 768px) {
  .courseFirst__inner {
    padding-top: 60px;
  }
}
.courseFirst__svg {
  margin: 0 auto 16px;
  display: block;
}
@media screen and (max-width: 768px) {
  .courseFirst__svg {
    width: 45px;
    height: auto;
  }
}
.courseFirst__ttl {
  text-align: center;
  font-size: 3.2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  padding-bottom: 70px;
}
@media screen and (max-width: 768px) {
  .courseFirst__ttl {
    font-size: 2rem;
    padding-bottom: 40px;
  }
}

.courseSecond {
  padding-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .courseSecond {
    padding-bottom: 0;
  }
}
.courseSecond__inner {
  border-top: 1px solid #e6e6e6;
  padding-top: 140px;
}
@media screen and (max-width: 768px) {
  .courseSecond__inner {
    padding-top: 70px;
  }
}
.courseSecond__unit {
  padding-bottom: 150px;
}
@media screen and (max-width: 768px) {
  .courseSecond__unit {
    padding-bottom: 60px;
  }
}
.courseSecond__ttl {
  position: relative;
  display: block;
  margin: auto;
  text-align: center;
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .courseSecond__ttl {
    font-size: 2rem;
    margin-bottom: 70px;
  }
}
.courseSecond__ttl::before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 108px 210px 0 210px;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: -20px;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .courseSecond__ttl::before {
    border-width: 80px 160px 0 160px;
  }
}
.courseSecond__num {
  font-size: 4.8rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-right: 6px;
}
@media screen and (max-width: 768px) {
  .courseSecond__num {
    font-size: 3.6rem;
  }
}
.courseSecond__intro {
  text-align: center;
  font-size: 1.8rem;
  padding-bottom: 72px;
}
@media screen and (max-width: 768px) {
  .courseSecond__intro {
    font-size: 1.6rem;
    line-height: 2;
    padding-bottom: 40px;
  }
}
.courseSecond__balloonTtl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 130px;
  border-radius: 65px;
  color: #fff;
  position: relative;
  margin-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .courseSecond__balloonTtl {
    height: 86px;
    margin-bottom: 50px;
  }
}
.courseSecond__balloonTtl::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 20px 0 20px;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .courseSecond__balloonTtl::before {
    bottom: -24px;
  }
}
.courseSecond__balloonJp {
  font-size: 3.2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  padding-bottom: 4px;
}
@media screen and (max-width: 768px) {
  .courseSecond__balloonJp {
    font-size: 1.8rem;
  }
}
.courseSecond__balloonEn {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0.15em;
}
@media screen and (max-width: 768px) {
  .courseSecond__balloonEn {
    font-size: 0.8rem;
  }
}
.courseSecond__flex {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  padding-bottom: 100px;
}
@media screen and (max-width: 768px) {
  .courseSecond__flex {
    padding-bottom: 60px;
  }
}
.courseSecond__wrap {
  width: calc(50% - 30px);
}
@media screen and (max-width: 1024px) {
  .courseSecond__wrap {
    width: 100%;
  }
}
.courseSecond__accent {
  font-size: 2.6rem;
  letter-spacing: 0.05em;
  line-height: 1.7;
  font-weight: 600;
  padding-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .courseSecond__accent {
    font-size: 2.2rem;
    padding-bottom: 30px;
  }
}
.courseSecond__txt {
  font-size: 1.8rem;
  line-height: 2;
  padding-bottom: 36px;
}
@media screen and (max-width: 768px) {
  .courseSecond__txt {
    font-size: 1.6rem;
    padding-top: 0.5em;
    padding-bottom: 1em;
  }
}
.courseSecond__whats {
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 30px 45px 35px 40px;
}
@media screen and (max-width: 768px) {
  .courseSecond__whats {
    padding: 40px 40px 30px;
  }
}
.courseSecond__whatsTxt {
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .courseSecond__whatsTxt {
    font-size: 1.5rem;
  }
}
.courseSecond__whatsTxt_color_red, .courseSecond__whatsTxt_color_orange, .courseSecond__whatsTxt_color_green {
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .courseSecond__whatsTxt_color_red, .courseSecond__whatsTxt_color_orange, .courseSecond__whatsTxt_color_green {
    font-size: 1.8rem;
  }
}
.courseSecond__fig {
  width: calc(50% - 30px);
  height: 100%;
  border-radius: 6px;
  aspect-ratio: 59/62;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .courseSecond__fig {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .courseSecond__fig {
    height: auto;
    aspect-ratio: 17/18;
  }
}
.courseSecond__fig_display_pc {
  display: block;
}
@media screen and (max-width: 768px) {
  .courseSecond__fig_display_pc {
    display: none;
  }
}
.courseSecond__fig_display_sp {
  display: none;
}
@media screen and (max-width: 768px) {
  .courseSecond__fig_display_sp {
    display: block;
  }
}
.courseSecond__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center/center;
}
.courseSecond__subTtl {
  padding-bottom: 57px;
}
@media screen and (max-width: 768px) {
  .courseSecond__subTtl {
    display: block;
    padding-bottom: 40px;
  }
}
.courseSecond__subEn {
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
  letter-spacing: 0.15em;
  font-weight: 600;
  margin-right: 27px;
}
@media screen and (max-width: 768px) {
  .courseSecond__subEn {
    display: block;
    font-size: 2.6rem;
    margin-right: 0;
  }
}
.courseSecond__subJp {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .courseSecond__subJp {
    font-size: 1.8rem;
  }
}

.courseCompany {
  padding-bottom: 220px;
}
@media screen and (max-width: 768px) {
  .courseCompany {
    padding-bottom: 60px;
  }
}
.courseCompany__inner {
  border-top: 1px solid #e6e6e6;
  padding-top: 108px;
}
@media screen and (max-width: 768px) {
  .courseCompany__inner {
    padding-top: 60px;
  }
}
.courseCompany__svg {
  margin: 0 auto 16px;
  display: block;
}
@media screen and (max-width: 768px) {
  .courseCompany__svg {
    width: 45px;
    height: auto;
  }
}
.courseCompany__ttl {
  text-align: center;
  font-size: 3.2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  padding-bottom: 70px;
}
@media screen and (max-width: 768px) {
  .courseCompany__ttl {
    font-size: 2rem;
    padding-bottom: 50px;
  }
}
.courseCompany__unit {
  border-radius: 6px;
  border: 4px solid #f8f8f8;
  padding: 40px 50px 50px;
}
@media screen and (max-width: 768px) {
  .courseCompany__unit {
    padding: 40px 30px;
  }
}
.courseCompany__unit + .courseCompany__unit {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .courseCompany__unit + .courseCompany__unit {
    margin-top: 40px;
  }
}
.courseCompany__subTtl {
  padding-left: 28px;
  position: relative;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 2.6rem;
  padding-bottom: 3px;
  margin-bottom: 24px;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .courseCompany__subTtl {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}
.courseCompany__subTtl::before {
  content: "";
  width: 12px;
  height: 26px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 3px;
}
@media screen and (max-width: 768px) {
  .courseCompany__subTtl::before {
    width: 10px;
    height: 22px;
  }
}
.courseCompany__list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 0;
}
@media screen and (max-width: 768px) {
  .courseCompany__list {
    flex-direction: column;
    gap: 6px 0;
  }
}
.courseCompany__list + .courseCompany__list {
  padding-top: 35px;
}
.courseCompany__term {
  width: 100%;
  display: block;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .courseCompany__term {
    font-size: 1.8rem;
    padding-bottom: 3px;
  }
}
.courseCompany__detail {
  padding: 0 14px;
  position: relative;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .courseCompany__detail {
    font-size: 1.4rem;
    line-height: 2;
  }
}
.courseCompany__detail::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 3px;
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .courseCompany__detail::before {
    top: 11px;
  }
}
.courseCompany__link {
  color: #438cc8;
  text-decoration: underline;
  transition: 0.2s;
}
.courseCompany__link:hover {
  opacity: 0.5;
}

.courseAssBtn {
  border-top: 1px solid #e6e6e6;
  padding-bottom: 120px;
  padding-top: 90px;
}
@media screen and (max-width: 768px) {
  .courseAssBtn {
    padding-bottom: 100px;
    padding-top: 40px;
  }
}
.courseAssBtn__link {
  align-items: center;
  background-color: #438cc8;
  border-radius: 3px;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 120px;
  margin: 0 auto;
  padding: 49px 20px;
  position: relative;
  transition: all 0.2s;
  width: 590px;
}
@media screen and (max-width: 768px) {
  .courseAssBtn__link {
    height: auto;
    padding: 25px;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .courseAssBtn__link {
    padding: 28px 35px;
  }
}
@media screen and (max-width: 480px) {
  .courseAssBtn__link {
    padding: 23px 15px;
  }
}
.courseAssBtn__link:hover {
  opacity: 0.4;
}
.courseAssBtn__link:before {
  background-color: #fff;
  border-radius: 9999px;
  content: "";
  height: 36px;
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
}
@media screen and (max-width: 768px) {
  .courseAssBtn__link:before {
    height: 24px;
    right: 23px;
    width: 24px;
  }
}
@media screen and (max-width: 480px) {
  .courseAssBtn__link:before {
    height: 20px;
    right: 15px;
    width: 20px;
  }
}
.courseAssBtn__link:after {
  content: "";
  border-top: solid 2px #438cc8;
  border-right: solid 2px #438cc8;
  height: 10px;
  position: absolute;
  right: 53px;
  top: 49%;
  transform: rotate(45deg) translateY(-50%);
  width: 10px;
}
@media screen and (max-width: 768px) {
  .courseAssBtn__link:after {
    height: 8px;
    right: 35px;
    top: 48%;
    width: 8px;
  }
}
@media screen and (max-width: 480px) {
  .courseAssBtn__link:after {
    height: 7px;
    right: 25px;
    width: 7px;
  }
}
.courseAssBtn__linkSpan {
  font-size: clamp(2rem, 1.686rem + 0.29vw, 2.2rem);
  font-weight: 500;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .courseAssBtn__linkSpan {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 480px) {
  .courseAssBtn__linkSpan {
    font-size: clamp(1.2rem, 1.057rem + 0.45vw, 1.4rem);
  }
}

.courseOther {
  background: #f8f8f8;
  padding-top: 118px;
  padding-bottom: 196px;
}
@media screen and (max-width: 768px) {
  .courseOther {
    padding-top: 60px;
    padding-bottom: 100px;
  }
}
.courseOther__ttl {
  font-size: 3.2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  padding-bottom: 70px;
}
@media screen and (max-width: 768px) {
  .courseOther__ttl {
    font-size: 2.2rem;
  }
}
.courseOther__list {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
}
.courseOther__item {
  width: calc(50% - 30px);
  position: relative;
}
@media screen and (max-width: 1024px) {
  .courseOther__item {
    width: 100%;
  }
}
.courseOther__item_color_red .courseOther__accent {
  color: #ff4841;
}
.courseOther__item_color_red .courseOther__circle {
  background: #ff4841;
}
@media screen and (max-width: 768px) {
  .courseOther__item_color_red .courseOther__wrap {
    background: #fff0f0;
  }
}
.courseOther__item_color_orange .courseOther__accent {
  color: #ffa53e;
}
.courseOther__item_color_orange .courseOther__circle {
  background: #ffa53e;
}
@media screen and (max-width: 768px) {
  .courseOther__item_color_orange .courseOther__wrap {
    background: #fff8f0;
  }
}
.courseOther__item_color_green .courseOther__accent {
  color: #3ac98a;
}
.courseOther__item_color_green .courseOther__circle {
  background: #3ac98a;
}
@media screen and (max-width: 768px) {
  .courseOther__item_color_green .courseOther__wrap {
    background: #effbf6;
  }
}
.courseOther__wrap {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #fff;
  padding: 67px 0 52px;
}
@media screen and (max-width: 768px) {
  .courseOther__wrap {
    position: relative;
    padding: 34px 0 76px;
  }
}
.courseOther__subTtl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  padding-bottom: 54px;
}
@media screen and (max-width: 768px) {
  .courseOther__subTtl {
    padding-bottom: 20px;
  }
}
.courseOther__accent {
  font-size: 3.6rem;
  font-weight: 600;
  padding-bottom: 14px;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .courseOther__accent {
    font-size: 2.2rem;
    padding-bottom: 11px;
  }
}
.courseOther__middle {
  font-size: 2.4rem;
  font-weight: 600;
  padding-bottom: 19px;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .courseOther__middle {
    font-size: 1.8rem;
    padding-bottom: 11px;
  }
}
.courseOther__small {
  font-weight: 600;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .courseOther__small {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 321px) {
  .courseOther__small {
    font-size: 1.4rem;
  }
}
.courseOther__course {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .courseOther__course {
    flex-direction: column;
  }
}
.courseOther__circle {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .courseOther__circle {
    width: 70px;
    height: 70px;
    font-size: 1.4rem;
  }
}
.courseOther__courseList {
  padding-left: 20px;
}
@media screen and (max-width: 768px) {
  .courseOther__courseList {
    padding-left: 0;
    padding-top: 20px;
    width: 100%;
  }
}
.courseOther__courseItem {
  width: 282px;
  height: 38px;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 600;
  border-radius: 6px;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .courseOther__courseItem {
    width: calc(100% - 60px);
    background: #fff;
    margin: auto;
  }
}
@media screen and (max-width: 321px) {
  .courseOther__courseItem {
    width: calc(100% - 40px);
  }
}
.courseOther__courseItem + .courseOther__courseItem {
  margin-top: 10px;
}
.courseOther__fig {
  width: 100%;
  aspect-ratio: 59/32;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .courseOther__fig {
    aspect-ratio: 5/4;
  }
}
.courseOther__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center/center;
}
.courseOther__link {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  max-width: 300px;
  height: 70px;
  bottom: -35px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 35px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  transition: 0.2s;
}
@media screen and (max-width: 768px) {
  .courseOther__link {
    width: calc(100% - 40px);
    height: 65px;
    bottom: -32.5px;
  }
}
.courseOther__link:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.32);
}
.courseOther__svg {
  position: absolute;
  left: 23px;
  top: 0;
  bottom: 0;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .courseOther__svg {
    width: 28px;
    height: 28px;
    left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .course .lowerTtlSec__figure img {
    object-position: left;
  }
}
.course__container {
  padding-top: 50px;
  padding-bottom: 277px;
}
@media screen and (max-width: 768px) {
  .course__container {
    padding-top: 58px;
    padding-bottom: 65px;
  }
}
.course__txt {
  text-align: center;
  font-size: 1.8rem;
}
@media screen and (max-width: 768px) {
  .course__txt {
    display: none;
  }
}
.course__list {
  padding-top: 117px;
}
@media screen and (max-width: 768px) {
  .course__list {
    padding-top: 0;
  }
}
.course__item {
  display: flex;
  width: 100%;
  height: auto;
}
@media screen and (max-width: 768px) {
  .course__item {
    flex-direction: column;
  }
}
.course__item + .course__item {
  padding-top: 128px;
}
@media screen and (max-width: 768px) {
  .course__item + .course__item {
    padding-top: 40px;
  }
}
.course__item_color_red .course__itemWrap {
  background: #fff0f0;
}
.course__item_color_red .course__accent {
  color: #ff4841;
}
.course__item_color_red .course__circle {
  background: #ff4841;
}
.course__item_color_orange .course__itemWrap {
  background: #fff8f0;
}
.course__item_color_orange .course__accent {
  color: #ffa53e;
}
.course__item_color_orange .course__circle {
  background: #ffa53e;
}
.course__item_color_green .course__itemWrap {
  background: #effbf6;
}
.course__item_color_green .course__accent {
  color: #3ac98a;
}
.course__item_color_green .course__circle {
  background: #3ac98a;
}
.course__itemWrap {
  min-width: 520px;
  width: 42%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 87px 78px 94px 60px;
  position: relative;
  transition: 0.2s;
}
@media screen and (max-width: 1024px) {
  .course__itemWrap {
    min-width: 450px;
    padding-left: 40px;
    padding-right: 50px;
  }
}
@media screen and (max-width: 768px) {
  .course__itemWrap {
    padding: 32px 0 76px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 0;
    min-width: auto;
    width: 100%;
  }
}
.course__ttl {
  text-align: center;
  line-height: 1;
  padding-bottom: 54px;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .course__ttl {
    padding-bottom: 30px;
    padding-bottom: 28px;
  }
}
.course__accent {
  font-size: 3.6rem;
  display: block;
  padding-bottom: 14px;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .course__accent {
    font-size: 2.4rem;
    padding-bottom: 12px;
  }
}
.course__middle {
  display: block;
  font-size: 2.4rem;
  padding-bottom: 19px;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .course__middle {
    font-size: 1.6rem;
    padding-bottom: 7px;
  }
}
.course__small {
  display: block;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .course__small {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
.course__course {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .course__course {
    padding: 0 20px;
  }
}
.course__circle {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .course__circle {
    width: 70px;
    height: 70px;
    font-size: 1.4rem;
  }
}
.course__courseList {
  width: calc(100% - 80px);
  padding-left: 20px;
}
@media screen and (max-width: 768px) {
  .course__courseList {
    width: calc(100% - 70px);
    padding-left: 10px;
  }
}
.course__courseItem {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background: #fff;
  border-radius: 6px;
  font-size: 1.8rem;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .course__courseItem {
    height: auto;
    font-size: 1.4rem;
    padding: 8.5px 10px 8.5px 20px;
    justify-content: flex-start;
  }
}
.course__courseItem + .course__courseItem {
  margin-top: 10px;
}
.course__link {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  height: 70px;
  margin: auto;
  background: #fff;
  border-radius: 35px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  position: absolute;
  bottom: -35px;
  left: 0;
  right: 0;
  transition: 0.2s;
}
@media screen and (max-width: 768px) {
  .course__link {
    width: calc(100% - 40px);
    height: 65px;
    bottom: -32.5px;
  }
}
.course__link:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.32);
}
.course__svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 23px;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .course__svg {
    width: 28px;
    height: 28px;
    left: 30px;
  }
}
.course__fig {
  max-width: 58%;
  height: 430px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .course__fig {
    max-width: none;
    width: 100%;
    height: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    aspect-ratio: 17/13;
  }
}
.course__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center/center;
}

.credentials .lowerMiddleTtl {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .credentials .lowerMiddleTtl {
    font-size: 2rem;
    margin-bottom: 30px;
  }
}
.credentials .moreBtn {
  padding: 23px 55px 23px 88px;
}
@media screen and (max-width: 768px) {
  .credentials .moreBtn {
    padding: 19px 22px 19px 68px;
    width: 100%;
  }
}
.credentials .moreBtn::before {
  left: 27px;
  top: 50%;
}
@media screen and (max-width: 768px) {
  .credentials .moreBtn::before {
    left: 20px;
  }
}
.credentials .moreBtn::after {
  left: 35px;
}
@media screen and (max-width: 768px) {
  .credentials .moreBtn::after {
    left: 28px;
  }
}
.credentials__inner {
  padding-bottom: 252px;
  padding-top: 100px;
}
@media screen and (max-width: 768px) {
  .credentials__inner {
    padding-bottom: 80px;
    padding-top: 40px;
  }
}
.credentials__unit + .credentials__unit {
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  .credentials__unit + .credentials__unit {
    margin-top: 60px;
  }
}
.credentials__txt {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 35px;
}
@media screen and (max-width: 768px) {
  .credentials__txt {
    font-size: 1.6rem;
  }
}
.credentials__caution {
  color: #ed1c24;
}
.credentials__group {
  border: 5px solid #f2f2f2;
  border-radius: 6px;
  margin: 40px 0 0;
  padding: 38px 65px 48px;
}
@media screen and (max-width: 768px) {
  .credentials__group {
    border-width: 3px;
    padding: 30px 17px 40px;
  }
}
.credentials__grTtl {
  margin-bottom: 10px;
  margin-left: -25px;
}
@media screen and (max-width: 768px) {
  .credentials__grTtl {
    margin-bottom: 20px;
    margin-left: 0;
  }
}
.credentials__grTxt {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .credentials__grTxt {
    font-size: 1.6rem;
  }
}
.credentials__grLink {
  color: #438cc8;
  display: inline-block;
  font-size: 1.8rem;
  margin-top: 10px;
  text-decoration: underline;
  transition: all 0.2s;
}
.credentials__grLink:hover {
  opacity: 0.4;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .credentials__grLink:hover {
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
  .credentials__grLink {
    font-size: 1.6rem;
  }
}
.credentials__info {
  margin: 20px 0 0;
}
.credentials__info + .credentials__info {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .credentials__info + .credentials__info {
    margin-top: 30px;
  }
}
.credentials__infoTtl {
  color: #438cc8;
  font-size: 1.8rem;
  line-height: 2;
  margin: 0 0 10px;
}
@media screen and (max-width: 768px) {
  .credentials__infoTtl {
    font-size: 1.7rem;
  }
}
.credentials__infoTxt {
  font-size: 1.6rem;
  line-height: 2;
  margin: 10px 0 0;
}
@media screen and (max-width: 768px) {
  .credentials__infoTxt {
    font-size: 1.5rem;
  }
}
.credentials__infoTxt_size_l {
  font-size: 1.8rem;
}
@media screen and (max-width: 768px) {
  .credentials__infoTxt_size_l {
    font-size: 1.6rem;
  }
}
.credentials__infoChips {
  column-gap: 1em;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.6rem;
  line-height: 2;
  margin: 1em 0 0;
}
@media screen and (max-width: 768px) {
  .credentials__infoChips {
    font-size: 1.5rem;
  }
}
.credentials__infoBtn {
  margin: 5px 0;
}
@media screen and (max-width: 768px) {
  .credentials__infoBtn {
    margin: 10px 0;
  }
}

.employment {
  padding-top: 285px;
  padding-bottom: 200px;
}
@media screen and (max-width: 1200px) {
  .employment {
    padding-top: 215px;
  }
}
@media screen and (max-width: 768px) {
  .employment {
    padding-top: 107px;
    padding-bottom: 80px;
  }
}
.employment__pagination {
  padding-top: 105px;
}
@media screen and (max-width: 768px) {
  .employment__pagination {
    padding-top: 60px;
  }
}

.employmentForm {
  margin-top: 80px;
  margin-bottom: 90px;
}
@media screen and (max-width: 768px) {
  .employmentForm {
    margin-top: 70px;
    margin-bottom: 60px;
  }
}
.employmentForm__list {
  display: flex;
  align-items: flex-end;
  gap: 30px;
  padding: 50px 50px 60px;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}
@media screen and (max-width: 1200px) {
  .employmentForm__list {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 768px) {
  .employmentForm__list {
    padding: 30px 25px 40px;
    gap: 25px;
  }
}
.employmentForm__item {
  width: 25%;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .employmentForm__item {
    width: 100%;
    max-width: 600px;
  }
}
.employmentForm__item::before {
  content: "";
  display: block;
  width: 10px;
  height: 5px;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.021%22%20height%3D%225.054%22%20viewBox%3D%220%200%209.021%205.054%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_78%22%20data-name%3D%22%E3%83%91%E3%82%B9%2078%22%20d%3D%22M4591.671%2C639.474l4.164%2C4%2C4.164-4%22%20transform%3D%22translate(-4591.325%20-639.113)%22%20fill%3D%22none%22%20stroke%3D%22%232d2d2d%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E") no-repeat;
  background-size: contain;
  position: absolute;
  right: 20px;
  bottom: 22px;
}
.employmentForm__item_type_submit {
  width: calc(25% - 90px);
}
@media screen and (max-width: 1200px) {
  .employmentForm__item_type_submit {
    width: 100%;
    max-width: 600px;
  }
}
.employmentForm__item_type_submit::before {
  content: none;
}
.employmentForm__ttl {
  font-weight: bold;
  padding-bottom: 8px;
}
.employmentForm__select {
  appearance: none;
  border: none;
  border-radius: 3px;
  background: #f6f6f6;
  width: 100%;
  height: 50px;
  padding: 0 30px 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  letter-spacing: 0.05em;
}
.employmentForm__option_hidden {
  display: none;
}
.employmentForm__btn {
  appearance: none;
  border: none;
  border-radius: 3px;
  width: 100%;
  height: 50px;
  color: #fff;
  background: #438cc8;
  transition: 0.2s;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 1200px) {
  .employmentForm__btn {
    margin-top: 15px;
  }
}
.employmentForm__btn:hover {
  opacity: 0.5;
}
.employmentForm__annotation {
  margin-top: 30px;
  text-align: center;
}

.employmentResult__ttl {
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .employmentResult__ttl {
    margin-bottom: 35px;
    line-height: 2;
  }
}
.employmentResult__ttl::before {
  bottom: auto;
  top: 1px;
}
@media screen and (max-width: 768px) {
  .employmentResult__ttl::before {
    top: 9px;
  }
}
.employmentResult__item {
  display: flex;
  padding: 37px 20px 48px;
  border-bottom: 1px solid #f2f2f2;
}
@media screen and (max-width: 768px) {
  .employmentResult__item {
    padding: 30px 0 40px;
  }
}
.employmentResult__item:first-of-type {
  border-top: 1px solid #f2f2f2;
}
.employmentResult__inner {
  flex: 1 1 auto;
}
.employmentResult__companyTtl {
  padding-bottom: 19px;
}
.employmentResult__name {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 1200px) {
  .employmentResult__name {
    display: block;
    padding-bottom: 10px;
  }
}
.employmentResult__furigana {
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  margin-left: 10px;
}
@media screen and (max-width: 1200px) {
  .employmentResult__furigana {
    margin-left: 0px;
    display: block;
  }
}
.employmentResult__catWrap {
  display: flex;
  gap: 10px;
  padding-bottom: 15px;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .employmentResult__catWrap {
    padding-bottom: 30px;
    flex-wrap: wrap;
  }
}
.employmentResult__cat {
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  padding: 5px 15px 6px;
  background: #f6f6f6;
  width: fit-content;
}
.employmentResult__txt {
  letter-spacing: 0.05em;
  line-height: 2;
  padding-bottom: 10px;
}
.employmentResult__link {
  letter-spacing: 0.05em;
  color: #438cc8;
  text-decoration: underline;
  transition: 0.2s;
  word-break: break-all;
}
.employmentResult__link:hover {
  opacity: 0.6;
  text-decoration: none;
}
.employmentResult__fig {
  width: 300px;
  min-width: 300px;
  height: fit-content;
  aspect-ratio: 3/2;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  margin-left: 60px;
  padding: 20px;
}
@media screen and (max-width: 768px) {
  .employmentResult__fig {
    margin: 0 auto 20px;
    max-width: calc(100vw - 40px);
    min-width: auto;
  }
}
.employmentResult__figLink {
  display: block;
  width: 100%;
  height: 100%;
}
.employmentResult__figLink:hover {
  opacity: 0.7;
}
.employmentResult__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center/center;
}
.employmentResult__notFound {
  line-height: 2;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 768px) {
  .employmentResult__notFound {
    font-size: 1.4rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  _::-webkit-full-page-media, _:future, :root .employmentResult__figLink {
    height: calc(100% - 40px);
  }
}
.examQuestion .lowerMiddleTtl {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .examQuestion .lowerMiddleTtl {
    font-size: 2rem;
    margin-bottom: 30px;
  }
}
.examQuestion .moreBtn {
  padding: 23px 128px 23px 136px;
}
@media screen and (max-width: 768px) {
  .examQuestion .moreBtn {
    padding: 19px 48px 19px 68px;
    width: 100%;
  }
}
.examQuestion .moreBtn::before {
  left: 27px;
  top: 50%;
}
@media screen and (max-width: 768px) {
  .examQuestion .moreBtn::before {
    left: 20px;
  }
}
.examQuestion .moreBtn::after {
  left: 35px;
}
@media screen and (max-width: 768px) {
  .examQuestion .moreBtn::after {
    left: 28px;
  }
}
.examQuestion__inner {
  padding-bottom: 200px;
  padding-top: 70px;
}
@media screen and (max-width: 768px) {
  .examQuestion__inner {
    padding-bottom: 100px;
    padding-top: 50px;
  }
}
.examQuestion__unit + .examQuestion__unit {
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  .examQuestion__unit + .examQuestion__unit {
    margin-top: 40px;
  }
}
.examQuestion__txt {
  font-size: 1.8rem;
  line-height: 2;
  margin: 10px 0 0;
}
@media screen and (max-width: 768px) {
  .examQuestion__txt {
    font-size: 1.6rem;
  }
}
.examQuestion__list {
  margin: 60px 0 0;
}
@media screen and (max-width: 768px) {
  .examQuestion__list {
    margin: 40px 0 0;
  }
}
.examQuestion__item {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .examQuestion__item {
    font-size: 1.6rem;
  }
}
.examQuestion__link {
  color: #438cc8;
  text-decoration: underline;
  transition: 0.2s;
}
.examQuestion__link:hover {
  opacity: 0.5;
  text-decoration: underline transparent;
}
.examQuestion__btn {
  margin: 100px 0 0;
}
@media screen and (max-width: 768px) {
  .examQuestion__btn {
    margin: 60px 0 0;
  }
}

.examiness__inner {
  padding-bottom: 70px;
  padding-top: 105px;
}
@media screen and (max-width: 768px) {
  .examiness__inner {
    padding-top: 66px;
    padding-bottom: 4px;
  }
}
.examiness__unit:nth-of-type(1) {
  padding-bottom: 100px;
}
@media screen and (max-width: 768px) {
  .examiness__unit:nth-of-type(1) {
    padding-bottom: 60px;
  }
}
.examiness__unit:nth-of-type(2) {
  padding-top: 89px;
}
@media screen and (max-width: 768px) {
  .examiness__unit:nth-of-type(2) {
    padding-top: 55px;
  }
}
.examiness__unit:nth-of-type(2) .lowerMiddleTtl {
  margin-bottom: 62px;
}
@media screen and (max-width: 768px) {
  .examiness__unit:nth-of-type(2) .lowerMiddleTtl {
    margin-bottom: 36px;
  }
}
.examiness__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 65px 55px;
}
@media screen and (max-width: 1024px) {
  .examiness__wrap {
    gap: 50px 40px;
  }
}
@media screen and (max-width: 768px) {
  .examiness__wrap {
    gap: 45px 0px;
  }
}
.examiness__linkUnit {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  width: calc(33.333333% - 37px);
}
@media screen and (max-width: 1024px) {
  .examiness__linkUnit {
    width: calc(33.333333% - 27px);
  }
}
@media screen and (max-width: 768px) {
  .examiness__linkUnit {
    width: 100%;
  }
}
.examiness__link {
  transition: all 0.2s;
}
.examiness__link:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .examiness__link:hover {
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
  .examiness__fig {
    height: 155px;
    overflow: hidden;
  }
}
.examiness__fig img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.examiness__detail {
  height: 100%;
  padding: 30px 40px;
}
@media screen and (max-width: 1024px) {
  .examiness__detail {
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .examiness__detail {
    height: auto;
    padding: 25px 30px;
  }
}
.examiness__ttl {
  display: flex;
  flex-direction: column;
}
.examiness__ttlJa {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}
@media screen and (max-width: 1024px) {
  .examiness__ttlJa {
    font-size: 1.8rem;
  }
}
.examiness__ttlEn {
  color: #438cc8;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.15em;
}
.examiness__bnrList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 40px;
  justify-content: center;
  padding-bottom: 120px;
}
@media screen and (max-width: 768px) {
  .examiness__bnrList {
    column-gap: 20px;
  }
}
.examiness__bnrItem {
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  max-width: 300px;
  overflow: hidden;
}
.examiness__bnrLink {
  display: block;
  transition: 0.2s;
}
.examiness__bnrLink:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .examiness__bnrLink:hover {
    opacity: 1;
  }
}
.examiness__bnrImg {
  display: block;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .facility .lowerMiddleTtl {
    font-size: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .facility .lowerTtlSec__figure img {
    object-position: left;
  }
}
.facility__inner {
  padding-bottom: 252px;
  padding-top: 100px;
}
@media screen and (max-width: 768px) {
  .facility__inner {
    padding-bottom: 80px;
    padding-top: 40px;
  }
}
.facility__unit:not(:last-of-type) {
  border-bottom: 1px solid #e6e6e6;
}
@media screen and (max-width: 768px) {
  .facility__unit:not(:last-of-type) {
    border: none;
  }
}
.facility__unit:nth-of-type(1) {
  padding-bottom: 172px;
}
@media screen and (max-width: 768px) {
  .facility__unit:nth-of-type(1) {
    padding-bottom: 0px;
  }
}
.facility__unit:nth-of-type(2) {
  padding-top: 127px;
}
@media screen and (max-width: 768px) {
  .facility__unit:nth-of-type(2) {
    padding-top: 54px;
  }
}
.facility__unit:nth-of-type(2) .lowerTtl {
  margin-bottom: 75px;
}
@media screen and (max-width: 768px) {
  .facility__unit:nth-of-type(2) .lowerTtl {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 37px;
  }
}
.facility__unit:nth-of-type(2) .lowerTtl__ja {
  font-size: 2.2rem;
}
.facility__unit:nth-of-type(2) .lowerTtl__en {
  font-size: 1.4rem;
}
@media screen and (max-width: 768px) {
  .facility__unit:nth-of-type(2) .lowerTtl__en {
    margin-top: 9px;
  }
}
.facility__place:not(:last-of-type) {
  margin-bottom: 95px;
}
@media screen and (max-width: 768px) {
  .facility__place:not(:last-of-type) {
    margin-bottom: 37px;
  }
}
.facility__place .lowerMiddleTtl {
  margin-bottom: 16px;
}
@media screen and (max-width: 768px) {
  .facility__place .lowerMiddleTtl {
    margin-bottom: 25px;
  }
}
.facility__imgList {
  display: flex;
  gap: 10px 56px;
  margin-top: 30px;
}
@media screen and (max-width: 1024px) {
  .facility__imgList {
    gap: 10px 30px;
  }
}
@media screen and (max-width: 768px) {
  .facility__imgList {
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 16px;
  }
}
.facility__figure {
  border-radius: 10px;
  overflow: hidden;
  width: calc(33.33333% - 38px);
}
@media screen and (max-width: 1024px) {
  .facility__figure {
    width: calc(33.33333% - 20px);
  }
}
@media screen and (max-width: 768px) {
  .facility__figure {
    border-radius: 3px;
    width: calc(50% - 5px);
  }
}
.facility__txt {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 35px;
}
@media screen and (max-width: 768px) {
  .facility__txt {
    font-size: 1.6rem;
    margin: -10px auto 0;
  }
}
.facility__virtual div {
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .facility__virtual div {
    min-height: 170px;
    height: 44.7368421053vw !important;
  }
}
@media screen and (max-width: 768px) {
  .facility__virtual iframe {
    min-height: 170px;
    height: 44.7368421053vw !important;
  }
}

.faq__inner {
  padding-bottom: 200px;
  padding-top: 70px;
}
@media screen and (max-width: 768px) {
  .faq__inner {
    padding-bottom: 100px;
    padding-top: 50px;
  }
}
.faq__unit + .faq__unit {
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  .faq__unit + .faq__unit {
    margin-top: 40px;
  }
}
.faq__subTtl {
  margin-bottom: 1em;
}
.faq__item {
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.faq__item:nth-of-type(n+2) {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .faq__item:nth-of-type(n+2) {
    margin-top: 32px;
  }
}
.faq__question {
  cursor: pointer;
  position: relative;
}
.faq__question::before {
  color: #438cc8;
  content: "Q";
  font-family: "Montserrat", sans-serif;
  font-size: 3.4rem;
  left: 40px;
  line-height: 1;
  position: absolute;
  top: 40px;
}
@media screen and (max-width: 768px) {
  .faq__question::before {
    font-size: 2.2rem;
    left: 20px;
    top: 20px;
  }
}
.faq__questionFrame {
  padding: 40px 110px 40px 100px;
}
@media screen and (max-width: 768px) {
  .faq__questionFrame {
    padding: 15px 60px;
  }
}
.faq__questionFrame::before, .faq__questionFrame::after {
  background: #438cc8;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  right: 70px;
  top: 52px;
  transition: 0.2s;
  width: 21px;
}
@media screen and (max-width: 768px) {
  .faq__questionFrame::before, .faq__questionFrame::after {
    height: 2px;
    right: 20px;
    top: 28px;
    width: 14px;
  }
}
.faq__questionFrame::after {
  transform: rotate(90deg);
}
.faq__item.is_open .faq__questionFrame::after {
  transform: rotate(0);
}
.faq__ttl {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.8;
}
@media screen and (max-width: 768px) {
  .faq__ttl {
    font-size: 1.5rem;
  }
}
.faq__answer {
  display: none;
  position: relative;
}
.faq__answer::before {
  color: #438cc8;
  content: "A";
  font-family: "Montserrat", sans-serif;
  font-size: 3.4rem;
  left: 40px;
  line-height: 1;
  position: absolute;
  top: 40px;
}
@media screen and (max-width: 768px) {
  .faq__answer::before {
    font-size: 2.2rem;
    left: 20px;
    top: 20px;
  }
}
.faq__answerFrame {
  background: #f9f9f9;
  padding: 40px 80px 40px 100px;
}
@media screen and (max-width: 768px) {
  .faq__answerFrame {
    padding: 15px 20px 15px 60px;
  }
}
.faq__txt {
  font-size: 1.8rem;
  line-height: 1.8;
}
@media screen and (max-width: 768px) {
  .faq__txt {
    font-size: 1.5rem;
  }
}
.faq__txt a {
  color: #438cc8;
  text-decoration: underline;
}
.faq__txt a:hover {
  text-decoration: none;
}

.guraduate__inner {
  padding-bottom: 70px;
  padding-top: 105px;
}
@media screen and (max-width: 768px) {
  .guraduate__inner {
    padding-top: 66px;
    padding-bottom: 4px;
  }
}
.guraduate__unit:not(:last-of-type) {
  border-bottom: 1px solid #e6e6e6;
}
.guraduate__unit:nth-of-type(1) {
  padding-bottom: 125px;
}
@media screen and (max-width: 768px) {
  .guraduate__unit:nth-of-type(1) {
    padding-bottom: 60px;
  }
}
.guraduate__unit:nth-of-type(2) {
  padding-top: 89px;
}
@media screen and (max-width: 768px) {
  .guraduate__unit:nth-of-type(2) {
    padding-top: 55px;
  }
}
.guraduate__unit:nth-of-type(2) .lowerMiddleTtl {
  margin-bottom: 62px;
}
@media screen and (max-width: 768px) {
  .guraduate__unit:nth-of-type(2) .lowerMiddleTtl {
    margin-bottom: 36px;
  }
}
.guraduate__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 65px 55px;
}
@media screen and (max-width: 1024px) {
  .guraduate__wrap {
    gap: 50px 40px;
  }
}
@media screen and (max-width: 768px) {
  .guraduate__wrap {
    gap: 45px 0px;
  }
}
.guraduate__linkUnit {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  width: calc(33.333333% - 37px);
}
@media screen and (max-width: 1024px) {
  .guraduate__linkUnit {
    width: calc(33.333333% - 27px);
  }
}
@media screen and (max-width: 768px) {
  .guraduate__linkUnit {
    width: 100%;
  }
}
.guraduate__link {
  transition: all 0.2s;
}
.guraduate__link:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .guraduate__link:hover {
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
  .guraduate__fig {
    height: 155px;
    overflow: hidden;
  }
}
.guraduate__fig img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.guraduate__detail {
  height: 100%;
  padding: 30px 40px;
}
@media screen and (max-width: 1024px) {
  .guraduate__detail {
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .guraduate__detail {
    height: auto;
    padding: 25px 30px;
  }
}
.guraduate__ttl {
  display: flex;
  flex-direction: column;
}
.guraduate__ttlJa {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}
@media screen and (max-width: 1024px) {
  .guraduate__ttlJa {
    font-size: 1.8rem;
  }
}
.guraduate__ttlEn {
  color: #438cc8;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.15em;
}

.topMv {
  position: relative;
}
.topMv__catch {
  position: absolute;
  left: 140px;
  top: 41%;
  z-index: 2;
}
@media screen and (max-width: 1200px) {
  .topMv__catch {
    left: 8.3333333333vw;
  }
}
@media screen and (max-width: 768px) {
  .topMv__catch {
    left: 34px;
    top: 12%;
  }
}
.topMv__catchJa {
  font-size: clamp(3.4rem, 0.257rem + 2.86vw, 5.4rem);
  font-weight: bold;
  display: block;
  letter-spacing: 0.05em;
  line-height: 1.5185185185;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .topMv__catchJa {
    font-size: 2.4rem;
  }
}
.topMv__catchEn {
  color: #438cc8;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: clamp(16px, 0.971rem + 0.57vw, 20px);
  letter-spacing: 0.15em;
  line-height: 2;
  margin-left: 5px;
}
@media screen and (max-width: 768px) {
  .topMv__catchEn {
    font-size: 1rem;
  }
}
.topMv__sideText {
  color: #cccccc;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  position: absolute;
  letter-spacing: 0.15em;
  right: -107px;
  top: 52%;
  transform: rotate(-90deg);
  z-index: 2;
}
@media screen and (max-width: 1200px) {
  .topMv__sideText {
    right: -140px;
  }
}
@media screen and (max-width: 768px) {
  .topMv__sideText {
    right: -145px;
  }
}
.topMv__slide {
  position: relative;
  z-index: 1;
}
.topMv__slide img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.topMv__txt {
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .topMv__txt {
    display: none;
  }
}
.topMv__bigTxt {
  display: none;
}
@media screen and (max-width: 768px) {
  .topMv__bigTxt {
    bottom: 0;
    color: #fff;
    display: block;
    font-family: "Montserrat", sans-serif;
    font-size: 13vw;
    line-height: 0.7;
    position: absolute;
    z-index: 1;
  }
}

.topNews {
  padding-bottom: 220px;
  padding-top: 180px;
}
@media screen and (max-width: 1024px) {
  .topNews {
    padding-top: 100px;
  }
}
@media screen and (max-width: 768px) {
  .topNews {
    padding-bottom: 93px;
    padding-top: 45px;
  }
}
.topNews__container {
  display: flex;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .topNews__container {
    flex-direction: column;
  }
}
.topNews__ttl {
  margin-right: 140px;
}
@media screen and (max-width: 1024px) {
  .topNews__ttl {
    margin: 0px 0px 30px;
  }
}
@media screen and (max-width: 768px) {
  .topNews__ttl {
    margin: 0px 0px 15px;
  }
}
.topNews__contents {
  display: flex;
  flex-direction: column;
  padding-top: 7px;
  width: 100%;
}
.topNews__extra {
  border: 1px solid red;
  border-radius: 6px;
  margin-bottom: 55px;
}
@media screen and (max-width: 768px) {
  .topNews__extra {
    margin-bottom: 35px;
  }
}
.topNews__extra_color_blue {
  border-color: #0e44fe;
  overflow: hidden;
}
.topNews__extra_color_blue .topNews__extraLink {
  background: #0e44fe;
  color: #fff;
}
.topNews__extra_color_blue .topNews__extraLink::before {
  background: #fff;
  height: 40px;
  left: 16px;
  mask: url(../images/icon_ex_triangle.svg) no-repeat center/contain;
  top: 12px;
  width: 40px;
}
@media screen and (max-width: 768px) {
  .topNews__extra_color_blue .topNews__extraLink::before {
    height: 28px;
    width: 28px;
  }
}
.topNews__extra_color_blue .topNews__extraLink::after {
  border-color: #fff;
}
.topNews__extra_color_blue .topNews__extraLink:hover {
  background: #fff;
  color: #0e44fe;
}
.topNews__extra_color_blue .topNews__extraLink:hover::before {
  background: #0e44fe;
}
.topNews__extra_color_blue .topNews__extraLink:hover::after {
  border-color: #0e44fe;
}
.topNews__extraLink {
  color: #f15a24;
  display: block;
  font-size: 2rem;
  padding: 16px 30px 20px 80px;
  position: relative;
  transition: all 0.2s;
}
@media screen and (max-width: 768px) {
  .topNews__extraLink {
    font-size: 1.6rem;
    padding: 12px 30px 16px 60px;
  }
}
.topNews__extraLink:before {
  background: url(../images/icon_ex.svg) no-repeat center/cover;
  content: "";
  display: block;
  height: 34px;
  left: 23px;
  position: absolute;
  top: 15px;
  transition: all 0.2s;
  width: 34px;
}
@media screen and (max-width: 768px) {
  .topNews__extraLink:before {
    height: 28px;
    left: 14px;
    width: 28px;
  }
}
.topNews__extraLink:after {
  border-top: solid 2px #f15a24;
  border-right: solid 2px #f15a24;
  content: "";
  height: 8px;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  width: 8px;
}
@media screen and (max-width: 768px) {
  .topNews__extraLink:after {
    right: 18px;
  }
}
.topNews__extraLink:hover {
  background-color: #f15a24;
  color: #fff;
}
.topNews__extraLink:hover:before {
  background: url(../images/icon_ex_white.svg) no-repeat center/cover;
}
.topNews__extraLink:hover:after {
  border-color: #fff;
}
.topNews__list {
  display: flex;
  margin-bottom: 22px;
}
@media screen and (max-width: 768px) {
  .topNews__list {
    gap: 0px 10px;
    justify-content: space-between;
    margin-bottom: -12px;
    margin-left: -20px;
    margin-right: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 10px 10px 33px 16px;
    width: calc(100% + 40px);
  }
}
.topNews__item {
  border-radius: 9999px;
  color: #666666;
  cursor: pointer;
  font-size: 1.6rem;
  padding: 12px 10px;
  position: relative;
  text-align: center;
  transition: all 0.3s;
  width: 25%;
}
.topNews__item:after {
  background-color: #e6e6e6;
  border-radius: 9999px;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  left: 50.5%;
  transform: translateX(-50%);
  top: -6px;
  width: 10px;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .topNews__item:after {
    bottom: -28px;
  }
}
@media screen and (max-width: 768px) {
  .topNews__item {
    background-color: #f8f8f8;
    font-size: 1.4rem;
    padding: 9px 24px;
    width: auto;
  }
}
.topNews__item:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .topNews__item:hover {
    opacity: 1;
  }
}
.topNews__item_status_current {
  background-color: #f8f8f8;
  color: #1a1a1a;
  pointer-events: none;
}
.topNews__item_status_current:after {
  background-color: #438cc8;
}
.topNews__item_status_current .topNews__span {
  font-weight: bold;
  position: relative;
}
.topNews__span {
  display: block;
}
@media screen and (max-width: 768px) {
  .topNews__span {
    padding: 0px 12px;
    white-space: nowrap;
  }
}
.topNews__artWrap {
  display: none;
}
.topNews__artWrap_status_current {
  display: block;
}
.topNews__article {
  align-items: center;
  border-top: 1px solid #ccc;
  display: flex;
  padding: 22px 45px 20px 30px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .topNews__article {
    flex-wrap: wrap;
    padding: 15px 40px 15px 20px;
  }
}
.topNews__article:after {
  content: "";
  border-top: solid 2px #dcdddd;
  border-right: solid 2px #dcdddd;
  height: 10px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  transition: all 0.3s;
  width: 10px;
}
@media screen and (max-width: 768px) {
  .topNews__article:after {
    right: 12px;
    top: 54%;
  }
}
.topNews__article:last-of-type {
  border-bottom: 1px solid #ccc;
}
.topNews__time {
  border-right: 1px solid #e6e6e6;
  color: #9fa0a0;
  font-size: 1.4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  min-width: 101px;
  padding-right: 23px;
}
@media screen and (max-width: 768px) {
  .topNews__time {
    border-right: none;
    order: 3;
    min-width: auto;
    padding-right: 0px;
  }
}
.topNews__cat {
  border-right: 1px solid #e6e6e6;
  font-size: 1.3rem;
  padding: 0 30px;
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .topNews__cat {
    background: #e6e6e6;
    border-radius: 9999px;
    border-right: none;
    font-size: 1.2rem;
    order: 1;
    padding: 3px 20px;
    margin-bottom: 5px;
  }
}
.topNews__link {
  font-size: 1.6rem;
  position: relative;
  margin-left: 23px;
  transition: all 0.5s;
}
@media screen and (max-width: 768px) {
  .topNews__link {
    line-height: 1.625;
    margin-bottom: 5px;
    margin-left: 0px;
    order: 2;
    width: 100%;
  }
}
.topNews__link:hover {
  opacity: 0.3;
}
.topNews__all {
  bottom: 8px;
  font-size: 1.6rem;
  left: 60px;
  padding-left: 25px;
  position: absolute;
  transition: all 0.2s;
}
@media screen and (max-width: 1024px) {
  .topNews__all {
    bottom: 0;
    left: unset;
    margin-top: 25px;
    margin-left: auto;
    position: relative;
  }
}
@media screen and (max-width: 768px) {
  .topNews__all {
    margin-left: unset;
    margin-right: auto;
  }
}
.topNews__all:before {
  background: url(../images/icon_arrow_right_white.svg) no-repeat center/cover;
  content: "";
  display: block;
  height: 18px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
}
.topNews__all:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .topNews__all:hover {
    opacity: 1;
  }
}

.topAbout {
  padding-bottom: 257px;
}
@media screen and (max-width: 1200px) {
  .topAbout {
    padding-bottom: 200px;
  }
}
@media screen and (max-width: 1024px) {
  .topAbout {
    padding-bottom: 160px;
  }
}
@media screen and (max-width: 768px) {
  .topAbout {
    padding-bottom: 60px;
  }
}
.topAbout__inner {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1530px) {
  .topAbout__inner {
    align-items: center;
  }
}
@media screen and (max-width: 768px) {
  .topAbout__inner {
    flex-direction: column;
  }
}
.topAbout__sideImg {
  max-width: 500px;
  min-width: 290px;
  width: 27.8%;
}
@media screen and (max-width: 1024px) {
  .topAbout__sideImg:first-of-type {
    margin-left: -70px;
  }
  .topAbout__sideImg:last-of-type {
    margin-right: -70px;
  }
}
@media screen and (max-width: 768px) {
  .topAbout__sideImg {
    max-width: none;
    min-width: auto;
    width: 100%;
  }
  .topAbout__sideImg:first-of-type, .topAbout__sideImg:last-of-type {
    margin-left: 0px;
    margin-right: 0px;
  }
  .topAbout__sideImg img {
    width: 100%;
  }
}
.topAbout__contents {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 90px 20px 0;
}
@media screen and (max-width: 1530px) {
  .topAbout__contents {
    padding: 0 20px 0;
  }
}
@media screen and (max-width: 768px) {
  .topAbout__contents {
    padding: 40px 20px 50px;
  }
}
.topAbout__logoWrap {
  margin-bottom: 32px;
}
@media screen and (max-width: 768px) {
  .topAbout__logoWrap {
    margin-bottom: 7px;
    width: 65px;
  }
}
.topAbout__ttl {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .topAbout__ttl {
    margin-bottom: 16px;
  }
}
.topAbout__ttlEn {
  color: #438cc8;
  font-size: clamp(3rem, -2.343rem + 4.86vw, 6.4rem);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 1.25;
  margin-bottom: 13px;
}
@media screen and (max-width: 768px) {
  .topAbout__ttlEn {
    font-size: 2.8rem;
    margin-bottom: 2px;
  }
}
.topAbout__ttlJa {
  font-size: clamp(2.2rem, 0.629rem + 1.43vw, 3.2rem);
}
.topAbout__txt {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 43px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .topAbout__txt {
    font-size: 1.6rem;
    line-height: 2.25;
    margin-bottom: 30px;
  }
}

.topCourse {
  padding-bottom: 295px;
}
@media screen and (max-width: 1200px) {
  .topCourse {
    padding-bottom: 200px;
  }
}
@media screen and (max-width: 1024px) {
  .topCourse {
    padding-bottom: 160px;
  }
}
@media screen and (max-width: 768px) {
  .topCourse {
    padding-bottom: 74px;
  }
}
.topCourse__ttl {
  margin-bottom: 43px;
}
@media screen and (max-width: 768px) {
  .topCourse__ttl {
    margin-bottom: 16px;
  }
}
.topCourse__tagList {
  display: flex;
  flex-wrap: wrap;
  max-width: 1140px;
  margin-bottom: 63px;
  gap: 20px 10px;
}
@media screen and (max-width: 1024px) {
  .topCourse__tagList {
    padding-bottom: 25px;
  }
}
@media screen and (max-width: 768px) {
  .topCourse__tagList {
    gap: 10px;
    padding-bottom: 0px;
    margin-bottom: 45px;
    justify-content: flex-start;
  }
}
.topCourse__input {
  display: none;
}
.topCourse__input:checked ~ .topCourse__label {
  background-color: #438cc8;
  color: #fff;
}
.topCourse__label {
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 9999px;
  display: block;
  font-size: 1.6rem;
  line-height: 36px;
  padding: 0 31px;
  transition: all 0.3s;
  width: fit-content;
}
@media screen and (max-width: 768px) {
  .topCourse__label {
    font-size: 1.4rem;
    line-height: 30px;
    padding: 0 25px;
  }
}
.topCourse__label:hover {
  background-color: #438cc8;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .topCourse__label:hover {
    background-color: #fff;
    color: #1a1a1a;
  }
}
.topCourse__label::before {
  content: "#";
}
.topCourse__courseWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 90px 0;
}
@media screen and (max-width: 768px) {
  .topCourse__courseWrap {
    flex-direction: column;
    gap: 42px 0;
  }
}
.topCourse__course {
  display: flex;
  flex-direction: column;
  width: calc(33.33333% - clamp(15px, -4.471rem + 5.43vw, 53px));
}
@media screen and (max-width: 1024px) {
  .topCourse__course {
    width: calc(33.33333% - 15px);
  }
}
@media screen and (max-width: 768px) {
  .topCourse__course {
    width: 100%;
  }
}
.topCourse__course_type_mechanical .topCourse__fig::before {
  background-color: #ff4841;
}
.topCourse__course_type_mechanical .topCourse__courseCat {
  color: #ff4841;
}
.topCourse__course_type_electrical .topCourse__fig::before {
  background-color: #ffa53e;
}
.topCourse__course_type_electrical .topCourse__courseCat {
  color: #ffa53e;
}
.topCourse__course_type_building .topCourse__fig::before {
  background-color: #3ac98a;
}
.topCourse__course_type_building .topCourse__courseCat {
  color: #3ac98a;
}
.topCourse__course:nth-of-type(1) {
  order: 1;
}
@media screen and (max-width: 1470px) {
  .topCourse__course:nth-of-type(1) .topCourse__text {
    min-height: 130px;
  }
}
.topCourse__course:nth-of-type(2) {
  order: 4;
}
@media screen and (max-width: 1650px) {
  .topCourse__course:nth-of-type(2) .topCourse__text {
    min-height: 130px;
  }
}
@media screen and (max-width: 768px) {
  .topCourse__course:nth-of-type(2) {
    order: 2;
  }
}
.topCourse__course:nth-of-type(3) {
  order: 2;
}
@media screen and (max-width: 1470px) {
  .topCourse__course:nth-of-type(3) .topCourse__text {
    min-height: 130px;
  }
}
@media screen and (max-width: 768px) {
  .topCourse__course:nth-of-type(3) {
    order: 3;
  }
}
.topCourse__course:nth-of-type(4) {
  order: 5;
}
@media screen and (max-width: 1650px) {
  .topCourse__course:nth-of-type(4) .topCourse__text {
    min-height: 130px;
  }
}
@media screen and (max-width: 768px) {
  .topCourse__course:nth-of-type(4) {
    order: 4;
  }
}
.topCourse__course:nth-of-type(5) {
  order: 3;
}
@media screen and (max-width: 1470px) {
  .topCourse__course:nth-of-type(5) .topCourse__text {
    min-height: 130px;
  }
}
@media screen and (max-width: 768px) {
  .topCourse__course:nth-of-type(5) {
    order: 5;
  }
}
.topCourse__course:nth-of-type(6) {
  order: 6;
}
@media screen and (max-width: 1650px) {
  .topCourse__course:nth-of-type(6) .topCourse__text {
    min-height: 130px;
  }
}
.topCourse__course .topCourse__text {
  min-height: 110px;
}
@media screen and (max-width: 1245px) {
  .topCourse__course .topCourse__text {
    min-height: 155px;
  }
}
.topCourse__link {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  transition: all 0.2s;
}
.topCourse__link:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .topCourse__link:hover {
    opacity: 1;
  }
}
.topCourse__fig {
  border-radius: 12px;
  margin-bottom: 27px;
  overflow: hidden;
  position: relative;
  padding-top: 66.6666%;
  width: 100%;
}
.topCourse__fig::before {
  content: "";
  border-radius: 6px;
  height: 20px;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 83.2%;
  z-index: 1;
}
.topCourse__fig img {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .topCourse__fig {
    margin-bottom: 17px;
  }
}
.topCourse__courseContents {
  flex-direction: column;
  display: flex;
  height: 100%;
  justify-content: flex-start;
}
.topCourse__eachCourse {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .topCourse__eachCourse {
    margin-bottom: 7px;
  }
}
.topCourse__courseCat {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 7px;
}
@media screen and (max-width: 768px) {
  .topCourse__courseCat {
    margin-bottom: 5px;
  }
}
.topCourse__courseName {
  font-size: 2.4rem;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .topCourse__courseName {
    font-size: 2.2rem;
  }
}
.topCourse__text {
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .topCourse__text {
    font-size: 1.4rem;
  }
}
.topCourse__futureWrap {
  border-top: 1px solid #e6e6e6;
  margin-top: 20px;
  padding-top: 20px;
}
@media screen and (max-width: 768px) {
  .topCourse__futureWrap {
    margin-top: 10px;
    padding-top: 15px;
  }
}
.topCourse__future {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.topCourse__futureList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
@media screen and (max-width: 1024px) {
  .topCourse__futureList {
    padding-bottom: 25px;
  }
}
@media screen and (max-width: 768px) {
  .topCourse__futureList {
    padding-bottom: 0px;
    justify-content: flex-start;
  }
}
.topCourse__futureItem {
  background-color: #f2f5f8;
  border-radius: 9999px;
  font-size: 1.4rem;
  padding: 5px 20px;
}

.topInfo {
  display: flex;
  padding-bottom: 258px;
}
@media screen and (max-width: 1200px) {
  .topInfo {
    padding-bottom: 200px;
  }
}
@media screen and (max-width: 1024px) {
  .topInfo {
    padding-bottom: 160px;
  }
}
@media screen and (max-width: 768px) {
  .topInfo {
    padding-bottom: 0px;
  }
}
.topInfo__ttl {
  font-size: 7.8rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0.15em;
  padding: 0px 64px 0px 90px;
  white-space: nowrap;
  writing-mode: vertical-lr;
}
@media screen and (max-width: 1200px) {
  .topInfo__ttl {
    font-size: 6.8rem;
  }
}
@media screen and (max-width: 900px) {
  .topInfo__ttl {
    display: none;
  }
}
.topInfo__ttlSpan {
  color: #438cc8;
}
.topInfo__unit {
  padding: 146px 118px 156px;
  width: calc(100vw - 250px);
}
@media screen and (max-width: 1200px) {
  .topInfo__unit {
    padding: 100px 70px 120px;
    width: calc(100vw - 237px);
  }
}
@media screen and (max-width: 900px) {
  .topInfo__unit {
    padding: 80px 40px 100px;
    width: 100%;
  }
}
.topInfo__unit:nth-of-type(1) {
  background: url(../images/bg_top_info_01.jpg) no-repeat center/cover;
  border-radius: 30px 0px 0px 0px;
}
@media screen and (max-width: 900px) {
  .topInfo__unit:nth-of-type(1) {
    border-radius: 0px;
  }
}
@media screen and (max-width: 768px) {
  .topInfo__unit:nth-of-type(1) {
    padding: 46px 27px 170px;
  }
}
.topInfo__unit:nth-of-type(2) {
  background: url(../images/bg_top_info_02.jpg) no-repeat center/cover;
}
@media screen and (max-width: 768px) {
  .topInfo__unit:nth-of-type(2) {
    padding: 60px 27px 190px;
  }
}
.topInfo__unit:nth-of-type(3) {
  background: url(../images/bg_top_info_03.jpg) no-repeat center/cover;
  border-radius: 0px 0px 0px 30px;
}
@media screen and (max-width: 900px) {
  .topInfo__unit:nth-of-type(3) {
    border-radius: 0px;
  }
}
@media screen and (max-width: 768px) {
  .topInfo__unit:nth-of-type(3) {
    padding: 60px 27px 157px;
  }
}
.topInfo__unit:not(:last-of-type) {
  margin-bottom: 3px;
}
@media screen and (max-width: 768px) {
  .topInfo__unit:not(:last-of-type) {
    margin-bottom: 1px;
  }
}
@media screen and (max-width: 768px) {
  .topInfo__unit .moreBtn {
    font-size: 1.4rem;
    padding: 15px 31px 15px 42px;
  }
  .topInfo__unit .moreBtn:before {
    height: 20px;
    left: 15px;
    width: 20px;
  }
  .topInfo__unit .moreBtn:after {
    height: 5px;
    left: 20px;
    width: 5px;
  }
}
.topInfo__num {
  color: #43c5e8;
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .topInfo__num {
    font-size: 1.4rem;
  }
}
.topInfo__unitTtl {
  color: #fff;
  font-size: 3.4rem;
  font-weight: bold;
  margin-bottom: 18px;
}
@media screen and (max-width: 768px) {
  .topInfo__unitTtl {
    font-size: 2.8rem;
    margin-bottom: 7px;
  }
}
.topInfo__text {
  color: #fff;
  font-size: 1.6rem;
  line-height: 2;
  margin-bottom: 27px;
}
@media screen and (max-width: 768px) {
  .topInfo__text {
    font-size: 1.5rem;
    line-height: 2.1333333;
    margin-bottom: 8px;
  }
}

.topInt {
  background: linear-gradient(180deg, #f2f5f8 0%, #f2f5f8 50%, #fff 50%, #fff 100%);
  padding-bottom: 255px;
  padding-top: 170px;
}
@media screen and (max-width: 1200px) {
  .topInt {
    padding-bottom: 200px;
  }
}
@media screen and (max-width: 1024px) {
  .topInt {
    padding-bottom: 160px;
  }
}
@media screen and (max-width: 768px) {
  .topInt {
    padding-bottom: 127px;
    overflow: hidden;
    padding-top: 70px;
  }
}
@media screen and (max-width: 768px) {
  .topInt .topTtl {
    align-items: flex-start;
    flex-direction: column;
  }
}
.topInt .topTtl__en {
  letter-spacing: 0.05em;
  padding-right: 30px;
}
@media screen and (max-width: 768px) {
  .topInt .topTtl__en {
    font-size: 3.4rem;
    letter-spacing: 0.1em;
    margin-bottom: 7px;
    padding-right: 0px;
  }
}
.topInt .topTtl__ja {
  font-size: 2.8rem;
}
@media screen and (max-width: 768px) {
  .topInt .topTtl__ja {
    font-size: 2.2rem;
    margin-bottom: 0px;
  }
}
.topInt__ttl {
  margin-bottom: 13px;
}
@media screen and (max-width: 768px) {
  .topInt__ttl {
    margin-bottom: 27px;
  }
}
.topInt__text {
  font-size: 1.6rem;
  line-height: 2;
  margin-bottom: 60px;
}
@media screen and (max-width: 900px) {
  .topInt__text {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .topInt__text {
    margin-bottom: 34px;
  }
}
.topInt__fig {
  border-radius: 10px;
  height: 420px;
  margin-bottom: 25px;
  overflow: hidden;
  width: 340px;
}
@media screen and (max-width: 768px) {
  .topInt__fig {
    height: 320px;
    margin-bottom: 21px;
    width: 260px;
  }
}
.topInt__fig img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.topInt__profile {
  max-width: 340px;
  padding-right: 50px;
  width: 340px;
}
@media screen and (max-width: 768px) {
  .topInt__profile {
    padding: 0 15px;
    max-width: 260px;
    width: 260px;
  }
}
.topInt__profLink {
  transition: all 0.2s;
}
.topInt__profLink:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .topInt__profLink:hover {
    opacity: 1;
  }
}
.topInt__cat {
  color: #438cc8;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  margin-bottom: 7px;
}
@media screen and (max-width: 768px) {
  .topInt__cat {
    font-size: 1.4rem;
    margin-bottom: 0px;
  }
}
.topInt__name {
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 11px;
}
@media screen and (max-width: 768px) {
  .topInt__name {
    font-size: 2.2rem;
    margin-bottom: 6px;
  }
}
.topInt__profText {
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .topInt__profText {
    font-size: 1.4rem;
  }
}

.info__inner {
  padding-bottom: 167px;
  padding-top: 40px;
}
@media screen and (max-width: 768px) {
  .info__inner {
    padding-top: 37px;
    padding-bottom: 80px;
  }
}
.info__lead {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 90px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .info__lead {
    font-size: 1.6rem;
    line-height: 2.25;
    margin-bottom: 35px;
    padding: 0 10px;
  }
}
.info__unit:not(:last-of-type) {
  border-bottom: 1px solid #e6e6e6;
}
.info__unit:nth-of-type(1) {
  padding-bottom: 125px;
}
@media screen and (max-width: 768px) {
  .info__unit:nth-of-type(1) {
    padding-bottom: 60px;
  }
}
.info__unit:nth-of-type(2) {
  padding-top: 89px;
}
@media screen and (max-width: 768px) {
  .info__unit:nth-of-type(2) {
    padding-top: 55px;
  }
}
.info__unit:nth-of-type(2) .lowerMiddleTtl {
  margin-bottom: 62px;
}
@media screen and (max-width: 768px) {
  .info__unit:nth-of-type(2) .lowerMiddleTtl {
    margin-bottom: 36px;
  }
}
.info__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 65px 55px;
}
@media screen and (max-width: 1024px) {
  .info__wrap {
    gap: 50px 40px;
  }
}
@media screen and (max-width: 768px) {
  .info__wrap {
    gap: 45px 0px;
  }
}
.info__linkUnit {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  width: calc(33.333333% - 37px);
}
@media screen and (max-width: 1024px) {
  .info__linkUnit {
    width: calc(33.333333% - 27px);
  }
}
@media screen and (max-width: 768px) {
  .info__linkUnit {
    width: 100%;
  }
  .info__linkUnit:nth-of-type(6) .info__fig img {
    object-position: top;
  }
}
.info__link {
  transition: all 0.2s;
}
.info__link:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .info__link:hover {
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
  .info__fig {
    height: 155px;
    overflow: hidden;
  }
}
.info__fig img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.info__detail {
  height: 100%;
  padding: 30px 40px;
}
@media screen and (max-width: 1024px) {
  .info__detail {
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .info__detail {
    height: auto;
    padding: 25px 30px;
  }
}
.info__ttl {
  display: flex;
  flex-direction: column;
}
.info__ttlJa {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}
@media screen and (max-width: 1024px) {
  .info__ttlJa {
    font-size: 1.8rem;
  }
}
.info__ttlEn {
  color: #438cc8;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.15em;
}
.info__virtual div {
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .info__virtual div {
    min-height: 170px;
    height: 44.7368421053vw !important;
  }
}
@media screen and (max-width: 768px) {
  .info__virtual iframe {
    min-height: 170px;
    height: 44.7368421053vw !important;
  }
}

@media screen and (max-width: 768px) {
  .interview_page_archive .container {
    padding: 0px;
  }
}
.interview_page_detail .lowerTtlOnly__ja {
  font-size: 3.4rem;
  line-height: 1.7647058824;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .interview_page_detail .lowerTtlOnly__ja {
    font-size: 2.2rem;
    line-height: 1.6363636364;
    margin-bottom: 16px;
  }
}
.interview_page_detail .interview__inner {
  padding-top: 80px;
  padding-bottom: 207px;
}
@media screen and (max-width: 768px) {
  .interview_page_detail .interview__inner {
    padding-top: 40px;
    padding-bottom: 81px;
  }
}
@media screen and (max-width: 768px) {
  .interview_page_detail .pagination {
    margin-bottom: 0;
  }
}
.interview_page_detail .pagination__list {
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .interview_page_detail .pagination__list {
    padding: 0;
  }
}
@media screen and (max-width: 360px) {
  .interview_page_detail .pagination__list {
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .interview_page_detail .pagination__item {
    margin: 0;
  }
}
@media screen and (max-width: 360px) {
  .interview_page_detail .pagination__item {
    margin: 5px auto;
  }
}
@media screen and (max-width: 768px) {
  .interview_page_detail .pagination__item_type_next {
    bottom: auto;
    left: auto;
    position: relative;
    right: auto;
    top: auto;
    transform: none;
  }
}
.interview__inner {
  padding-bottom: 245px;
  padding-top: 138px;
}
@media screen and (max-width: 768px) {
  .interview__inner {
    padding-top: 68px;
    padding-bottom: 97px;
  }
}
.interview__wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .interview__wrap {
    justify-content: space-between;
    margin-bottom: 17px;
    padding: 0 20px;
  }
}
.interview__art {
  width: 25%;
}
@media screen and (max-width: 768px) {
  .interview__art {
    width: 50%;
    width: calc(50% - 5px);
  }
}
@media screen and (max-width: 620px) {
  .interview__art {
    width: 100%;
  }
}
.interview__link {
  display: block;
  height: 100%;
  overflow: hidden;
  transition: all 0.2s;
}
.interview__link:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .interview__link:hover {
    opacity: 1;
  }
}
.interview__fig {
  height: 31.1111111111vw;
}
@media screen and (max-width: 768px) {
  .interview__fig {
    height: 337px;
    margin: 0 auto;
    width: 280px;
  }
}
.interview__fig img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.interview__detail {
  padding: 36px 50px 69px;
}
@media screen and (max-width: 1200px) {
  .interview__detail {
    padding: 36px 30px 69px;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .interview__detail {
    padding: 24px 0px 60px;
  }
}
.interview__cat {
  color: #fff;
  border-radius: 9999px;
  display: inline-block;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  margin-bottom: 5px;
  padding: 8px 24px;
}
@media screen and (max-width: 1024px) {
  .interview__cat {
    padding: 8px 20px;
  }
}
@media screen and (max-width: 768px) {
  .interview__cat {
    font-size: 1.4rem;
    padding: 7px 23px;
    margin-bottom: 12px;
  }
}
.interview__cat_color_red {
  background-color: #ff4841;
}
.interview__cat_color_orange {
  background-color: #ffa53e;
}
.interview__cat_color_green {
  background-color: #3ac98a;
}
.interview__read {
  font-size: 1.8rem;
  letter-spacing: 0.05em;
  line-height: 1.8888888889;
}
@media screen and (max-width: 1024px) {
  .interview__read {
    font-size: 1.6rem;
    line-height: 2;
    text-align: left;
  }
}
.interview__job {
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  margin-top: 12px;
}
@media screen and (max-width: 1024px) {
  .interview__job {
    font-size: 1.5rem;
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  .interview__job {
    margin-top: 10px;
  }
}
.interview__people {
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  margin-top: 13px;
}
@media screen and (max-width: 1024px) {
  .interview__people {
    font-size: 1.4rem;
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  .interview__people {
    margin-top: 17px;
  }
}
.interview__graduation {
  margin-right: 13px;
}
.interview__headDetail {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .interview__headDetail .interview__cat {
    margin-bottom: 0px;
  }
}
.interview__headDetail .interview__job {
  font-size: 1.8rem;
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .interview__headDetail .interview__job {
    font-size: 1.6rem;
    margin-top: 18px;
    text-align: center;
  }
}
.interview__headDetail .interview__people {
  font-size: 1.8rem;
}
@media screen and (max-width: 768px) {
  .interview__headDetail .interview__people {
    font-size: 1.6rem;
    margin-top: 13px;
    text-align: center;
  }
}
.interview__mv {
  border-radius: 10px;
  margin-bottom: 70px;
  overflow: hidden;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .interview__mv {
    border-radius: 6px;
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 480px) {
  .interview__mv {
    aspect-ratio: 1/1;
  }
}
.interview__mv img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.interview__editor {
  margin-bottom: 116px;
  padding: 0 80px;
}
@media screen and (max-width: 1024px) {
  .interview__editor {
    margin-bottom: 45px;
    padding: 0 30px;
  }
}
@media screen and (max-width: 768px) {
  .interview__editor {
    margin-bottom: 70px;
    padding: 0px;
  }
}
.interview__editor figure {
  overflow: hidden;
  margin-bottom: 72px;
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .interview__editor figure {
    margin-bottom: 50px;
    margin-top: 40px;
  }
}
.interview__editor img {
  max-height: 1000px;
  height: 100%;
  object-fit: contain;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .interview__editor img {
    max-height: none;
  }
}
.interview__editor figcaption {
  font-size: 1.6rem;
  line-height: 2;
  margin-bottom: 0em !important;
  margin-top: 1.5em !important;
}
@media screen and (max-width: 768px) {
  .interview__editor figcaption {
    font-size: 1.3rem;
    margin-top: 1em !important;
  }
}
.interview__editor h2 {
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 30px;
  margin-top: 70px;
}
@media screen and (max-width: 768px) {
  .interview__editor h2 {
    font-size: 2.2rem;
    margin-bottom: 20px;
    margin-top: 60px;
  }
}
.interview__editor h3 {
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 30px;
  margin-top: 40px;
  padding-left: 40px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .interview__editor h3 {
    font-size: 2rem;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.interview__editor h3:before {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  height: 6px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
}
@media screen and (max-width: 768px) {
  .interview__editor h3:before {
    height: 4px;
    width: 17px;
  }
}
.interview__editor h4 {
  font-size: 2.2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 1.5;
  margin-bottom: 30px;
  margin-top: 40px;
  padding-left: 20px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .interview__editor h4 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    margin-top: 15px;
  }
}
.interview__editor h4:before {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  height: 8px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}
.interview__editor p {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .interview__editor p {
    font-size: 1.4rem;
    line-height: 2.1428571429;
    margin-bottom: 20px;
  }
}
.interview__editor a {
  color: #438cc8;
  text-decoration: underline;
  transition: all 0.2s;
}
.interview__editor a:hover {
  opacity: 0.4;
  text-decoration: none;
}
.interview__editor ul {
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .interview__editor ul {
    margin-bottom: 64px;
  }
}
.interview__editor li {
  font-size: 1.8rem;
  line-height: 2;
  padding-left: 1em;
  position: relative;
}
.interview__editor li:before {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  display: block;
  height: 5px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 5px;
}
@media screen and (max-width: 768px) {
  .interview__editor li {
    font-size: 1.6rem;
    line-height: 2.25;
    padding-left: 1.4em;
  }
}
.interview__editor .is-nowrap.wp-block-group {
  gap: 0 60px !important;
}
@media screen and (max-width: 1024px) {
  .interview__editor .is-nowrap.wp-block-group {
    gap: 0 30px !important;
  }
}
@media screen and (max-width: 768px) {
  .interview__editor .is-nowrap.wp-block-group {
    flex-direction: column;
    gap: 30px 0 !important;
    margin-bottom: 65px;
  }
}
.interview__editor .is-nowrap.wp-block-group figure {
  aspect-ratio: 51/32;
  margin-top: 0px;
  width: calc(50% - 30px);
}
@media screen and (max-width: 1024px) {
  .interview__editor .is-nowrap.wp-block-group figure {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .interview__editor .is-nowrap.wp-block-group figure {
    aspect-ratio: unset;
    margin-bottom: 0;
    width: 100%;
  }
}
.interview__editor .is-nowrap.wp-block-group figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.interview__editor .is-nowrap.wp-block-group figcaption {
  margin-top: 15px !important;
}
@media screen and (max-width: 768px) {
  .interview__editor .is-nowrap.wp-block-group figcaption {
    margin-top: 12px !important;
  }
}
.interview__editor .wp-block-group {
  margin-bottom: 70px;
}
@media screen and (max-width: 768px) {
  .interview__editor .wp-block-group {
    margin-bottom: 60px;
  }
}
.interview__editor .wp-block-group .wp-block-group__inner-container {
  border-radius: 6px;
  border: 5px solid #f2f2f2;
  padding: 40px 45px;
}
@media screen and (max-width: 768px) {
  .interview__editor .wp-block-group .wp-block-group__inner-container {
    border: 3px solid #f2f2f2;
    padding: 27px 17px;
  }
}
.interview__editor .wp-block-group .wp-block-group__inner-container h2,
.interview__editor .wp-block-group .wp-block-group__inner-container h3,
.interview__editor .wp-block-group .wp-block-group__inner-container h4 {
  margin-bottom: 15px;
  margin-top: 0px;
}
.interview__editor .wp-block-group .wp-block-group__inner-container p {
  margin-bottom: 0;
}

.jobReq__inner {
  padding-bottom: 188px;
  padding-top: 67px;
}
@media screen and (max-width: 768px) {
  .jobReq__inner {
    padding-bottom: 80px;
    padding-top: 39px;
  }
}
.jobReq__read {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 65px;
}
@media screen and (max-width: 768px) {
  .jobReq__read {
    font-size: 1.6rem;
    margin-bottom: 50px;
  }
}
.jobReq__ttl {
  margin-bottom: 55px;
}
@media screen and (max-width: 768px) {
  .jobReq__ttl {
    margin-bottom: 40px;
  }
}
.jobReq__link:nth-of-type(1) {
  margin-right: 40px;
}
@media screen and (max-width: 768px) {
  .jobReq__link:nth-of-type(1) {
    margin-right: 0px;
  }
}
.jobReq__btnWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 35px 0;
  margin-bottom: 33px;
}
@media screen and (max-width: 768px) {
  .jobReq__btnWrap {
    gap: 25px 0;
  }
}
.jobReq__addressee {
  border: 5px solid #f2f2f2;
  border-radius: 6px;
  padding: 38px 37px 48px;
}
@media screen and (max-width: 768px) {
  .jobReq__addressee {
    padding: 26px 8px 35px;
  }
}
.jobReq__smTtl {
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .jobReq__smTtl {
    margin-bottom: 20px;
  }
}
.jobReq__btnUnit {
  margin-bottom: 68px;
}
@media screen and (max-width: 768px) {
  .jobReq__btnUnit {
    margin-bottom: 56px;
  }
}
.jobReq__add {
  font-size: 1.8rem;
  line-height: 2;
  margin-left: 30px;
}
@media screen and (max-width: 768px) {
  .jobReq__add {
    font-size: 1.6rem;
    margin-left: 7px;
  }
}
.jobReq__tel {
  transition: all 0.2s;
}
.jobReq__tel:hover {
  opacity: 0.5;
}
.jobReq__mail {
  color: #438cc8;
  text-decoration: underline;
  transition: all 0.2s;
}
.jobReq__mail:hover {
  opacity: 0.5;
  text-decoration: none;
}
.jobReq__att {
  color: #ff4841;
  font-size: 1.6rem;
  line-height: 2;
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .jobReq .moreBtn {
    padding: 19px 40px 19px 70px;
    width: 100%;
  }
  .jobReq .moreBtn:before {
    left: 18px;
  }
  .jobReq .moreBtn:after {
    left: 25px;
  }
}

@media screen and (max-width: 768px) {
  .news .container {
    padding: 0;
  }
}
.news_page_detail .lowerTtlOnly {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .news_page_detail .lowerTtlOnly {
    padding: 60px 20px 0;
  }
}
.news_page_detail .lowerTtlOnly:before {
  background-color: #1a1a1a;
  border-radius: 9999px;
  content: "";
  height: 70px;
  left: 50%;
  position: absolute;
  top: -37px;
  transform: translateX(-50%);
  width: 6px;
}
@media screen and (max-width: 768px) {
  .news_page_detail .lowerTtlOnly:before {
    height: 35px;
    top: -17px;
    width: 3px;
  }
}
.news_page_detail .lowerTtlOnly:after {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  height: 20px;
  left: 50%;
  position: absolute;
  top: 40px;
  transform: translateX(-50%);
  width: 6px;
}
@media screen and (max-width: 768px) {
  .news_page_detail .lowerTtlOnly:after {
    height: 10px;
    top: 21px;
    width: 3px;
  }
}
.news_page_detail .lowerTtlOnly__ttl {
  padding-top: 0px;
}
.news_page_detail .lowerTtlOnly__ttl:before, .news_page_detail .lowerTtlOnly__ttl:after {
  content: none;
}
.news_page_detail .lowerTtlOnly__ja {
  margin-bottom: 20px;
}
.news_page_detail .lowerTtlOnly__en {
  font-size: 1.6rem;
}
.news_page_detail .news__inner {
  padding-bottom: 413px;
}
@media screen and (max-width: 768px) {
  .news_page_detail .news__inner {
    padding-bottom: 81px;
  }
}
@media screen and (max-width: 768px) {
  .news_page_detail .pagination {
    margin-bottom: 0;
  }
}
.news_page_detail .pagination__list {
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .news_page_detail .pagination__list {
    padding: 0 20px;
  }
}
@media screen and (max-width: 360px) {
  .news_page_detail .pagination__list {
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .news_page_detail .pagination__item {
    margin: 0;
  }
}
@media screen and (max-width: 360px) {
  .news_page_detail .pagination__item {
    margin: 5px auto;
  }
}
@media screen and (max-width: 768px) {
  .news_page_detail .pagination__item_type_next {
    bottom: auto;
    left: auto;
    position: relative;
    right: auto;
    top: auto;
    transform: none;
  }
}
@media screen and (max-width: 768px) {
  .news_page_detail .lowerTtlOnly.container {
    padding: 60px 20px 0px;
  }
}
.news .pagination__item_diabled {
  pointer-events: none;
  opacity: 0.6;
}
.news__inner {
  padding-bottom: 165px;
  padding-top: 60px;
}
@media screen and (max-width: 768px) {
  .news__inner {
    padding-bottom: 93px;
    padding-top: 40px;
  }
}
.news__wrap {
  margin-bottom: 123px;
}
@media screen and (max-width: 768px) {
  .news__wrap {
    margin-bottom: 65px;
  }
}
.news__art {
  border-top: 1px solid #ededed;
}
.news__art:last-of-type {
  border-bottom: 1px solid #ededed;
}
.news__link {
  display: block;
  padding: 40px 50px;
  transition: all 0.2s;
}
@media screen and (max-width: 1024px) {
  .news__link {
    padding: 40px 35px;
  }
}
@media screen and (max-width: 768px) {
  .news__link {
    padding: 14px 40px;
  }
}
.news__link:hover {
  background-color: #438cc8;
}
@media screen and (max-width: 768px) {
  .news__link:hover {
    background-color: #fff;
  }
}
.news__link:hover .news__time {
  color: #fff;
}
@media screen and (max-width: 768px) {
  .news__link:hover .news__time {
    color: #438cc8;
  }
}
.news__link:hover .news__cat {
  color: #fff;
}
.news__link:hover .news__txt {
  color: #fff;
}
@media screen and (max-width: 768px) {
  .news__link:hover .news__txt {
    color: #1a1a1a;
  }
}
.news__data {
  align-items: center;
  display: flex;
}
@media screen and (max-width: 768px) {
  .news__data {
    margin-bottom: 4px;
  }
}
.news__time {
  color: #438cc8;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.15em;
  padding-right: 20px;
}
@media screen and (max-width: 768px) {
  .news__time {
    font-size: 1.4rem;
    padding-right: 10px;
  }
}
.news__cat {
  border-left: 1px solid #e6e6e6;
  padding-left: 20px;
}
@media screen and (max-width: 768px) {
  .news__cat {
    font-size: 1.4rem;
    padding-left: 10px;
  }
}
.news__catDt {
  font-size: 1.6rem;
  background: #e6e6e6;
  border-radius: 9999px;
  display: flex;
  font-size: 1.4rem;
  padding: 3px 20px;
  justify-content: center;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .news__catDt {
    font-size: 1.3rem;
  }
}
.news__txt {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .news__txt {
    font-size: 1.4rem;
    line-height: 1.7142857143;
  }
}
.news__editor {
  margin-bottom: 116px;
  padding: 0 80px;
}
@media screen and (max-width: 1024px) {
  .news__editor {
    margin-bottom: 45px;
    padding: 0 30px;
  }
}
@media screen and (max-width: 768px) {
  .news__editor {
    margin-bottom: 60px;
    padding: 0 20px;
  }
}
.news__editor figure {
  overflow: hidden;
  margin-bottom: 72px;
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .news__editor figure {
    margin-bottom: 50px;
    margin-top: 40px;
  }
}
.news__editor img {
  width: auto;
}
.news__editor .wp-block-media-text__media img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .news__editor .wp-block-media-text__content {
    padding-left: 0;
    padding-right: 0;
  }
}
.news__editor figcaption {
  font-size: 1.6rem;
  line-height: 2;
  margin-bottom: 0em !important;
  margin-top: 1.5em !important;
}
@media screen and (max-width: 768px) {
  .news__editor figcaption {
    font-size: 1.3rem;
    margin-top: 1em !important;
  }
}
.news__editor h2 {
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 30px;
  margin-top: 70px;
}
@media screen and (max-width: 768px) {
  .news__editor h2 {
    font-size: 2.2rem;
    margin-bottom: 20px;
    margin-top: 60px;
  }
}
.news__editor h3 {
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 30px;
  margin-top: 40px;
  padding-left: 40px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .news__editor h3 {
    font-size: 2rem;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.news__editor h3:before {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  height: 6px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
}
@media screen and (max-width: 768px) {
  .news__editor h3:before {
    height: 4px;
    width: 17px;
  }
}
.news__editor h4 {
  font-size: 2.2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 1.5;
  margin-bottom: 30px;
  margin-top: 40px;
  padding-left: 20px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .news__editor h4 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    margin-top: 15px;
  }
}
.news__editor h4:before {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  height: 8px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}
.news__editor p {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .news__editor p {
    font-size: 1.4rem;
    line-height: 2.1428571429;
    margin-bottom: 20px;
  }
}
.news__editor a {
  color: #438cc8;
  text-decoration: underline;
  transition: all 0.2s;
}
.news__editor a:hover {
  opacity: 0.4;
  text-decoration: none;
}
.news__editor ul {
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .news__editor ul {
    margin-bottom: 64px;
  }
}
.news__editor li {
  font-size: 1.8rem;
  line-height: 2;
  padding-left: 1em;
  position: relative;
}
.news__editor li:before {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  display: block;
  height: 5px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 5px;
}
@media screen and (max-width: 768px) {
  .news__editor li {
    font-size: 1.6rem;
    line-height: 2.25;
    padding-left: 1.4em;
  }
}
.news__editor .is-nowrap.wp-block-group {
  gap: 0 60px !important;
}
@media screen and (max-width: 1024px) {
  .news__editor .is-nowrap.wp-block-group {
    gap: 0 30px !important;
  }
}
@media screen and (max-width: 768px) {
  .news__editor .is-nowrap.wp-block-group {
    flex-direction: column;
    gap: 30px 0px !important;
    margin-bottom: 65px;
  }
}
.news__editor .is-nowrap.wp-block-group figure {
  aspect-ratio: 51/32;
  margin-top: 0px;
  width: calc(50% - 30px);
}
@media screen and (max-width: 1024px) {
  .news__editor .is-nowrap.wp-block-group figure {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .news__editor .is-nowrap.wp-block-group figure {
    aspect-ratio: unset;
    margin-bottom: 0;
    width: 100%;
  }
}
.news__editor .is-nowrap.wp-block-group figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news__editor .is-nowrap.wp-block-group figcaption {
  margin-top: 15px !important;
}
@media screen and (max-width: 768px) {
  .news__editor .is-nowrap.wp-block-group figcaption {
    margin-top: 12px !important;
  }
}
.news__editor .wp-block-group {
  margin-bottom: 70px;
}
@media screen and (max-width: 768px) {
  .news__editor .wp-block-group {
    margin-bottom: 60px;
  }
}
.news__editor .wp-block-group .wp-block-group__inner-container {
  border-radius: 6px;
  border: 5px solid #f2f2f2;
  padding: 40px 45px;
}
@media screen and (max-width: 768px) {
  .news__editor .wp-block-group .wp-block-group__inner-container {
    border: 3px solid #f2f2f2;
    padding: 27px 17px;
  }
}
.news__editor .wp-block-group .wp-block-group__inner-container h2,
.news__editor .wp-block-group .wp-block-group__inner-container h3,
.news__editor .wp-block-group .wp-block-group__inner-container h4 {
  margin-bottom: 15px;
  margin-top: 0px;
}
.news__editor .wp-block-group .wp-block-group__inner-container p {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .ocam .lowerMiddleTtl {
    font-size: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .ocam .moreBtn {
    padding: 19px 30px 19px 60px;
  }
  .ocam .moreBtn:before {
    left: 20px;
  }
  .ocam .moreBtn:after {
    left: 27px;
    top: 50%;
  }
}
.ocam__inner {
  padding-bottom: 167px;
  padding-top: 62px;
}
@media screen and (max-width: 768px) {
  .ocam__inner {
    padding-bottom: 82px;
    padding-top: 48px;
  }
}
.ocam__unit:not(:last-of-type) {
  border-bottom: 1px solid #e6e6e6;
}
.ocam__unit:nth-of-type(1) {
  padding-bottom: 104px;
}
@media screen and (max-width: 768px) {
  .ocam__unit:nth-of-type(1) {
    padding-bottom: 61px;
  }
}
.ocam__unit:nth-of-type(1) .lowerMiddleTtl {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .ocam__unit:nth-of-type(1) .moreBtn {
    font-size: 1.4rem;
  }
}
.ocam__unit:nth-of-type(2) {
  padding-bottom: 101px;
  padding-top: 89px;
}
@media screen and (max-width: 768px) {
  .ocam__unit:nth-of-type(2) {
    padding-bottom: 48px;
    padding-top: 54px;
  }
}
.ocam__unit:nth-of-type(2) .lowerMiddleTtl {
  margin-bottom: 44px;
}
@media screen and (max-width: 768px) {
  .ocam__unit:nth-of-type(2) .lowerMiddleTtl {
    margin-bottom: 29px;
  }
}
.ocam__unit:nth-of-type(3) {
  padding-bottom: 102px;
  padding-top: 94px;
}
@media screen and (max-width: 768px) {
  .ocam__unit:nth-of-type(3) {
    padding-bottom: 61px;
    padding-top: 56px;
  }
}
.ocam__unit:nth-of-type(3) .lowerMiddleTtl {
  margin-bottom: 36px;
}
@media screen and (max-width: 768px) {
  .ocam__unit:nth-of-type(3) .lowerMiddleTtl {
    margin-bottom: 37px;
  }
}
.ocam__unit:nth-of-type(4) {
  padding-bottom: 142px;
  padding-top: 89px;
}
@media screen and (max-width: 768px) {
  .ocam__unit:nth-of-type(4) {
    padding-bottom: 60px;
    padding-top: 55px;
  }
}
.ocam__unit:nth-of-type(4) .lowerMiddleTtl {
  margin-bottom: 62px;
}
@media screen and (max-width: 768px) {
  .ocam__unit:nth-of-type(4) .lowerMiddleTtl {
    margin-bottom: 28px;
  }
}
.ocam__unit:nth-of-type(5) {
  padding-top: 86px;
}
@media screen and (max-width: 768px) {
  .ocam__unit:nth-of-type(5) {
    padding-top: 54px;
  }
}
.ocam__unit:nth-of-type(5) .lowerMiddleTtl {
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .ocam__unit:nth-of-type(5) .lowerMiddleTtl {
    margin-bottom: 34px;
  }
}
@media screen and (max-width: 768px) {
  .ocam__unit:nth-of-type(5) .moreBtn {
    margin-top: 18px;
  }
}
.ocam__flex {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .ocam__flex {
    flex-direction: column;
  }
}
.ocam__descWrap {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .ocam__descWrap {
    font-size: 1.6rem;
  }
}
.ocam__annotationWrap {
  font-size: 1.6rem;
  line-height: 2;
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .ocam__annotationWrap {
    font-size: 1.4rem;
    line-height: 2.2857142857;
    margin-top: 20px;
  }
}
.ocam__fig {
  margin-left: 80px;
  min-width: 320px;
  width: 320px;
}
@media screen and (max-width: 768px) {
  .ocam__fig {
    margin-left: 0px;
    margin-top: 30px;
    min-width: auto;
    width: 60%;
  }
}
@media screen and (max-width: 480px) {
  .ocam__fig {
    width: 100%;
  }
}
.ocam__txt {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .ocam__txt {
    font-size: 1.6rem;
  }
}
.ocam__link {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .ocam__link {
    width: 100%;
  }
}
.ocam__virtual div {
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .ocam__virtual div {
    min-height: 170px;
    height: 44.7368421053vw !important;
  }
}
@media screen and (max-width: 768px) {
  .ocam__virtual iframe {
    min-height: 170px;
    height: 44.7368421053vw !important;
  }
}
.ocam__offerLink {
  background-color: #438cc8;
  border-radius: 3px;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 120px;
  margin-top: 53px;
  max-width: 590px;
  padding: 32px clamp(25px, -2.057rem + 4.14vw, 54px);
  position: relative;
  transition: all 0.2s;
  width: 47.6%;
}
@media screen and (max-width: 1200px) {
  .ocam__offerLink {
    height: auto;
    padding: 25px;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .ocam__offerLink {
    margin-top: 33px;
    max-width: none;
    padding: 19px 35px;
  }
}
@media screen and (max-width: 321px) {
  .ocam__offerLink {
    padding: 19px 15px;
  }
}
.ocam__offerLink:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .ocam__offerLink:hover {
    opacity: 1;
  }
}
.ocam__offerLink:before {
  background-color: #fff;
  border-radius: 9999px;
  content: "";
  height: 36px;
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
}
@media screen and (max-width: 1300px) {
  .ocam__offerLink:before {
    height: 24px;
    right: 23px;
    top: 52.5%;
    width: 24px;
  }
}
.ocam__offerLink:after {
  content: "";
  border-top: solid 2px #438cc8;
  border-right: solid 2px #438cc8;
  height: 10px;
  position: absolute;
  right: 53px;
  top: 49%;
  transform: rotate(45deg) translateY(-50%);
  width: 10px;
}
@media screen and (max-width: 1300px) {
  .ocam__offerLink:after {
    height: 8px;
    right: 35px;
    width: 8px;
  }
}
@media screen and (max-width: 768px) {
  .ocam__offerLink:after {
    height: 7px;
    top: 52%;
    width: 7px;
  }
}
.ocam__offerLink_type_tel {
  background-color: #1a1a1a;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .ocam__offerLink_type_tel {
    padding: 23px 25px 32px;
  }
}
.ocam__offerLink_type_tel:before, .ocam__offerLink_type_tel:after {
  content: none;
}
.ocam__offerLinkSpan_lg_ja {
  font-size: clamp(2rem, 1.686rem + 0.29vw, 2.2rem);
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .ocam__offerLinkSpan_lg_ja {
    font-size: 1.6rem;
    margin-bottom: 5px;
  }
}
.ocam__offerLinkSpan_lg_en {
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 768px) {
  .ocam__offerLinkSpan_lg_en {
    font-size: 1.2rem;
  }
}
.ocam__dayFlex {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .ocam__dayFlex {
    align-items: center;
    flex-direction: column;
  }
}
.ocam__dayFlex + .ocam__dayFlex {
  border-top: 1px dashed #e6e6e6;
  padding-top: 30px;
}
@media screen and (max-width: 768px) {
  .ocam__dayFlex + .ocam__dayFlex {
    margin-top: 20px;
  }
}
.ocam__dayWrap {
  margin-bottom: 30px;
  margin-right: 80px;
}
@media screen and (max-width: 768px) {
  .ocam__dayWrap {
    margin: 0 auto 26px;
  }
}
.ocam__year, .ocam__month, .ocam__day {
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1;
}
.ocam__dayTxt, .ocam__week {
  font-size: 2rem;
}
@media screen and (max-width: 768px) {
  .ocam__dayTxt, .ocam__week {
    font-size: 1.9rem;
  }
}
.ocam__ampm {
  display: flex;
  margin-bottom: 30px;
  gap: 0 60px;
}
@media screen and (max-width: 768px) {
  .ocam__ampm {
    flex-wrap: wrap;
    gap: 0 44px;
    margin-bottom: 0px;
    justify-content: center;
  }
}
.ocam__ampmWrap {
  align-items: center;
  display: flex;
}
@media screen and (max-width: 768px) {
  .ocam__ampmWrap {
    flex-direction: column;
    margin-bottom: 10px;
  }
}
.ocam__part {
  align-items: center;
  background-color: #438cc8;
  border-radius: 9999px;
  color: #fff;
  display: flex;
  font-size: 1.8rem;
  height: 36px;
  justify-content: center;
  margin-right: 23px;
  min-width: 134px;
  width: 134px;
}
@media screen and (max-width: 768px) {
  .ocam__part {
    font-size: 1.4rem;
    margin-bottom: 14px;
    margin-right: 0px;
    min-width: 136px;
    width: 136px;
  }
}
.ocam__start, .ocam__hyphen, .ocam__end {
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .ocam__start, .ocam__hyphen, .ocam__end {
    font-size: 2.4rem;
  }
}
.ocam__instaWrap {
  display: flex;
  justify-content: space-between;
  gap: 0 clamp(20px, -7.2rem + 7.67vw, 66px);
}
@media screen and (max-width: 768px) {
  .ocam__instaWrap {
    gap: 30px 0;
    flex-wrap: wrap;
  }
}
.ocam__instaWrap iframe {
  min-width: auto !important;
  width: calc(33.3333% - 10px) !important;
}
@media screen and (max-width: 768px) {
  .ocam__instaWrap iframe {
    margin: 0 auto !important;
    width: 100% !important;
  }
}

.outline__inner {
  padding-bottom: 160px;
  padding-top: 90px;
}
@media screen and (max-width: 768px) {
  .outline__inner {
    padding-bottom: 60px;
    padding-top: 49px;
  }
}
.outline__unit:not(:last-of-type) {
  border-bottom: 1px solid #e6e6e6;
}
.outline__unit:nth-of-type(1) {
  padding-bottom: 145px;
}
@media screen and (max-width: 768px) {
  .outline__unit:nth-of-type(1) {
    padding-bottom: 57px;
  }
}
.outline__unit:nth-of-type(1) .lowerTtl {
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .outline__unit:nth-of-type(1) .lowerTtl {
    margin-bottom: 27px;
  }
}
.outline__unit:nth-of-type(2) {
  padding-bottom: 155px;
  padding-top: 163px;
}
@media screen and (max-width: 768px) {
  .outline__unit:nth-of-type(2) {
    padding-bottom: 54px;
    padding-top: 69px;
  }
}
.outline__unit:nth-of-type(2) .lowerTtl {
  flex-direction: column;
  margin: 0px;
  width: 38.5%;
}
.outline__unit:nth-of-type(2) .lowerTtl__ja {
  letter-spacing: 0;
  margin: 0 0 31px;
  writing-mode: vertical-lr;
}
@media screen and (max-width: 768px) {
  .outline__unit:nth-of-type(2) .lowerTtl__ja {
    letter-spacing: 0.1em;
    margin: 0 0 11px;
  }
}
@media screen and (max-width: 768px) {
  .outline__unit:nth-of-type(2) .lowerTtl {
    margin-bottom: 35px;
  }
}
.outline__unit:nth-of-type(3) {
  padding-top: 157px;
}
@media screen and (max-width: 768px) {
  .outline__unit:nth-of-type(3) {
    padding-top: 66px;
  }
}
.outline__unit:nth-of-type(3) .lowerTtl {
  margin-bottom: 96px;
}
@media screen and (max-width: 768px) {
  .outline__unit:nth-of-type(3) .lowerTtl {
    margin-bottom: 40px;
  }
}
.outline__flex {
  display: flex;
}
@media screen and (max-width: 768px) {
  .outline__flex {
    align-items: center;
    flex-direction: column;
  }
}
.outline__msgWrap {
  margin-right: 60px;
}
@media screen and (max-width: 768px) {
  .outline__msgWrap {
    margin-right: 0px;
    order: 2;
  }
}
.outline__message {
  font-size: 1.8rem;
  line-height: 2;
}
.outline__message:not(:last-of-type) {
  margin-bottom: 36px;
}
@media screen and (max-width: 768px) {
  .outline__message:not(:last-of-type) {
    margin-bottom: 32px;
  }
}
.outline__message:nth-last-of-type(2) {
  margin-bottom: 19px;
}
@media screen and (max-width: 768px) {
  .outline__message:nth-last-of-type(2) {
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 768px) {
  .outline__message {
    font-size: 1.6rem;
  }
}
.outline__principalFig {
  border-radius: 10px;
  overflow: hidden;
  height: clamp(368px, 10.4rem + 22vw, 500px);
  min-width: clamp(280px, 8rem + 16.67vw, 380px);
}
.outline__principalFig img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .outline__principalFig {
    border-radius: 6px;
    order: 1;
    margin-bottom: 25px;
    min-width: auto;
    height: 250px;
    width: 200px;
  }
}
.outline__principal {
  font-size: 2.4rem;
  font-weight: bold;
  margin-right: 7px;
  text-align: right;
}
@media screen and (max-width: 768px) {
  .outline__principal {
    margin-right: 0px;
  }
}
.outline__priSpan {
  font-size: 1.8rem;
  margin-right: 20px;
}
.outline__ttlWrap {
  align-items: center;
  display: flex;
  position: relative;
  margin-bottom: 64px;
}
@media screen and (max-width: 768px) {
  .outline__ttlWrap {
    flex-direction: column;
    margin-bottom: 71px;
  }
}
.outline__ttlTxt {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .outline__ttlTxt {
    font-size: 1.6rem;
  }
}
.outline__logo {
  position: absolute;
  right: -50px;
  top: -30px;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .outline__logo {
    bottom: -37px;
    right: 2px;
    top: auto;
    width: 212px;
  }
}
.outline__list {
  display: flex;
  flex-wrap: wrap;
  gap: 53px 0px;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .outline__list {
    gap: 27px 0px;
  }
}
.outline__item {
  width: calc(33.33333% - clamp(30px, 1rem + 1.67vw, 40px));
}
@media screen and (max-width: 1024px) {
  .outline__item {
    width: calc(33.33333% - clamp(15px, -1.5rem + 2.5vw, 30px));
  }
}
@media screen and (max-width: 768px) {
  .outline__item {
    width: 100%;
  }
}
.outline__listTtl {
  align-items: center;
  display: flex;
  margin-bottom: 18px;
}
@media screen and (max-width: 768px) {
  .outline__listTtl {
    margin-bottom: 8px;
  }
}
.outline__num {
  color: #438cc8;
  font-family: "Montserrat", sans-serif;
  font-size: 4rem;
  font-weight: 600;
  transform: rotate(9deg);
  display: inline-block;
  padding: 3px 20px 0 6px;
}
@media screen and (max-width: 1160px) {
  .outline__num {
    font-size: 3rem;
  }
}
@media screen and (max-width: 768px) {
  .outline__num {
    font-size: 3.9rem;
    padding: 5px 21px 0 9px;
  }
}
.outline__ttlJa {
  font-size: 2.2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 1160px) {
  .outline__ttlJa {
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 768px) {
  .outline__ttlJa {
    font-size: 2.2rem;
  }
}
.outline__fig {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
  padding-top: 69.1489%;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .outline__fig {
    border-radius: 6px;
    margin-bottom: 13px;
  }
}
.outline__fig img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.outline__txt {
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .outline__txt {
    font-size: 1.4rem;
  }
}
.outline__hisItem {
  align-items: baseline;
  display: flex;
  gap: 0 130px;
  padding-bottom: 83px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .outline__hisItem {
    flex-direction: column;
    padding-bottom: 13px;
    padding-left: 53px;
  }
}
.outline__hisItem:not(:last-of-type):before {
  background-color: #1a1a1a;
  content: "";
  display: block;
  height: calc(100% - 49px);
  position: absolute;
  left: 229px;
  top: 42px;
  width: 3px;
}
@media screen and (max-width: 768px) {
  .outline__hisItem:not(:last-of-type):before {
    height: calc(100% - 52px);
    left: 15px;
  }
}
.outline__year {
  align-items: center;
  color: #438cc8;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-size: 2.6rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  min-width: 175px;
  position: relative;
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .outline__year {
    font-size: 2.4rem;
    margin-bottom: 6px;
    min-width: auto;
  }
}
.outline__year:before {
  background-color: #fff;
  border: 3px solid #438cc8;
  border-radius: 9999px;
  content: "";
  display: block;
  height: 30px;
  position: absolute;
  right: -71px;
  top: 0;
  width: 30px;
}
@media screen and (max-width: 768px) {
  .outline__year:before {
    right: auto;
    left: -51px;
  }
}
.outline__year:after {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  right: -61px;
  top: 10px;
  width: 10px;
}
@media screen and (max-width: 768px) {
  .outline__year:after {
    right: auto;
    left: -41px;
  }
}
.outline__jpYear {
  color: #1a1a1a;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  letter-spacing: 0em;
  margin-left: 16px;
}
@media screen and (max-width: 768px) {
  .outline__jpYear {
    font-size: 1.4rem;
  }
}
.outline__historyTxt {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .outline__historyTxt {
    font-size: 1.6rem;
    line-height: 2.25;
  }
}
.outline__link {
  text-decoration: underline;
  transition: all 0.2s;
}
.outline__link:hover {
  opacity: 0.4;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .outline__link:hover {
    opacity: 1;
  }
}

.privacy .lowerMiddleTtl {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .privacy .lowerMiddleTtl {
    font-size: 2rem;
    margin-bottom: 30px;
  }
}
.privacy__inner {
  padding-bottom: 200px;
  padding-top: 70px;
}
@media screen and (max-width: 768px) {
  .privacy__inner {
    padding-bottom: 100px;
    padding-top: 50px;
  }
}
.privacy__lead {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 80px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .privacy__lead {
    font-size: 1.6rem;
    margin-bottom: 40px;
    text-align: unset;
  }
}
.privacy__unit + .privacy__unit {
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  .privacy__unit + .privacy__unit {
    margin-top: 40px;
  }
}
.privacy__txt {
  font-size: 1.8rem;
  line-height: 2;
  margin: 10px 0 0;
}
@media screen and (max-width: 768px) {
  .privacy__txt {
    font-size: 1.6rem;
  }
}
.privacy__link {
  text-decoration: underline;
  transition: 0.2s;
}
.privacy__link:hover {
  opacity: 0.5;
  text-decoration: underline rgba(26, 26, 26, 0);
}

.qc .lowerMiddleTtl {
  margin-bottom: 31px;
}
@media screen and (max-width: 768px) {
  .qc .lowerMiddleTtl {
    font-size: 2rem;
  }
}
.qc .lowerSmallTtl {
  margin-bottom: 25px;
}
@media screen and (max-width: 768px) {
  .qc .lowerSmallTtl {
    font-size: 1.8rem;
    margin-bottom: 22px;
  }
}
.qc__inner {
  padding-bottom: 144px;
  padding-top: 68px;
}
@media screen and (max-width: 768px) {
  .qc__inner {
    padding-top: 35px;
    padding-bottom: 71px;
  }
}
.qc__lead {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 85px;
}
@media screen and (max-width: 768px) {
  .qc__lead {
    font-size: 1.6rem;
    line-height: 2.25;
    margin-bottom: 46px;
  }
}
.qc__unit:nth-of-type(1) {
  margin-bottom: 42px;
}
@media screen and (max-width: 768px) {
  .qc__unit:nth-of-type(1) {
    margin-bottom: 47px;
  }
}
.qc__unit:nth-of-type(2) {
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .qc__unit:nth-of-type(2) {
    margin-bottom: 49px;
  }
}
.qc__unit:nth-of-type(3) {
  margin-bottom: 62px;
}
@media screen and (max-width: 768px) {
  .qc__unit:nth-of-type(3) {
    margin-bottom: 47px;
  }
}
.qc__unit:nth-of-type(4) {
  margin-bottom: 39px;
}
.qc__group:not(:last-of-type) {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .qc__group:not(:last-of-type) {
    margin-bottom: 31px;
  }
}
.qc__txt {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .qc__txt {
    font-size: 1.6rem;
  }
}
.qc__addTxt {
  font-size: 1.8rem;
  line-height: 1.7777777778;
}
@media screen and (max-width: 768px) {
  .qc__addTxt {
    font-size: 1.6rem;
    line-height: 2;
  }
}
.qc__item {
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  line-height: 2;
  padding-left: 1em;
  position: relative;
}
.qc__item:not(:last-of-type) {
  margin-bottom: 6px;
}
@media screen and (max-width: 768px) {
  .qc__item:not(:last-of-type) {
    margin-bottom: 5px;
  }
}
.qc__item:before {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  display: block;
  height: 5px;
  left: 0;
  position: absolute;
  top: 13px;
  width: 5px;
}
.qc__itemLink {
  color: #438cc8;
  text-decoration: underline;
  transition: all 0.2s;
}
.qc__itemLink:hover {
  opacity: 0.5;
  text-decoration: none;
}
.qc__link {
  color: #438cc8;
  text-decoration: underline;
  transition: all 0.2s;
}
.qc__link:hover {
  opacity: 0.5;
  text-decoration: none;
}
.qc__tel {
  transition: all 0.2s;
}
.qc__tel:hover {
  opacity: 0.5;
}
.qc__mail {
  color: #438cc8;
  text-decoration: underline;
  transition: all 0.2s;
}
.qc__mail:hover {
  opacity: 0.5;
  text-decoration: none;
}
.qc__att {
  color: #ff4841;
  font-size: 1.6rem;
  line-height: 2;
  margin-top: 20px;
}

.resultPublication .lowerMiddleTtl {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .resultPublication .lowerMiddleTtl {
    font-size: 2rem;
    margin-bottom: 30px;
  }
}
.resultPublication .moreBtn {
  padding: 23px 96px 23px 136px;
}
@media screen and (max-width: 768px) {
  .resultPublication .moreBtn {
    padding: 19px 48px 19px 68px;
    width: 100%;
  }
}
.resultPublication .moreBtn::before {
  left: 27px;
  top: 50%;
}
@media screen and (max-width: 768px) {
  .resultPublication .moreBtn::before {
    left: 20px;
  }
}
.resultPublication .moreBtn::after {
  left: 35px;
}
@media screen and (max-width: 768px) {
  .resultPublication .moreBtn::after {
    left: 28px;
  }
}
.resultPublication__inner {
  padding-bottom: 130px;
  padding-top: 70px;
}
@media screen and (max-width: 768px) {
  .resultPublication__inner {
    padding-bottom: 100px;
    padding-top: 50px;
  }
}
.resultPublication__unit + .resultPublication__unit {
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  .resultPublication__unit + .resultPublication__unit {
    margin-top: 40px;
  }
}
.resultPublication__fig {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
  margin: 80px auto 0;
  max-width: 620px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .resultPublication__fig {
    margin: 40px auto 0;
    width: calc(100% - 40px);
  }
}
.resultPublication__txt {
  font-size: 1.6rem;
  line-height: 2;
  margin: 50px 0 0;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .resultPublication__txt {
    margin: 30px 0 0;
  }
}
.resultPublication__txt a {
  color: #438cc8;
  text-decoration: underline;
  transition: 0.2s;
}
.resultPublication__txt a:hover {
  opacity: 0.5;
  text-decoration: underline transparent;
}
.resultPublication__btn {
  margin: 100px 0 0;
}
@media screen and (max-width: 768px) {
  .resultPublication__btn {
    margin: 60px 0 0;
  }
}

.schedule__unit_color_red {
  background: #fff0f0;
}
.schedule__unit_color_red::before {
  background: #FFE4E2;
}
.schedule__unit_color_red .schedule__ttl {
  border: 1px solid #ff4841;
}
.schedule__unit_color_red .schedule__ttl, .schedule__unit_color_red .schedule__en {
  color: #ff4841;
}
.schedule__unit_color_red .schedule__circle, .schedule__unit_color_red .schedule__term::before {
  background: #ff4841;
}
.schedule__unit_color_red .schedule__circle::before {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2292%22%20height%3D%222%22%20viewBox%3D%220%200%2092%202%22%3E%20%3Cline%20id%3D%22%E7%B7%9A_11%22%20data-name%3D%22%E7%B7%9A%2011%22%20x2%3D%2290%22%20transform%3D%22translate(1%201)%22%20fill%3D%22none%22%20stroke%3D%22%23ff4841%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%220%205%22%2F%3E%3C%2Fsvg%3E");
}

.schedule__unit_color_blue {
  background: #f0f6fb;
}
.schedule__unit_color_blue::before {
  background: #E3EEF7;
}
.schedule__unit_color_blue .schedule__ttl {
  border: 1px solid #438cc8;
}
.schedule__unit_color_blue .schedule__ttl, .schedule__unit_color_blue .schedule__en {
  color: #438cc8;
}
.schedule__unit_color_blue .schedule__circle, .schedule__unit_color_blue .schedule__term::before {
  background: #438cc8;
}
.schedule__unit_color_blue .schedule__circle::before {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2292%22%20height%3D%222%22%20viewBox%3D%220%200%2092%202%22%3E%20%3Cline%20id%3D%22%E7%B7%9A_14%22%20data-name%3D%22%E7%B7%9A%2014%22%20x2%3D%2290%22%20transform%3D%22translate(1%201)%22%20fill%3D%22none%22%20stroke%3D%22%23438cc8%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%220%205%22%2F%3E%3C%2Fsvg%3E");
}

.schedule__unit_color_orange {
  background: #fff8f0;
}
.schedule__unit_color_orange::before {
  background: #FFF1E2;
}
.schedule__unit_color_orange .schedule__ttl {
  border: 1px solid #ffa53e;
}
.schedule__unit_color_orange .schedule__ttl, .schedule__unit_color_orange .schedule__en {
  color: #ffa53e;
}
.schedule__unit_color_orange .schedule__circle, .schedule__unit_color_orange .schedule__term::before {
  background: #ffa53e;
}
.schedule__unit_color_orange .schedule__circle::before {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2292%22%20height%3D%222%22%20viewBox%3D%220%200%2092%202%22%3E%20%3Cline%20id%3D%22%E7%B7%9A_17%22%20data-name%3D%22%E7%B7%9A%2017%22%20x2%3D%2290%22%20transform%3D%22translate(1%201)%22%20fill%3D%22none%22%20stroke%3D%22%23ffa53e%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%220%205%22%2F%3E%3C%2Fsvg%3E");
}

.schedule__unit_color_green {
  background: #effbf6;
}
.schedule__unit_color_green::before {
  background: #E1F7ED;
}
.schedule__unit_color_green .schedule__ttl {
  border: 1px solid #3ac98a;
}
.schedule__unit_color_green .schedule__ttl, .schedule__unit_color_green .schedule__en {
  color: #3ac98a;
}
.schedule__unit_color_green .schedule__circle, .schedule__unit_color_green .schedule__term::before {
  background: #3ac98a;
}
.schedule__unit_color_green .schedule__circle::before {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2292%22%20height%3D%222%22%20viewBox%3D%220%200%2092%202%22%3E%20%3Cline%20id%3D%22%E7%B7%9A_21%22%20data-name%3D%22%E7%B7%9A%2021%22%20x2%3D%2290%22%20transform%3D%22translate(1%201)%22%20fill%3D%22none%22%20stroke%3D%22%233ac98a%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%220%205%22%2F%3E%3C%2Fsvg%3E");
}

.schedule {
  padding-bottom: 190px;
}
@media screen and (max-width: 768px) {
  .schedule {
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 768px) {
  .schedule__container {
    padding: 0;
  }
}
.schedule__txt {
  text-align: center;
  font-size: 1.8rem;
  padding-top: 70px;
  padding-bottom: 110px;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .schedule__txt {
    text-align: left;
    font-size: 1.6rem;
    padding: 40px 20px 60px;
  }
}
.schedule__quaterUnit {
  position: relative;
  z-index: 0;
  padding: 120px 50px 65px 105px;
}
@media screen and (max-width: 768px) {
  .schedule__quaterUnit {
    padding: 80px 30px;
  }
}
.schedule__quaterUnit::before {
  content: "";
  display: block;
  height: calc(100% - 90px);
  width: 40px;
  border-radius: 20px;
  position: absolute;
  top: 50px;
  left: 135px;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .schedule__quaterUnit::before {
    height: calc(100% - 80px);
    width: 20px;
    top: 40px;
    left: 60px;
  }
}
.schedule__quaterUnit:first-of-type {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
@media screen and (max-width: 768px) {
  .schedule__quaterUnit:first-of-type {
    border-radius: 0;
  }
}
.schedule__quaterUnit:last-of-type {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
@media screen and (max-width: 768px) {
  .schedule__quaterUnit:last-of-type {
    border-radius: 0;
  }
}
.schedule__ttl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  border-radius: 75px;
  background: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.15em;
  position: absolute;
  top: -20px;
  left: 80px;
}
@media screen and (max-width: 768px) {
  .schedule__ttl {
    left: 30px;
  }
}
.schedule__monthUnit {
  padding-left: 160px;
  position: relative;
  min-height: 155px;
  padding-top: 75px;
}
@media screen and (max-width: 768px) {
  .schedule__monthUnit {
    padding-left: 90px;
    padding-top: 68px;
    min-height: auto;
  }
}
.schedule__monthUnit + .schedule__monthUnit {
  margin-top: 45px;
}
@media screen and (max-width: 768px) {
  .schedule__monthUnit + .schedule__monthUnit {
    margin-top: 15px;
  }
}
.schedule__circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .schedule__circle {
    width: 75px;
    height: 75px;
  }
}
.schedule__circle::before {
  content: "";
  display: block;
  width: 90px;
  height: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 50px;
  background-repeat: no-repeat;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .schedule__circle::before {
    width: 65px;
    left: 37px;
  }
}
.schedule__jp {
  color: #fff;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .schedule__jp {
    font-size: 1.2rem;
  }
}
.schedule__num {
  font-size: 2.4rem;
  margin-right: 3px;
}
@media screen and (max-width: 768px) {
  .schedule__num {
    font-size: 1.8rem;
  }
}
.schedule__en {
  position: absolute;
  left: 160px;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 600;
  font-size: 2rem;
  letter-spacing: 0.15em;
}
@media screen and (max-width: 768px) {
  .schedule__en {
    font-size: 1.8rem;
    left: 113px;
  }
}
.schedule__list {
  width: calc(50% - 20px);
}
@media screen and (max-width: 1200px) {
  .schedule__list {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .schedule__list {
    margin-bottom: 26px;
  }
}
.schedule__term {
  padding-left: 15px;
  position: relative;
  padding-bottom: 12px;
}
@media screen and (max-width: 768px) {
  .schedule__term {
    padding-bottom: 0;
    margin-bottom: 10px;
  }
}
.schedule__term::before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 3px;
  position: absolute;
  top: 10px;
  left: 0;
}
.schedule__detail {
  font-size: 1.4rem;
  line-height: 2;
  padding-bottom: 12px;
}
@media screen and (max-width: 768px) {
  .schedule__detail {
    padding-bottom: 0;
  }
}
.schedule__detail_type_imgAbsolute {
  position: relative;
  width: 200px;
}
.schedule__detail_type_imgAbsolute .schedule__img {
  position: absolute;
}
@media screen and (max-width: 1200px) {
  .schedule__detail_type_imgAbsolute .schedule__img {
    position: relative;
  }
}
@media screen and (max-width: 768px) {
  .schedule p.schedule__detail {
    margin-bottom: 15px;
  }
}
.schedule__row {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .schedule__row {
    flex-direction: column;
  }
}
.schedule__row .schedule__img {
  margin-left: 20px;
  margin-top: -36px;
}
@media screen and (max-width: 1200px) {
  .schedule__row .schedule__img {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 18px;
  }
}
@media screen and (max-width: 768px) {
  .schedule__row .schedule__img {
    margin-bottom: 0;
  }
}
.schedule__row_align_top .schedule__img {
  margin-top: 0;
}
.schedule__column {
  width: calc(50% - 20px);
}
@media screen and (max-width: 1200px) {
  .schedule__column {
    width: 100%;
  }
}
.schedule__column .schedule__list {
  width: 100%;
}
.schedule__img {
  width: 180px;
  height: 120px;
}

.seminar .lowerMiddleTtl {
  margin-bottom: 35px;
}
@media screen and (max-width: 768px) {
  .seminar .lowerMiddleTtl {
    font-size: 2rem;
    margin-bottom: 31px;
  }
}
.seminar .lowerSmallTtl {
  margin-bottom: 22px;
}
@media screen and (max-width: 768px) {
  .seminar .lowerSmallTtl {
    font-size: 1.8rem;
  }
}
.seminar__inner {
  padding-bottom: 204px;
  padding-top: 68px;
}
@media screen and (max-width: 768px) {
  .seminar__inner {
    padding-top: 31px;
    padding-bottom: 80px;
  }
}
.seminar__lead {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .seminar__lead {
    font-size: 1.6rem;
    line-height: 2;
    margin-bottom: 35px;
  }
}
.seminar__unit:not(:last-of-type) {
  margin-bottom: 53px;
}
@media screen and (max-width: 768px) {
  .seminar__unit:not(:last-of-type) {
    margin-bottom: 48px;
  }
}
.seminar__fig {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .seminar__fig {
    border-radius: 6px;
    height: 300px;
    margin-bottom: 51px;
  }
}
.seminar__fig img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.seminar__group {
  border: 4px solid #f8f8f8;
  border-radius: 6px;
  padding: 35px 46px 40px;
}
@media screen and (max-width: 768px) {
  .seminar__group {
    padding: 31px 20px 40px 17px;
  }
}
.seminar__group:not(:last-of-type) {
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .seminar__group:not(:last-of-type) {
    margin-bottom: 30px;
  }
}
.seminar__group:last-of-type {
  margin-bottom: 74px;
}
@media screen and (max-width: 768px) {
  .seminar__group:last-of-type {
    margin-bottom: 55px;
  }
}
.seminar__detail {
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 32px 75px 37px 56px;
}
@media screen and (max-width: 768px) {
  .seminar__detail {
    padding: 20px 10px 37px 16px;
  }
}
.seminar__detail p {
  font-size: 1.6rem;
  line-height: 2;
  margin-bottom: 6px;
  padding-left: 1.2em;
  position: relative;
}
.seminar__detail p span {
  font-size: 1.6rem !important;
}
@media screen and (max-width: 768px) {
  .seminar__detail p span {
    font-size: 1.5rem !important;
    line-height: 2.1333333333 !important;
  }
}
.seminar__detail p:before {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  display: block;
  height: 8px;
  left: 0;
  position: absolute;
  top: 13px;
  width: 8px;
}
@media screen and (max-width: 768px) {
  .seminar__detail p {
    font-size: 1.5rem;
    line-height: 2.1333333333;
  }
}
.seminar__detailHead {
  font-size: 1.3em;
  font-weight: bold;
  color: #ff0000;
  margin-bottom: 0.5em;
}
.seminar__addTxt {
  font-size: 1.8rem;
  line-height: 1.7777777778;
}
@media screen and (max-width: 768px) {
  .seminar__addTxt {
    font-size: 1.6rem;
    line-height: 2;
  }
}
.seminar__tel {
  transition: all 0.2s;
}
.seminar__tel:hover {
  opacity: 0.5;
}
.seminar__mail {
  color: #438cc8;
  text-decoration: underline;
  transition: all 0.2s;
}
.seminar__mail:hover {
  opacity: 0.5;
  text-decoration: none;
}
.seminar__att {
  color: #ff4841;
  font-size: 1.6rem;
  line-height: 2;
  margin-top: 20px;
}
.seminar__txt {
  font-size: 1.6rem;
  line-height: 2;
  margin-bottom: 6px;
  padding-left: 1.2em;
  position: relative;
}
.seminar__txt:before {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  display: block;
  height: 8px;
  left: 0;
  position: absolute;
  top: 13px;
  width: 8px;
}
@media screen and (max-width: 768px) {
  .seminar__txt {
    font-size: 1.5rem;
    line-height: 2.1333333333;
  }
}
.seminar__txtRed {
  color: #ff4841;
}
.seminar__item:not(:last-of-type) {
  margin-bottom: 7px;
}
@media screen and (max-width: 768px) {
  .seminar__item:not(:last-of-type) {
    margin-bottom: 6px;
  }
}
.seminar__detailLink {
  color: #438cc8;
  text-decoration: underline;
  transition: all 0.2s;
}
.seminar__detailLink:hover {
  opacity: 0.5;
  text-decoration: none;
}
.seminar__link {
  display: inline-block;
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  line-height: 2;
  padding-left: 1em;
  position: relative;
  text-decoration: underline;
  transition: all 0.2s;
}
.seminar__link:before {
  background-color: #438cc8;
  border-radius: 9999px;
  content: "";
  display: block;
  height: 5px;
  left: 0;
  position: absolute;
  top: 13px;
  width: 5px;
}
.seminar__link:hover {
  opacity: 0.5;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .seminar {
    font-size: 1.5rem;
    line-height: 2.1333333333;
  }
}

.seminarDet .lowerMiddleTtl {
  margin-bottom: 46px;
}
@media screen and (max-width: 768px) {
  .seminarDet .lowerMiddleTtl {
    font-size: 2rem;
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 768px) {
  .seminarDet .moreBtn {
    display: flex;
    justify-content: center;
    padding: 19px 70px 19px 70px;
    width: 100%;
  }
  .seminarDet .moreBtn:before {
    left: 28px;
  }
  .seminarDet .moreBtn:after {
    left: 36px;
  }
}
.seminarDet__inner {
  padding-bottom: 208px;
  padding-top: 68px;
}
@media screen and (max-width: 768px) {
  .seminarDet__inner {
    padding-top: 40px;
    padding-bottom: 80px;
  }
}
.seminarDet__seminarDetTtl {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 34px;
}
@media screen and (max-width: 768px) {
  .seminarDet__seminarDetTtl {
    font-size: 1.8rem;
    line-height: 2;
    margin-bottom: 23px;
  }
}
.seminarDet__list {
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .seminarDet__list {
    margin-bottom: 50px;
  }
}
.seminarDet__item {
  align-items: flex-start;
  display: flex;
}
.seminarDet__item:not(:last-of-type) {
  margin-bottom: 25px;
}
@media screen and (max-width: 768px) {
  .seminarDet__item:not(:last-of-type) {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 768px) {
  .seminarDet__item_type_target, .seminarDet__item_type_text, .seminarDet__item_type_contents, .seminarDet__item_type_detail {
    flex-direction: column;
  }
  .seminarDet__item_type_target .seminarDet__cat, .seminarDet__item_type_text .seminarDet__cat, .seminarDet__item_type_contents .seminarDet__cat, .seminarDet__item_type_detail .seminarDet__cat {
    margin-bottom: 15px;
  }
  .seminarDet__item_type_target .seminarDet__contents, .seminarDet__item_type_text .seminarDet__contents, .seminarDet__item_type_contents .seminarDet__contents, .seminarDet__item_type_detail .seminarDet__contents {
    padding: 0;
  }
}
.seminarDet__cat {
  align-items: center;
  background-color: #438cc8;
  border-radius: 9999px;
  color: #fff;
  display: flex;
  font-size: 1.8rem;
  height: 36px;
  justify-content: center;
  margin-top: 2px;
  min-width: 134px;
  width: 134px;
}
@media screen and (max-width: 768px) {
  .seminarDet__cat {
    font-size: 1.4rem;
    min-width: 90px;
    width: 90px;
  }
}
.seminarDet__contents {
  font-size: 1.8rem;
  line-height: 1.7777777778;
  padding-left: 23px;
}
@media screen and (max-width: 768px) {
  .seminarDet__contents {
    font-size: 1.6rem;
    line-height: 2;
    padding-left: 13px;
  }
}
.seminarDet__contents_type_bold {
  font-size: 2.4rem;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .seminarDet__contents_type_bold {
    font-size: 2.2rem;
    line-height: 1.6363636364;
  }
}
.seminarDet__link {
  transition: all 0.2s;
  text-decoration: underline;
}
.seminarDet__link:hover {
  opacity: 0.5;
  text-decoration: none;
}

.shingaku {
  padding-bottom: 190px;
}
@media screen and (max-width: 768px) {
  .shingaku {
    padding-bottom: 80px;
  }
}
.shingaku__section + .shingaku__section .shingaku__inner {
  border-top: 1px solid #e6e6e6;
}
.shingaku__outline .shingaku__inner {
  padding-top: 60px;
  padding-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .shingaku__outline .shingaku__inner {
    padding-top: 45px;
    padding-bottom: 60px;
  }
}
.shingaku__ttl {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .shingaku__ttl {
    margin-bottom: 30px;
  }
}
.shingaku__txt {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  .shingaku__txt {
    font-size: 1.6rem;
  }
}
.shingaku__txt_color_red {
  color: #ed1c24;
}
.shingaku__schedule .shingaku__inner {
  padding-top: 85px;
}
@media screen and (max-width: 768px) {
  .shingaku__schedule .shingaku__inner {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.shingaku__unit {
  display: flex;
}
.shingaku__unit + .shingaku__unit {
  border-top: 1px dashed #e6e6e6;
  padding-top: 56px;
}
.shingaku__flex {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
@media screen and (max-width: 768px) {
  .shingaku__flex {
    gap: 18px;
  }
}
.shingaku__listWrap {
  padding-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .shingaku__listWrap {
    padding-bottom: 30px;
  }
}
.shingaku__list {
  display: flex;
  align-items: flex-start;
  column-gap: 23px;
}
@media screen and (max-width: 768px) {
  .shingaku__list {
    column-gap: 12px;
    flex-wrap: wrap;
    row-gap: 12px;
  }
}
.shingaku__list_w_100 {
  width: 100%;
}
.shingaku__term {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  flex: none;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
  background: #438cc8;
  border-radius: 18px;
  padding-top: 4px;
  padding-bottom: 5px;
  height: fit-content;
}
@media screen and (max-width: 768px) {
  .shingaku__term {
    width: 90px;
    font-size: 1.4rem;
    padding-top: 7px;
    padding-bottom: 8px;
  }
}
.shingaku__detail {
  font-size: 1.8rem;
  line-height: 1.9444444444;
}
@media screen and (max-width: 768px) {
  .shingaku__detail {
    font-size: 1.6rem;
    line-height: 2.1875;
  }
}
.shingaku__detail_f_l {
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1.25;
}
@media screen and (max-width: 768px) {
  .shingaku__detail_f_l {
    font-size: 2.4rem;
    line-height: 1.4583333333;
  }
}
.shingaku__scheduleHeader {
  display: flex;
  font-size: 2rem;
  column-gap: 20px;
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .shingaku__scheduleHeader {
    align-items: center;
    flex-direction: column;
    padding-bottom: 17px;
  }
}
.shingaku__date_sts_finish {
  position: relative;
}
.shingaku__date_sts_finish::before {
  background: #1a1a1a;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.shingaku__finish {
  color: #ed1c24;
}
.shingaku__num {
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .shingaku__num {
    font-size: 2.4rem;
    margin-top: -15px;
    display: inline-block;
  }
}
.shingaku__btnWrap {
  padding-bottom: 56px;
}
@media screen and (max-width: 768px) {
  .shingaku__btnWrap {
    justify-content: center;
    padding-bottom: 28px;
  }
}
@media screen and (max-width: 480px) {
  .shingaku .moreBtn {
    width: 100%;
    padding: 19px 0 19px 30px;
    text-align: center;
  }
  .shingaku .moreBtn::before {
    left: 20px;
  }
  .shingaku .moreBtn::after {
    left: 28px;
  }
}
.shingaku__txtSmall {
  font-size: 1.6rem;
  line-height: 2;
  padding-bottom: 56px;
}
.shingaku__pdf {
  min-width: 320px;
  width: 320px;
  height: fit-content;
  margin-left: 35px;
  transition: 0.2s;
}
@media screen and (max-width: 768px) {
  .shingaku__pdf {
    margin: auto;
    min-width: auto;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 30px;
  }
}
.shingaku__pdf:hover {
  opacity: 0.5;
}
.shingaku__img {
  width: 100%;
  height: auto;
}
.shingaku__contact {
  background-color: #438cc8;
  border-radius: 3px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 590px;
  height: 120px;
  padding-right: 90px;
  padding-left: 50px;
  position: relative;
  transition: 0.2s;
}
@media screen and (max-width: 768px) {
  .shingaku__contact {
    max-width: none;
    width: 100%;
  }
}
.shingaku__contact:before {
  background: #fff;
  border-radius: 9999px;
  content: "";
  height: 36px;
  position: absolute;
  right: 35px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 36px;
}
.shingaku__contact:after {
  content: "";
  border-top: solid 2px #438cc8;
  border-right: solid 2px #438cc8;
  height: 10px;
  position: absolute;
  right: 53px;
  top: 49%;
  transform: rotate(45deg) translateY(-50%);
  width: 10px;
}
.shingaku__contact:hover {
  opacity: 0.5;
}
.shingaku__jp {
  font-size: clamp(2rem, 1.686rem + 0.29vw, 2.2rem);
  font-weight: bold;
}
.shingaku__en {
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  padding-bottom: 10px;
}
.shingaku__webOc .shingaku__inner {
  padding-top: 85px;
}
.shingaku__webOcLink {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .shingaku__webOcLink {
    margin: 30px auto 0;
  }
}

.sitemap .lowerMiddleTtl {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .sitemap .lowerMiddleTtl {
    font-size: 2rem;
    margin-bottom: 30px;
  }
}
.sitemap__inner {
  padding-bottom: 200px;
  padding-top: 70px;
}
@media screen and (max-width: 768px) {
  .sitemap__inner {
    padding-bottom: 100px;
    padding-top: 50px;
  }
}
.sitemap__unit + .sitemap__unit {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .sitemap__unit + .sitemap__unit {
    margin-top: 40px;
  }
}
.sitemap__list {
  column-gap: 60px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
}
@media screen and (max-width: 768px) {
  .sitemap__list {
    column-gap: 30px;
    row-gap: 5px;
  }
}
.sitemap__list_has_children {
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .sitemap__list_has_children {
    justify-content: flex-start;
  }
}
.sitemap__list_has_children .sitemap__list {
  display: block;
}
.sitemap__item {
  font-size: 1.6rem;
  line-height: 2;
  padding-left: 20px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .sitemap__item {
    font-size: 1.5rem;
  }
}
.sitemap__item::before {
  background: #438cc8;
  border-radius: 50%;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  top: 1em;
  transform: translateY(-50%);
  width: 5px;
}
.sitemap__link {
  color: #438cc8;
  text-decoration: underline;
  transition: 0.2s;
}
.sitemap__link:hover {
  opacity: 0.5;
  text-decoration: underline transparent;
}
.sitemap__btn {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  margin: 80px 0 0;
}
@media screen and (max-width: 768px) {
  .sitemap__btn {
    margin: 60px 0 0;
  }
}
.sitemap__group {
  border: 5px solid #f8f8f8;
  border-radius: 6px;
  margin: 40px 0 0;
  padding: 30px 45px;
}
@media screen and (max-width: 768px) {
  .sitemap__group {
    border-width: 3px;
    margin: 30px 0 0;
    padding: 20px 17px;
  }
}

.song .lowerTtl {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .song .lowerTtl {
    align-items: center;
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .song .lowerTtl__ja {
    font-size: 2.2rem;
    margin-bottom: 10px;
    margin-right: 0px;
  }
}
@media screen and (max-width: 321px) {
  .song .lowerTtl__ja {
    font-size: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .song .lowerTtl__en {
    font-size: 1.4rem;
  }
}
.song .lowerMiddleTtl {
  margin-bottom: 26px;
}
@media screen and (max-width: 768px) {
  .song .lowerMiddleTtl {
    margin-bottom: 36px;
  }
}
.song__inner {
  padding-top: 100px;
  padding-bottom: 205px;
}
@media screen and (max-width: 768px) {
  .song__inner {
    padding-top: 38px;
    padding-bottom: 80px;
  }
}
.song__maker {
  font-size: 1.8rem;
  margin-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .song__maker {
    margin-bottom: 25px;
    text-align: center;
  }
}
.song__playWrap {
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  padding: 26px 50px 32px;
  margin-bottom: 57px;
}
@media screen and (max-width: 768px) {
  .song__playWrap {
    align-items: flex-start;
    border-radius: 6px;
    margin-bottom: 59px;
    padding: 17px clamp(10px, -0.143rem + 3.57vw, 26px) 20px;
  }
}
.song__playInner {
  align-items: center;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .song__playInner {
    align-items: flex-start;
    border-radius: 6px;
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .song__playInner audio {
    width: clamp(255px, 23.357rem + 6.7vw, 285px);
  }
}
.song__play {
  font-size: 2.2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-right: 64px;
  padding-left: 35px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .song__play {
    font-size: 1.8rem;
    margin: 0 0 15px 0;
  }
}
.song__play:before {
  background: url(../images/icon_play.svg) no-repeat center/cover;
  content: "";
  display: block;
  height: 21px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 17px;
}
.song__lyricsWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 106px;
}
@media screen and (max-width: 768px) {
  .song__lyricsWrap {
    flex-wrap: wrap;
    margin-bottom: 46px;
  }
}
.song__lyricsItem {
  width: calc(33.3333333% - 37px);
}
@media screen and (max-width: 1024px) {
  .song__lyricsItem {
    width: calc(33.3333333% - 10px);
  }
}
@media screen and (max-width: 768px) {
  .song__lyricsItem {
    display: flex;
    padding: 0 clamp(10px, -0.643rem + 5.13vw, 33px);
    width: 100%;
  }
  .song__lyricsItem:not(:last-of-type) {
    margin-bottom: 25px;
  }
}
.song__num {
  color: #438cc8;
  font-family: "Montserrat", sans-serif;
  font-size: 2.4rem;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .song__num {
    margin-right: 30px;
  }
}
.song__lyrics {
  font-size: 1.8rem;
  line-height: 2;
  white-space: nowrap;
}
@media screen and (max-width: 1024px) {
  .song__lyrics {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 768px) {
  .song__lyrics {
    font-size: clamp(1.4rem, 1.257rem + 0.45vw, 1.6rem);
    line-height: 2.25;
  }
}
.song__imgWrap {
  margin: 0 auto;
  width: 47.2%;
}
@media screen and (max-width: 1024px) {
  .song__imgWrap {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .song__imgWrap {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .webOcam .lowerMiddleTtl {
    font-size: 2rem;
  }
}
.webOcam__inner {
  padding-bottom: 240px;
  padding-top: 100px;
}
@media screen and (max-width: 768px) {
  .webOcam__inner {
    padding-bottom: 82px;
    padding-top: 48px;
  }
}
.webOcam__unit:not(:last-of-type) {
  border-bottom: 1px solid #e6e6e6;
}
.webOcam__unit:nth-of-type(1) {
  padding-bottom: 117px;
}
@media screen and (max-width: 768px) {
  .webOcam__unit:nth-of-type(1) {
    padding-bottom: 61px;
  }
}
.webOcam__unit:nth-of-type(1) .lowerMiddleTtl {
  margin-bottom: 30px;
}
.webOcam__unit:nth-of-type(2) {
  padding-bottom: 114px;
  padding-top: 89px;
}
@media screen and (max-width: 768px) {
  .webOcam__unit:nth-of-type(2) {
    padding-bottom: 63px;
    padding-top: 54px;
  }
}
.webOcam__unit:nth-of-type(2) .lowerMiddleTtl {
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .webOcam__unit:nth-of-type(2) .lowerMiddleTtl {
    margin-bottom: 37px;
  }
}
.webOcam__unit:nth-of-type(3) {
  padding-bottom: 108px;
  padding-top: 89px;
}
@media screen and (max-width: 768px) {
  .webOcam__unit:nth-of-type(3) {
    padding-bottom: 61px;
    padding-top: 56px;
  }
}
.webOcam__unit:nth-of-type(3) .lowerMiddleTtl {
  margin-bottom: 48px;
}
@media screen and (max-width: 768px) {
  .webOcam__unit:nth-of-type(3) .lowerMiddleTtl {
    margin-bottom: 37px;
  }
}
.webOcam__unit:nth-of-type(4) {
  padding-bottom: 108px;
  padding-top: 89px;
}
@media screen and (max-width: 768px) {
  .webOcam__unit:nth-of-type(4) {
    padding-bottom: 60px;
    padding-top: 55px;
  }
}
.webOcam__unit:nth-of-type(4) .lowerMiddleTtl {
  margin-bottom: 48px;
}
@media screen and (max-width: 768px) {
  .webOcam__unit:nth-of-type(4) .lowerMiddleTtl {
    margin-bottom: 38px;
  }
}
.webOcam__unit:nth-of-type(5) {
  padding-top: 86px;
}
@media screen and (max-width: 768px) {
  .webOcam__unit:nth-of-type(5) {
    padding-top: 54px;
  }
}
.webOcam__unit:nth-of-type(5) .lowerMiddleTtl {
  margin-bottom: 48px;
}
@media screen and (max-width: 768px) {
  .webOcam__unit:nth-of-type(5) .lowerMiddleTtl {
    margin-bottom: 38px;
  }
}
.webOcam__ttl_color_red.lowerMiddleTtl:before {
  background-color: #ff4841;
}
.webOcam__ttl_color_orange.lowerMiddleTtl:before {
  background-color: #ffa53e;
}
.webOcam__ttl_color_green.lowerMiddleTtl:before {
  background-color: #3ac98a;
}
.webOcam__txt {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .webOcam__txt {
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
}
.webOcam__virtual div {
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .webOcam__virtual div {
    min-height: 170px;
    height: 44.7368421053vw !important;
  }
}
@media screen and (max-width: 768px) {
  .webOcam__virtual iframe {
    min-height: 170px;
    height: 44.7368421053vw !important;
  }
}
.webOcam__movie {
  max-width: 854px;
  margin: 0 auto;
}
.webOcam__movieWrap {
  padding-top: 56.206%;
  position: relative;
  width: 100%;
}
.webOcam__movieWrap iframe {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.webOcam__movieList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 38px 0;
  margin-top: 100px;
}
@media screen and (max-width: 768px) {
  .webOcam__movieList {
    flex-direction: column;
    margin-top: 53px;
    gap: 32px 0;
  }
}
.webOcam__item {
  border-radius: 9999px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.08);
  font-size: 1.6rem;
  width: calc(50% - 20px);
}
@media screen and (max-width: 768px) {
  .webOcam__item {
    font-size: 1.5rem;
    width: 100%;
  }
}
.webOcam__link {
  align-items: center;
  display: flex;
  height: 100%;
  padding: 20px 40px 20px 78px;
  position: relative;
  transition: all 0.2s;
}
@media screen and (max-width: 768px) {
  .webOcam__link {
    padding: 20px 35px 20px 78px;
  }
}
.webOcam__link:before {
  background: url(../images/icon_youtube_red.svg) no-repeat center/cover;
  content: "";
  display: block;
  height: 19px;
  position: absolute;
  left: 38px;
  top: 50%;
  transform: translateY(-50%);
  width: 27px;
}
@media screen and (max-width: 768px) {
  .webOcam__link:before {
    left: 31px;
  }
}
.webOcam__link:hover {
  opacity: 0.4;
}
@media screen and (max-width: 768px) {
  .webOcam__link:hover {
    opacity: 1;
  }
}