.course {
    .lowerTtlSec__figure img {
        @include media() {
            object-position: left;
        }
    }
    &__container {
        padding-top: 50px;
        padding-bottom: 277px;
        @include media() {
            padding-top: 58px;
            padding-bottom: 65px;
        }
    }
    &__txt {
        text-align: center;
        font-size: 1.8rem;
        @include media() {
            display: none;
        }
    }
    &__list {
        padding-top: 117px;
        @include media() {
            padding-top: 0;
        }
    }
    &__item {
        display: flex;
        width: 100%;
        height: auto;
        @include media() {
            flex-direction: column;
        }
        & + & {
            padding-top: 128px;
            @include media() {
                padding-top: 40px;
            }
        }
        &_color_red {
            & .course__itemWrap {
                background: $red-sub;
            }
            & .course__accent {
                color: $red;
            }
            & .course__circle {
                background: $red;
            }
        }
        &_color_orange {
            & .course__itemWrap {
                background: $orange-sub;
            }
            & .course__accent {
                color: $orange;
            }
            & .course__circle {
                background: $orange;
            }
        }
        &_color_green {
            & .course__itemWrap {
                background: $green-sub;
            }
            & .course__accent {
                color: $green;
            }
            & .course__circle {
                background: $green;
            }
        }
    }
    &__itemWrap {
        min-width: 520px;
        width: 42%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 87px 78px 94px 60px;
        position: relative;
        transition: .2s;
        @include media(tab) {
            min-width: 450px;
            padding-left: 40px;
            padding-right: 50px;
        }
        @include media() {
            padding: 32px 0 76px;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 0;
            min-width: auto;
            width: 100%;
        }
    }
    &__ttl {
        text-align: center;
        line-height: 1;
        padding-bottom: 54px;
        letter-spacing: 0.05em;
        @include media() {
            padding-bottom: 30px;
            padding-bottom: 28px;
        }
    }
    &__accent {
        font-size: 3.6rem;
        display: block;
        padding-bottom: 14px;
        font-weight: bold;
        @include media() {
            font-size: 2.4rem;
            padding-bottom: 12px;
        }
    }
    &__middle {
        display: block;
        font-size: 2.4rem;
        padding-bottom: 19px;
        font-weight: bold;
        @include media() {
            font-size: 1.6rem;
            padding-bottom: 7px;
        }
    }
    &__small {
        display: block;
        font-weight: bold;
        @include media() {
            font-size: 1.6rem;
            line-height: 1.5;
        }
    }
    &__course {
        display: flex;
        align-items: center;
        @include media() {
            padding: 0 20px;
        }
    }
    &__circle {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #fff;
        letter-spacing: 0.05em;
        @include media() {
            width: 70px;
            height: 70px;
            font-size: 1.4rem;
        }
    }
    &__courseList {
        width: calc(100% - 80px);
        padding-left: 20px;
        @include media() {
            width: calc(100% - 70px);
            padding-left: 10px;
        }
    }
    &__courseItem {
        width: 100%;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        background: #fff;
        border-radius: 6px;
        font-size: 1.8rem;
        letter-spacing: 0.05em;
        @include media() {
            height: auto;
            font-size: 1.4rem;
            padding: 8.5px 10px 8.5px 20px;
            justify-content: flex-start;
        }
        & + & {
            margin-top: 10px;
        }
    }
    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 300px;
        height: 70px;
        margin: auto;
        background: #fff;
        border-radius: 35px;
        box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.08);
        position: absolute;
        bottom: -35px;
        left: 0;
        right: 0;
        transition: .2s;
        @include media() {
            width: calc(100% - 40px);
            height: 65px;
            bottom: -32.5px;
        }
        &:hover {
            box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.32);
        }
    }
    &__svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 23px;
        margin: auto;
        @include media() {
            width: 28px;
            height: 28px;
            left: 30px;
        }
    }
    &__fig {
        max-width: 58%;
        height: 430px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        overflow: hidden;
        @include media() {
            max-width: none;
            width: 100%;
            height: auto;
            border-top-right-radius: 0;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
            aspect-ratio: 17 / 13;
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center / center;
    }
}