.notFound {
    padding-top: 285px;
    padding-bottom: 200px;
    @include media(lt) {
        padding-top: 215px;
    }
    @include media() {
        padding-top: 107px;
        padding-bottom: 80px;
    }
    &__section {
        padding-top: 70px;
        @include media() {
            padding-top: 50px;
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
        }
    }
}