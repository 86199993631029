//////////////////////////////
// 求人のお願い　/job-request/
//////////////////////////////
.jobReq {
    &__inner {
        padding-bottom: 188px;
        padding-top: 67px;
        @include media() {
            padding-bottom: 80px;
            padding-top: 39px;
        }
    }
    &__read {
        font-size: 1.8rem;
        line-height: 2;
        margin-bottom: 65px;
        @include media() {
            font-size: 1.6rem;
            margin-bottom: 50px;
        }
    }
    &__ttl {
        margin-bottom: 55px;
        @include media() {
            margin-bottom: 40px;
        }
    }
    &__link {
        &:nth-of-type(1) {
            margin-right: 40px;
            @include media() {
                margin-right: 0px;
            }
        }
    }
    &__btnWrap {
        display: flex;
        flex-wrap: wrap;
        gap: 35px 0;
        margin-bottom: 33px;
        @include media() {
            gap: 25px 0;
        }
    }
    &__addressee {
        border: 5px solid #f2f2f2;
        border-radius: 6px;
        padding: 38px 37px 48px;
        @include media() {
            padding: 26px 8px 35px;
        }
    }
    &__smTtl {
        margin-bottom: 10px;
        @include media() {
            margin-bottom: 20px;
        }
    }
    &__btnUnit {
        margin-bottom: 68px;
        @include media() {
            margin-bottom: 56px;
        }
    }
    &__add {
        font-size: 1.8rem;
        line-height: 2;
        margin-left: 30px;
        @include media() {
            font-size: 1.6rem;
            margin-left: 7px;
        }
    }
        &__tel {
        transition: all 0.2s;
        &:hover {
            opacity: 0.5;
        }
    }
    &__mail {
        color: $blue;
        text-decoration: underline;
        transition: all 0.2s;
        &:hover {
            opacity: 0.5;
            text-decoration: none;
        }
    }
    &__att {
        color: $red;
        font-size: 1.6rem;
        line-height: 2;
        margin-top: 20px;
    }
    .moreBtn {
        @include media() {
            padding: 19px 40px 19px 70px;
            width: 100%;
            &:before {
                left: 18px;
            }
            &:after {
                left: 25px;
            }
        }
    }
}
