//////////////////////////////
// TOP
//////////////////////////////
.topMv {
    // min-height: 1100px;
    position: relative;
    &__catch {
        position: absolute;
        left: 140px;
        top: 41%;
        z-index: 2;
        @include media("lt") {
            left: calc(100 / 1200 * 100vw);
        }
        @include media("sp") {
            left: 34px;
            top: 12%;
        }
    }
    &__catchJa {
        font-size: clamp(3.4rem, 0.257rem + 2.86vw, 5.4rem);
        // font-size: 5.4rem;
        font-weight: bold;
        display: block;
        letter-spacing: 0.05em;
        line-height: 1.5185185185;
        margin-bottom: 10px;
        @include media() {
            font-size: 2.4rem;
        }
    }
    &__catchEn {
        color: $blue;
        display: block;
        font-family: $mont;
        font-size: clamp(16px, 0.971rem + 0.57vw, 20px);
        // font-size: 2rem;
        letter-spacing: 0.15em;
        line-height: 2;
        margin-left: 5px;
        @include media() {
            font-size: 1rem;
        }
    }
    &__sideText {
        color: #cccccc;
        font-family: $mont;
        font-size: 1rem;
        position: absolute;
        letter-spacing: 0.15em;
        right: -107px;
        top: 52%;
        transform: rotate(-90deg);
        z-index: 2;
        @include media("lt") {
            right: -140px;
        }
        @include media("sp") {
            right: -145px;
        }
    }
    &__slide {
        position: relative;
        z-index: 1;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    &__item {
    }
    &__txt {
        left: 0px;
        position: absolute;
        top: 0px;
        width: 100%;
        z-index: 1;
        @include media() {
            display: none;
        }
    }
    &__bigTxt {
        display: none;
        @include media() {
            bottom: 0;
            color: #fff;
            display: block;
            font-family: $mont;
            font-size: 13vw;
            line-height: 0.7;
            position: absolute;
            z-index: 1;
        }
    }
}

//お知らせ
.topNews {
    padding-bottom: 220px;
    padding-top: 180px;
    @include media("tab") {
        padding-top: 100px;
    }
    @include media("sp") {
        padding-bottom: 93px;
        padding-top: 45px;
    }
    &__container {
        display: flex;
        position: relative;
        @include media("tab") {
            flex-direction: column;
        }
    }
    &__ttl {
        margin-right: 140px;
        @include media("tab") {
            margin: 0px 0px 30px;
        }
        @include media("sp") {
            margin: 0px 0px 15px;
        }
    }
    &__contents {
        display: flex;
        flex-direction: column;
        padding-top: 7px;
        width: 100%;
    }
    &__extra {
        border: 1px solid red;
        border-radius: 6px;
        margin-bottom: 55px;
        @include media() {
            margin-bottom: 35px;
        }

        &_color_blue {
            border-color: #0e44fe;
            overflow: hidden;
            .topNews__extraLink {
                background: #0e44fe;
                color: #fff;
                &::before {
                    background: #fff;
                    height: 40px;
                    left: 16px;
                    mask: url(../images/icon_ex_triangle.svg) no-repeat center / contain;
                    top: 12px;
                    width: 40px;
                    @include media () {
                        height: 28px;
                        width: 28px;
                    }
                }
                &::after {
                    border-color: #fff;
                }
                &:hover {
                    background: #fff;
                    color: #0e44fe;
                    &::before {
                        background: #0e44fe;
                    }
                    &::after {
                        border-color: #0e44fe;
                    }
                }
            }
        }
    }
    &__extraLink {
        color: #f15a24;
        display: block;
        font-size: 2rem;
        padding: 16px 30px 20px 80px;
        position: relative;
        transition: all 0.2s;
        @include media() {
            font-size: 1.6rem;
            padding: 12px 30px 16px 60px;
        }
        &:before {
            background: url(../images/icon_ex.svg) no-repeat center / cover;
            content: "";
            display: block;
            height: 34px;
            left: 23px;
            position: absolute;
            top: 15px;
            transition: all 0.2s;
            width: 34px;
            @include media() {
                height: 28px;
                left: 14px;
                width: 28px;
            }
        }
        &:after {
            border-top: solid 2px #f15a24;
            border-right: solid 2px #f15a24;
            content: "";
            height: 8px;
            position: absolute;
            right: 24px;
            top: 50%;
            transform: rotate(45deg) translateY(-50%);
            width: 8px;
            @include media() {
                right: 18px;
            }
        }
        &:hover {
            background-color: #f15a24;
            color: #fff;
            &:before {
                background: url(../images/icon_ex_white.svg) no-repeat center / cover;
            }
            &:after {
                border-color: #fff;
            }
        }
    }
    &__list {
        display: flex;
        margin-bottom: 22px;
        // margin-left: -20px;
        // margin-right: -20px;
        @include media() {
            gap: 0px 10px;
            justify-content: space-between;
            margin-bottom: -12px;
            margin-left: -20px;
            margin-right: auto;
            overflow-x: scroll;
            overflow-y: hidden;
            padding: 10px 10px 33px 16px;
            width: calc(100% + 40px);
        }
    }
    &__item {
        border-radius: 9999px;
        color: #666666;
        cursor: pointer;
        font-size: 1.6rem;
        padding: 12px 10px;
        position: relative;
        text-align: center;
        transition: all 0.3s;
        width: 25%;
        &:after {
            background-color: #e6e6e6;
            border-radius: 9999px;
            content: "";
            display: block;
            height: 10px;
            position: absolute;
            left: 50.5%;
            transform: translateX(-50%);
            top: -6px;
            width: 10px;
            z-index: 1;
            @include media() {
                bottom: -28px;
            }
        }
        @include media() {
            background-color: #f8f8f8;
            font-size: 1.4rem;
            padding: 9px 24px;
            width: auto;
        }
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
        &_status_current {
            background-color: #f8f8f8;
            color: #1a1a1a;
            pointer-events: none;
            &:after {
                background-color: $blue;
            }
            .topNews__span {
                font-weight: bold;
                position: relative;
            }
        }
    }
    &__span {
        display: block;
        @include media() {
            padding: 0px 12px;
            white-space: nowrap;
        }
    }
    &__artWrap {
        display: none;
        @include media() {
            // margin-left: -20px;
            // margin-right: -20px;
            // width: calc(100% + 40px);
        }
        &_status_current {
            display: block;
        }
    }
    &__article {
        align-items: center;
        border-top: 1px solid #ccc;
        display: flex;
        padding: 22px 45px 20px 30px;
        position: relative;
        @include media() {
            flex-wrap: wrap;
            // margin-left: -20px;
            padding: 15px 40px 15px 20px;
        }
        &:after {
            content: "";
            border-top: solid 2px #dcdddd;
            border-right: solid 2px #dcdddd;
            height: 10px;
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            transition: all 0.3s;
            width: 10px;
            @include media() {
                right: 12px;
                top: 54%;
            }
        }
        &:last-of-type {
            border-bottom: 1px solid #ccc;
        }
    }
    &__time {
        border-right: 1px solid #e6e6e6;
        color: #9fa0a0;
        font-size: 1.4rem;
        font-family: $mont;
        font-weight: $semiBold;
        min-width: 101px;
        padding-right: 23px;
        @include media() {
            border-right: none;
            order: 3;
            min-width: auto;
            padding-right: 0px;
        }
    }
    &__cat {
        border-right: 1px solid #e6e6e6;
        font-size: 1.3rem;
        padding: 0 30px;
        white-space: nowrap;
        @include media() {
            background: #e6e6e6;
            border-radius: 9999px;
            border-right: none;
            font-size: 1.2rem;
            order: 1;
            padding: 3px 20px;
            margin-bottom: 5px;
        }
    }
    &__link {
        font-size: 1.6rem;
        position: relative;
        margin-left: 23px;
        transition: all 0.5s;
        @include media() {
            line-height: 1.625;
            margin-bottom: 5px;
            margin-left: 0px;
            order: 2;
            width: 100%;
        }
        &:hover {
            opacity: 0.3;
        }
    }
    &__all {
        bottom: 8px;
        font-size: 1.6rem;
        left: 60px;
        padding-left: 25px;
        position: absolute;
        transition: all 0.2s;
        @include media("tab") {
            bottom: 0;
            left: unset;
            margin-top: 25px;
            margin-left: auto;
            position: relative;
        }
        @include media("sp") {
            margin-left: unset;
            margin-right: auto;
        }
        &:before {
            background: url(../images/icon_arrow_right_white.svg) no-repeat center / cover;
            content: "";
            display: block;
            height: 18px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 18px;
        }
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
}

//大学について
.topAbout {
    padding-bottom: 257px;
    @include media("lt") {
        padding-bottom: 200px;
    }
    @include media("tab") {
        padding-bottom: 160px;
    }
    @include media("sp") {
        padding-bottom: 60px;
    }
    &__inner {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1530px) {
            align-items: center;
        }
        @include media("sp") {
            flex-direction: column;
        }
    }
    &__sideImg {
        max-width: 500px;
        min-width: 290px;
        width: 27.8%;
        @include media("tab") {
            &:first-of-type {
                margin-left: -70px;
            }
            &:last-of-type {
                margin-right: -70px;
            }
        }
        @include media("sp") {
            max-width: none;
            min-width: auto;
            width: 100%;
            &:first-of-type,
            &:last-of-type {
                margin-left: 0px;
                margin-right: 0px;
            }
            img {
                width: 100%;
            }
        }
    }
    &__contents {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 90px 20px 0;
        @media screen and (max-width: 1530px) {
            padding: 0 20px 0;
        }
        @include media("sp") {
            padding: 40px 20px 50px;
        }
    }
    &__logoWrap {
        margin-bottom: 32px;
        @include media() {
            margin-bottom: 7px;
            width: 65px;
        }
    }
    &__ttl {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 36px;
        text-align: center;
        @include media() {
            margin-bottom: 16px;
        }
    }
    &__ttlEn {
        color: $blue;
        font-size: clamp(3rem, -2.343rem + 4.86vw, 6.4rem);
        // font-size: unquote(clamp(3rem, -2.343rem + 4.86vw, 6.4rem));
        font-family: $mont;
        font-weight: $semiBold;
        letter-spacing: 0.1em;
        line-height: 1.25;
        margin-bottom: 13px;
        @include media() {
            font-size: 2.8rem;
            margin-bottom: 2px;
        }
    }
    &__ttlJa {
        font-size: clamp(2.2rem, 0.629rem + 1.43vw, 3.2rem);
        // font-size: 3.2rem;
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        margin-bottom: 43px;
        text-align: center;
        @include media() {
            font-size: 1.6rem;
            line-height: 2.25;
            margin-bottom: 30px;
        }
    }
}

//コース紹介
.topCourse {
    padding-bottom: 295px;
    @include media("lt") {
        padding-bottom: 200px;
    }
    @include media("tab") {
        padding-bottom: 160px;
    }
    @include media("sp") {
        padding-bottom: 74px;
    }
    &__ttl {
        margin-bottom: 43px;
        @include media() {
            margin-bottom: 16px;
        }
    }
    &__tagList {
        display: flex;
        flex-wrap: wrap;
        max-width: 1140px;
        margin-bottom: 63px;
        gap: 20px 10px;
        // padding-bottom: 65px;
        @include media("tab") {
            padding-bottom: 25px;
        }
        @include media("sp") {
            gap: 10px;
            padding-bottom: 0px;
            margin-bottom: 45px;
            justify-content: flex-start;
        }
    }
    &__input {
        display: none;
        &:checked {
            ~ .topCourse__label {
                background-color: $blue;
                color: #fff;
            }
        }
    }
    &__label {
        background: #fff;
        border: 1px solid #e6e6e6;
        border-radius: 9999px;
        display: block;
        font-size: 1.6rem;
        line-height: 36px;
        padding: 0 31px;
        transition: all 0.3s;
        width: fit-content;
        @include media() {
            font-size: 1.4rem;
            line-height: 30px;
            padding: 0 25px;
        }
        &:hover {
            background-color: $blue;
            color: #fff;
            @include media() {
                background-color: #fff;
                color: #1a1a1a;
            }
        }
        &::before {
            content: "#";
        }
    }
    &__courseWrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 90px 0;
        @include media() {
            flex-direction: column;
            gap: 42px 0;
        }
    }
    &__course {
        display: flex;
        flex-direction: column;
        // gap: 90px 0;
        // width: calc(33.33333% - 53px);
        width: calc(33.33333% - clamp(15px, -4.471rem + 5.43vw, 53px));
        @include media("tab") {
            width: calc(33.33333% - 15px);
        }
        @include media() {
            width: 100%;
        }
        &_type_mechanical {
            .topCourse__fig {
                &::before {
                    background-color: $red;
                }
            }
            .topCourse__courseCat {
                color: $red;
            }
        }
        &_type_electrical {
            .topCourse__fig {
                &::before {
                    background-color: $orange;
                }
            }
            .topCourse__courseCat {
                color: $orange;
            }
        }
        &_type_building {
            .topCourse__fig {
                &::before {
                    background-color: $green;
                }
            }
            .topCourse__courseCat {
                color: $green;
            }
        }
        &:nth-of-type(1) {
            order: 1;
            @media screen and (max-width:1470px){
                .topCourse__text {
                    min-height: 130px;
                }
            }
        }
        &:nth-of-type(2) {
            order: 4;
            @media screen and (max-width:1650px){
                .topCourse__text {
                    min-height: 130px;
                }
            }
            @include media() {
                order: 2;
            }
        }
        &:nth-of-type(3) {
            order: 2;
            @media screen and (max-width:1470px){
                .topCourse__text {
                    min-height: 130px;
                }
            }
            @include media() {
                order: 3;
            }
        }
        &:nth-of-type(4) {
            order: 5;
            @media screen and (max-width:1650px){
                .topCourse__text {
                    min-height: 130px;
                }
            }
            @include media() {
                order: 4;
            }
        }
        &:nth-of-type(5) {
            order: 3;
            @media screen and (max-width:1470px){
                .topCourse__text {
                    min-height: 130px;
                }
            }
            @include media() {
                order: 5;
            }
        }
        &:nth-of-type(6) {
            order: 6;
            @media screen and (max-width:1650px){
                .topCourse__text {
                    min-height: 130px;
                }
            }
        }
        .topCourse__text {
            min-height: 110px;
            @media screen and (max-width:1245px){
                min-height: 155px;
            }
        }
    }
    &__link {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        transition: all 0.2s;
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
    &__courseWrap {
    }
    &__fig {
        border-radius: 12px;
        margin-bottom: 27px;
        overflow: hidden;
        position: relative;
        padding-top: 66.6666%;
        width: 100%;
        &::before {
            content: "";
            border-radius: 6px;
            height: 20px;
            position: absolute;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
            width: 83.2%;
            z-index: 1;
        }
        img {
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
        }
        @include media() {
            margin-bottom: 17px;
        }
    }
    &__courseContents {
        flex-direction: column;
        display: flex;
        height: 100%;
        justify-content: flex-start;
        // justify-content: space-between;
    }
    &__eachCourse {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        @include media() {
            margin-bottom: 7px;
        }
    }
    &__courseCat {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 7px;
        @include media() {
            margin-bottom: 5px;
        }
    }
    &__courseName {
        font-size: 2.4rem;
        font-weight: bold;
        @include media() {
            font-size: 2.2rem;
        }
    }
    &__text {
        font-size: 1.6rem;
        line-height: 2;
        @include media() {
            font-size: 1.4rem;
        }
    }
    &__futureWrap {
        border-top: 1px solid #e6e6e6;
        margin-top: 20px;
        padding-top: 20px;
        // flex-shrink: 0;
        @include media() {
            margin-top: 10px;
            padding-top: 15px;
        }
    }
    &__future {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 10px;
    }
    &__futureList {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        // padding-bottom: 65px;
        @include media(tab) {
            padding-bottom: 25px;
        }
        @include media() {
            padding-bottom: 0px;
            justify-content: flex-start;
        }
    }
    &__futureItem {
        background-color: #f2f5f8;
        border-radius: 9999px;
        font-size: 1.4rem;
        padding: 5px 20px;
    }
}

//下部リンク
.topInfo {
    display: flex;
    padding-bottom: 258px;
    @include media("lt") {
        padding-bottom: 200px;
    }
    @include media("tab") {
        padding-bottom: 160px;
    }
    @include media("sp") {
        padding-bottom: 0px;
    }
    &__ttl {
        font-size: 7.8rem;
        // font-size: clamp(4rem, -1.971rem + 5.43vw, 7.8rem);
        font-family: $mont;
        font-weight: $semiBold;
        letter-spacing: 0.15em;
        padding: 0px 64px 0px 90px;
        white-space: nowrap;
        writing-mode: vertical-lr;
        @include media("lt") {
            font-size: 6.8rem;
        }
        @media screen and (max-width: 900px) {
            display: none;
        }
    }
    &__ttlSpan {
        color: $blue;
    }
    &__unit {
        padding: 146px 118px 156px;
        width: calc(100vw - 250px);
        @include media("lt") {
            padding: 100px 70px 120px;
            width: calc(100vw - 237px);
        }
        @media screen and (max-width: 900px) {
            padding: 80px 40px 100px;
            width: 100%;
        }
        &:nth-of-type(1) {
            background: url(../images/bg_top_info_01.jpg) no-repeat center / cover;
            border-radius: 30px 0px 0px 0px;
            @media screen and (max-width: 900px) {
                border-radius: 0px;
            }
            @include media("sp") {
                padding: 46px 27px 170px;
            }
        }
        &:nth-of-type(2) {
            background: url(../images/bg_top_info_02.jpg) no-repeat center / cover;
            @include media("sp") {
                padding: 60px 27px 190px;
            }
        }
        &:nth-of-type(3) {
            background: url(../images/bg_top_info_03.jpg) no-repeat center / cover;
            border-radius: 0px 0px 0px 30px;
            @media screen and (max-width: 900px) {
                border-radius: 0px;
            }
            @include media("sp") {
                padding: 60px 27px 157px;
            }
        }
        &:not(:last-of-type) {
            margin-bottom: 3px;
            @include media() {
                margin-bottom: 1px;
            }
        }
        .moreBtn {
            @include media() {
                font-size: 1.4rem;
                padding: 15px 31px 15px 42px;
                &:before {
                    height: 20px;
                    left: 15px;
                    width: 20px;
                }
                &:after {
                    height: 5px;
                    left: 20px;
                    width: 5px;
                }
            }
        }
    }
    &__num {
        color: #43c5e8;
        font-family: $mont;
        font-size: 1.8rem;
        font-weight: $semiBold;
        letter-spacing: 0.1em;
        margin-bottom: 10px;
        @include media() {
            font-size: 1.4rem;
        }
    }
    &__unitTtl {
        color: #fff;
        font-size: 3.4rem;
        font-weight: bold;
        margin-bottom: 18px;
        @include media() {
            font-size: 2.8rem;
            margin-bottom: 7px;
        }
    }
    &__text {
        color: #fff;
        font-size: 1.6rem;
        line-height: 2;
        margin-bottom: 27px;
        @include media() {
            font-size: 1.5rem;
            line-height: 2.1333333;
            margin-bottom: 8px;
        }
    }
}

//在校生・先輩インタビュー
.topInt {
    background: linear-gradient(180deg, #f2f5f8 0%, #f2f5f8 50%, #fff 50%, #fff 100%);
    padding-bottom: 255px;
    padding-top: 170px;
    @include media("lt") {
        padding-bottom: 200px;
    }
    @include media("tab") {
        padding-bottom: 160px;
    }
    @include media("sp") {
        padding-bottom: 127px;
        overflow: hidden;
        padding-top: 70px;
    }
    .topTtl {
        @include media() {
            align-items: flex-start;
            flex-direction: column;
        }
        &__en {
            letter-spacing: 0.05em;
            padding-right: 30px;
            @include media() {
                font-size: 3.4rem;
                letter-spacing: 0.1em;
                margin-bottom: 7px;
                padding-right: 0px;
            }
        }
        &__ja {
            font-size: 2.8rem;
            @include media() {
                font-size: 2.2rem;
                margin-bottom: 0px;
            }
        }
    }
    &__ttl {
        margin-bottom: 13px;
        @include media() {
            margin-bottom: 27px;
        }
    }
    &__text {
        font-size: 1.6rem;
        line-height: 2;
        margin-bottom: 60px;
        @media screen and (max-width:900px) {
            width: 80%;
        }
        @include media() {
            margin-bottom: 34px;
        }
    }
    &__fig {
        border-radius: 10px;
        height: 420px;
        margin-bottom: 25px;
        overflow: hidden;
        width: 340px;
        @include media() {
            height: 320px;
            margin-bottom: 21px;
            width: 260px;
        }
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    &__profile {
        max-width: 340px;
        padding-right: 50px;
        width: 340px;
        @include media() {
            padding: 0 15px;
            max-width: 260px;
            width: 260px;
        }
    }
    &__profLink {
        transition: all 0.2s;
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
    &__cat {
        color: $blue;
        font-size: 1.6rem;
        letter-spacing: 0.1em;
        margin-bottom: 7px;
        @include media() {
            font-size: 1.4rem;
            margin-bottom: 0px;
        }
    }
    &__name {
        font-size: 2.4rem;
        font-weight: bold;
        margin-bottom: 11px;
        @include media() {
            font-size: 2.2rem;
            margin-bottom: 6px;
        }
    }
    &__profText {
        font-size: 1.6rem;
        line-height: 2;
        @include media() {
            font-size: 1.4rem;
        }
    }
}
