/////////////////////////
// ページネーション
/////////////////////////
.pagination {
    @include media() {
        margin-bottom: 36px;
    }
    &__list {
        align-items: center;
        display: flex;
        justify-content: center;
        @include media("sp") {
            flex-wrap: wrap;
            position: relative;
            padding: 0 20px;
        }
    }
    &__item {
        flex: none;
        font-size: 1.5rem;
        line-height: 65px;
        margin: 0 20px;
        position: relative;
        @include media("tab") {
            line-height: 50px;
            margin: 0 10px;
        }
        @include media("sp") {
            font-size: 1.3rem;
            line-height: 38px;
            margin: 0 4px 10px;
            padding: 0px;
        }
        &_type_prev,
        &_type_next,
        &_type_back {
            &:before {
                background-color: $blue;
                border-radius: 9999px;
                content: "";
                height: 30px;
                position: absolute;
                transition: all 0.2s;
                width: 30px;
                @include media() {
                    height: 20px;
                    width: 20px;
                }
            }
            &:after {
                content: "";
                height: 7px;
                position: absolute;
                transition: all 0.2s;
                width: 7px;
                @include media() {
                    height: 5px;
                    width: 5px;
                }
            }
            .pagination__link {
                font-size: 1.8rem;
                letter-spacing: 0.15em;
                @include media("tab") {
                    font-size: 1.6rem;
                }
                @include media("sp") {
                    font-size: 1.2rem;
                }
            }
        }
        &_type_prev {
            margin: 0 auto 0 0;
            width: 210px;
            @include media("tab") {
                width: 150px;
            }
            @include media("sp") {
                margin: 0 20px 10px 0;
                padding: 0;
                width: 94px;
            }
            @include media("spm") {
                margin: 0 10px 10px 0;
            }
            &:before {
                left: 30px;
                top: 50%;
                transform: translateY(-50%);
                @include media("tab") {
                    left: 10px;
                }
            }
            &:after {
                border-bottom: solid 2px #fff;
                border-left: solid 2px #fff;
                left: 40px;
                top: 50%;
                transform: rotate(45deg) translateY(-50%);
                @include media("tab") {
                    left: 20px;
                }
                @include media("sp") {
                    left: 17px;
                    border-bottom: solid 1px #fff;
                    border-left: solid 1px #fff;
                }
            }
            .pagination__link {
                justify-content: center;
                padding: 0 70px;
                min-width: 110px;
                @include media("sp") {
                    padding: 0 25px;
                    padding-right: 0px;
                    min-width: 80px;
                }
                @include media("spm") {
                    min-width: 40px;
                }
            }
        }
        &_type_back {
            &:before {
                left: 30px;
                top: 50%;
                transform: translateY(-50%);
                @include media("tab") {
                    left: 10px;
                }
                @include media("sp") {
                    content: none;
                }
            }
            &:after {
                border-bottom: solid 2px #fff;
                border-left: solid 2px #fff;
                left: 40px;
                top: 50%;
                transform: rotate(45deg) translateY(-50%);
                @include media("tab") {
                    left: 20px;
                }
                @include media("sp") {
                    content: none;
                }
            }
            .pagination__link {
                padding: 0px 60px 0 87px;
                min-width: 110px;
                @include media("sp") {
                    min-width: 80px;
                    padding: 0px 11px;
                }
                @include media("spm") {
                    min-width: 40px;
                }
            }
        }
        &_type_next {
            margin: 0 0 0 auto;
            width: 210px;
            @include media("tab") {
                width: 150px;
            }
            @include media("sp") {
                margin: 0 0px 10px 20px;
                width: 94px;
            }
            @include media("spm") {
                bottom: -49px;
                left: 50%;
                margin: 0;
                transform: translateX(-50%);
                position: absolute;
            }
            &:before {
                right: 22px;
                top: 50%;
                transform: translateY(-50%);
                @include media("tab") {
                    right: 10px;
                }
            }
            &:after {
                border-right: solid 2px #fff;
                border-top: solid 2px #fff;
                right: 36px;
                top: 50%;
                transform: rotate(45deg) translateY(-50%);
                @include media("tab") {
                    right: 25px;
                }
                @include media("sp") {
                    border-right: solid 1px #fff;
                    border-top: solid 1px #fff;
                    right: 20px;
                }
            }
            .pagination__link {
                justify-content: center;
                padding-right: 24px;
                min-width: 110px;
                @include media("sp") {
                    min-width: 80px;
                    padding-left: 0px;
                }
                @include media("spm") {
                    min-width: 40px;
                }
            }
        }
        &:hover {
            .pagination__link {
                background-color: $blue;
                color: #fff;
                transition: 0.2s;
            }
            &:before {
                background-color: #fff;
            }
            &:after {
                border-color: $blue;
            }
        }
    }
    &__link {
        align-items: center;
        border-radius: 9999px;
        box-shadow: 0 2px 4px 0px rgb(0 0 0 / 8%);
        display: flex;
        color: $blue;
        font-family: $mont;
        justify-content: center;
        min-height: 65px;
        min-width: 65px;
        padding: 0px 5px;
        transition: 0.2s;
        @include media("tab") {
            min-height: 50px;
            min-width: 50px;
        }
        @include media("sp") {
            min-height: 38px;
            min-width: 38px;
            // padding: 5px;
        }
        @include media("spm") {
        }
    }
    &__item_type_current &__link {
        background-color: $blue;
        color: #fff;
        pointer-events: none;
    }
}
