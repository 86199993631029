//////////////////////////////
// 共通設定
//////////////////////////////
html {
    font-size: 62.5%;
    // &.is_open {
    //     width: 100%;
    //     height: 100%;
    //     position: fixed;
    // }
}
body {
    color: #1a1a1a;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.6rem;
    -webkit-text-size-adjust: 100%;
    overflow: hidden;
    @include media() {
        min-width: 320px;
    }
    &.body_overflow_hidden {
        overflow: hidden;
    }
}
a {
    color: inherit;
    text-decoration: none;
}
.largeContainer {
    margin: 0 auto;
    max-width: 1720px;
    padding: 0 60px;
    width: 100%;
    @include media("lt") {
        padding: 0 40px;
    }
    @include media("sp") {
        padding: 0 20px;
    }
}
.container {
    margin: 0 auto;
    max-width: 1360px;
    padding: 0 60px;
    width: 100%;
    @include media("lt") {
        padding: 0 40px;
    }
    @include media("sp") {
        padding: 0 20px;
    }
}

.spOnly {
    display: none;
    @include media() {
        display: block;
    }
}
.pcOnly {
    display: block;
    @include media() {
        display: none;
    }
}
img {
    vertical-align: bottom;
}

//////////////////////////////
// main
//////////////////////////////
.main {
    display: block;
    // @include media("lt") {
    //     padding-top: 100px;
    // }
    // @include media() {
    //     padding-top: 64px;
    // }
}

.lower {
    padding-top: 180px;
    @include media() {
        padding-top: 60px;
    }
    &_type_noImg {
        padding-top: 275px;
        @include media() {
            padding-top: 107px;
        }
    }
}
