//////////////////////////////
// 各種証明 /credentials/
//////////////////////////////
.credentials {
    .lowerMiddleTtl {
        margin-bottom: 20px;
        @include media() {
            font-size: 2rem;
            margin-bottom: 30px;
        }
    }
    .moreBtn {
        padding: 23px 55px 23px 88px;
        @include media() {
            padding: 19px 22px 19px 68px;
            width: 100%;
        }
        &::before {
            left: 27px;
            top: 50%;
            @include media() {
                left: 20px;
            }
        }
        &::after {
            left: 35px;
            @include media() {
                left: 28px;
            }
        }
    }
    &__inner {
        padding-bottom: 252px;
        padding-top: 100px;
        @include media() {
            padding-bottom: 80px;
            padding-top: 40px;
        }
    }
    &__unit {
        & + & {
            margin-top: 80px;
            @include media() {
                margin-top: 60px;
            }
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        margin-bottom: 35px;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__caution {
        color: #ed1c24;
    }

    &__group {
        border: 5px solid #f2f2f2;
        border-radius: 6px;
        margin: 40px 0 0;
        padding: 38px 65px 48px;
        @include media() {
            border-width: 3px;
            padding: 30px 17px 40px;
        }
    }
    &__grTtl {
        margin-bottom: 10px;
        margin-left: -25px;
        @include media() {
            margin-bottom: 20px;
            margin-left: 0;
        }
    }
    &__grTxt {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__grLink {
        color: $blue;
        display: inline-block;
        font-size: 1.8rem;
        margin-top: 10px;
        text-decoration: underline;
        transition: all 0.2s;
        &:hover {
            opacity: 0.4;
            text-decoration: none;
            @include media() {
                opacity: 1;
            }
        }
        @include media() {
            font-size: 1.6rem;
        }
    }

    &__info {
        margin: 20px 0 0;
        & + & {
            margin-top: 40px;
            @include media() {
                margin-top: 30px;
            }
        }
    }
    &__infoTtl {
        color: $blue;
        font-size: 1.8rem;
        line-height: 2;
        margin: 0 0 10px;
        @include media() {
            font-size: 1.7rem;
        }
    }
    &__infoTxt {
        font-size: 1.6rem;
        line-height: 2;
        margin: 10px 0 0;
        @include media() {
            font-size: 1.5rem;
        }
        &_size_l {
            font-size: 1.8rem;
            @include media() {
                font-size: 1.6rem;
            }
        }
    }
    &__infoChips {
        column-gap: 1em;
        display: flex;
        flex-wrap: wrap;
        font-size: 1.6rem;
        line-height: 2;
        margin: 1em 0 0;
        @include media() {
            font-size: 1.5rem;
        }
    }
    &__infoBtn {
        margin: 5px 0;
        @include media() {
            margin: 10px 0;
        }
    }
}
