.collaboration {
    &__inner {
        padding-bottom: 70px;
        padding-top: 105px;
        @include media() {
            padding-top: 66px;
            padding-bottom: 4px;
        }
    }
    &__unit {
        &:not(:last-of-type) {
            border-bottom: 1px solid #e6e6e6;
        }
        &:nth-of-type(1) {
            padding-bottom: 125px;
            @include media() {
                padding-bottom: 60px;
            }
        }
        &:nth-of-type(2) {
            padding-top: 89px;
            @include media() {
                padding-top: 55px;
            }
            .lowerMiddleTtl {
                margin-bottom: 62px;
                @include media() {
                    margin-bottom: 36px;
                }
            }
        }
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 65px 55px;
        @include media("tab") {
            gap: 50px 40px;
        }
        @include media("sp") {
            gap: 45px 0px;
        }
    }
    &__linkUnit {
        border-radius: 10px;
        box-shadow: 0 2px 4px 0px rgb(0 0 0 / 8%);
        overflow: hidden;
        width: calc(33.333333% - 37px);
        @include media("tab") {
            width: calc(33.333333% - 27px);
        }
        @include media("sp") {
            width: 100%;
        }
    }
    &__link {
        transition: all 0.2s;
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
    &__fig {
        @include media() {
            height: 155px;
            overflow: hidden;
        }
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    &__detail {
        height: 100%;
        padding: 30px 40px;
        @include media("tab") {
            padding: 20px;
        }
        @include media("sp") {
            height: auto;
            padding: 25px 30px;
        }
    }
    &__ttl {
        display: flex;
        flex-direction: column;
    }
    &__ttlJa {
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        margin-bottom: 10px;
        @include media("tab") {
            font-size: 1.8rem;
        }
    }
    &__ttlEn {
        color: $blue;
        font-family: $mont;
        font-size: 1.2rem;
        font-weight: $semiBold;
        letter-spacing: 0.15em;
    }
}
