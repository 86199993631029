//////////////////////////////
// 学校概要 /outline/
//////////////////////////////
.outline {
    &__inner {
        padding-bottom: 160px;
        padding-top: 90px;
        @include media() {
            padding-bottom: 60px;
            padding-top: 49px;
        }
    }
    &__unit {
        &:not(:last-of-type) {
            border-bottom: 1px solid #e6e6e6;
        }
        &:nth-of-type(1) {
            padding-bottom: 145px;
            @include media() {
                padding-bottom: 57px;
            }
            .lowerTtl {
                margin-bottom: 60px;
                @include media() {
                    margin-bottom: 27px;
                }
            }
        }
        &:nth-of-type(2) {
            padding-bottom: 155px;
            padding-top: 163px;
            @include media() {
                padding-bottom: 54px;
                padding-top: 69px;
            }
            .lowerTtl {
                flex-direction: column;
                margin: 0px;
                width: 38.5%;
                &__ja {
                    letter-spacing: 0;
                    margin: 0 0 31px;
                    writing-mode: vertical-lr;
                    @include media() {
                        letter-spacing: 0.1em;
                        margin: 0 0 11px;
                    }
                }
                @include media() {
                    margin-bottom: 35px;
                }
            }
        }
        &:nth-of-type(3) {
            padding-top: 157px;
            @include media() {
                padding-top: 66px;
            }
            .lowerTtl {
                margin-bottom: 96px;
                @include media() {
                    margin-bottom: 40px;
                }
            }
        }
    }
    &__flex {
        display: flex;
        @include media() {
            align-items: center;
            flex-direction: column;
        }
    }
    &__msgWrap {
        margin-right: 60px;
        @include media() {
            margin-right: 0px;
            order: 2;
        }
    }
    &__message {
        font-size: 1.8rem;
        line-height: 2;
        &:not(:last-of-type) {
            margin-bottom: 36px;
            @include media() {
                margin-bottom: 32px;
            }
        }
        &:nth-last-of-type(2) {
            margin-bottom: 19px;
            @include media() {
                margin-bottom: 8px;
            }
        }
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__principalFig {
        border-radius: 10px;
        overflow: hidden;
        height: clamp(368px, 10.4rem + 22vw, 500px);
        min-width: clamp(280px, 8rem + 16.67vw, 380px);
        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
        @include media("sp") {
            border-radius: 6px;
            order: 1;
            margin-bottom: 25px;
            min-width: auto;
            height: 250px;
            width: 200px;
        }
        @include media("spm") {
            // width: 100%;
        }
    }
    &__principal {
        font-size: 2.4rem;
        font-weight: bold;
        margin-right: 7px;
        text-align: right;
        @include media() {
            margin-right: 0px;
        }
    }
    &__priSpan {
        font-size: 1.8rem;
        margin-right: 20px;
    }
    &__ttlWrap {
        align-items: center;
        display: flex;
        position: relative;
        margin-bottom: 64px;
        @include media() {
            flex-direction: column;
            margin-bottom: 71px;
        }
    }
    &__ttlTxt {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__logo {
        position: absolute;
        right: -50px;
        top: -30px;
        z-index: -1;
        @include media() {
            bottom: -37px;
            right: 2px;
            top: auto;
            width: 212px;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 53px 0px;
        justify-content: space-between;
        @include media() {
            gap: 27px 0px;
        }
    }
    &__item {
        width: calc(33.33333% - clamp(30px, 1rem + 1.67vw, 40px));
        @include media("tab") {
            width: calc(33.33333% - clamp(15px, -1.5rem + 2.5vw, 30px));
        }
        @include media("sp") {
            width: 100%;
        }
    }
    &__listTtl {
        align-items: center;
        display: flex;
        margin-bottom: 18px;
        @include media() {
            margin-bottom: 8px;
        }
    }
    &__num {
        color: $blue;
        font-family: $mont;
        font-size: 4rem;
        font-weight: $semiBold;
        transform: rotate(9deg);
        display: inline-block;
        padding: 3px 20px 0 6px;
        @media screen and (max-width: 1160px) {
            font-size: 3rem;
        }
        @include media("sp") {
            font-size: 3.9rem;
            padding: 5px 21px 0 9px;
        }
    }
    &__ttlJa {
        font-size: 2.2rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        @media screen and (max-width: 1160px) {
            font-size: 1.9rem;
        }
        @include media("sp") {
            font-size: 2.2rem;
        }
    }
    &__fig {
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 30px;
        position: relative;
        padding-top: 69.1489%;
        width: 100%;
        @include media() {
            border-radius: 6px;
            margin-bottom: 13px;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    &__txt {
        font-size: 1.6rem;
        line-height: 2;
        @include media() {
            font-size: 1.4rem;
        }
    }
    &__hisItem {
        align-items: baseline;
        display: flex;
        gap: 0 130px;
        padding-bottom: 83px;
        position: relative;
        @include media() {
            flex-direction: column;
            padding-bottom: 13px;
            padding-left: 53px;
        }
        &:not(:last-of-type) {
            &:before {
                background-color: #1a1a1a;
                content: "";
                display: block;
                height: calc(100% - 49px);
                position: absolute;
                left: 229px;
                top: 42px;
                width: 3px;
                @include media() {
                    height: calc(100% - 52px);
                    left: 15px;
                }
            }
        }
    }
    &__year {
        align-items: center;
        color: $blue;
        display: flex;
        font-family: $mont;
        font-size: 2.6rem;
        font-weight: $semiBold;
        letter-spacing: 0.1em;
        min-width: 175px;
        position: relative;
        white-space: nowrap;
        @include media() {
            font-size: 2.4rem;
            margin-bottom: 6px;
            min-width: auto;
        }
        &:before {
            background-color: #fff;
            border: 3px solid $blue;
            border-radius: 9999px;
            content: "";
            display: block;
            height: 30px;
            position: absolute;
            right: -71px;
            top: 0;
            width: 30px;
            @include media() {
                right: auto;
                left: -51px;
            }
        }
        &:after {
            background-color: $blue;
            border-radius: 9999px;
            content: "";
            display: block;
            height: 10px;
            position: absolute;
            right: -61px;
            top: 10px;
            width: 10px;
            @include media() {
                right: auto;
                left: -41px;
            }
        }
    }
    &__jpYear {
        color: #1a1a1a;
        font-family: "Noto Sans JP", sans-serif;
        font-size: 1.6rem;
        letter-spacing: 0em;
        margin-left: 16px;
        @include media() {
            font-size: 1.4rem;
        }
    }
    &__historyTxt {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
            line-height: 2.25;
        }
    }
    &__link {
        text-decoration: underline;
        transition: all 0.2s;
        &:hover {
            opacity: 0.4;
            text-decoration: none;
            @include media() {
                opacity: 1;
            }
        }
    }
}
