.contact {
    margin-bottom: 150px;

    @include media () {
        margin-bottom: 82px;
    }

    &__lowerTtlOnly {
        margin-bottom: 70px;

        @include media () {
            margin-bottom: 30px;
        }
    }

    &__container {}

    &__lead {
        font-size: 1.8rem;
        line-height: 2;
        margin-bottom: 56px;
        text-align: center;

        @include media () {
            font-size: 1.6rem;
            line-height: 2.25;
            margin-bottom: 29px;
        }
    }

    &__form {}

    &__section {
        border-top: 1px solid #e6e6e6;
        padding-top: 124px;

        @include media () {
            padding-top: 55px;
        }
    }

    &__ttl {
        margin-bottom: 40px;

        @include media () {
            margin-bottom: 26px;
        }
    }

    &__txt {
        font-size: 1.8rem;
        letter-spacing: 0;
        line-height: 2;
        margin-bottom: 57px;

        @include media () {
            font-size: 1.6rem;
            line-height: 2.25;
            margin-bottom: 23px;
        }
    }

    &__pamphletList {
        display: grid;
        gap: 100px 80px;
        grid-template-columns: 62% 31%;
        justify-content: center;
        justify-items: center;
        padding: 0 51px;

        @include media (lt) {
            column-gap: 40px;
            padding: 0 20px;
        }

        @include media (tab) {
            grid-template-columns: minmax(0, auto);
        }

        @include media () {
            padding: 0;
            row-gap: 63px;
        }
    }

    &__pamphlet {
        text-align: center;
    }

    &__pamphletImgLink {
        display: block;
        margin-bottom: 48px;
        transition: .2s;

        @include media () {
            margin-bottom: 40px;
        }

        &:hover {
            opacity: 0.5;
        }
    }

    &__pamphletFig {
        overflow: hidden;
    }

    &__pamphletImg {
        display: block;
        max-height: 498px;
        object-fit: contain;
        width: 100%;
    }

    &__pamphletMoreBtn {
        box-shadow: 0 2px 4px rgba(#000, 0.08);
        max-width: 100%;

        @include media () {
            padding: 17px 30px 19px 64px;
            width: 100%;

            &::before {
                height: 28px;
                left: 28px;
                width: 28px;
            }

            &::after {
                left: 50px;
                left: 35px;
            }
        }
    }

    &__moreBtn {
        box-shadow: 0 2px 4px rgba(#000, 0.08);
        display: block;
        margin: 0 auto 138px;
        max-width: 100%;
        width: fit-content;

        @include media () {
            margin-bottom: 62px;

            &::before {
                height: 28px;
                left: 28px;
                width: 28px;
            }

            &::after {
                left: 50px;
                left: 35px;
            }
        }
    }
}

.form,
.mw_wp_form {
    .contact__lead {
        &_type_confirm {
            display: none;
        }
    }

    &__outer {
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(#000, 0.08);
        font-size: 1.8rem;
        line-height: 2;
        margin-bottom: 138px;
        padding: 96px 82px 93px;

        @include media (lt) {
            padding: 96px 60px 93px;
        }

        @include media (tab) {
            padding: 96px 40px 93px;
        }

        @include media () {
            font-size: 1.6rem;
            margin-bottom: 62px;
            padding: 42px 20px 61px;
        }
    }

    &__inner {
        margin-bottom: 46px;
        padding-right: 43px;

        @include media (lt) {
            padding-right: 20px;
        }

        @include media (tab) {
            padding-right: 0;
        }
    }

    &__dl {
        align-items: flex-start;
        display: grid;
        gap: 20px 33px;
        grid-template-columns: 30% 1fr;

        @include media (tab) {
            grid-template-columns: minmax(0, 1fr);
        }

        @include media () {
            row-gap: 14px;
        }

        &:not(:where(:last-child)) {
            margin-bottom: 56px;

            @include media () {
                margin-bottom: 23px;
            }
        }

        &_type_radioWrap {
            .form__dt {
                padding-top: 3px;
            }
        }
    }

    &__dt {
        align-items: center;
        display: flex;
        gap: 12px;
        padding-top: 12px;
    }

    &__dd {}

    &__itemRequired {
        background: #ff4841;
        border-radius: 3.43px;
        color: #fff;
        flex: 0 0 auto;
        font-size: 1.4rem;
        letter-spacing: 0;
        line-height: 1.785;
        padding: 0px 12px;

        @include media () {
            font-size: 1.3rem;
            line-height: 1.7;
            padding: 0 10px;
        }

        &_type_false {
            background: #aaa;
        }
    }

    &__itemName {
        letter-spacing: 0.05em;
    }

    &__radioWrap {
        display: flex;
        flex-wrap: wrap;
        gap: 20px 31px;

        @include media () {
            display: grid;
            row-gap: 18px;
        }

    }

    .horizontal-item + .horizontal-item {
        margin-left: 0!important;
    }

    &__radioLabel,
    .mwform-radio-field-text {
        display: grid;
        gap: 8px;
        grid-template-areas: "radio .";
        grid-template-columns: 2.3rem auto;
        letter-spacing: 0.01em;

        @include media () {
            font-size: 1.5rem;
            grid-template-columns: 1.8rem auto;
        }

        &::before {
            aspect-ratio: 1;
            border: 2.3px solid #ebebeb;
            border-radius: 10rem;
            content: "";
            flex: 0 0 auto;
            grid-area: radio;
            height: auto;
            place-self: center;
            width: 100%;
        }

        &::after {
            aspect-ratio: 1;
            background: $blue;
            border-radius: 10rem;
            content: "";
            grid-area: radio;
            height: auto;
            opacity: 0;
            place-self: center;
            transition: .2s;
            visibility: hidden;
            width: 60%;
        }
    }

    &__radio {
        display: none;

        &:checked + .form__radioLabel,
        &:checked + .mwform-radio-field-text {
            &::after {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__input {
        border: 2.3px solid #ebebeb;
        border-radius: 3px;
        height: 6rem;
        padding: 10px;
        width: 100%;

        @include media () {
            height: 4.8rem;
        }

        &_type_textarea {
            height: 23rem;
            resize: vertical;

            @include media () {
                height: 15rem;
            }
        }
    }

    &__privacy {
        border-bottom: 1px solid #ededed;
        border-top: 1px solid #ededed;
        margin-bottom: 28px;
        padding: 35px 18px 47px;

        @include media () {
            margin-bottom: 33px;
            padding: 32px 0 24px;
        }
    }

    &__privacyHead {
        font-size: 1.7rem;
        letter-spacing: 0.08em;
        margin-bottom: 15px;

        @include media () {
            font-size: 1.6rem;
        }
    }

    &__privacyBody {
        font-size: 1.5rem;
        height: 18.2rem;
        letter-spacing: 0;
        line-height: 2.133;
        overflow: auto scroll;
        overscroll-behavior: contain;
        padding-right: 20px;
    }

    &__privacyTtl {}

    &__privacyTxt {
        &:not(:where(:last-child)) {
            margin-bottom: 2em;
        }

        &_mb_none {
            margin-bottom: 0;
        }
    }

    &__privacyLink {
        color: $blue;
        transition: .2s;

        &:hover {
            opacity: 0.5;
        }

        &_type_tel {
            color: #1a1a1a;
        }
    }

    &__privacyList {
        &:not(:where(:last-child)) {
            margin-bottom: 2em;
        }
    }

    &__privacyListItem {}


    &__checkWrap {
        display: block;
        margin: 0 auto 54px;
        width: fit-content;
    }

    &__checkLabel,
    .mwform-checkbox-field-text {
        display: grid;
        font-size: 1.7rem;
        gap: 10px;
        grid-template-areas: "check .";
        grid-template-columns: 1.8rem auto;
        justify-content: center;
        letter-spacing: 0.08em;

        @include media () {
            font-size: 1.5rem;
            grid-template-columns: 1.5rem auto;
        }

        &::before {
            aspect-ratio: 1;
            background: #ddd;
            content: "";
            grid-area: check;
            height: auto;
            margin-top: 0.2rem;
            place-self: center;
            transition: .2s;
            width: 100%;
        }

        &::after {
            aspect-ratio: 5 / 3;
            border-bottom: 2px solid $blue;
            border-left: 2px solid $blue;
            content: "";
            grid-area: check;
            height: auto;
            opacity: 0;
            place-self: center;
            transform: translate(4%, -20%) rotate(-45deg);
            transition: .2s;
            visibility: hidden;
            width: 80%;
        }
    }

    &__check {
        display: none;

        &:checked + .form__checkLabel,
        &:checked + .mwform-checkbox-field-text {
            &::before {
                background: #fff;
                transition: .2s;
            }

            &::after {
                opacity: 1;
                transition: .2s;
                visibility: visible;
            }
        }
    }
    &__btn {
        appearance: none;
        border: none;
        box-shadow: 0 2px 4px rgba(#000, 0.08);
        color: inherit;
        display: block;
        font-family: inherit;
        font-weight: inherit;
        margin: 0 auto;
        max-width: 100%;
        width: 29.5rem;

        @include media () {
            padding: 18px 70px 20px 71px;
            width: 30rem;
        }

        &::before {
            left: 30px;
            top: 50%;

            @include media () {
                height: 28px;
                left: 29px;
                width: 28px;
            }
        }

        &::after {
            left: 38px;
            top: 50%;

            @include media () {
                left: 36px;
                top: 48%;
            }
        }
    }

    &_input {
        .contact__lead {
            &_type_input {}
    
            &_type_confirm {
                display: none;
            }
        }
    }
    

    &_type_confirm,
    &_confirm {
        .contact__lead {
            &_type_input {
                display: none;
            }
    
            &_type_confirm {
                display: block;
            }
        }
    
        .form {
            &__dt {
                padding-top: 0;
            }
    
            &__checkWrap {
                display: grid;
                font-size: 1.7rem;
                gap: 10px;
                grid-template-areas: "check .";
                grid-template-columns: 1.8rem auto;
                justify-content: center;
                letter-spacing: 0.08em;
    
                &::after {
                    aspect-ratio: 5 / 3;
                    border-bottom: 2px solid $blue;
                    border-left: 2px solid $blue;
                    content: "";
                    grid-area: check;
                    height: auto;
                    place-self: center;
                    transform: translate(4%, -20%) rotate(-45deg);
                    transition: .2s;
                    width: 80%;
                }
            }
    
            &__privacy {
                display: none;
            }
        }
    }
}

.form {
    &__btn {
        & + & {
            margin-top: 10px;
        }
    }
}

.error {
    display: block;
    width: 100%;
}