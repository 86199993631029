.shingaku {
    padding-bottom: 190px;
    @include media() {
        padding-bottom: 80px;
    }
    &__section {
        & + & .shingaku__inner {
            border-top: 1px solid #e6e6e6;
        }
    }
    &__outline {
        & .shingaku__inner {
            padding-top: 60px;
            padding-bottom: 80px;
            @include media() {
                padding-top: 45px;
                padding-bottom: 60px;
            }
        }
    }
    &__ttl {
        margin-bottom: 40px;
        @include media() {
            margin-bottom: 30px;
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
        }
        &_color_red {
            color: #ed1c24;
        }
    }
    &__schedule {
        & .shingaku__inner {
            padding-top: 85px;
            // padding-bottom: 80px;
            @include media() {
                padding-top: 60px;
                padding-bottom: 60px;
            }
        }
    }
    &__unit {
        display: flex;
    }
    &__unit + &__unit {
        border-top: 1px dashed #e6e6e6;
        padding-top: 56px;
    }
    &__flex {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        @include media() {
            gap: 18px;
        }
    }
    &__listWrap {
        padding-bottom: 60px;
        @include media() {
            padding-bottom: 30px;
        }
    }
    &__list {
        display: flex;
        align-items: flex-start;
        column-gap: 23px;
        @include media() {
            column-gap: 12px;
            flex-wrap: wrap;
            row-gap: 12px;
        }
        &_w_100 {
            width: 100%;
        }
    }
    &__term {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 136px;
        flex: none;
        font-size: 1.8rem;
        color: #fff;
        font-weight: $semiBold;
        background: $blue;
        border-radius: 18px;
        padding-top: 4px;
        padding-bottom: 5px;
        height: fit-content;
        @include media() {
            width: 90px;
            font-size: 1.4rem;
            padding-top: 7px;
            padding-bottom: 8px;
        }
    }
    &__detail {
        font-size: 1.8rem;
        line-height: calc(35 / 18);
        @include media() {
            font-size: 1.6rem;
            line-height: calc(35 / 16);
        }
        &_f_l {
            font-size: 2.8rem;
            font-weight: bold;
            line-height: calc(35 / 28);
            @include media() {
                font-size: 2.4rem;
                line-height: calc(35 / 24);
            }
        }
    }
    &__scheduleHeader {
        display: flex;
        font-size: 2rem;
        column-gap: 20px;
        padding-bottom: 30px;
        @include media() {
            align-items: center;
            flex-direction: column;
            padding-bottom: 17px;
        }
    }
    &__date {
        &_sts_finish {
            position: relative;
            &::before {
                background: #1a1a1a;
                content: "";
                height: 2px;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
            }
        }
    }
    &__finish {
        color: #ed1c24;
    }
    &__num {
        font-size: 2.8rem;
        font-weight: $semiBold;
        line-height: 1;
        @include media() {
            font-size: 2.4rem;
            margin-top: -15px;
            display: inline-block;
        }
    }
    &__btnWrap {
        padding-bottom: 56px;
        @include media() {
            justify-content: center;
            padding-bottom: 28px;
        }
    }
    & .moreBtn {
        @include media(spm) {
            width: 100%;
            padding: 19px 0 19px 30px;
            text-align: center;
            &::before {
                left: 20px;
            }
            &::after {
                left: 28px;
            }
        }
    }
    &__txtSmall {
        font-size: 1.6rem;
        line-height: 2;
        padding-bottom: 56px;
    }
    &__pdf {
        min-width: 320px;
        width: 320px;
        height: fit-content;
        margin-left: 35px;
        transition: .2s;
        @include media() {
            margin: auto;
            min-width: auto;
            width: 100%;
            padding: 0 10px;
            margin-bottom: 30px;
        }
        &:hover {
            opacity: .5;
        }
    }
    &__img {
        width: 100%;
        height: auto;
    }
    &__contact {
        background-color: $blue;
        border-radius: 3px;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 590px;
        height: 120px;
        padding-right: 90px;
        padding-left: 50px;
        position: relative;
        transition: .2s;
        @include media() {
            max-width: none;
            width: 100%;
        }
        &:before {
            background: #fff;
            border-radius: 9999px;
            content: "";
            height: 36px;
            position: absolute;
            right: 35px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 36px;
        }
        &:after {
            content: "";
            border-top: solid 2px $blue;
            border-right: solid 2px $blue;
            height: 10px;
            position: absolute;
            right: 53px;
            top: 49%;
            transform: rotate(45deg) translateY(-50%);
            width: 10px;
        }
        &:hover {
            opacity: .5;
        }
    }
    &__jp {
        font-size: clamp(2rem, 1.686rem + 0.29vw, 2.2rem);
        font-weight: bold;
    }
    &__en {
        font-family: $mont;
        font-size: 1.4rem;
        font-weight: $semiBold;
        letter-spacing: 0.1em;
        padding-bottom: 10px;
    }
    &__webOc {
        & .shingaku__inner {
            padding-top: 85px;
        }
    }
    &__webOcLink {
        margin-top: 60px;
        @include media() {
            margin: 30px auto 0;
            // display: block;
            // width: fit-content;
        }
    }
}