//////////////////////////////
// QCサークル活動支援 /qc/
//////////////////////////////
.qc {
    .lowerMiddleTtl {
        margin-bottom: 31px;
        @include media() {
            font-size: 2rem;
        }
    }
    .lowerSmallTtl {
        margin-bottom: 25px;
        @include media() {
            font-size: 1.8rem;
            margin-bottom: 22px;
        }
    }
    &__inner {
        padding-bottom: 144px;
        padding-top: 68px;
        @include media() {
            padding-top: 35px;
            padding-bottom: 71px;
        }
    }
    &__lead {
        font-size: 1.8rem;
        line-height: 2;
        margin-bottom: 85px;
        @include media() {
            font-size: 1.6rem;
            line-height: 2.25;
            margin-bottom: 46px;
        }
    }
    &__unit {
        &:nth-of-type(1) {
            margin-bottom: 42px;
            @include media() {
                margin-bottom: 47px;
            }
        }
        &:nth-of-type(2) {
            margin-bottom: 60px;
            @include media() {
                margin-bottom: 49px;
            }
        }
        &:nth-of-type(3) {
            margin-bottom: 62px;
            @include media() {
                margin-bottom: 47px;
            }
        }
        &:nth-of-type(4) {
            margin-bottom: 39px;
        }
    }
    &__group {
        &:not(:last-of-type) {
            margin-bottom: 30px;
            @include media() {
                margin-bottom: 31px;
            }
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__addTxt {
        font-size: 1.8rem;
        line-height: 1.7777777778;
        @include media() {
            font-size: 1.6rem;
            line-height: 2;
        }
    }
    &__item {
        font-size: 1.6rem;
        letter-spacing: 0.05em;
        line-height: 2;
        padding-left: 1em;
        position: relative;
        &:not(:last-of-type) {
            margin-bottom: 6px;
            @include media() {
                margin-bottom: 5px;
            }
        }
        &:before {
            background-color: $blue;
            border-radius: 9999px;
            content: "";
            display: block;
            height: 5px;
            left: 0;
            position: absolute;
            top: 13px;
            width: 5px;
        }
    }
    &__itemLink {
        color: $blue;
        text-decoration: underline;
        transition: all 0.2s;
        &:hover {
            opacity: 0.5;
            text-decoration: none;
        }
    }
    &__link {
        color: $blue;
        text-decoration: underline;
        transition: all 0.2s;
        &:hover {
            opacity: 0.5;
            text-decoration: none;
        }
    }
    &__tel {
        transition: all 0.2s;
        &:hover {
            opacity: 0.5;
        }
    }
    &__mail {
        color: $blue;
        text-decoration: underline;
        transition: all 0.2s;
        &:hover {
            opacity: 0.5;
            text-decoration: none;
        }
    }
    &__att {
        color: $red;
        font-size: 1.6rem;
        line-height: 2;
        margin-top: 20px;
    }
}
