.cost {
    padding-top: 285px;
    padding-bottom: 210px;
    @include media(lt) {
        padding-top: 215px;
    }
    @include media() {
        padding-top: 107px;
        padding-bottom: 80px;
    }
    &__section {
        padding-top: 75px;
        @include media() {
            padding-top: 45px;
        }
    }
    &__ttl {
        margin-bottom: 30px;
        @include media() {
            font-size: 2rem;
        }
    }
    &__smallTtl {
        margin-bottom: 20px;
        @include media() {
            font-size: 1.8rem;
        }
    }
    &__dotTxt {
        font-size: 1.8rem;
        font-weight: normal;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
        }
        &:not(:first-of-type) {
            margin-bottom: 20px;
        }
        &_mb_small {
            &:not(:first-of-type) {
                margin-bottom: 6px;
            }
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
        }
        &_paddingBtm_1em {
            padding-bottom: 1em;
        }
        &_paddingBtm_2em {
            padding-bottom: 2em;
        }
        &_marginBtm_10px {
            margin-bottom: 10px;
        }
        &_size_small {
            font-size: 1.4rem;
            letter-spacing: 0.05em;
            line-height: 2.57;
        }
        + .cost__smallTtl {
            margin-top: 2em;
        }
    }
    &__attention {
        font-size: 1.4rem;
        line-height: 2;
        color: #ed1c24;
    }
    &__link {
        color: $blue;
        text-decoration: underline;
        transition: .2s;
        &:hover {
            opacity: .5;
            text-decoration: underline transparent;
        }
        &_color_inherit {
            color: inherit;
            text-decoration: underline transparent;
        }
    }
    &__inner {
        border: 5px solid #f2f2f2;
        padding: 40px 70px 60px;
        border-radius: 6px;
        margin: 50px auto 70px;
        @include media() {
            padding: 30px 28px  30px 37px;
        }
    }
    &__dotTtl {
        margin-left: -27px;
    }
    &__btn {
        box-shadow: 0 2px 4px rgba(#000, 0.08);
        margin-top: 55px;
        min-width: 29.7rem;
        @include media() {
            margin: 55px auto 0;
            display: block;
            width: fit-content;
        }
        @include media(spm) {
            min-width: unset;
            width: 100%;
        }
    }
    &__list {
        font-size: 1.8rem;
        line-height: 2;
    }
    &__listItem {
        display: flex;
        &::before {
            content: "・";
            flex: 0 0 auto;
        }
    }
}