//////////////////////////////
// 交通アクセス /access/
//////////////////////////////
.access {
    &__inner {
        padding-bottom: 230px;
        padding-top: 67px;
        @include media() {
            padding-bottom: 82px;
            padding-top: 52px;
        }
    }
    &__unit {
        &:not(:last-of-type) {
            border-bottom: 1px solid #e6e6e6;
        }
        &:nth-of-type(1) {
            padding-bottom: 145px;
            @include media() {
                padding-bottom: 60px;
            }
        }
        &:nth-of-type(2) {
            padding-bottom: 161px;
            padding-top: 127px;
            @include media() {
                padding-bottom: 0px;
                padding-top: 54px;
                border-bottom: none;
            }
            .lowerTtl {
                margin-bottom: 65px;
                @include media() {
                    margin-bottom: 28px;
                }
            }
        }
        &:nth-of-type(3) {
            padding-top: 127px;
            @include media() {
                padding-top: 57px;
            }
            .lowerTtl {
                margin-bottom: 65px;
                @include media() {
                    margin-bottom: 32px;
                }
            }
        }
    }
    &__read {
        font-size: 1.8rem;
        margin-bottom: 78px;
        text-align: center;
        @include media() {
            font-size: 1.6rem;
            margin-bottom: 42px;
        }
    }
    &__tel {
        transition: all 0.2s;
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
    &__mapWrap {
        margin-bottom: 60px;
        padding-top: 54.8387%;
        position: relative;
        width: 100%;
        @include media() {
            height: 580px;
            padding-top: 0%;
        }
        iframe {
            border-radius: 10px;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            @include media() {
                border-radius: 6px;
            }
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 62px 10px;
        @include media("tab") {
            gap: 20px 0px;
        }
    }
    &__trans {
        border-radius: 10px;
        box-shadow: 0 2px 4px 0px rgb(0 0 0 / 8%);
        padding: 30px 50px 50px;
        width: calc(50% - 31px);
        @include media("tab") {
            padding: 30px 40px 50px;
            width: 100%;
        }
        @include media("sp") {
            padding: 26px 30px 40px;
        }
        .lowerTtl {
            margin-bottom: 28px;
            @include media() {
                align-items: flex-start;
                flex-direction: column;
            }
            &__ja {
                font-size: 2.2rem;
                margin-right: 12px;
                @include media() {
                    font-size: 2rem;
                    margin: 0px 0px 5px 0px;
                }
            }
            &__en {
                font-size: 1.2rem;
                @include media() {
                    letter-spacing: 0.15em;
                }
            }
        }
    }
    &__wayWrap {
        display: flex;
        justify-content: space-between;
        @include media() {
            flex-direction: column;
        }
    }
    &__way {
        &:nth-of-type(odd) {
            width: 50%;
            @include media() {
                width: 100%;
            }
        }
        &:nth-of-type(even) {
            width: 47%;
            @include media() {
                width: 100%;
            }
        }
        &:not(:last-of-type) {
            margin-bottom: 22px;
        }
    }
    &__wayTtlWrap {
        align-items: center;
        display: flex;
        &_type_car {
            margin-bottom: 17px;
        }
        &_type_jr {
            margin-bottom: 7px;
            @include media() {
                margin-bottom: 12px;
            }
        }
        &_type_bus {
            margin-bottom: 10px;
        }
    }
    &__iconWrap {
        margin-right: 17px;
        @include media() {
            margin-right: 21px;
        }
    }
    &__wayTtl {
        font-size: 1.8rem;
        font-weight: bold;
    }
    &__wayTxt {
        font-size: 1.6rem;
        line-height: 2;
    }
    &__flex {
        align-items: center;
        display: flex;
        justify-content: space-between;
        @include media("tab") {
            align-items: unset;
            flex-direction: column;
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        margin-right: 20px;
        margin-top: -50px;
        @include media("tab") {
            margin: 0 0 30px 0;
        }
        @include media("sp") {
            font-size: 1.6rem;
            margin: 0 0 22px 0;
        }
    }
    &__ttl {
        @include media() {
            .lowerTtl {
                &__ja {
                    font-size: 2.2rem;
                    margin-right: 10px;
                }
                &__en {
                    font-size: 1.4rem;
                }
            }
        }
    }
    &__fig {
        border-radius: 10px;
        overflow: hidden;
        width: 64.5%;
        min-width: 64.5%;
        @include media("tab") {
            margin: 0 auto;
            width: 85%;
            min-width: 85%;
        }
        @include media("sp") {
            width: 100%;
            min-width: 100%;
        }
        &_width_full{
            width: 100%;
            min-width: 100%;
        }
    }
}
