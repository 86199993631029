//////////////////////////////
// TOP MV
//////////////////////////////
.topMv {
    .swiper {
        @include media() {
            height: 100vh;
        }
    }
}

.swiper-slide {
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.swiper-pagination-current {
    align-items: center;
    background: $blue;
    border-radius: 50%;
    box-shadow: 0 0px 4px 0px rgb(0 0 0 / 50%);
    color: #1a1a1a;
    display: flex;
    height: 60px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 60px;
    z-index: 1;
    @include media() {
        font-size: 1rem;
        height: 37px;
        width: 37px;
    }
}
.swiper-pagination-total {
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 0px 4px 0px rgb(0 0 0 / 50%);
    color: #1a1a1a;
    display: flex;
    height: 60px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 60px;
    z-index: 1;
    @include media() {
        background-color: #fff;
        font-size: 1rem;
        height: 35px;
        width: 35px;
    }
}

.swiper-pagination-current::before {
    background: #fff;
    content: "";
    display: block;
    height: 60px;
    left: -30px;
    position: absolute;
    top: 0;
    transform-origin: right 30px;
    width: 60px;
    z-index: 2;
    @include media() {
        height: 43px;
        left: -24px;
        transform-origin: right 21px;
        width: 43px;
    }
}

.swiper-pagination-current::after {
    background: #fff;
    content: "";
    display: block;
    height: 60px;
    left: 30px;
    position: absolute;
    top: 0px;
    transform-origin: left 30px;
    width: 60px;
    z-index: 3;
    @include media() {
        height: 43px;
        left: 18px;
        transform-origin: left 21px;
        width: 43px;
    }
}
.swiper-pagination-current.js_start::before {
    animation: circleBefore 5s linear infinite;
}
.swiper-pagination-current.js_start::after {
    animation: circleAfter 5s linear infinite;
}
.swiper-pagination-current .swiper-pagination-inner {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    color: #1a1a1a;
    display: flex;
    height: 55px;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 55px;
    z-index: 4;
    @include media() {
        height: 35px;
        transform: translateY(-51%);
        width: 35px;
    }
}

@keyframes circleAfter {
    0% {
        background: #fff;
        transform: rotate(0deg);
    }
    50% {
        background: #fff;
        transform: rotate(180deg);
    }
    50.01% {
        background: $blue;
        transform: rotate(360deg);
    }
    100% {
        background: $blue;
        transform: rotate(360deg);
    }
}
@keyframes circleBefore {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}
.swiper-pagination {
    align-items: center;
    color: transparent;
    display: flex;
    font-family: $mont;
    font-size: 1.6rem;
    justify-content: space-between;
    left: 84%;
    position: absolute;
    top: 52%;
    width: 160px;
    z-index: 1;
    &:before {
        background-color: #e6e6e6;
        content: "";
        display: block;
        height: 2px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        @include media() {
            width: 9px;
        }
    }
    @include media("blt") {
        left: 80%;
    }
    @include media("sp") {
        bottom: calc(53 / 380 * 100vw);
        left: auto;
        right: 19px;
        top: auto;
        width: 98px;
    }
}

//////////////////////////////
// TOP 先輩インタビュー
//////////////////////////////
.swiper02 {
    position: relative;

    .swiper-button-prev,
    .swiper-button-next {
        position: relative;
        top: auto;
        bottom: auto;
        height: 60px;
        transition: all 0.2s;
        width: 60px;
        z-index: 0 !important;
        &:hover {
            opacity: 0.5;
            @include media() {
                opacity: 1;
            }
        }
    }

    .swiper-button-prev {
        left: -30px;
        margin-right: 18px;
        @include media() {
            margin-right: 5px;
        }
    }
    
    .swiper-button-next {
        right: -30px;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
        content: "";
        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 0px 4px 0px rgb(0 0 0 / 50%);
        display: flex;
        height: 60px;
        justify-content: center;
        overflow: hidden;
        position: relative;
        text-align: center;
        width: 60px;
        @include media() {
            height: 50px;
            width: 50px;
        }
    }

    .swiper-button-prev:before,
    .swiper-button-next:before {
        content: "";
        height: 10px;
        position: absolute;
        transition: all 0.3s;
        width: 10px;
        z-index: 1;
        @include media() {
            height: 9px;
            width: 9px;
        }
    }

    .swiper-button-prev:before {
        border-bottom: solid 2px $blue;
        border-left: solid 2px $blue;
        right: 24px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
    }

    .swiper-button-next:before {
        border-top: solid 2px $blue;
        border-right: solid 2px $blue;
        right: 27px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
    }

    .swiper-button-wrap {
        display: flex;
        position: absolute;
        max-width: 1720px;
        justify-content: space-between;
        top: 38%;
        width: 100%;
        z-index: 1;
        @include media("lt") {
            top: 33%;
        }
        @include media("sp") {
            left: 50%;
            top: 173px;
            transform: translateX(-50%);
            width: 260px;
        }
    }

    .swiper-pagination {
        align-items: center;
        color: #1a1a1a;
        display: flex;
        font-family: $mont;
        font-size: 1.6rem;
        justify-content: space-between;
        left: 84%;
        position: absolute;
        top: 52%;
        width: 150px;
        z-index: 1;
        @include media("blt") {
            left: 80%;
        }
        @include media("sp") {
            bottom: 30px;
            left: auto;
            right: 30px;
            top: auto;
        }
    }
}