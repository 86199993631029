.consultation {
    padding-top: 285px;
    padding-bottom: 200px;
    @include media(lt) {
        padding-top: 215px;
    }
    @include media() {
        padding-top: 107px;
        padding-bottom: 75px;
    }
    &__container {
        padding-top: 70px;
        @include media() {
            padding-top: 50px;
        }
    }
    &__ttl {
        margin-bottom: 25px;
        @include media() {
            margin-bottom: 40px;
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        padding-bottom: 1em;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__dotTxt {
        font-size: 1.8rem;
        line-height: 2;
        font-weight: normal;
        align-items: flex-start;
        @include media() {
            font-size: 1.6rem;
        }
        &:before {
            @include media() {
                padding-top: 3px;
            }
        }
    }
    &__group {
        border: 5px solid #f2f2f2;
        border-radius: 6px;
        margin: 80px 0 0;
        padding: 38px 65px 48px;
        @include media() {
            border-width: 3px;
            margin: 40px 0 0;
            padding: 30px 17px 40px;
        }
    }
    &__grTtl {
        margin-bottom: 10px;
        margin-left: -25px;
        @include media() {
            margin-bottom: 20px;
            margin-left: 0;
        }
    }
    &__grTxt {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__grLink {
        transition: .2s;
        &:hover {
            opacity: 0.5;
        }
    }
    &__grAnnotation {
        color: #ed1c24;
        font-size: 1.4rem;
        line-height: 2;
        margin: 10px 0 0;
    }
}