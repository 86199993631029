/////////////////////////////////////////
// webオープンキャンパス /web-opencampus/
/////////////////////////////////////////
.webOcam {
    .lowerMiddleTtl {
        @include media() {
            font-size: 2rem;
        }
    }
    &__inner {
        padding-bottom: 240px;
        padding-top: 100px;
        @include media() {
            padding-bottom: 82px;
            padding-top: 48px;
        }
    }
    &__unit {
        &:not(:last-of-type) {
            border-bottom: 1px solid #e6e6e6;
        }
        &:nth-of-type(1) {
            padding-bottom: 117px;
            @include media() {
                padding-bottom: 61px;
            }
            .lowerMiddleTtl {
                margin-bottom: 30px;
            }
        }
        &:nth-of-type(2) {
            padding-bottom: 114px;
            padding-top: 89px;
            @include media() {
                padding-bottom: 63px;
                padding-top: 54px;
            }
            .lowerMiddleTtl {
                margin-bottom: 50px;
                @include media() {
                    margin-bottom: 37px;
                }
            }
        }
        &:nth-of-type(3) {
            padding-bottom: 108px;
            padding-top: 89px;
            @include media() {
                padding-bottom: 61px;
                padding-top: 56px;
            }
            .lowerMiddleTtl {
                margin-bottom: 48px;
                @include media() {
                    margin-bottom: 37px;
                }
            }
        }
        &:nth-of-type(4) {
            padding-bottom: 108px;
            padding-top: 89px;
            @include media() {
                padding-bottom: 60px;
                padding-top: 55px;
            }
            .lowerMiddleTtl {
                margin-bottom: 48px;
                @include media() {
                    margin-bottom: 38px;
                }
            }
        }
        &:nth-of-type(5) {
            padding-top: 86px;
            @include media() {
                padding-top: 54px;
            }
            .lowerMiddleTtl {
                margin-bottom: 48px;
                @include media() {
                    margin-bottom: 38px;
                }
            }
        }
    }
    &__ttl {
        &_color_red {
            &.lowerMiddleTtl {
                &:before {
                    background-color: $red;
                }
            }
        }
        &_color_orange {
            &.lowerMiddleTtl {
                &:before {
                    background-color: $orange;
                }
            }
        }
        &_color_green {
            &.lowerMiddleTtl {
                &:before {
                    background-color: $green;
                }
            }
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        margin-bottom: 40px;
        @include media() {
            font-size: 1.6rem;
            margin-bottom: 20px;
        }
    }
    &__virtual {
        div {
            //コード上ではblockquoteタグ
            margin: 0 auto;
            @include media() {
                min-height: 170px;
                height: calc(170 / 380 * 100vw) !important;
            }
        }
        iframe {
            @include media() {
                min-height: 170px;
                height: calc(170 / 380 * 100vw) !important;
            }
        }
    }
    &__movie {
        max-width: 854px;
        margin: 0 auto;
    }
    &__movieWrap {
        padding-top: 56.206%;
        position: relative;
        width: 100%;
        iframe {
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
        }
    }
    &__movieList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 38px 0;
        margin-top: 100px;
        @include media() {
            flex-direction: column;
            margin-top: 53px;
            gap: 32px 0;
        }
    }
    &__item {
        border-radius: 9999px;
        box-shadow: 0 2px 4px 0px rgb(0 0 0 / 8%);
        font-size: 1.6rem;
        width: calc(50% - 20px);
        @include media() {
            font-size: 1.5rem;
            width: 100%;
        }
    }
    &__link {
        align-items: center;
        display: flex;
        height: 100%;
        padding: 20px 40px 20px 78px;
        position: relative;
        transition: all 0.2s;
        @include media() {
            padding: 20px 35px 20px 78px;
        }
        &:before {
            background: url(../images/icon_youtube_red.svg) no-repeat center / cover;
            content: "";
            display: block;
            height: 19px;
            position: absolute;
            left: 38px;
            top: 50%;
            transform: translateY(-50%);
            width: 27px;
            @include media() {
                left: 31px;
            }
        }
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
}
