//////////////////////////////
// よくある質問 /faq/
//////////////////////////////
.faq {
    $this: &;

    &__inner {
        padding-bottom: 200px;
        padding-top: 70px;
        @include media() {
            padding-bottom: 100px;
            padding-top: 50px;
        }
    }
    &__unit {
        & + & {
            margin-top: 80px;
            @include media() {
                margin-top: 40px;
            }
        }
    }

    &__subTtl{
        margin-bottom: 1em;
    }

    &__item {
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(#000, .08);
        overflow: hidden;
        &:nth-of-type(n+2) {
            margin-top: 40px;
            @include media() {
                margin-top: 32px;
            }
        }
    }
    &__question {
        cursor: pointer;
        position: relative;
        &::before {
            color: $blue;
            content: "Q";
            font-family: $mont;
            font-size: 3.4rem;
            left: 40px;
            line-height: 1;
            position: absolute;
            top: 40px;
            @include media() {
                font-size: 2.2rem;
                left: 20px;
                top: 20px;
            }
        }
    }
    &__questionFrame {
        padding: 40px 110px 40px 100px;
        @include media() {
            padding: 15px 60px;
        }
        &::before,
        &::after {
            background: $blue;
            content: "";
            display: block;
            height: 3px;
            position: absolute;
            right: 70px;
            top: 52px;
            transition: .2s;
            width: 21px;
            @include media() {
                height: 2px;
                right: 20px;
                top: 28px;
                width: 14px;
            }
        }

        &::after {
            transform: rotate(90deg);
        }
    }
    &__item.is_open &__questionFrame {
        &::after {
            // transform: rotate(90deg);
            transform: rotate(0);
        }
    }
    // &__item:not(.is_open) &__questionFrame {
    //     &::after {
    //         opacity: 0;
    //         visibility: hidden;
    //     }
    // }
    &__ttl {
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1.8;
        @include media() {
            font-size: 1.5rem;
        }
    }
    &__answer {
        display: none;
        position: relative;
        &::before {
            color: $blue;
            content: "A";
            font-family: $mont;
            font-size: 3.4rem;
            left: 40px;
            line-height: 1;
            position: absolute;
            top: 40px;
            @include media() {
                font-size: 2.2rem;
                left: 20px;
                top: 20px;
            }
        }
    }
    &__answerFrame {
        background: #f9f9f9;
        padding: 40px 80px 40px 100px;
        @include media() {
            padding: 15px 20px 15px 60px;
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 1.8;
        @include media() {
            font-size: 1.5rem;
        }
        a {
            color: #438cc8;
            text-decoration: underline;
            &:hover{
                text-decoration: none;
            }
        }
    }
}
