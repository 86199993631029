@charset 'utf-8';
$responsive: (
    "se": "screen and (max-width: 321px)",
    "spm": "screen and (max-width: 480px)",
    "sp": "screen and (max-width: 768px)",
    "tab": "screen and (max-width: 1024px)",
    "lt": "screen and (max-width: 1200px)",
    "blt": "screen and (max-width: 1300px)",
    "pc": "screen and (max-width: 1400px)",
    "4k": "screen and (max-width: 2560px)",
) !default;

@mixin media($breakpoint: sp) {
    @media #{map-get($responsive, $breakpoint)} {
        @content;
    }
}

//英字フォント
$mont: "Montserrat", sans-serif;

//色
$blue: #438cc8; //メインの青
$blue-sub: #f0f6fb;

$red: #ff4841; //機械システム系
$red-sub: #fff0f0;

$orange: #ffa53e; //電気・電子システム系
$orange-sub: #fff8f0;

$green: #3ac98a; // 建築システム系
$green-sub: #effbf6;

//Montserrat セミボールド
$semiBold: 600;

@import "~html5-reset";
@import "module/_pagination.scss";
@import "common/_base.scss";
@import "common/_component.scss";
@import "common/_footer.scss";
@import "common/_header.scss";
@import "common/_slider.scss";
@import "pages/_404.scss";
@import "pages/_access.scss";
@import "pages/_anv.scss";
@import "pages/_app-guidelines.scss";
@import "pages/_association.scss";
@import "pages/_campus.scss";
@import "pages/_career-job.scss";
@import "pages/_circle.scss";
@import "pages/_collaboration.scss";
@import "pages/_consultation.scss";
@import "pages/_contact.scss";
@import "pages/_cost.scss";
@import "pages/_course-lower.scss";
@import "pages/_course.scss";
@import "pages/_credentials.scss";
@import "pages/_employment.scss";
@import "pages/_exam-question.scss";
@import "pages/_examiness.scss";
@import "pages/_facility.scss";
@import "pages/_faq.scss";
@import "pages/_guraduate.scss";
@import "pages/_index.scss";
@import "pages/_info.scss";
@import "pages/_interview.scss";
@import "pages/_job-req.scss";
@import "pages/_news.scss";
@import "pages/_ocam.scss";
@import "pages/_outline.scss";
@import "pages/_privacy.scss";
@import "pages/_qc.scss";
@import "pages/_result-publication.scss";
@import "pages/_schedule.scss";
@import "pages/_seminar.scss";
@import "pages/_shingaku.scss";
@import "pages/_sitemap.scss";
@import "pages/_song.scss";
@import "pages/_web-ocam.scss";
