//////////////////////////////////////
// 在校生・卒業生インタビュー /interview/
//////////////////////////////////////
.interview {
    &_page_archive {
        .container {
            @include media() {
                padding: 0px;
            }
        }
    }
    &_page_detail {
        .lowerTtlOnly {
            &__ja {
                font-size: 3.4rem;
                line-height: 1.7647058824;
                margin-bottom: 20px;
                @include media() {
                    font-size: 2.2rem;
                    line-height: 1.6363636364;
                    margin-bottom: 16px;
                }
            }
        }
        .interview__inner {
            padding-top: 80px;
            padding-bottom: 207px;
            @include media() {
                padding-top: 40px;
                padding-bottom: 81px;
            }
        }
        .pagination {
            @include media() {
                margin-bottom: 0;
            }
            &__list {
                justify-content: space-between;
                @include media("sp") {
                    padding: 0;
                }
                @media screen and (max-width: 360px) {
                    flex-direction: column;
                }
            }
            &__item {
                @include media() {
                    margin: 0;
                }
                @media screen and (max-width: 360px) {
                    margin: 5px auto;
                }
                &_type_next {
                    @include media() {
                        bottom: auto;
                        left: auto;
                        position: relative;
                        right: auto;
                        top: auto;
                        transform: none;
                    }
                }
            }
        }
    }
    &__inner {
        padding-bottom: 245px;
        padding-top: 138px;
        @include media() {
            padding-top: 68px;
            padding-bottom: 97px;
        }
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 80px;
        @include media() {
            justify-content: space-between;
            margin-bottom: 17px;
            padding: 0 20px;
        }
    }
    &__art {
        width: 25%;
        @include media("sp") {
            width: 50%;
            width: calc(50% - 5px);
        }
        @media screen and (max-width: 620px) {
            width: 100%;
        }
    }
    &__link {
        display: block;
        height: 100%;
        overflow: hidden;
        transition: all 0.2s;
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
    &__fig {
        height: calc(560 / 1800 * 100vw);
        @include media() {
            height: 337px;
            margin: 0 auto;
            width: 280px;
        }
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    &__detail {
        padding: 36px 50px 69px;
        @include media("lt") {
            padding: 36px 30px 69px;
            text-align: center;
        }
        @include media("sp") {
            padding: 24px 0px 60px;
        }
    }
    &__cat {
        color: #fff;
        border-radius: 9999px;
        display: inline-block;
        font-size: 1.4rem;
        letter-spacing: 0.05em;
        margin-bottom: 5px;
        padding: 8px 24px;
        @include media("tab") {
            padding: 8px 20px;
        }
        @include media("sp") {
            font-size: 1.4rem;
            padding: 7px 23px;
            margin-bottom: 12px;
        }
        &_color_red {
            background-color: $red;
        }
        &_color_orange {
            background-color: $orange;
        }
        &_color_green {
            background-color: $green;
        }
    }
    &__read {
        font-size: 1.8rem;
        letter-spacing: 0.05em;
        line-height: 1.8888888889;
        @include media("tab") {
            font-size: 1.6rem;
            line-height: 2;
            text-align: left;
        }
    }
    &__job {
        font-size: 1.6rem;
        letter-spacing: 0.05em;
        margin-top: 12px;
        @include media("tab") {
            font-size: 1.5rem;
            text-align: left;
        }
        @include media("sp") {
            margin-top: 10px;
        }
    }
    &__people {
        font-size: 1.6rem;
        letter-spacing: 0.05em;
        margin-top: 13px;
        @include media("tab") {
            font-size: 1.4rem;
            text-align: left;
        }
        @include media("sp") {
            margin-top: 17px;
        }
    }
    &__graduation {
        margin-right: 13px;
    }
    &__headDetail {
        text-align: center;
        .interview__cat {
            @include media() {
                margin-bottom: 0px;
            }
        }
        .interview__job {
            font-size: 1.8rem;
            margin-top: 20px;
            @include media() {
                font-size: 1.6rem;
                margin-top: 18px;
                text-align: center;
            }
        }
        .interview__people {
            font-size: 1.8rem;
            @include media() {
                font-size: 1.6rem;
                margin-top: 13px;
                text-align: center;
            }
        }
    }
    &__mv {
        // aspect-ratio: 31/14;
        border-radius: 10px;
        margin-bottom: 70px;
        overflow: hidden;
        width: 100%;
        @include media("sp") {
            // aspect-ratio: 16/9;
            border-radius: 6px;
            margin-bottom: 14px;
        }
        @include media("spm") {
            aspect-ratio: 1/1;
        }
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    &__editor {
        margin-bottom: 116px;
        padding: 0 80px;
        @include media("tab") {
            margin-bottom: 45px;
            padding: 0 30px;
        }
        @include media("sp") {
            margin-bottom: 70px;
            padding: 0px;
        }
        figure {
            overflow: hidden;
            margin-bottom: 72px;
            margin-top: 60px;
            @include media() {
                margin-bottom: 50px;
                margin-top: 40px;
            }
        }
        img {
            max-height: 1000px;
            height: 100%;
            object-fit: contain;
            width: 100%;
            @include media() {
                max-height: none;
            }
        }
        figcaption {
            font-size: 1.6rem;
            line-height: 2;
            margin-bottom: 0em !important;
            margin-top: 1.5em !important;
            @include media() {
                font-size: 1.3rem;
                margin-top: 1em !important;
            }
        }
        h2 {
            font-size: 3.2rem;
            font-weight: bold;
            line-height: 1.5;
            margin-bottom: 30px;
            margin-top: 70px;
            @include media() {
                font-size: 2.2rem;
                margin-bottom: 20px;
                margin-top: 60px;
            }
        }
        h3 {
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;
            margin-bottom: 30px;
            margin-top: 40px;
            padding-left: 40px;
            position: relative;
            @include media() {
                font-size: 2rem;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            &:before {
                background-color: $blue;
                border-radius: 9999px;
                content: "";
                height: 6px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 22px;
                @include media() {
                    height: 4px;
                    width: 17px;
                }
            }
        }
        h4 {
            font-size: 2.2rem;
            font-weight: bold;
            letter-spacing: 0.05em;
            line-height: 1.5;
            margin-bottom: 30px;
            margin-top: 40px;
            padding-left: 20px;
            position: relative;
            @include media() {
                font-size: 1.8rem;
                margin-bottom: 20px;
                margin-top: 15px;
            }
            &:before {
                background-color: $blue;
                border-radius: 9999px;
                content: "";
                height: 8px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 8px;
            }
        }
        p {
            font-size: 1.8rem;
            line-height: 2;
            margin-bottom: 30px;
            @include media() {
                font-size: 1.4rem;
                line-height: 2.1428571429;
                margin-bottom: 20px;
            }
        }
        a {
            color: $blue;
            text-decoration: underline;
            transition: all 0.2s;
            &:hover {
                opacity: 0.4;
                text-decoration: none;
            }
        }
        ul {
            margin-bottom: 60px;
            @include media() {
                margin-bottom: 64px;
            }
        }
        li {
            font-size: 1.8rem;
            line-height: 2;
            padding-left: 1em;
            position: relative;
            &:before {
                background-color: $blue;
                border-radius: 9999px;
                content: "";
                display: block;
                height: 5px;
                left: 0;
                position: absolute;
                top: 16px;
                width: 5px;
            }
            @include media() {
                font-size: 1.6rem;
                line-height: 2.25;
                padding-left: 1.4em;
            }
        }
        .is-nowrap.wp-block-group {
            gap: 0 60px !important;
            @include media("tab") {
                gap: 0 30px !important;
            }
            @include media("sp") {
                flex-direction: column;
                gap: 30px 0 !important;
                margin-bottom: 65px;
            }
            figure {
                aspect-ratio: 51/32;
                margin-top: 0px;
                width: calc(50% - 30px);
                @include media("tab") {
                    width: 50%;
                }
                @include media("sp") {
                    aspect-ratio: unset;
                    margin-bottom: 0;
                    width: 100%;
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            figcaption {
                margin-top: 15px !important;
                @include media() {
                    margin-top: 12px !important;
                }
            }
        }
        .wp-block-group {
            margin-bottom: 70px;
            @include media() {
                margin-bottom: 60px;
            }
            .wp-block-group__inner-container {
                border-radius: 6px;
                border: 5px solid #f2f2f2;
                padding: 40px 45px;
                @include media() {
                    border: 3px solid #f2f2f2;
                    padding: 27px 17px;
                }
                h2,
                h3,
                h4 {
                    margin-bottom: 15px;
                    margin-top: 0px;
                }
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}
