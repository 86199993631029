// カラー設定
.schedule__unit_color_red {
    &::before {
        background: #FFE4E2;
    }
    background: $red-sub;
    & .schedule__ttl {
        border: 1px solid $red;
    }
    & .schedule__ttl,
    & .schedule__en {
        color: $red;
    }
    & .schedule__circle,
    & .schedule__term::before {
        background: $red;
    }
    & .schedule__circle::before {
        background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2292%22%20height%3D%222%22%20viewBox%3D%220%200%2092%202%22%3E%20%3Cline%20id%3D%22%E7%B7%9A_11%22%20data-name%3D%22%E7%B7%9A%2011%22%20x2%3D%2290%22%20transform%3D%22translate(1%201)%22%20fill%3D%22none%22%20stroke%3D%22%23ff4841%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%220%205%22%2F%3E%3C%2Fsvg%3E');
    }
}

.schedule__unit_color_blue {
    &::before {
        background: #E3EEF7;
    }
    background: $blue-sub;
    & .schedule__ttl {
        border: 1px solid $blue;
    }
    & .schedule__ttl,
    & .schedule__en {
        color: $blue;
    }
    & .schedule__circle,
    & .schedule__term::before {
        background: $blue;
    }
    & .schedule__circle::before {
        background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2292%22%20height%3D%222%22%20viewBox%3D%220%200%2092%202%22%3E%20%3Cline%20id%3D%22%E7%B7%9A_14%22%20data-name%3D%22%E7%B7%9A%2014%22%20x2%3D%2290%22%20transform%3D%22translate(1%201)%22%20fill%3D%22none%22%20stroke%3D%22%23438cc8%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%220%205%22%2F%3E%3C%2Fsvg%3E');
    }
}

.schedule__unit_color_orange {
    &::before {
        background: #FFF1E2;
    }
    background: $orange-sub;
    & .schedule__ttl {
        border: 1px solid $orange;
    }
    & .schedule__ttl,
    & .schedule__en {
        color: $orange;
    }
    & .schedule__circle,
    & .schedule__term::before {
        background: $orange;
    }
    & .schedule__circle::before {
        background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2292%22%20height%3D%222%22%20viewBox%3D%220%200%2092%202%22%3E%20%3Cline%20id%3D%22%E7%B7%9A_17%22%20data-name%3D%22%E7%B7%9A%2017%22%20x2%3D%2290%22%20transform%3D%22translate(1%201)%22%20fill%3D%22none%22%20stroke%3D%22%23ffa53e%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%220%205%22%2F%3E%3C%2Fsvg%3E');
    }
}

.schedule__unit_color_green {
    &::before {
        background: #E1F7ED;
    }
    background: $green-sub;
    & .schedule__ttl {
        border: 1px solid $green;
    }
    & .schedule__ttl,
    & .schedule__en {
        color: $green;
    }
    & .schedule__circle,
    & .schedule__term::before {
        background: $green;
    }
    & .schedule__circle::before {
        background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2292%22%20height%3D%222%22%20viewBox%3D%220%200%2092%202%22%3E%20%3Cline%20id%3D%22%E7%B7%9A_21%22%20data-name%3D%22%E7%B7%9A%2021%22%20x2%3D%2290%22%20transform%3D%22translate(1%201)%22%20fill%3D%22none%22%20stroke%3D%22%233ac98a%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%220%205%22%2F%3E%3C%2Fsvg%3E');
    }
}

// 共通設定
.schedule {
    padding-bottom: 190px;
    @include media() {
        padding-bottom: 60px;
    }
    &__container {
        @include media() {
            padding: 0;
        }
    }
    &__txt {
        text-align: center;
        font-size: 1.8rem;
        padding-top: 70px;
        padding-bottom: 110px;
        line-height: 2;
        @include media() {
            text-align: left;
            font-size: 1.6rem;
            padding: 40px 20px 60px;
        }
    }
    &__quaterUnit {
        position: relative;
        z-index: 0;
        padding: 120px 50px  65px 105px;
        @include media() {
            padding: 80px 30px;
        }
        &::before {
            content: "";
            display: block;
            height: calc(100% - 90px);
            width: 40px;
            border-radius: 20px;
            position: absolute;
            top: 50px;
            left: 135px;
            z-index: -1;
            @include media() {
                height: calc(100% - 80px);
                width: 20px;
                top: 40px;
                left: 60px;
            }
        }
        &:first-of-type {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            @include media() {
                border-radius: 0;
            }
        }
        &:last-of-type {
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            @include media() {
                border-radius: 0;
            }
        }
    }
    &__ttl {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 40px;
        border-radius: 75px;
        background: #fff;
        font-family: $mont;
        font-size: 1.4rem;
        letter-spacing: 0.15em;
        position: absolute;
        top: -20px;
        left: 80px;
        @include media() {
            left: 30px;
        }
    }
    &__monthUnit {
        padding-left: 160px;
        position: relative;
        min-height: 155px;
        padding-top: 75px;
        @include media() {
            padding-left: 90px;
            padding-top: 68px;
            min-height: auto;
        }
        & + & {
            margin-top: 45px;
            @include media() {
                margin-top: 15px;
            }
        }
    }
    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border-radius: 50px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        @include media() {
            width: 75px;
            height: 75px;
        }
        &::before {
            content: "";
            display: block;
            width: 90px;
            height: 2px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 50px;
            background-repeat: no-repeat;
            z-index: -1;
            @include media() {
                width: 65px;
                left: 37px;
            }
        }
    }
    &__jp {
        color: #fff;
        font-weight: $semiBold;
        @include media() {
            font-size: 1.2rem;
        }
    }
    &__num {
        font-size: 2.4rem;
        margin-right: 3px;
        @include media() {
            font-size: 1.8rem;
        }
    }
    &__en {
        position: absolute;
        left: 160px;
        display: block;
        font-family: $mont;
        font-size: $semiBold;
        font-size: 2rem;
        letter-spacing: 0.15em;
        @include media() {
            font-size: 1.8rem;
            left: 113px;
        }
    }
    &__list {
        width: calc(50% - 20px);
        @include media(lt) {
            width: 100%;
        }
        @include media() {
            margin-bottom: 26px;
        }
    }
    &__term {
        padding-left: 15px;
        position: relative;
        padding-bottom: 12px;
        @include media() {
            padding-bottom: 0;
            margin-bottom: 10px;
        }
        &::before {
            content: "";
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 3px;
            position: absolute;
            top: 10px;
            left: 0;
        }
    }
    &__detail {
        font-size: 1.4rem;
        line-height: 2;
        padding-bottom: 12px;
        @include media() {
            padding-bottom: 0;
        }

        &_type_imgAbsolute {
            position: relative;
            width: 200px;

            .schedule__img {
                position: absolute;

                @include media (lt) {
                    position: relative;
                }
            }
        }
    }
    & p.schedule__detail {
        @include media() {
            margin-bottom: 15px;
        }
    }
    &__row {
        display: flex;
        justify-content: space-between;
        @include media(lt) {
            flex-direction: column;
        }
        & .schedule__img {
            margin-left: 20px;
            margin-top: -36px;
            @include media(lt) {
                margin-left: 0;
                margin-top: 0;
                margin-bottom: 18px;
            }
            @include media() {
                margin-bottom: 0;
            }
        }

        &_align_top {
            .schedule__img {
                margin-top: 0;
            }
        }
    }
    &__column {
        width: calc(50% - 20px);
        @include media(lt) {
            width: 100%;
        }

        .schedule__list {
            width: 100%;
        }
    }
    &__img {
        width: 180px;
        height: 120px;
    }
}