.career {
    padding-bottom: 190px;
    @include media() {
        padding-bottom: 84px;
    }
    &__section {
        padding-top: 70px;
        @include media() {
            padding-top: 48px;
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__education {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 60px auto 150px;
        @include media(tab) {
            flex-wrap: wrap;
        }
        @include media() {
            margin: 50px auto 100px;
        }
    }
    &__circle {
        display: flex;
        width: 200px;
        height: 200px;
        border-radius: 100px;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 2.4rem;
        letter-spacing: 0.05em;
        text-align: center;
        position: relative;
        @include media() {
            width: 120px;
            height: 120px;
            font-size: 1.5rem;
        }
        @include media(spm) {
            width: 110px;
            height: 110px;
        }
        &_color_orange {
            background: $orange;
            margin-right: 100px;
            @include media() {
                margin-right: 80px;
            }
            @include media(spm) {
                margin-right: 60px;
            }
            &::after {
                content: "";
                display: block;
                width: 42px;
                height: 42px;
                background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2243.414%22%20height%3D%2243.414%22%20viewBox%3D%220%200%2043.414%2043.414%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_272%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20272%22%20transform%3D%22translate(-642.293%20-1315.293)%22%3E%20%3Cline%20id%3D%22%E7%B7%9A_7%22%20data-name%3D%22%E7%B7%9A%207%22%20x2%3D%2242%22%20y2%3D%2242%22%20transform%3D%22translate(643%201316)%22%20fill%3D%22none%22%20stroke%3D%22%231a1a1a%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%222%22%2F%3E%20%3Cline%20id%3D%22%E7%B7%9A_8%22%20data-name%3D%22%E7%B7%9A%208%22%20x1%3D%2242%22%20y2%3D%2242%22%20transform%3D%22translate(643%201316)%22%20fill%3D%22none%22%20stroke%3D%22%231a1a1a%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%222%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E') no-repeat;
                position: absolute;
                right: -72px;
                top: 0;
                bottom: 0;
                margin: auto;
                background-size: cover;
                @include media() {
                    width: 35px;
                    height: 35px;
                    right: -58px;
                }
                @include media(spm) {
                    right: -48px;
                }
            }
        }
        &_color_green {
            background: $green;
        }
    }
    &__skill {
        text-align: center;
        position: relative;
        display: block;
        margin-left: 125px;
        @include media(tab) {
            margin-top: 40px;
        }
        @include media() {
            margin-top: 20px;
            margin-left: 76px;
        }
        @include media(spm) {
            margin-left: 56px;
        }
        &::before {
            content: "";
            display: block;
            width: 46px;
            height: 18px;
            background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2246%22%20height%3D%2218%22%20viewBox%3D%220%200%2046%2018%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_273%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20273%22%20transform%3D%22translate(-953%20-1322)%22%3E%20%3Cline%20id%3D%22%E7%B7%9A_9%22%20data-name%3D%22%E7%B7%9A%209%22%20x2%3D%2246%22%20transform%3D%22translate(953%201323)%22%20fill%3D%22none%22%20stroke%3D%22%231a1a1a%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%222%22%2F%3E%20%3Cline%20id%3D%22%E7%B7%9A_10%22%20data-name%3D%22%E7%B7%9A%2010%22%20x2%3D%2246%22%20transform%3D%22translate(953%201339)%22%20fill%3D%22none%22%20stroke%3D%22%231a1a1a%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%222%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E') no-repeat;
            position: absolute;
            left: -85px;
            top: 0;
            bottom: 0;
            margin: auto;
            background-size: cover;
            @include media() {
                left: -56px;
                width: 35px;
                height: 14px;
            }
        }
    }
    &__marker {
        font-weight: bold;
        font-size: 2.4rem;
        letter-spacing: 3.6rem;
        letter-spacing: 0.05em;
        position: relative;
        @include media() {
            font-size: 2rem;
        }
        @include media(spm) {
            font-size: 1.8rem;
        }
        &::after {
            content: "";
            width: 103%;
            height: 14px;
            position: absolute;
            bottom: 0;
            left: -3px;
            right: 0;
            margin: auto;
            background: #FCEE21;
            z-index: -1;
            @include media() {
                height: 12px;
            }
        }
    }
    &__unit {
        padding-bottom: 130px;
        @include media() {
            padding-bottom: 55px;
        }
    }
    &__fig {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 480px;
        border-radius: 10px;
        margin-bottom: 60px;
        @include media() {
            height: 350px;
            margin-bottom: 40px;
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center / center;
    }
    &__ttl {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 300px;
        height: 300px;
        border-radius: 150px;
        background: #fff;
        text-align: center;
        line-height: 1;
        @include media() {
            height: 180px;
            width: 180px;
        }
    }
    &__span {
        font-size: 2.4rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        padding-bottom: 10px;
        @include media() {
            font-size: 1.4rem;
            padding-bottom: 7px;
        }
        &:last-of-type {
            padding-bottom: 20px;
            @include media() {
                padding-bottom: 14px;
            }
        }
    }
    &__num {
        font-family: $mont;
        font-size: 6.8rem;
        color: $blue;
        font-weight: $semiBold;
        margin-right: 13px;
        @include media() {
            font-size: 4rem;
            margin-right: 10px;
        }
    }
    &__other {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.08);
        padding: 60px 60px 64px;
        @include media() {
            padding: 40px 20px;
        }
    }
    &__subTtl {
        font-size: 2.2rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        padding-bottom: 30px;
        @include media() {
            font-size: 2rem;
        }
    }
    &__svg {
        margin-right: 15px;
        @include media() {
            width: 28px;
        }
    }
}

.careerUl {
    display: flex;
    flex-wrap: wrap;
    gap: 74px 56px;
    @include media() {
        gap: 40px;
    }
    &__item {
        width: calc((100% / 3) - (112px / 3));
        @include media(tab) {
            width: calc(50% - 28px);
        }
        @include media() {
            width: 100%;
        }
    }
    &__ttl {
        padding-left: 28px;
        position: relative;
        font-size: 2.2rem;
        font-weight: $semiBold;
        line-height: 2.6rem;
        padding-bottom: 3px;
        margin-bottom: 30px;
        letter-spacing: 0.05em;
        @include media() {
            font-size: 2rem;
            line-height: 2.2rem;
            padding-bottom: 0;
            margin-bottom: 20px;
        }
        &::before {
            content: "";
            width: 12px;
            height: 26px;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            background: $blue;
            @include media() {
                width: 10px;
                height: 22px;
            }
        }
    }
    &__txt {
        line-height: 2;
        @include media() {
            font-size: 1.4rem;
        }
    }
}

.careerDl {
    display: flex;
    padding: 33px 0;
    border-top: 1px solid #f2f2f2;
    @include media() {
        flex-direction: column;
        padding: 25px 0;
    }
    &:last-of-type {
        border-bottom: 1px solid #f2f2f2;
    }
    &__term {
        width: 13em;
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        padding-left: 20px;
        position: relative;
        @include media() {
            width: 100%;
            font-size: 1.8rem;
            padding-bottom: 10px;
            padding-left: 18px;
        }
        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background: $blue;
            top: 11px;
            left: 0;
            @include media() {
                top: 10px;
            }
        }
    }
    &__detail {
        width: calc(100% - 26rem);
        line-height: 2;
        @include media() {
            width: 100%;
            font-size: 1.5rem;
        }
    }
}