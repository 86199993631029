//////////////////////////////
// サークル活動 /circle/
//////////////////////////////
.circle {
    .lowerMiddleTtl {
        margin-bottom: 20px;
        @include media() {
            font-size: 2rem;
            margin-bottom: 30px;
        }
    }
    &__inner {
        padding-bottom: 180px;
        padding-top: 70px;
        @include media() {
            padding-bottom: 80px;
            padding-top: 40px;
        }
    }
    &__lead {
        font-size: 1.8rem;
        line-height: 2;
        margin-bottom: 100px;
        text-align: center;
        @include media() {
            font-size: 1.6rem;
            margin-bottom: 50px;
            text-align: unset;
        }
    }
    &__unit {
        & + & {
            margin-top: 110px;
            @include media() {
                margin-top: 60px;
            }
            &_has_hr {
                border-top: 1px solid #e6e6e6;
                margin-top: 160px;
                padding-top: 110px;
                @include media() {
                    margin-top: 60px;
                    padding-top: 60px;
                }
            }
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        margin: 0 0 50px;
        @include media() {
            font-size: 1.4rem;
            margin: 0 0 20px;
        }
    }

    &__pickup {
        column-gap: 60px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr auto auto 1fr;
        @include media() {
            display: block;
        }
        &:nth-of-type(n+2) {
            margin-top: 100px;
            @include media() {
                margin-top: 60px;
            }
        }
        &:nth-of-type(2n) {
            direction: rtl;
            @include media() {
                direction: unset;
            }
        }
    }
    &__pickupDetail {
        direction: ltr;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        @include media() {
            direction: unset;
        }
    }
    &__pickupTxt {
        font-size: 1.8rem;
        line-height: 2;
        margin: 30px 0 0;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__pickupFig {
        align-self: start;
        border-radius: 10px;
        direction: ltr;
        grid-column: 2 / 3;
        grid-row: 1 / -1;
        overflow: hidden;
        @include media() {
            border-radius: 6px;
            direction: unset;
            margin: 20px 0 0;
        }
        img {
            width: 100%;
        }
    }
    &__pickupSubFigs {
        align-self: start;
        column-gap: 40px;
        direction: ltr;
        display: flex;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        margin: 40px 0 0;
        @include media() {
            display: block;
            margin: 30px 0 0;
        }
    }
    &__pickupSubFig {
        border-radius: 6px;
        overflow: hidden;
        width: calc((100% - 40px) * .5);
        @include media() {
            width: 100%;
        }
        &:nth-of-type(n+2) {
            @include media() {
                margin-top: 30px;
            }
        }
        img {
            width: 100%;
        }
    }

    &__others {
        column-gap: 56px;
        display: flex;
        flex-wrap: wrap;
        row-gap: 60px;
        @include media() {
            display: block;
        }
    }
    &__other {
        width: calc((100% - 56px * 2) * .3333);
        @include media() {
            width: 100%;
        }
        &:nth-of-type(n+2) {
            @include media() {
                margin-top: 30px;
            }
        }
    }
    &__otherFig {
        border-radius: 10px;
        overflow: hidden;
        @include media() {
            border-radius: 6px;
        }
        img {
            width: 100%;
        }
    }
    &__otherTtl {
        font-size: 1.8rem;
        line-height: 2;
        margin: 20px 0 0;
        @include media() {
            font-size: 1.6rem;
            margin: 10px 0 0;
        }
    }
}
