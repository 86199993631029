//////////////////////////////
// 施設・学生寮案内 /facility/
//////////////////////////////
.facility {
    .lowerMiddleTtl {
        @include media() {
            font-size: 2rem;
        }
    }
    .lowerTtlSec__figure img {
        @include media() {
            object-position: left;
        }
    }
    &__inner {
        padding-bottom: 252px;
        padding-top: 100px;
        @include media() {
            padding-bottom: 80px;
            padding-top: 40px;
        }
    }
    &__unit {
        &:not(:last-of-type) {
            border-bottom: 1px solid #e6e6e6;
            @include media() {
                border: none;
            }
        }
        &:nth-of-type(1) {
            padding-bottom: 172px;
            @include media() {
                padding-bottom: 0px;
            }
        }
        &:nth-of-type(2) {
            padding-top: 127px;
            @include media() {
                padding-top: 54px;
            }
            .lowerTtl {
                margin-bottom: 75px;
                @include media() {
                    align-items: flex-start;
                    flex-direction: column;
                    margin-bottom: 37px;
                }
                &__ja {
                    font-size: 2.2rem;
                }
                &__en {
                    font-size: 1.4rem;
                    @include media() {
                        margin-top: 9px;
                    }
                }
            }
        }
    }
    &__place {
        &:not(:last-of-type) {
            margin-bottom: 95px;
            @include media() {
                margin-bottom: 37px;
            }
        }
        .lowerMiddleTtl {
            margin-bottom: 16px;
            @include media() {
                margin-bottom: 25px;
            }
        }
    }
    &__imgList {
        display: flex;
        gap: 10px 56px;
        margin-top: 30px;
        @include media("tab") {
            gap: 10px 30px;
        }
        @include media("sp") {
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 16px;
        }
    }
    &__figure {
        border-radius: 10px;
        overflow: hidden;
        width: calc(33.33333% - 38px);
        @include media("tab") {
            width: calc(33.33333% - 20px);
        }
        @include media("sp") {
            border-radius: 3px;
            width: calc(50% - 5px);
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        margin-bottom: 35px;
        @include media() {
            font-size: 1.6rem;
            margin: -10px auto 0;
        }
    }
    &__virtual {
        div {
            //コード上ではblockquoteタグ
            margin: 0 auto;
            @include media() {
                min-height: 170px;
                height: calc(170 / 380 * 100vw) !important;
            }
        }
        iframe {
            @include media() {
                min-height: 170px;
                height: calc(170 / 380 * 100vw) !important;
            }
        }
    }
}
