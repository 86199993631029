//////////////////////////////
// header
//////////////////////////////
.header {
    padding: 56px clamp(30px, -20.333rem + 16.67vw, 50px) 40px clamp(30px, -20.333rem + 16.67vw, 50px);
    position: fixed;
    transition: all 0.2s;
    width: 100%;
    z-index: 10;
    &_page_lower {
        background-color: #fff;
    }
    @include media("lt") {
        display: flex;
        padding: 14px 30px;
        height: 107px;
    }
    @include media("sp") {
        background-color: #fff;
        height: 60px;
        padding: 16px 20px 13px;
    }
    @media screen and (max-width: 330px) {
        padding: 16px 10px 13px;
    }
    &.js_scroll {
        background-color: #fff;
        padding: 20px clamp(30px, -20.333rem + 16.67vw, 50px) 20px clamp(30px, -20.333rem + 16.67vw, 50px);
        .header__logoImg {
            height: 67px;
            width: 400px;
            @include media("sp") {
                width: auto;
                height: 65px;
            }
        }
    }
    &__inner {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
    &__logo {
        margin-right: 30px;
        transition: all 0.2s;
        &:hover {
            opacity: 0.5;
            @include media(){
                opacity: 1;
            }
        }
    }
    &__logoImg {
        transition: all 0.2s;
        width: clamp(400px, 25.6rem + 12vw, 472px);
        @include media("sp") {
            width: 239px;
        }
    }
}
.gnav {
    @include media("lt") {
        background: #fff;
        display: flex;
        flex-direction: column;
        height: 100vh;
        right: -380px;
        padding: 130px 40px 100px;
        position: fixed;
        top: 0px;
        transition: all 0.3s;
        width: 380px;
        &.is_open {
            right: 0px;
        }
    }
    @include media("sp") {
        height: calc(100vh - 60px);
        overflow: scroll;
        padding: 22px 40px;
        right: -100vw;
        top: 60px;
        width: 100%;
        &.is_open {
            right: 0px;
        }
    }
    &__top {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 17px;
        @include media("lt") {
            flex-direction: column;
            order: 2;
        }
    }
    &__bottom {
        @include media("lt") {
            order: 1;
        }
    }
    &__index {
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 0px 4px 0px rgb(0 0 0 / 50%);
        margin-right: 18px;
        max-width: 218px;
        // padding: 8px 10px 8px 19px;
        position: relative;
        transition: all 0.2s;
        @include media("lt") {
            margin-right: 0px;
            margin-bottom: 20px;
            max-width: none;
            width: 100%;
        }
        &:before {
            content: "|";
            color: #ccc;
            position: absolute;
            right: 31px;
            top: 6px;
            @include media("lt") {
                top: 14px;
            }
        }
        &:after {
            content: "";
            border-bottom: solid 2px #ccc;
            border-right: solid 2px #ccc;
            height: 8px;
            position: absolute;
            right: 16px;
            transform: rotate(45deg);
            top: 15px;
            width: 8px;
            @include media("lt") {
                top: 22px;
            }
        }
        &.js_open {
            border-radius: 6px 6px 0px 0px;
            @include media() {
                border-radius: 6px;
            }
        }
    }
    &__indexTxt {
        cursor: default;
        font-size: 1.5rem;
        letter-spacing: -0.01em;
        padding: 10px 49px 10px 20px;
        @include media("lt") {
            padding: 18px 49px 18px 16px;
        }
    }
    &__indexList {
        display: none;
        background: #fff;
        border-radius: 0px 0px 6px 6px;
        box-shadow: 0 0px 4px 0px rgb(0 0 0 / 50%);
        margin-top: 1px;
        position: absolute;
        width: 100%;
        @include media("lt") {
            position: relative;
            box-shadow: 0 0px 1px 0px rgb(0 0 0 / 50%);
        }
    }
    &__indexItem {
        font-size: 1.5rem;
        letter-spacing: -0.01em;
        &:not(:first-of-type) {
            border-top: 1px solid #ccc;
        }
        .gnav__indexLink {
            padding: 10px 20px 10px 20px;
        }
    }
    &__indexLink {
        width: 100%;
        display: block;
        transition: all 0.2s;
        &:hover {
            background-color: $blue;
            color: #fff;
        }
    }
    &__topLink {
        background-color: $blue;
        border-radius: 9999px;
        color: #fff;
        font-size: 1.5rem;
        padding: 9px 30px 9px 30px;
        position: relative;
        transition: all 0.2s;
        @include media("lt") {
            font-size: 1.7rem;
            padding: 16px 16px 16px 37px;
            width: 100%;
        }
        &:not(:last-of-type) {
            margin-right: 10px;
            @include media("lt") {
                margin: 0 auto 10px;
            }
        }
        &:before {
            border-top: solid 2px #fff;
            border-right: solid 2px #fff;
            content: "";
            height: 8px;
            position: absolute;
            left: 13px;
            transform: rotate(45deg) translateY(-50%);
            top: 48%;
            width: 8px;
        }
        &:hover {
            opacity: 0.5;
            @include media(){
                opacity: 1;
            }
        }
    }
    &__list {
        display: flex;
        @include media("lt") {
            flex-direction: column;
        }
    }
    &__item {
        &:not(:last-of-type) {
            margin-right: clamp(30px, -14.5rem + 12.5vw, 45px);
        }
        @include media("lt") {
            margin-right: 0;
            margin-bottom: 27px;
        }
    }
    &__link {
        // font-size: clamp(1.3rem, 0.9rem + 0.33vw, 1.5rem);
        font-size: clamp(1.3rem, 0.55rem + 0.63vw, 1.5rem);
        transition: all 0.2s;
        white-space: nowrap;
        @include media("lt") {
            font-size: 1.7rem;
        }
        &:hover {
            opacity: 0.5;
            @include media(){
                opacity: 1;
            }
        }
    }
}

//ハンバーガーメニュー
.hamburger {
    display: none;
    @include media("lt") {
        background-color: $blue;
        bottom: 0;
        cursor: pointer;
        display: block;
        height: 107px;
        position: absolute;
        right: 0px;
        top: 0px;
        transition: all 0.3s;
        width: 107px;
        z-index: 3;
        &.is_open {
            // background-color: transparent;
            margin: 0;
            position: fixed;
            @include media() {
                top: 0;
            }
        }
        &:focus {
            outline: none;
        }
    }
    @include media() {
        height: 60px;
        width: 60px;
    }
    &__bar {
        background-color: #fff;
        display: block;
        height: 2px;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        // transition: all 0.5s;
        // transition: transform 0.5s;
        width: 50px;
        @include media("sp") {
            height: 1px;
            left: 50%;
            width: 32px;
        }
    }
    &__bar:nth-child(1) {
        @include media("lt") {
            top: 48px;
        }
        @include media("sp") {
            top: 27px;
        }
    }
    &__bar:nth-child(2) {
        @include media("lt") {
            top: 57px;
        }
        @include media("sp") {
            top: 32px;
        }
    }
    &.is_open &__bar {
        @include media("lt") {
            left: 45%;
        }
        @include media("sp") {
            left: 45%;
            top: 21px;
            transform: rotate(-42deg) translateX(-50%);
        }
    }
    &.is_open &__bar:nth-child(1) {
        @include media("lt") {
            top: 35px;
            transform: rotate(-44deg) translateX(-50%);
        }
        @include media("sp") {
            left: 45%;
            top: 21px;
            transform: rotate(-42deg) translateX(-50%);
        }
    }
    &.is_open &__bar:nth-child(2) {
        @include media("lt") {
            top: 68px;
            transform: rotate(44deg) translateX(-50%);
        }
        @include media("sp") {
            left: 45%;
            top: 41px;
            transform: rotate(42deg) translateX(-50%);
        }
    }
}