////////////////////
// カラー設定
// 機械
.courseMechanical {
    & .lowerTtlSec__ttl::after,
    & .courseIntro__workItem::before,
    & .courseList__ttl::before,
    & .courseSecond__balloonTtl,
    & .courseCompany__subTtl::before,
    & .courseCompany__detail::before {
        background: $red;
    }
    & .lowerTtlSec__en,
    & .courseIntro__ttlAccent,
    & .courseIntro__workTxt,
    & .courseLicense__year,
    & .courseLicense__txt_color_red,
    & .courseFirst__ttl_color_red,
    & .courseSecond__num,
    & .courseSecond__whatsTxt_color_red,
    & .courseSecond__subEn {
        color: $red;
    }
    & .courseIntro__work,
    & .courseLicense__year {
        background: $red-sub;
    }
    & .courseSecond__ttl::before {
        border-color: $red-sub transparent transparent transparent;
    }
    & .courseSecond__balloonTtl::before {
        border-color: $red transparent transparent transparent;
    }
}

// 電気・電子
.courseElectrical {
    & .lowerTtlSec__ttl::after,
    & .courseIntro__workItem::before,
    & .courseList__ttl::before,
    & .courseSecond__balloonTtl,
    & .courseCompany__subTtl::before,
    & .courseCompany__detail::before {
        background: $orange;
    }
    & .lowerTtlSec__en,
    & .courseIntro__ttlAccent,
    & .courseIntro__workTxt,
    & .courseLicense__year,
    & .courseLicense__txt_color_orange,
    & .courseFirst__ttl_color_orange,
    & .courseSecond__num,
    & .courseSecond__whatsTxt_color_orange,
    & .courseSecond__subEn {
        color: $orange;
    }
    & .courseIntro__work,
    & .courseLicense__year {
        background: $orange-sub;
    }
    & .courseSecond__ttl::before {
        border-color: $orange-sub transparent transparent transparent;
    }
    & .courseSecond__balloonTtl::before {
        border-color: $orange transparent transparent transparent;
    }
}

// 建築
.courseBuilding {
    & .lowerTtlSec__ttl::after,
    & .courseIntro__workItem::before,
    & .courseList__ttl::before,
    & .courseSecond__balloonTtl,
    & .courseCompany__subTtl::before,
    & .courseCompany__detail::before {
        background: $green;
    }
    & .lowerTtlSec__en,
    & .courseIntro__ttlAccent,
    & .courseIntro__workTxt,
    & .courseLicense__year,
    & .courseLicense__txt_color_green,
    & .courseFirst__ttl_color_green,
    & .courseSecond__num,
    & .courseSecond__whatsTxt_color_green,
    & .courseSecond__subEn {
        color: $green;
    }
    & .courseIntro__work,
    & .courseLicense__year {
        background: $green-sub;
    }
    & .courseSecond__ttl::before {
        border-color: $green-sub transparent transparent transparent;
    }
    & .courseSecond__balloonTtl::before {
        border-color: $green transparent transparent transparent;
    }
}

////////////////////
// 共通設定
.courseLower {
    & .lowerTtlSec__small {
        margin-bottom: 12px;
    }
}

// ページ上部
.courseIntro {
    padding-top: 73px;
    margin-bottom: 73px;
    @include media() {
        padding-top: 50px;
        margin-bottom: 34px;
    }
    &__inner {
        display: flex;
        flex-wrap: wrap;
        gap: 60px;
        @include media() {
            gap: 40px;
        }
    }
    &__wrap {
        width: calc(50% - 30px);
        @include media(tab) {
            width: 100%;
        }
    }
    &__ttl {
        font-size: 3.2rem;
        line-height: 1.75;
        padding-bottom: 26px;
        @include media() {
            font-size: 2.2rem;
            padding-bottom: 40px;
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__work {
        padding: 38px 40px 46px;
        margin-top: 42px;
        border-radius: 6px;
        @include media() {
            padding: 30px 20px 40px;
        }
    }
    &__workTxt {
        font-size: 2.2rem;
        font-weight: $semiBold;
        padding-bottom: 20px;
        letter-spacing: 0.05em;
        @include media() {
            font-size: 1.8rem;
        }
    }
    &__workList {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 14px 28px;
        margin: auto;
    }
    &__workItem {
        padding-left: 15px;
        position: relative;
        &::before {
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 5px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }
    }
    &__fig {
        width: calc(50% - 30px);
        aspect-ratio: 59 / 62;
        border-radius: 10px;
        overflow: hidden;
        @include media(tab) {
            width: 100%;
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center / center;
    }
}

//個別設定
//電気・電子システム系
.courseElectrical {
    & .courseIntro__workItem {
        width: 143px;
        @include media("tab") {
            width: auto;
        }
    }
}

// 取得可能資格
.courseLicense {
    padding-bottom: 133px;
    @include media() {
        padding-bottom: 60px;
    }
    &__inner {
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.08);
        padding: 60px 60px 57px;
        @include media() {
            padding: 40px 20px 50px;
        }
    }
    &__ttl {
        display: flex;
        align-items: center;
        font-size: 2.2rem;
        font-weight: $semiBold;
        padding-bottom: 30px;
        @include media() {
            font-size: 2rem;
        }
    }
    &__svg {
        margin-right: 17px;
        @include media() {
            width: 28px;
            height: auto;
        }
    }
    &__list {
        list-style: none;
    }
    &__item {
        display: flex;
        width: 100%;
        @include media() {
            flex-direction: column;
        }
        & + & {
            padding-top: 20px;
            @include media() {
                padding-top: 40px;
            }
        }
    }
    &__year {
        width: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 38px;
        line-height: 1;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        @include media() {
            width: 100%;
            height: 80px;
            border-bottom-left-radius: 0;
            border-top-right-radius: 6px;
            padding-top: 0;
            justify-content: center;
        }
    }
    &__num {
        font-size: 3.6rem;
        font-family: $mont;
        @include media() {
            font-size: 2.8rem;
        }
    }
    &__small {
        font-size: 1.4rem;
        letter-spacing: 0.05em;
        padding-top: 8px;
        @include media() {
            padding-top: 2px;
        }
    }
    &__wrap {
        width: calc(100% - 120px);
        padding: 36px 40px 40px;
        background: #f8f8f8;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        @include media() {
            padding: 30px 18px 40px;
            width: 100%;
        }
    }
    &__txt {
        line-height: 2;
        word-wrap: break-word;
        &_color_red,
        &_color_orange,
        &_color_green {
            font-size: 1.8rem;
            font-weight: $semiBold;
            line-height: 1;
            padding-top: 6px;
            padding-bottom: 8px;
        }
        & + &_color_red,
        & + &_color_orange,
        & + &_color_green {
            padding-top: 23px;
        }
        .courseLicense__span {
            margin-left: 5px;
        }
    }
    &__span {
        color: #c93a3a;
        font-size: 1.2rem;
    }
    &__smallTxt {
        font-size: 1.2rem;
        line-height: 2.6666666667;
        & + .courseLicense__txt_color_red,
        & + .courseLicense__txt_color_orange,
        & + .courseLicense__txt_color_green {
            padding-top: 23px;
        }
    }
}

// 1年次に機械システム系で学ぶこと
.courseFirst {
    padding-bottom: 138px;
    @include media() {
        padding-bottom: 60px;
    }
    &__inner {
        border-top: 1px solid #e6e6e6;
        padding-top: 105px;
        @include media() {
            padding-top: 60px;
        }
    }
    &__svg {
        margin: 0 auto 16px;
        display: block;
        @include media() {
            width: 45px;
            height: auto;
        }
    }
    &__ttl {
        text-align: center;
        font-size: 3.2rem;
        font-weight: $semiBold;
        letter-spacing: 0.05em;
        padding-bottom: 70px;
        @include media() {
            font-size: 2rem;
            padding-bottom: 40px;
        }
    }
}

// 2年次
.courseSecond {
    padding-bottom: 50px;
    @include media() {
        padding-bottom: 0;
    }
    &__inner {
        border-top: 1px solid #e6e6e6;
        padding-top: 140px;
        @include media() {
            padding-top: 70px;
        }
    }
    &__unit {
        padding-bottom: 150px;
        @include media() {
            padding-bottom: 60px;
        }
    }
    &__ttl {
        position: relative;
        display: block;
        margin: auto;
        text-align: center;
        font-size: 2.2rem;
        font-weight: $semiBold;
        letter-spacing: 0.05em;
        margin-bottom: 80px;
        @include media() {
            font-size: 2rem;
            margin-bottom: 70px;
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 108px 210px 0 210px;
            z-index: -1;
            left: 0;
            right: 0;
            top: 0;
            bottom: -20px;
            margin: auto;
            @include media() {
                border-width: 80px 160px 0 160px;
            }
        }
    }
    &__num {
        font-size: 4.8rem;
        font-weight: $semiBold;
        font-family: $mont;
        margin-right: 6px;
        @include media() {
            font-size: 3.6rem;
        }
    }
    &__intro {
        text-align: center;
        font-size: 1.8rem;
        padding-bottom: 72px;
        @include media() {
            font-size: 1.6rem;
            line-height: 2;
            padding-bottom: 40px;
        }
    }
    &__balloonTtl {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 130px;
        border-radius: 65px;
        color: #fff;
        position: relative;
        margin-bottom: 80px;
        @include media() {
            height: 86px;
            margin-bottom: 50px;
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: -30px;
            left: 0;
            right: 0;
            margin: auto;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 30px 20px 0 20px;
            z-index: -1;
            @include media() {
                bottom: -24px;
            }
        }
    }
    &__balloonJp {
        font-size: 3.2rem;
        font-weight: $semiBold;
        letter-spacing: 0.05em;
        padding-bottom: 4px;
        @include media() {
            font-size: 1.8rem;
        }
    }
    &__balloonEn {
        font-family: $mont;
        font-weight: $semiBold;
        letter-spacing: 0.15em;
        @include media() {
            font-size: 0.8rem;
        }
    }
    &__flex {
        display: flex;
        flex-wrap: wrap;
        gap: 60px;
        padding-bottom: 100px;
        @include media() {
            padding-bottom: 60px;
        }
    }
    &__wrap {
        width: calc(50% - 30px);
        @include media(tab) {
            width: 100%;
        }
    }
    &__accent {
        font-size: 2.6rem;
        letter-spacing: 0.05em;
        line-height: 1.7;
        font-weight: $semiBold;
        padding-bottom: 10px;
        @include media() {
            font-size: 2.2rem;
            padding-bottom: 30px;
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        padding-bottom: 36px;
        @include media() {
            font-size: 1.6rem;
            padding-top: 0.5em;
            padding-bottom: 1em;
        }
    }
    &__whats {
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.08);
        padding: 30px 45px 35px 40px;
        @include media() {
            padding: 40px 40px 30px;
        }
    }
    &__whatsTxt {
        line-height: 2;
        @include media() {
            font-size: 1.5rem;
        }
        &_color_red,
        &_color_orange,
        &_color_green {
            font-size: 2.2rem;
            font-weight: $semiBold;
            letter-spacing: 0.05em;
            @include media() {
                font-size: 1.8rem;
            }
        }
    }
    &__fig {
        width: calc(50% - 30px);
        height: 100%;
        border-radius: 6px;
        aspect-ratio: 59 / 62;
        overflow: hidden;
        @include media(tab) {
            width: 100%;
        }
        @include media() {
            height: auto;
            aspect-ratio: 17 / 18;
        }
        &_display_pc {
            display: block;
            @include media() {
                display: none;
            }
        }
        &_display_sp {
            display: none;
            @include media() {
                display: block;
            }
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center / center;
    }
    &__subTtl {
        padding-bottom: 57px;
        @include media() {
            display: block;
            padding-bottom: 40px;
        }
    }
    &__subEn {
        font-family: $mont;
        font-size: 3rem;
        letter-spacing: 0.15em;
        font-weight: $semiBold;
        margin-right: 27px;
        @include media() {
            display: block;
            font-size: 2.6rem;
            margin-right: 0;
        }
    }
    &__subJp {
        font-size: 2rem;
        font-weight: $semiBold;
        letter-spacing: 0.05em;
        @include media() {
            font-size: 1.8rem;
        }
    }
}

// 就職先実績
.courseCompany {
    padding-bottom: 220px;
    @include media() {
        padding-bottom: 60px;
    }
    &__inner {
        border-top: 1px solid #e6e6e6;
        padding-top: 108px;
        @include media() {
            padding-top: 60px;
        }
    }
    &__svg {
        margin: 0 auto 16px;
        display: block;
        @include media() {
            width: 45px;
            height: auto;
        }
    }
    &__ttl {
        text-align: center;
        font-size: 3.2rem;
        font-weight: $semiBold;
        letter-spacing: 0.05em;
        padding-bottom: 70px;
        @include media() {
            font-size: 2rem;
            padding-bottom: 50px;
        }
    }
    &__unit {
        border-radius: 6px;
        border: 4px solid #f8f8f8;
        padding: 40px 50px 50px;
        @include media() {
            padding: 40px 30px;
        }
        & + & {
            margin-top: 60px;
            @include media() {
                margin-top: 40px;
            }
        }
    }
    &__subTtl {
        padding-left: 28px;
        position: relative;
        font-size: 2.2rem;
        font-weight: $semiBold;
        line-height: 2.6rem;
        padding-bottom: 3px;
        margin-bottom: 24px;
        letter-spacing: 0.05em;
        @include media() {
            font-size: 1.8rem;
            line-height: 2.2rem;
        }
        &::before {
            content: "";
            width: 12px;
            height: 26px;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            border-radius: 3px;
            @include media() {
                width: 10px;
                height: 22px;
            }
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px 0;
        @include media() {
            flex-direction: column;
            gap: 6px 0;
        }
        & + & {
            padding-top: 35px;
        }
    }
    &__term {
        width: 100%;
        display: block;
        font-size: 2rem;
        font-weight: $semiBold;
        letter-spacing: 0.05em;
        @include media() {
            font-size: 1.8rem;
            padding-bottom: 3px;
        }
    }
    &__detail {
        padding: 0 14px;
        position: relative;
        letter-spacing: 0.05em;
        @include media() {
            font-size: 1.4rem;
            line-height: 2;
        }
        &::before {
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 3px;
            display: block;
            position: absolute;
            left: 0;
            top: 10px;
            margin: auto;
            @include media() {
                top: 11px;
            }
        }
    }
    &__link {
        color: $blue;
        text-decoration: underline;
        transition: 0.2s;
        &:hover {
            opacity: 0.5;
        }
    }
}

//就職先情報リンク
.courseAssBtn {
    border-top: 1px solid #e6e6e6;
    padding-bottom: 120px;
    padding-top: 90px;
    @include media("sp") {
        padding-bottom: 100px;
        padding-top: 40px;
    }
    &__link {
        align-items: center;
        background-color: $blue;
        border-radius: 3px;
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 120px;
        margin: 0 auto;
        padding: 49px 20px;
        position: relative;
        transition: all 0.2s;
        width: 590px;
        @include media("sp") {
            height: auto;
            padding: 25px;
            width: 100%;
        }
        @include media("sp") {
            padding: 28px 35px;
        }
        @include media("spm") {
            padding: 23px 15px;
        }
        &:hover {
            opacity: 0.4;
        }
        &:before {
            background-color: #fff;
            border-radius: 9999px;
            content: "";
            height: 36px;
            position: absolute;
            right: 35px;
            top: 50%;
            transform: translateY(-50%);
            width: 36px;
            @include media("sp") {
                height: 24px;
                right: 23px;
                width: 24px;
            }
            @include media("spm") {
                height: 20px;
                right: 15px;
                width: 20px;
            }
        }
        &:after {
            content: "";
            border-top: solid 2px $blue;
            border-right: solid 2px $blue;
            height: 10px;
            position: absolute;
            right: 53px;
            top: 49%;
            transform: rotate(45deg) translateY(-50%);
            width: 10px;
            @include media("sp") {
                height: 8px;
                right: 35px;
                top: 48%;
                width: 8px;
            }
            @include media("spm") {
                height: 7px;
                right: 25px;
                width: 7px;
            }
        }
    }
    &__linkSpan {
            font-size: clamp(2rem, 1.686rem + 0.29vw, 2.2rem);
            font-weight: 500;
            line-height: 1;
            @include media("sp") {
                font-size: 1.6rem;
            }
            @include media("spm") {
                font-size: clamp(1.2rem, 1.057rem + 0.45vw, 1.4rem);
            }
    }
}

// 他のコースを見る
.courseOther {
    background: #f8f8f8;
    padding-top: 118px;
    padding-bottom: 196px;
    @include media() {
        padding-top: 60px;
        padding-bottom: 100px;
    }
    &__ttl {
        font-size: 3.2rem;
        font-weight: $semiBold;
        letter-spacing: 0.05em;
        padding-bottom: 70px;
        @include media() {
            font-size: 2.2rem;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 60px;
    }
    &__item {
        width: calc(50% - 30px);
        position: relative;
        @include media(tab) {
            width: 100%;
        }
        &_color_red {
            & .courseOther__accent {
                color: $red;
            }
            & .courseOther__circle {
                background: $red;
            }
            & .courseOther__wrap {
                @include media() {
                    background: $red-sub;
                }
            }
        }
        &_color_orange {
            & .courseOther__accent {
                color: $orange;
            }
            & .courseOther__circle {
                background: $orange;
            }
            & .courseOther__wrap {
                @include media() {
                    background: $orange-sub;
                }
            }
        }
        &_color_green {
            & .courseOther__accent {
                color: $green;
            }
            & .courseOther__circle {
                background: $green;
            }
            & .courseOther__wrap {
                @include media() {
                    background: $green-sub;
                }
            }
        }
    }
    &__wrap {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: #fff;
        padding: 67px 0 52px;
        @include media() {
            position: relative;
            padding: 34px 0 76px;
        }
    }
    &__subTtl {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 1;
        padding-bottom: 54px;
        @include media() {
            padding-bottom: 20px;
        }
    }
    &__accent {
        font-size: 3.6rem;
        font-weight: $semiBold;
        padding-bottom: 14px;
        letter-spacing: 0.05em;
        @include media() {
            font-size: 2.2rem;
            padding-bottom: 11px;
        }
    }
    &__middle {
        font-size: 2.4rem;
        font-weight: $semiBold;
        padding-bottom: 19px;
        letter-spacing: 0.05em;
        @include media() {
            font-size: 1.8rem;
            padding-bottom: 11px;
        }
    }
    &__small {
        font-weight: $semiBold;
        letter-spacing: 0.05em;
        @include media("sp") {
            font-size: 1.6rem;
        }
        @include media("se") {
            font-size: 1.4rem;
        }
    }
    &__course {
        display: flex;
        align-items: center;
        justify-content: center;
        @include media() {
            flex-direction: column;
        }
    }
    &__circle {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #fff;
        letter-spacing: 0.05em;
        @include media() {
            width: 70px;
            height: 70px;
            font-size: 1.4rem;
        }
    }
    &__courseList {
        padding-left: 20px;
        @include media() {
            padding-left: 0;
            padding-top: 20px;
            width: 100%;
        }
    }
    &__courseItem {
        width: 282px;
        height: 38px;
        background: #f8f8f8;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        font-weight: $semiBold;
        border-radius: 6px;
        letter-spacing: 0.05em;
        @include media("sp") {
            width: calc(100% - 60px);
            background: #fff;
            margin: auto;
        }
        @include media("se") {
            width: calc(100% - 40px);
        }
        & + & {
            margin-top: 10px;
        }
    }
    &__fig {
        width: 100%;
        aspect-ratio: 59 / 32;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        overflow: hidden;
        @include media() {
            aspect-ratio: 5 / 4;
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center / center;
    }
    &__link {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        max-width: 300px;
        height: 70px;
        bottom: -35px;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 35px;
        box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.08);
        transition: 0.2s;
        @include media() {
            width: calc(100% - 40px);
            height: 65px;
            bottom: -32.5px;
        }
        &:hover {
            box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.32);
        }
    }
    &__svg {
        position: absolute;
        left: 23px;
        top: 0;
        bottom: 0;
        margin: auto;
        @include media() {
            width: 28px;
            height: 28px;
            left: 30px;
        }
    }
}
