//////////////////////////////
// 個人情報について /privacy/
//////////////////////////////
.privacy {
    .lowerMiddleTtl {
        margin-bottom: 20px;
        @include media() {
            font-size: 2rem;
            margin-bottom: 30px;
        }
    }
    &__inner {
        padding-bottom: 200px;
        padding-top: 70px;
        @include media() {
            padding-bottom: 100px;
            padding-top: 50px;
        }
    }
    &__lead {
        font-size: 1.8rem;
        line-height: 2;
        margin-bottom: 80px;
        text-align: center;
        @include media() {
            font-size: 1.6rem;
            margin-bottom: 40px;
            text-align: unset;
        }
    }
    &__unit {
        & + & {
            margin-top: 80px;
            @include media() {
                margin-top: 40px;
            }
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        margin: 10px 0 0;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__link {
        text-decoration: underline;
        transition: .2s;
        &:hover {
            opacity: 0.5;
            text-decoration: underline rgba(#1a1a1a, 0);
        }
    }
}
