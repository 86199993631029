//////////////////////////////
// 試験例題 /exam-question/
//////////////////////////////
.examQuestion {
    .lowerMiddleTtl {
        margin-bottom: 20px;
        @include media() {
            font-size: 2rem;
            margin-bottom: 30px;
        }
    }
    .moreBtn {
        padding: 23px 128px 23px 136px;
        @include media() {
            padding: 19px 48px 19px 68px;
            width: 100%;
        }
        &::before {
            left: 27px;
            top: 50%;
            @include media() {
                left: 20px;
            }
        }
        &::after {
            left: 35px;
            @include media() {
                left: 28px;
            }
        }
    }
    &__inner {
        padding-bottom: 200px;
        padding-top: 70px;
        @include media() {
            padding-bottom: 100px;
            padding-top: 50px;
        }
    }
    &__unit {
        & + & {
            margin-top: 80px;
            @include media() {
                margin-top: 40px;
            }
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        margin: 10px 0 0;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__list {
        margin: 60px 0 0;
        @include media() {
            margin: 40px 0 0;
        }
    }
    &__item {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__link {
        color: $blue;
        text-decoration: underline;
        transition: .2s;
        &:hover {
            opacity: 0.5;
            text-decoration: underline transparent;
        }
    }
    &__btn {
        margin: 100px 0 0;
        @include media() {
            margin: 60px 0 0;
        }
    }
}
