/////////////////////////////////////////
// オープンキャンパス /opencampus/
/////////////////////////////////////////
.ocam {
    .lowerMiddleTtl {
        @include media() {
            font-size: 2rem;
        }
    }
    .moreBtn {
        @include media() {
            padding: 19px 30px 19px 60px;
            &:before {
                left: 20px;
            }
            &:after {
                left: 27px;
                top: 50%;
            }
        }
    }
    &__inner {
        padding-bottom: 167px;
        padding-top: 62px;
        @include media() {
            padding-bottom: 82px;
            padding-top: 48px;
        }
    }
    &__unit {
        &:not(:last-of-type) {
            border-bottom: 1px solid #e6e6e6;
        }
        &:nth-of-type(1) {
            padding-bottom: 104px;
            @include media() {
                padding-bottom: 61px;
            }
            .lowerMiddleTtl {
                margin-bottom: 30px;
            }
            .moreBtn {
                @include media() {
                    font-size: 1.4rem;
                }
            }
        }
        &:nth-of-type(2) {
            padding-bottom: 101px;
            padding-top: 89px;
            @include media() {
                padding-bottom: 48px;
                padding-top: 54px;
            }
            .lowerMiddleTtl {
                margin-bottom: 44px;
                @include media() {
                    margin-bottom: 29px;
                }
            }
            .ocam__annotationWrap {
                // margin-top: 10px;
            }
        }
        &:nth-of-type(3) {
            padding-bottom: 102px;
            padding-top: 94px;
            @include media() {
                padding-bottom: 61px;
                padding-top: 56px;
            }
            .lowerMiddleTtl {
                margin-bottom: 36px;
                @include media() {
                    margin-bottom: 37px;
                }
            }
        }
        &:nth-of-type(4) {
            padding-bottom: 142px;
            padding-top: 89px;
            @include media() {
                padding-bottom: 60px;
                padding-top: 55px;
            }
            .lowerMiddleTtl {
                margin-bottom: 62px;
                @include media() {
                    margin-bottom: 28px;
                }
            }
        }
        &:nth-of-type(5) {
            padding-top: 86px;
            @include media() {
                padding-top: 54px;
            }
            .lowerMiddleTtl {
                margin-bottom: 60px;
                @include media() {
                    margin-bottom: 34px;
                }
            }
            .moreBtn {
                @include media() {
                    margin-top: 18px;
                }
            }
        }
    }
    &__flex {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        @include media() {
            flex-direction: column;
        }
    }
    &__descWrap {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__annotationWrap {
        font-size: 1.6rem;
        line-height: 2;
        margin-top: 40px;
        @include media() {
            font-size: 1.4rem;
            line-height: 2.2857142857;
            margin-top: 20px;
        }
    }
    &__fig {
        margin-left: 80px;
        min-width: 320px;
        width: 320px;
        @include media("sp") {
            margin-left: 0px;
            margin-top: 30px;
            min-width: auto;
            width: 60%;
        }
        @include media("spm") {
            width: 100%;
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__link {
        margin-top: 40px;
        @include media() {
            width: 100%;
        }
    }
    &__virtual {
        div {
            //コード上ではblockquoteタグ
            margin: 0 auto;
            @include media() {
                min-height: 170px;
                height: calc(170 / 380 * 100vw) !important;
            }
        }
        iframe {
            @include media() {
                min-height: 170px;
                height: calc(170 / 380 * 100vw) !important;
            }
        }
    }
    &__offerLink {
        background-color: $blue;
        border-radius: 3px;
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 120px;
        margin-top: 53px;
        max-width: 590px;
        padding: 32px clamp(25px, -2.057rem + 4.14vw, 54px);
        position: relative;
        transition: all 0.2s;
        width: 47.6%;
        @include media("lt") {
            height: auto;
            padding: 25px;
            width: 100%;
        }
        @include media("sp") {
            margin-top: 33px;
            max-width: none;
            padding: 19px 35px;
        }
        @include media("se") {
            padding: 19px 15px;
        }
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
        &:before {
            background-color: #fff;
            border-radius: 9999px;
            content: "";
            height: 36px;
            position: absolute;
            right: 35px;
            top: 50%;
            transform: translateY(-50%);
            width: 36px;
            @include media("blt") {
                height: 24px;
                right: 23px;
                top: 52.5%;
                width: 24px;
            }
        }
        &:after {
            content: "";
            border-top: solid 2px $blue;
            border-right: solid 2px $blue;
            height: 10px;
            position: absolute;
            right: 53px;
            top: 49%;
            transform: rotate(45deg) translateY(-50%);
            width: 10px;
            @include media("blt") {
                height: 8px;
                right: 35px;
                width: 8px;
            }
            @include media("sp") {
                height: 7px;
                top: 52%;
                width: 7px;
            }
        }
        &_type_tel {
            background-color: #1a1a1a;
            text-align: center;
            @include media() {
                padding: 23px 25px 32px;
            }
            &:before,
            &:after {
                content: none;
            }
        }
    }
    &__offerLinkSpan {
        &_lg_ja {
            font-size: clamp(2rem, 1.686rem + 0.29vw, 2.2rem);
            // font-size: 2.2rem;
            font-weight: bold;
            @include media() {
                font-size: 1.6rem;
                margin-bottom: 5px;
            }
        }
        &_lg_en {
            font-family: $mont;
            font-size: 1.4rem;
            font-weight: $semiBold;
            letter-spacing: 0.1em;
            @include media() {
                font-size: 1.2rem;
            }
        }
    }
    &__dayFlex {
        display: flex;
        flex-wrap: wrap;
        @include media() {
            align-items: center;
            flex-direction: column;
        }
    }
    &__dayFlex + &__dayFlex {
        border-top: 1px dashed #e6e6e6;
        padding-top: 30px;
        @include media() {
            margin-top: 20px;
        }
    }
    &__dayWrap {
        margin-bottom: 30px;
        margin-right: 80px;
        @include media() {
            margin: 0 auto 26px;
        }
    }
    &__year,
    &__month,
    &__day {
        font-size: 2.8rem;
        font-weight: 500;
        line-height: 1;
    }
    &__dayTxt,
    &__week {
        font-size: 2rem;
        @include media() {
            font-size: 1.9rem;
        }
    }
    &__ampm {
        display: flex;
        margin-bottom: 30px;
        gap: 0 60px;
        @include media() {
            flex-wrap: wrap;
            gap: 0 44px;
            margin-bottom: 0px;
            justify-content: center;
        }
    }
    &__ampmWrap {
        align-items: center;
        display: flex;
        @include media() {
            flex-direction: column;
            margin-bottom: 10px;
        }
    }
    &__part {
        align-items: center;
        background-color: $blue;
        border-radius: 9999px;
        color: #fff;
        display: flex;
        font-size: 1.8rem;
        height: 36px;
        justify-content: center;
        margin-right: 23px;
        min-width: 134px;
        width: 134px;
        @include media() {
            font-size: 1.4rem;
            margin-bottom: 14px;
            margin-right: 0px;
            min-width: 136px;
            width: 136px;
        }
    }
    &__start,
    &__hyphen,
    &__end{
        font-size: 2.8rem;
        font-weight: 500;
        line-height: 1;
        @include media() {
            font-size: 2.4rem;
        }
    }
    &__instaWrap {
        display: flex;
        justify-content: space-between;
        gap: 0 clamp(20px, -7.2rem + 7.67vw, 66px);
        @include media() {
            gap: 30px 0;
            flex-wrap: wrap;
        }
        iframe {
            min-width: auto !important;
            width: calc(33.3333% - 10px) !important;
            @include media() {
                margin: 0 auto !important;
                width: 100% !important;
            }
        }
    }
}
