//////////////////////////////
// お知らせ
//////////////////////////////
.news {
    .container {
        @include media() {
            padding: 0;
        }
    }
    &_page_detail {
        //news詳細のみカテゴリーある関係でCSS変更
        .lowerTtlOnly {
            align-items: center;
            display: flex;
            flex-direction: column;
            padding-top: 120px;
            position: relative;
            @include media() {
                padding: 60px 20px 0;
            }
            &:before {
                background-color: #1a1a1a;
                border-radius: 9999px;
                content: "";
                height: 70px;
                left: 50%;
                position: absolute;
                top: -37px;
                transform: translateX(-50%);
                width: 6px;
                @include media() {
                    height: 35px;
                    top: -17px;
                    width: 3px;
                }
            }
            &:after {
                background-color: $blue;
                border-radius: 9999px;
                content: "";
                height: 20px;
                left: 50%;
                position: absolute;
                top: 40px;
                transform: translateX(-50%);
                width: 6px;
                @include media() {
                    height: 10px;
                    top: 21px;
                    width: 3px;
                }
            }
            &__ttl {
                padding-top: 0px;
                &:before,
                &:after {
                    content: none;
                }
            }
            &__ja {
                margin-bottom: 20px;
            }
            &__en {
                font-size: 1.6rem;
            }
        }
        .news__inner {
            padding-bottom: 413px;
            @include media() {
                padding-bottom: 81px;
            }
        }
        .pagination {
            @include media() {
                margin-bottom: 0;
            }
            &__list {
                justify-content: space-between;
                @include media("sp") {
                    padding: 0 20px;
                }
                @media screen and (max-width: 360px) {
                    flex-direction: column;
                }
            }
            &__item {
                @include media() {
                    margin: 0;
                }
                @media screen and (max-width: 360px) {
                    margin: 5px auto;
                }
                &_type_next {
                    @include media() {
                        bottom: auto;
                        left: auto;
                        position: relative;
                        right: auto;
                        top: auto;
                        transform: none;
                    }
                }
            }
        }
        .lowerTtlOnly.container {
            @include media() {
                padding: 60px 20px 0px;
            }
        }
    }
    .pagination__item_diabled {
        pointer-events: none;
        opacity: 0.6;
    }
    &__inner {
        padding-bottom: 165px;
        padding-top: 60px;
        @include media() {
            padding-bottom: 93px;
            padding-top: 40px;
        }
    }
    &__wrap {
        margin-bottom: 123px;
        @include media() {
            margin-bottom: 65px;
        }
    }
    &__art {
        border-top: 1px solid #ededed;
        &:last-of-type {
            border-bottom: 1px solid #ededed;
        }
    }
    &__link {
        display: block;
        padding: 40px 50px;
        transition: all 0.2s;
        @include media("tab") {
            padding: 40px 35px;
        }
        @include media("sp") {
            padding: 14px 40px;
        }
        &:hover {
            background-color: $blue;
            @include media() {
                background-color: #fff;
            }
            .news__time {
                color: #fff;
                @include media() {
                    color: $blue;
                }
            }
            .news__cat {
                color: #fff;
            }
            .news__txt {
                color: #fff;
                @include media() {
                    color: #1a1a1a;
                }
            }
        }
    }
    &__data {
        align-items: center;
        display: flex;
        @include media() {
            margin-bottom: 4px;
        }
    }
    &__time {
        color: $blue;
        font-family: $mont;
        font-size: 1.6rem;
        font-weight: $semiBold;
        letter-spacing: 0.15em;
        padding-right: 20px;
        @include media() {
            font-size: 1.4rem;
            padding-right: 10px;
        }
    }
    &__cat {
        border-left: 1px solid #e6e6e6;
        padding-left: 20px;
        @include media() {
            font-size: 1.4rem;
            padding-left: 10px;
        }
    }
    &__catDt {
        font-size: 1.6rem;
        background: #e6e6e6;
        border-radius: 9999px;
        display: flex;
        font-size: 1.4rem;
        padding: 3px 20px;
        justify-content: center;
        margin-bottom: 15px;
        @include media() {
            font-size: 1.3rem;
        }
    }
    &__txt {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.4rem;
            line-height: 1.7142857143;
        }
    }
    &__editor {
        margin-bottom: 116px;
        padding: 0 80px;
        @include media("tab") {
            margin-bottom: 45px;
            padding: 0 30px;
        }
        @include media("sp") {
            margin-bottom: 60px;
            padding: 0 20px;
        }
        figure {
            overflow: hidden;
            margin-bottom: 72px;
            margin-top: 60px;
            @include media() {
                margin-bottom: 50px;
                margin-top: 40px;
            }
        }
        img {
            // max-height: 1000px;
            // height: 100%;
            // object-fit: contain;
            // width: 100%;
            width: auto;
            // @include media() {
            //     max-height: none;
            // }
        }
        .wp-block-media-text__media{
            img {
                width: 100%;
            }
        }
        .wp-block-media-text__content {
            @include media() {
                padding-left: 0;
                padding-right: 0;
            }
        }
        figcaption {
            font-size: 1.6rem;
            line-height: 2;
            margin-bottom: 0em !important;
            margin-top: 1.5em !important;
            @include media() {
                font-size: 1.3rem;
                margin-top: 1em !important;
            }
        }
        h2 {
            font-size: 3.2rem;
            font-weight: bold;
            line-height: 1.5;
            margin-bottom: 30px;
            margin-top: 70px;
            @include media() {
                font-size: 2.2rem;
                margin-bottom: 20px;
                margin-top: 60px;
            }
        }
        h3 {
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 1.5;
            margin-bottom: 30px;
            margin-top: 40px;
            padding-left: 40px;
            position: relative;
            @include media() {
                font-size: 2rem;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            &:before {
                background-color: $blue;
                border-radius: 9999px;
                content: "";
                height: 6px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 22px;
                @include media() {
                    height: 4px;
                    width: 17px;
                }
            }
        }
        h4 {
            font-size: 2.2rem;
            font-weight: bold;
            letter-spacing: 0.05em;
            line-height: 1.5;
            margin-bottom: 30px;
            margin-top: 40px;
            padding-left: 20px;
            position: relative;
            @include media() {
                font-size: 1.8rem;
                margin-bottom: 20px;
                margin-top: 15px;
            }
            &:before {
                background-color: $blue;
                border-radius: 9999px;
                content: "";
                height: 8px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 8px;
            }
        }
        p {
            font-size: 1.8rem;
            line-height: 2;
            margin-bottom: 30px;
            @include media() {
                font-size: 1.4rem;
                line-height: 2.1428571429;
                margin-bottom: 20px;
            }
        }
        a {
            color: #438cc8;
            text-decoration: underline;
            transition: all 0.2s;
            &:hover {
                opacity: 0.4;
                text-decoration: none;
            }
        }
        ul {
            margin-bottom: 60px;
            @include media() {
                margin-bottom: 64px;
            }
        }
        li {
            font-size: 1.8rem;
            line-height: 2;
            padding-left: 1em;
            position: relative;
            &:before {
                background-color: $blue;
                border-radius: 9999px;
                content: "";
                display: block;
                height: 5px;
                left: 0;
                position: absolute;
                top: 16px;
                width: 5px;
            }
            @include media() {
                font-size: 1.6rem;
                line-height: 2.25;
                padding-left: 1.4em;
            }
        }
        .is-nowrap.wp-block-group {
            gap: 0 60px !important;
            @include media("tab") {
                gap: 0 30px !important;
            }
            @include media("sp") {
                flex-direction: column;
                gap: 30px 0px !important;
                margin-bottom: 65px;
            }
            figure {
                aspect-ratio: 51/32;
                margin-top: 0px;
                width: calc(50% - 30px);
                @include media("tab") {
                    width: 50%;
                }
                @include media("sp") {
                    aspect-ratio: unset;
                    margin-bottom: 0;
                    width: 100%;
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            figcaption {
                margin-top: 15px !important;
                @include media() {
                    margin-top: 12px !important;
                }
            }
        }
        .wp-block-group {
            margin-bottom: 70px;
            @include media() {
                margin-bottom: 60px;
            }
            .wp-block-group__inner-container {
                border-radius: 6px;
                border: 5px solid #f2f2f2;
                padding: 40px 45px;
                @include media() {
                    border: 3px solid #f2f2f2;
                    padding: 27px 17px;
                }
                h2,
                h3,
                h4 {
                    margin-bottom: 15px;
                    margin-top: 0px;
                }
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}
