//////////////////////////////
// 共通パーツ
//////////////////////////////

//TOP
//お知らせタイトル
.topSmallTtl {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    &__ja {
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 5px;
        @include media("sp") {
            font-size: 2rem;
        }
    }
    &__en {
        color: $blue;
        font-size: 1.2rem;
        font-family: $mont;
        font-weight: $semiBold;
        letter-spacing: 0.1em;
    }
}

//コース紹介・インタビュータイトル
.topTtl {
    align-items: center;
    display: flex;
    @include media() {
        // flex-direction: column;
    }
    &__en {
        color: $blue;
        font-size: 6.8rem;
        font-family: $mont;
        font-weight: $semiBold;
        letter-spacing: 0.1em;
        padding-right: 18px;
        @include media() {
            font-size: 3.4rem;
            padding-right: 10px;
        }
    }
    &__ja {
        font-size: 3.2rem;
        font-weight: bold;
        margin-bottom: 5px;
        @include media() {
            font-size: 1.6rem;
        }
    }
}

//共通
//詳しく見るボタン
.moreBtn {
    background-color: #fff;
    border-radius: 9999px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 8%);
    display: inline-block;
    font-size: 1.6rem;
    padding: 21px 73px 21px 90px;
    position: relative;
    text-align: center;
    transition: all 0.2s;
    @include media() {
        padding: 19px 70px 19px 110px;
    }
    &:before {
        background-color: $blue;
        border-radius: 9999px;
        content: "";
        height: 30px;
        position: absolute;
        left: 22px;
        top: 52%;
        transform: translateY(-50%);
        width: 30px;
        @include media() {
            left: 42px;
        }
    }
    &:after {
        content: "";
        border-top: solid 2px #fff;
        border-right: solid 2px #fff;
        height: 7px;
        position: absolute;
        left: 30px;
        top: 49%;
        transform: rotate(45deg) translateY(-50%);
        width: 7px;
        @include media() {
            left: 50px;
        }
    }
    &:hover {
        opacity: 0.4;
    }
}

//下層ヘッダー
.lowerTtlSec {
    margin: 0 auto;
    // max-width: 1680px;
    position: relative;
    &__figure {
        overflow: hidden;
        padding: 0 60px;
        @include media("lt") {
            padding: 0 40px;
        }
        @include media("sp") {
            height: 300px;
            padding: 0px;
        }
        img {
            border-radius: 0px 30px 0px 30px;
            object-fit: cover;
            max-height: 680px;
            height: 100%;
            width: 100%;
            @include media() {
                border-radius: 0px;
            }
        }
    }
    &__sideTxt {
        font-family: $mont;
        font-size: 1rem;
        position: absolute;
        letter-spacing: 0.15em;
        left: 25px;
        top: 40%;
        transform: translateY(-50%) rotate(180deg);
        writing-mode: vertical-rl;
        white-space: nowrap;
        z-index: 2;
        @include media("lt") {
            left: 18px;
        }
        @include media("sp") {
            display: none;
        }
    }
    &__spTxt {
        display: none;
        @include media() {
            display: block;
            height: 259px;
            position: absolute;
            right: 18px;
            top: 19px;
            width: 10px;
        }
    }
    &__ttl {
        display: flex;
        flex-direction: column;
        padding-top: 73px;
        position: relative;
        text-align: center;
        @include media() {
            padding-top: 64px;
        }
        &:before {
            background-color: #1a1a1a;
            border-radius: 9999px;
            content: "";
            height: 70px;
            left: 50%;
            position: absolute;
            top: -37px;
            transform: translateX(-50%);
            width: 6px;
            @include media() {
                height: 35px;
                left: 49.8%;
                top: -17px;
                width: 3px;
            }
        }
        &:after {
            background-color: $blue;
            border-radius: 9999px;
            content: "";
            height: 20px;
            left: 50%;
            position: absolute;
            top: 40px;
            transform: translateX(-50%);
            width: 6px;
            @include media() {
                height: 10px;
                left: 49.8%;
                top: 21px;
                width: 3px;
            }
        }
    }
    &__ja {
        font-size: 4rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        margin-bottom: 10px;
        @include media() {
            font-size: 2.2rem;
            margin-bottom: 8px;
        }
    }
    &__en {
        color: $blue;
        font-family: $mont;
        font-size: 1.4rem;
        font-weight: $semiBold;
        letter-spacing: 0.15em;
        @include media() {
            font-size: 1.2rem;
        }
    }
}

//下層ヘッダー画像なしタイトルのみ
.lowerTtlOnly {
    &__ttl {
        display: flex;
        flex-direction: column;
        padding-top: 90px;
        position: relative;
        text-align: center;
        @include media() {
            padding-top: 64px;
        }
        &:before {
            background-color: #1a1a1a;
            border-radius: 9999px;
            content: "";
            height: 70px;
            left: 50%;
            position: absolute;
            top: -37px;
            transform: translateX(-50%);
            width: 6px;
            @include media() {
                height: 35px;
                top: -17px;
                width: 3px;
            }
        }
        &:after {
            background-color: $blue;
            border-radius: 9999px;
            content: "";
            height: 20px;
            left: 50%;
            position: absolute;
            top: 40px;
            transform: translateX(-50%);
            width: 6px;
            @include media() {
                height: 10px;
                top: 21px;
                width: 3px;
            }
        }
    }
    &__ja {
        font-size: 4rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        margin-bottom: 10px;
        @include media() {
            font-size: 2.2rem;
        }
    }
    &__en {
        color: $blue;
        font-family: $mont;
        font-size: 1.4rem;
        font-weight: $semiBold;
        letter-spacing: 0.15em;
        @include media() {
            font-size: 1.2rem;
        }
    }
    &__time {
        color: $blue;
        font-family: $mont;
        font-size: 1.4rem;
        font-weight: $semiBold;
        letter-spacing: 0.15em;
        @include media() {
            font-size: 1.2rem;
            margin-top: 15px;
        }
    }
}

//日本語＋青字英語
.lowerTtl {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    &__ja {
        font-size: 3.2rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        margin-right: 26px;
        @include media() {
            font-size: 2.2rem;
            letter-spacing: 0em;
            margin-right: 17px;
        }
    }
    &__en {
        color: $blue;
        font-family: $mont;
        font-size: 1.8rem;
        font-weight: $semiBold;
        letter-spacing: 0.1em;
        @include media() {
            font-size: 1.4rem;
        }
    }
}

//青色横線付き
.lowerMiddleTtl {
    font-size: 2.8rem;
    font-weight: bold;
    padding-left: 40px;
    position: relative;
    @include media() {
        font-size: 1.8rem;
        padding-left: 37px;
    }
    &:before {
        background-color: $blue;
        border-radius: 9999px;
        content: "";
        height: 6px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 22px;
        @include media() {
            height: 4px;
            width: 17px;
        }
    }
}

//青色縦帯付き
.lowerSmallTtl {
    font-size: 2.2rem;
    font-weight: $semiBold;
    letter-spacing: 0.05em;
    line-height: 2.6rem;
    padding-bottom: 3px;
    padding-left: 22px;
    position: relative;
    @include media() {
        font-size: 2rem;
        line-height: 2.2rem;
        padding-bottom: 0;
    }
    &::before {
        background-color: $blue;
        border-radius: 3px;
        bottom: 0;
        content: "";
        display: block;
        height: 26px;
        left: 0;
        margin: auto;
        position: absolute;
        top: 0;
        width: 12px;
        @include media() {
            height: 22px;
            width: 10px;
        }
    }
}

//青色ドット付き
.dotTtl {
    align-items: center;
    display: flex;
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    position: relative;
    &:before {
        color: $blue;
        content: "・";
        margin-right: 4px;
        @include media() {
            font-size: 2.6rem;
            margin-right: 0px;
            line-height: 1;
        }
    }
    @include media() {
        font-size: 1.8rem;
    }
}

//コース紹介リスト
//カラー設定は_course-lower.scss
.courseList {
    display: flex;
    flex-wrap: wrap;
    gap: 74px 56px;
    @include media() {
        gap: 40px;
    }
    &__item {
        width: calc((100% / 3) - (112px / 3));
        @include media(tab) {
            width: calc(50% - 28px);
        }
        @include media() {
            width: 100%;
        }
    }
    &__ttl {
        padding-left: 28px;
        position: relative;
        font-size: 2.2rem;
        font-weight: $semiBold;
        line-height: 2.6rem;
        padding-bottom: 3px;
        margin-bottom: 30px;
        letter-spacing: 0.05em;
        @include media() {
            font-size: 2rem;
            line-height: 2.2rem;
            padding-bottom: 0;
            margin-bottom: 20px;
        }
        &::before {
            content: "";
            width: 12px;
            height: 26px;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            @include media() {
                width: 10px;
                height: 22px;
            }
        }
    }
    &__fig {
        border-radius: 10px;
        width: 100%;
        aspect-ratio: 94 / 65;
        overflow: hidden;
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__txt {
        line-height: 2;
        padding-top: 1em;
        @include media() {
            font-size: 1.4rem;
        }
    }
}
