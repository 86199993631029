.employment {
    padding-top: 285px;
    padding-bottom: 200px;
    @include media(lt) {
        padding-top: 215px;
    }
    @include media() {
        padding-top: 107px;
        padding-bottom: 80px;
    }
    &__pagination {
        padding-top: 105px;
        @include media() {
            padding-top: 60px;
        }
    }
}

.employmentForm {
    margin-top: 80px;
    margin-bottom: 90px;
    @include media() {
        margin-top: 70px;
        margin-bottom: 60px;
    }
    &__list {
        display: flex;
        align-items: flex-end;
        gap: 30px;
        padding: 50px 50px 60px;
        border-radius: 3px;
        box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.08);
        @include media(lt) {
            flex-direction: column;
            align-items: center;
        }
        @include media() {
            padding: 30px 25px 40px;
            gap: 25px;
        }
    }
    &__item {
        width: 25%;
        position: relative;
        @include media(lt) {
            width: 100%;
            max-width: 600px;
        }
        &::before {
            content: "";
            display: block;
            width: 10px;
            height: 5px;
            background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.021%22%20height%3D%225.054%22%20viewBox%3D%220%200%209.021%205.054%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_78%22%20data-name%3D%22%E3%83%91%E3%82%B9%2078%22%20d%3D%22M4591.671%2C639.474l4.164%2C4%2C4.164-4%22%20transform%3D%22translate(-4591.325%20-639.113)%22%20fill%3D%22none%22%20stroke%3D%22%232d2d2d%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%221%22%2F%3E%3C%2Fsvg%3E') no-repeat;
            background-size: contain;
            position: absolute;
            right: 20px;
            bottom: 22px;
        }
        &_type_submit {
            width: calc(25% - 90px);
            @include media(lt) {
                width: 100%;
                max-width: 600px;
            }
            &::before {
                content: none;
            }
        }
    }
    &__ttl {
        font-weight: bold;
        padding-bottom: 8px;
    }
    &__select {
        appearance: none;
        border: none;
        border-radius: 3px;
        background: #f6f6f6;
        width: 100%;
        height: 50px;
        padding: 0 30px 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        letter-spacing: 0.05em;
    }
    &__option_hidden {
        display: none;
    }
    &__btn {
        appearance: none;
        border: none;
        border-radius: 3px;
        width: 100%;
        height: 50px;
        color: #fff;
        background: $blue;
        transition: .2s;
        font-size: 1.5rem;
        letter-spacing: 0.05em;
        @include media(lt) {
            margin-top: 15px;
        }
        &:hover {
            opacity: .5;
        }
    }
    &__annotation {
        margin-top: 30px;
        text-align: center;
    }
}

.employmentResult {
    &__ttl {
        margin-bottom: 50px;
        @include media() {
            margin-bottom: 35px;
            line-height: 2;

        }
        &::before {
            bottom: auto;
            top: 1px;
            @include media() {
                top: 9px;
            }
        }
    }
    &__item {
        display: flex;
        padding: 37px 20px 48px;
        border-bottom: 1px solid #f2f2f2;
        @include media() {
            padding: 30px 0 40px;
        }
        &:first-of-type {
            border-top: 1px solid #f2f2f2;
        }
    }
    &__inner {
        flex: 1 1 auto;
    }
    &__companyTtl {
        padding-bottom: 19px;
    }
    &__name {
        font-size: 2rem;
        font-weight: $semiBold;
        letter-spacing: 0.05em;
        @include media(lt) {
            display: block;
            padding-bottom: 10px;
        }
    }
    &__furigana {
        font-size: 1.4rem;
        letter-spacing: 0.05em;
        margin-left: 10px;
        @include media(lt) {
            margin-left: 0px;
            display: block;
        }
    }
    &__catWrap {
        display: flex;
        gap: 10px;
        padding-bottom: 15px;
        flex-direction: column;
        @include media() {
            padding-bottom: 30px;
            flex-wrap: wrap;
        }
    }
    &__cat {
        font-size: 1.4rem;
        letter-spacing: 0.05em;
        padding: 5px 15px 6px;
        background: #f6f6f6;
        width: fit-content;
    }
    &__txt {
        letter-spacing: 0.05em;
        line-height: 2;
        padding-bottom: 10px;
    }
    &__link {
        letter-spacing: 0.05em;
        color: $blue;
        text-decoration: underline;
        transition: .2s;
        word-break: break-all;
        &:hover {
            opacity: .6;
            text-decoration: none;
        }
    }
    &__fig {
        width: 300px;
        min-width: 300px;
        height: fit-content;
        aspect-ratio: 3 / 2;
        border-radius: 3px;
        overflow: hidden;
        box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.08);
        margin-left: 60px;
        padding: 20px;
        @include media() {
            margin: 0 auto 20px;
            max-width: calc(100vw - 40px);
            min-width: auto;
        }
    }
    &__figLink {
        display: block;
        width: 100%;
        height: 100%;
        &:hover{
            opacity: .7;
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center / center;
    }
    &__notFound {
        line-height: 2;
        letter-spacing: 0.05em;
        @include media() {
            font-size: 1.4rem;
        }
    }
}

//safariのみ
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    _::-webkit-full-page-media, _:future, :root .employmentResult__figLink {
        height: calc(100% - 40px);
    }
}