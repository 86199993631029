//////////////////////////////
// footer
//////////////////////////////
.footer {
    &__top {
        background-color: #333333;
        color: #fff;
        overflow: hidden;
        padding-bottom: 213px;
        padding-top: 130px;
        position: relative;
        @include media("blt") {
            padding-bottom: 140px;
        }
        @include media("sp") {
            padding-bottom: 60px;
            padding-top: 80px;
        }
        .footer__container {
            position: relative;
            z-index: 1;
        }
    }
    &__linkList {
        display: flex;
        gap: 30px;
        justify-content: flex-start;
        margin-bottom: 95px;
        @include media("lt") {
            flex-direction: column;
            gap: 0px;
        }
        @include media("sp") {
            margin-bottom: 60px;
        }
    }
    &__topLink {
        background-color: $blue;
        border-radius: 3px;
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 160px;
        padding: 54px clamp(25px, -2.057rem + 4.14vw, 54px);
        position: relative;
        transition: all 0.2s;
        width: calc(33.33333% - clamp(10px, -1.357rem + 2.14vw, 25px));
        @include media("lt") {
            height: auto;
            padding: 25px;
            width: 100%;
            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }
        @include media("sp") {
            padding: 28px 35px;
            &:not(:last-of-type) {
                margin-bottom: 41px;
            }
        }
        @include media("se") {
            padding: 28px 15px;
        }
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
        &:before {
            background-color: #fff;
            border-radius: 9999px;
            content: "";
            height: 36px;
            position: absolute;
            right: 35px;
            top: 50%;
            transform: translateY(-50%);
            width: 36px;
            @include media("blt") {
                height: 24px;
                right: 23px;
                top: 52.5%;
                width: 24px;
            }
        }
        &:after {
            border-right: solid 2px $blue;
            border-top: solid 2px $blue;
            content: "";
            height: 10px;
            position: absolute;
            right: 53px;
            top: 49%;
            transform: rotate(45deg) translateY(-50%);
            width: 10px;
            @include media("blt") {
                height: 8px;
                right: 35px;
                top: 51%;
                width: 8px;
            }
            @include media("sp") {
                height: 7px;
                width: 7px;
            }
        }
        &_type_tel {
            background-color: #1a1a1a;
            text-align: center;
            @include media() {
                padding: 23px 25px 32px;
            }
            &:before,
            &:after {
                content: none;
            }
        }
    }
    &__topLinkSpan {
        &_lg_ja {
            font-size: clamp(2rem, 1.686rem + 0.29vw, 2.2rem);
            // font-size: 2.2rem;
            font-weight: bold;
            @include media() {
                font-size: 1.6rem;
                margin-bottom: 5px;
            }
        }
        &_lg_en {
            font-family: $mont;
            font-size: 1.4rem;
            font-weight: $semiBold;
            letter-spacing: 0.1em;
            @include media() {
                font-size: 1.2rem;
            }
        }
    }
    &__telLinkSpan {
        &_type_num {
            font-family: $mont;
            font-size: clamp(2rem, 0.114rem + 1.71vw, 3.2rem);
            // font-size: 3.2rem;
            font-weight: $semiBold;
            letter-spacing: 0.1em;
            margin-bottom: 5px;
            @include media() {
                font-size: 2.2rem;
                margin-bottom: 0px;
            }
        }
        &_type_time {
            font-size: 1.4rem;
            @include media() {
                font-size: 1.2rem;
            }
        }
    }
    &__info {
        display: flex;
        justify-content: space-between;
        @include media("blt") {
            flex-wrap: wrap;
        }
    }
    &__infoUnit {
        @include media("blt") {
            width: 100%;
        }
        &:first-of-type {
            @include media() {
                padding: 0 5px;
            }
        }
        &:last-of-type {
            width: 48.1%;
            @include media("blt") {
                margin: 40px auto;
                width: 100%;
            }
            @include media("sp") {
                margin: 39px auto 0 auto;
            }
        }
    }
    &__logoWrap {
        margin-bottom: 28px;
        @include media() {
            margin-bottom: 33px;
        }
    }
    &__logoImg {
        max-width: 503px;
    }
    &__addUnit {
        color: #fff;
        font-size: 1.8rem;
        font-style: normal;
        margin-bottom: 34px;
        @include media() {
            font-size: 1.4rem;
            margin-bottom: 16px;
        }
    }
    &__add {
        margin-bottom: 10px;
        @include media() {
            margin-bottom: 17px;
        }
    }
    &__telWrap {
        display: flex;
        @include media() {
            flex-direction: column;
        }
    }
    &__tel {
        margin-right: 37px;
        @include media() {
            margin-bottom: 16px;
            margin-right: 0px;
        }
    }
    &__telLink {
        transition: all 0.2s;
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
    &__telTime {
        font-size: 1.6rem;
        @include media() {
            font-size: 1.4rem;
        }
    }
    &__mapLink {
        background-color: $blue;
        border-radius: 9999px;
        color: #fff;
        display: inline-block;
        padding: 8px 24px 8px 34px;
        position: relative;
        transition: all 0.2s;
        &:before {
            border-right: solid 2px #fff;
            border-top: solid 2px #fff;
            content: "";
            height: 7px;
            left: 18px;
            position: absolute;
            top: 44%;
            transform: translateY(-50%);
            transform: rotate(45deg);
            width: 7px;
        }
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
    &__key {
        margin-bottom: 36px;
        padding-top: 13px;
        @include media() {
            padding-top: 0px;
        }
    }
    &__keywordWrap {
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: relative;
        @include media() {
            // flex-direction: column;
        }
        &:before {
            background: url(../images/icon_search_gray.svg) no-repeat center / cover;
            content: "";
            display: block;
            height: 22px;
            left: 25px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 22px;
            @include media() {
                height: 17px;
                left: 12px;
                top: 13px;
                transform: none;
                width: 17px;
            }
        }
    }
    &__keyword {
        background: #fff;
        border: none;
        border-radius: 3px;
        font-size: 1.8rem;
        line-height: 70px;
        margin-right: 2px;
        padding: 0 0 0 64px;
        width: calc(100% - 180px);
        @include media("tab") {
            margin-right: 20px;
        }
        @include media("sp") {
            border-radius: 1.5px;
            font-size: 1.4rem;
            line-height: 40px;
            margin-right: 0;
            padding: 0 0 0 35px;
            width: calc(100% - 78px);
            &::placeholder {
                font-size: 1.4rem;
            }
        }
        &::placeholder {
            color: #9fa0a0;
        }
        &:focus {
            // line-height: 58px;
            outline: none;
        }
    }
    &__searchBtn {
        background: $blue;
        border: none;
        border-radius: 3px;
        color: #fff;
        font-size: 1.6rem;
        line-height: 70px;
        transition: all 0.3s;
        width: 160px;
        &:hover {
            background-color: #fff;
            color: $blue;
            @include media() {
                background: $blue;
                color: #fff;
            }
        }
        @include media() {
            border-radius: 1.5px;
            font-size: 1.4rem;
            line-height: 40px;
            width: 72px;
        }
    }
    &__linkUnit {
        align-items: center;
        display: flex;
        justify-content: space-between;
        @include media() {
            flex-direction: column;
        }
        &:not(:last-child) {
            margin-bottom: 36px;
        }
    }
    &__prefList {
        display: flex;
        @include media() {
            display: none;
            flex-direction: column;
        }
    }
    &__prefItem {
        &:not(:last-of-type) {
            margin-right: 30px;
        }
    }
    &__prefLink {
        color: #fff;
        font-size: 1.6rem;
        padding-left: 28px;
        position: relative;
        transition: all 0.2s;
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
        &:before {
            background-color: #fff;
            border-radius: 9999px;
            content: "";
            height: 18px;
            left: 0px;
            position: absolute;
            top: 54%;
            transform: translateY(-50%);
            width: 18px;
        }
        &:after {
            border-right: solid 1px $blue;
            border-top: solid 1px $blue;
            content: "";
            height: 5px;
            left: 4px;
            position: absolute;
            top: 49%;
            transform: rotate(45deg) translateY(-50%);
            width: 5px;
        }
    }
    &__snsList {
        display: flex;
        margin-right: 5px;
        @include media() {
            margin: 0;
            padding-top: 11px;
        }
    }
    &__snsItem {
        &:not(:last-of-type) {
            margin-right: 25px;
        }
    }
    &__snsLink {
        transition: all 0.2s;
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
    &__bigTxt {
        bottom: 0px;
        color: #3a3a3a;
        font-family: $mont;
        font-size: 12.1vw;
        font-weight: $semiBold;
        letter-spacing: 0.05em;
        line-height: 0.7;
        position: absolute;
        z-index: 0;
        @include media() {
            display: none;
        }
    }
    &__bottom {
        background-color: #1a1a1a;
        padding-bottom: 120px;
        padding-top: 116px;
        @include media() {
            padding-bottom: 35px;
            padding-top: 65px;
        }
    }
    &__siteList {
        display: flex;
        justify-content: space-between;
        margin-bottom: 82px;
        @include media("blt") {
            flex-wrap: wrap;
            gap: 50px 0;
        }
        @include media("sp") {
            gap: 0;
            margin-bottom: 110px;
        }
    }
    &__siteItem {
        @include media("blt") {
            width: 33.3333333%;
        }
        @include media("sp") {
            text-align: center;
            width: 50%;
        }
    }
    &__siteLink {
        color: $blue;
        display: inline-block;
        font-size: 1.8rem;
        margin-bottom: 17px;
        transition: all 0.2s;
        @include media() {
            font-size: 1.6rem;
            margin-bottom: 26px;
        }
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
    &__siteLinkTxt {
        color: $blue;
        font-size: 1.8rem;
        margin-bottom: 17px;
        @include media() {
            font-size: 1.6rem;
            margin-bottom: 26px;
        }
    }
    &__subSiteList {
        @include media() {
            display: none;
        }
    }
    &__subSiteItem {
        &:not(:last-of-type) {
            margin-bottom: 12px;
        }
        &:last-of-type {
            .footer__smallSiteList {
                margin-bottom: 0;
            }
        }
    }
    &__subSiteLink {
        color: #fff;
        font-size: 1.4rem;
        transition: all 0.2s;
        @include media("blt") {
            font-size: 1.4rem;
        }
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
    &__smallSiteList {
        margin-bottom: 27px;
        margin-left: 20px;
        margin-top: 8px;
    }
    &__smallSiteItem {
        margin-bottom: 6px;
    }
    &__smallSiteLink {
        color: #fff;
        font-size: 1.4rem;
        margin-bottom: 32px;
        transition: all 0.2s;
        &:before {
            content: "-";
        }
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
    &__smallUnit {
        color: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include media() {
            justify-content: flex-start;
        }
    }
    &__privacyWrap {
        order: 2;
        @include media() {
            display: flex;
            flex-wrap: wrap;
            gap: 15px 0;
            margin-top: -110px;
            order: 1;
            position: absolute;
            width: calc(100% - 40px);
        }
    }
    &__smallItem {
        font-size: 1.4rem;
        text-decoration: underline;
        transition: all 0.2s;
        &:not(:last-of-type) {
            margin-right: 10px;
            @include media() {
                margin-right: 0px;
            }
        }
        @include media() {
            font-size: 1.2rem;
            text-align: center;
            text-decoration: underline;
            width: 50%;
        }
        &:hover {
            opacity: 0.4;
            text-decoration: none;
            @include media() {
                opacity: 1;
            }
        }
    }
    &__smallWrap {
        display: flex;
        flex-direction: column;
        order: 1;
        @include media() {
            order: 2;
        }
    }
    &__copy {
        margin-bottom: 16px;
        @include media() {
            font-size: 1.2rem;
            margin-bottom: 9px;
        }
    }
    &__caution {
        @include media("sp") {
            font-size: 1.2rem;
            line-height: 2.5;
            word-break: break-all;
        }
    }
    &__bnrUnit {
    }
    &__bnrList {
        display: grid;
        gap: 40px 40px;
        grid-template-columns: repeat(2, minmax(100px,300px));
        
        @include media(blt) {
            justify-content: center;
        }
        @include media() {
            column-gap: 20px;
            row-gap: 20px;
        }
    }
    &__bnrItem {
        border-radius: 3px;
        overflow: hidden;
    }
    &__bnrLink {
        display: block;
        transition: 0.2s;
        &:hover {
            opacity: 0.4;
            @include media() {
                opacity: 1;
            }
        }
    }
    &__bnrImg {
        display: block;
        width: 100%;
    }
    &__bnrItem_type_brochure {
        align-items: center;
        display: flex;
        grid-column: span 2;
        position: relative;
        transition: all 0.2s;
        width: 70%;
        @include media("blt") {
            width: 100%;
        }
        .footer__bnrLink {
            background: #438cc8;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            height: 90px;
            justify-content: center;
            padding: 20px clamp(25px, -2.057rem + 4.14vw, 54px);
            width: 100%;
            @include media("sp") {
                padding: 20px 35px;
            }
            @include media("se") {
                padding: 20px 15px;
            }
            &:before {
                background-color: #fff;
                border-radius: 9999px;
                content: "";
                height: 36px;
                position: absolute;
                right: 35px;
                top: 50%;
                transform: translateY(-50%);
                width: 36px;
                @include media("blt") {
                    height: 24px;
                    right: 23px;
                    top: 52.5%;
                    width: 24px;
                }
            }
            &:after {
                border-right: solid 2px $blue;
                border-top: solid 2px $blue;
                content: "";
                height: 10px;
                position: absolute;
                right: 53px;
                top: 49%;
                transform: rotate(45deg) translateY(-50%);
                width: 10px;
                @include media("blt") {
                    height: 8px;
                    right: 35px;
                    top: 51%;
                    width: 8px;
                }
                @include media("sp") {
                    height: 7px;
                    width: 7px;
                }
            
        }
        .footer__bnrLink:hover {
            opacity: 1;
        }
    }
    }
    &__bnrLinkSpan {
        &_lg_ja {
            font-size: clamp(2rem, 1.686rem + 0.29vw, 2.2rem);
            // font-size: 2.2rem;
            font-weight: bold;
            @include media() {
                font-size: 1.6rem;
                margin-bottom: 5px;
            }
        }
        &_lg_en {
            font-family: $mont;
            font-size: 1.4rem;
            font-weight: $semiBold;
            letter-spacing: 0.1em;
            @include media() {
                font-size: 1.2rem;
            }
        }
    }
}
