//////////////////////////////
// 募集要項 /app-guidelines/
//////////////////////////////
.appGuidelines {
    .lowerMiddleTtl {
        margin-bottom: 40px;
        @include media() {
            font-size: 2rem;
            margin-bottom: 30px;
        }
    }
    &__inner {
        padding-bottom: 130px;
        padding-top: 70px;
        @include media() {
            padding-bottom: 100px;
            padding-top: 50px;
        }
    }
    &__unit {
        & + & {
            margin-top: 100px;
            @include media() {
                margin-top: 60px;
            }
            &_has_hr {
                border-top: 1px solid #e6e6e6;
                margin-top: 80px;
                padding-top: 100px;
                @include media() {
                    margin-top: 60px;
                    padding-top: 60px;
                }
            }
        }
    }
    &__txt {
        font-size: 1.6rem;
        line-height: 2;
        margin: 30px 0 0;
        @include media() {
            margin: 20px 0 0;
        }
        &_size_18 {
            font-size: 1.8rem;
        }
        a {
            color: $blue;
            text-decoration: underline;
        }
    }
    &__annotation {
        font-size: 1.6rem;
        line-height: 2;
        margin: 30px 0 0;
        @include media() {
            font-size: 1.4rem;
            margin: 20px 0 0;
        }
    }
    &__markCircle {
        border: 1px solid;
        border-radius: 50%;
        display: block;
        margin: 0 auto;
        width: 18px;
        &::before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }
    &__list {
        margin: 30px 0 0;
        @include media() {
            margin: 20px 0 0;
        }
    }
    &__item {
        font-size: 1.6rem;
        line-height: 2;
        padding-left: 20px;
        position: relative;
        @include media() {
            font-size: 1.5rem;
        }
        &::before {
            background: $blue;
            border-radius: 50%;
            content: "";
            height: 5px;
            left: 0;
            position: absolute;
            top: 1em;
            transform: translateY(-50%);
            width: 5px;
        }
        &_type_btn {
            padding-left: 0px;
            &::before {
                content: none;
            }
        }
    }
    &__link {
        text-decoration: underline;
        transition: .2s;
        &:hover {
            opacity: 0.5;
            text-decoration: underline transparent;
        }
        &.moreBtn {
            @include media() {
                display: block;
                padding: 21px 73px 21px 90px;
            }
        }
    }

    &__rows {
        border-bottom: 1px solid #eaeaea;
        border-top: 1px solid #eaeaea;
    }
    &__row {
        display: flex;
        @include media() {
            display: block;
        }
        & + & {
            border-top: 1px solid #eaeaea;
        }
    }
    &__rowTtl {
        background: #f9f9f9;
        flex: 0 0 auto;
        font-size: 1.6rem;
        line-height: 2;
        padding: 32px 40px;
        position: relative;
        width: 260px;
        @include media() {
            padding: 24px 48px;
            width: 100%;
        }
        &::before {
            background: $blue;
            border-radius: 50%;
            content: "";
            display: block;
            height: 6px;
            margin: 1em 0 0 -17px;
            position: absolute;
            transform: translateY(-50%);
            width: 6px;
        }
    }
    &__rowData {
        flex: 1 1 auto;
        padding: 32px 40px;
        @include media() {
            padding: 24px 20px;
        }
    }

    &__table {
        table {
            border: 1px solid #f9f9f9;
            border-collapse: collapse;
            width: 100%;
            thead {
                th {
                    background: #f9f9f9;
                    border: 1px solid #eaeaea;
                    font-size: 1.6rem;
                    font-weight: normal;
                    line-height: 2;
                    padding: 12px 28px;
                    text-align: center;
                    vertical-align: middle;
                }
            }
            tbody {
                th {
                    background: #f9f9f9;
                    border: 1px solid #eaeaea;
                    font-size: 1.6rem;
                    font-weight: normal;
                    line-height: 2;
                    padding: 12px 40px;
                    position: relative;
                    text-align: left;
                    vertical-align: top;
                    width: 260px;
                    &::before {
                        background: $blue;
                        border-radius: 50%;
                        content: "";
                        display: block;
                        height: 6px;
                        margin: 1em 0 0 -17px;
                        position: absolute;
                        transform: translateY(-50%);
                        width: 6px;
                    }
                }
                td {
                    border: 1px solid #eaeaea;
                    padding: 12px 20px;
                    vertical-align: top;
                }
            }
        }

        &_for_guideline {
            @include media() {
                display: grid;
                margin: 0 -20px;
                overflow-x: auto;
                padding: 0 20px;
            }
            table {
                @include media() {
                    width: 1240px;
                }
                tbody {
                    td {
                        &:nth-of-type(1),
                        &:nth-of-type(2),
                        &:nth-of-type(3),
                        &:nth-of-type(4) {
                            vertical-align: middle;
                            width: 94px;
                        }
                        &.appGuidelines__td_width_134 {
                            vertical-align: middle;
                            width: 134px;
                        }
                        &.appGuidelines__td_width_200 {
                            min-width: 200px;
                        }
                    }
                }
            }
        }
    }

    &__infos {
        &_for_limit {
            .appGuidelines {
                &__info {
                    column-gap: 20px;
                    display: flex;
                }
                &__infoTtl {
                    flex: 0 0 auto;
                    width: 240px;
                    @include media() {
                        width: min(200px, 80%);
                    }
                }
                &__infoData {
                    flex: 1 1 auto;
                }
            }
        }
        &_for_entitle {
            .appGuidelines {
                &__info {
                    column-gap: 20px;
                    display: flex;
                    @include media() {
                        display: block;
                    }
                }
                &__infoTtl {
                    flex: 0 0 auto;
                    width: 240px;
                    @include media() {
                        width: 100%;
                    }
                }
                &__infoData {
                    flex: 1 1 auto;
                }
            }
        }
        &_for_schedule {
            column-gap: 20px;
            display: flex;
            flex-wrap: wrap;
            @include media() {
                display: block;
            }
            .appGuidelines {
                &__info {
                    column-gap: 40px;
                    display: flex;
                    @include media() {
                        column-gap: 20px;
                    }
                }
                &__infoTtl {
                    flex: 0 0 auto;
                    @include media() {
                        width: 90px;
                    }
                }
                &__infoData {
                    min-width: 220px;
                    @include media() {
                        flex: 1 1 auto;
                        min-width: 0;
                    }
                    &:only-child {
                        margin-left: calc(2em + 40px);
                        @include media() {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
    &__infoTtl {
        font-size: 1.6rem;
        line-height: 2;
    }
    &__infoData {
        font-size: 1.6rem;
        line-height: 2;
    }

    &__group {
        border: 5px solid #f2f2f2;
        border-radius: 6px;
        margin: 40px 0 0;
        padding: 38px 65px 48px;
        @include media() {
            border-width: 3px;
            margin: 30px 0 0;
            padding: 30px 17px 40px;
        }
        &_type_narrow {
            padding: 38px 45px;
            @include media() {
                padding: 30px 17px;
            }
        }
        &_type_boxShadow {
            border: none;
            box-shadow: 0 2px 4px rgba(#000, 0.08);
        }
    }
    &__grTtl {
        margin-bottom: 10px;
        @include media() {
            margin-bottom: 20px;
        }
        &.dotTtl {
            margin-left: -25px;
            @include media() {
                margin-left: 0;
            }
        }
        &[data-num] {
            font-size: 2.2rem;
            font-weight: $semiBold;
            letter-spacing: 0.05em;
            line-height: 2.6rem;
            padding: 0 0 0 50px;
            position: relative;
            @include media() {
                font-size: 2rem;
                line-height: 2.2rem;
            }
            &::before {
                color: $blue;
                content: attr(data-num);
                font-family: $mont;
                font-size: 4rem;
                font-weight: $semiBold;
                left: 6px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(9deg);
                @include media() {
                    font-size: 3.5rem;
                }
            }
        }
    }
    &__grTxt {
        font-size: 1.8rem;
        line-height: 2;
        @include media() {
            font-size: 1.6rem;
        }
    }
    &__grLink {
        color: $blue;
        text-decoration: underline;
        transition: .2s;
        &:hover {
            opacity: 0.5;
            text-decoration: underline transparent;
        }
        &_color_inherit {
            color: inherit;
            text-decoration: underline transparent;
        }
    }
    &__grAnnotation {
        color: #ed1c24;
        font-size: 1.4rem;
        line-height: 2;
        margin: 10px 0 0;
    }
    &__examLink {
        margin: 40px auto 100px;
        @include media() {
            display: block;
            margin: 20px auto 70px;
        }
    }
}
