//////////////////////////////
// 校歌 /song/
//////////////////////////////
.song {
    .lowerTtl {
        margin-bottom: 40px;
        @include media() {
            align-items: center;
            flex-direction: column;
        }
        &__ja {
            @include media("sp") {
                font-size: 2.2rem;
                margin-bottom: 10px;
                margin-right: 0px;
            }
            @include media("se") {
                font-size: 2rem;
            }
        }
        &__en {
            @include media() {
                font-size: 1.4rem;
            }
        }
    }
    .lowerMiddleTtl {
        margin-bottom: 26px;
        @include media() {
            margin-bottom: 36px;
        }
    }
    &__inner {
        padding-top: 100px;
        padding-bottom: 205px;
        @include media() {
            padding-top: 38px;
            padding-bottom: 80px;
        }
    }
    &__maker {
        font-size: 1.8rem;
        margin-bottom: 60px;
        @include media() {
            margin-bottom: 25px;
            text-align: center;
        }
    }
    &__playWrap {
        align-items: center;
        border-radius: 10px;
        box-shadow: 0 2px 4px 0px rgb(0 0 0 / 8%);
        display: flex;
        justify-content: center;
        padding: 26px 50px 32px;
        margin-bottom: 57px;
        @include media() {
            align-items: flex-start;
            border-radius: 6px;
            margin-bottom: 59px;
            padding: 17px clamp(10px, -0.143rem + 3.57vw, 26px) 20px;
        }
    }
    &__playInner {
        align-items: center;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        @include media() {
            align-items: flex-start;
            border-radius: 6px;
            flex-direction: column;
        }
        audio {
            @include media() {
                width: clamp(255px, 23.357rem + 6.7vw, 285px);
            }
        }
    }
    &__play {
        font-size: 2.2rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        margin-right: 64px;
        padding-left: 35px;
        position: relative;
        @include media() {
            font-size: 1.8rem;
            margin: 0 0 15px 0;
        }
        &:before {
            background: url(../images/icon_play.svg) no-repeat center / cover;
            content: "";
            display: block;
            height: 21px;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 17px;
        }
    }
    &__lyricsWrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 106px;
        @include media() {
            flex-wrap: wrap;
            margin-bottom: 46px;
        }
    }
    &__lyricsItem {
        width: calc(33.3333333% - 37px);
        @include media("tab") {
            width: calc(33.3333333% - 10px);
        }
        @include media("sp") {
            display: flex;
            padding: 0 clamp(10px, -0.643rem + 5.13vw, 33px);
            width: 100%;
            &:not(:last-of-type) {
                margin-bottom: 25px;
            }
        }
    }
    &__num {
        color: $blue;
        font-family: $mont;
        font-size: 2.4rem;
        font-weight: $semiBold;
        @include media() {
            margin-right: 30px;
        }
    }
    &__lyrics {
        font-size: 1.8rem;
        line-height: 2;
        white-space: nowrap;
        @include media("tab") {
            font-size: 1.5rem;
        }
        @include media("sp") {
            font-size: clamp(1.4rem, 1.257rem + 0.45vw, 1.6rem);
            line-height: 2.25;
        }
    }
    &__imgWrap {
        margin: 0 auto;
        width: 47.2%;
        @include media("tab") {
            width: 80%;
        }
        @include media("sp") {
            width: 100%;
        }
    }
}
