//////////////////////////////
// 合格発表 /result-publication/
//////////////////////////////
.resultPublication {
    .lowerMiddleTtl {
        margin-bottom: 20px;
        @include media() {
            font-size: 2rem;
            margin-bottom: 30px;
        }
    }
    .moreBtn {
        padding: 23px 96px 23px 136px;
        @include media() {
            padding: 19px 48px 19px 68px;
            width: 100%;
        }
        &::before {
            left: 27px;
            top: 50%;
            @include media() {
                left: 20px;
            }
        }
        &::after {
            left: 35px;
            @include media() {
                left: 28px;
            }
        }
    }
    &__inner {
        padding-bottom: 130px;
        padding-top: 70px;
        @include media() {
            padding-bottom: 100px;
            padding-top: 50px;
        }
    }
    &__unit {
        & + & {
            margin-top: 80px;
            @include media() {
                margin-top: 40px;
            }
        }
    }
    &__fig {
        box-shadow: 0 0 4px rgba(#000, .08);
        margin: 80px auto 0;
        max-width: 620px;
        text-align: center;
        @include media() {
            margin: 40px auto 0;
            width: calc(100% - 40px);
        }
    }
    &__txt {
        font-size: 1.6rem;
        line-height: 2;
        margin: 50px 0 0;
        text-align: center;
        @include media() {
            margin: 30px 0 0;
        }
        a {
            color: $blue;
            text-decoration: underline;
            transition: .2s;
            &:hover {
                opacity: 0.5;
                text-decoration: underline transparent;
            }
        }
    }
    &__btn {
        margin: 100px 0 0;
        @include media() {
            margin: 60px 0 0;
        }
    }
}
